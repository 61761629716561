import styled from '@emotion/styled';
import typography from 'styles/typography';
import zIndex from 'styles/zIndex';
import Checkbox from '../checkbox/Checkbox';
import { DropdownItemProps, StyledDropdownProps } from './Dropdown.consts';
import { Icon, IconProps } from '../icon';
import TextField from '../textField/TextField';

export const StyledDropdown = styled.ul<StyledDropdownProps>`
  position: absolute;
  top: 40px;
  margin: 0;
  padding: 0;
  border: ${({ theme }) => `1px solid ${theme.colors.global.inputDisabled}`};
  border-radius: ${typography.borderRadius}px;
  background: white;
  width: max-content;
  min-width: 100%;
  max-width: 290px;
  max-height: 190px;
  overflow: auto;
  z-index: ${zIndex.dropdown};
  box-shadow: ${({ theme }) => theme.colors.global.boxShadow};

  &:disabled {
    pointer-events: none;
    color: ${({ theme }) => theme.colors.global.inputDisabled};
  }
`;

export const StyledDropdownItem = styled.li<DropdownItemProps>`
  list-style: none;
  padding: 0 ${typography.unit * 3}px;
  height: ${typography.unit * 10}px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${({ selected, theme }) => selected && theme.colors.global.selectedBackground};
  color: ${({ selected, theme }) => selected && theme.colors.global.inputPrimary};

  &:hover {
    background: ${({ theme }) => theme.colors.global.selectedBackground};
  }
`;

export const StyledDropdownSearch = styled(StyledDropdownItem)<DropdownItemProps>`
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.global.background};
  width: 100%;
`;

export const StyledCheckbox = styled(Checkbox)`
  width: 100%;
  height: 100%;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
`;

export const StyledSearch = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.global.border};
  width: 16px;
`;

export const AddNew = styled(StyledDropdownItem)`
  border-top: ${({ theme }) => `1px solid ${theme.colors.global.inputDisabled}`};
  color: ${({ theme }) => theme.colors.text.primary};
  position: sticky;
  bottom: 0;
  background: ${({ theme }) => theme.colors.global.background};
`;

export const AddNewText = styled.span`
  font-size: 12px;
  margin-left: ${typography.unit}px;
`;
