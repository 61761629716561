import styled from '@emotion/styled/macro';
import typography from 'styles/typography';
import Accordion from '@mui/material/Accordion';
import { StyledWithThemeProps } from 'utils/types';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import { ButtonText } from 'components/shared/button';
import { AccordionArrow } from 'components/shared/accordion/Accordion.style';

export const AccordionHeader = styled.div<StyledWithThemeProps>`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.global.border};
  justify-content: space-between;
  padding: ${typography.unit}px 0 ${typography.unit * 2}px ${typography.unit}px;
  margin-bottom: ${typography.unit * 2}px;
`;

export const StyledAccordion = styled(Accordion)<{ expanded: boolean } & StyledWithThemeProps>`
  flex: ${({ expanded }) => `${expanded ? '1' : '0'}`};
  min-height: ${({ expanded }) => `${expanded ? '' : '51px !important'}`};
  width: 100%;
  margin: 0 !important;
  box-shadow: none !important;
  padding: 0 ${typography.unit * 3}px;

  :first-of-type {
    border-top: none !important;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)<StyledWithThemeProps>`
  padding: 0 !important;
  min-height: 50px !important;

  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledAccordionArrow = styled(AccordionArrow)`
  margin-right: ${typography.unit * 2}px;
  width: 12px;
`;

export const ListHeadline = styled.div`
  font-family: ${typography.primaryFont};
  font-weight: 500;
`;

export const SortSelectbox = styled(Selectbox)`
  font-size: 12px;
  padding-left: ${typography.unit}px;
` as unknown as typeof Selectbox;

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  padding: 0 !important;
`;

export const StyledButtonText = styled(ButtonText)`
  margin-left: ${typography.unit * 2}px;
  font-size: 12px;
`;
