import React, { useState } from 'react';
import { ButtonContained, ButtonText } from 'components/shared/button';
import TextField from 'components/shared/textField/TextField';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import usersGqls from 'pages/settings/userManagement/UserManagement.gqls';
import { ForgotPasswordFormProps } from './ForgotPasswordForm.consts';
import { LoginContainerBody } from './ForgotPasswordForm.style';
import { FormState } from '../../Login.consts';
import { Error as ErrorComponent } from '../../Login.style';

export const ForgotPasswordForm = ({ dispatch }: ForgotPasswordFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });
  const [mutate, { loading }] = useMutation(usersGqls.mutations.forgotPassword);
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [responseData, setResponseData] = useState('');
  const onSubmit = (data: any) => {
    mutate({
      variables: {
        data,
      },
    }).then((response) => {
      const responseData1: string = response?.data?.forgotPassword;
      setResponseData(responseData1);
      setEmail(data.email);
      setSubmitted(true);
    });
  };

  return (
    <LoginContainerBody>
      <h3>Forgot Password</h3>
      {!submitted || responseData === 'Your account is locked. Please contact admin for assistance' ? (
        <>
          <p>Enter your email and we will send you instructions to reset your password.</p>
          <TextField
            register={register}
            placeholder="Email"
            name="email"
            type="email"
            validation={{ required: true, pattern: /\S+@\S+\.\S+/ }}
          />
          <ButtonContained onClick={handleSubmit(onSubmit)} disabled={!isValid || loading}>
            Continue
          </ButtonContained>
          <ErrorComponent>{responseData}</ErrorComponent>
        </>
      ) : (
          <p>{responseData}</p>
      )}
      <ButtonText onClick={() => dispatch({ type: 'SET_FORM', payload: FormState.Login })}>Back to Login</ButtonText>    
    </LoginContainerBody>
  );
};
