import styled from '@emotion/styled';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import Error from '../error/Error';
import { StyleMultipleChoiceProps, MultipleChoiceProps } from './MultipleChoice.consts';

export const StyledContainer = styled.div<MultipleChoiceProps>`
  display: flex;
  flex-direction: ${({ labelIsHorizontal }) => labelIsHorizontal && 'column'};
  position: relative;
`;

export const StyledMultipleChoiceContainer = styled.ul<MultipleChoiceProps>`
  display: flex;
  margin: 0;
  padding: ${typography.unit * 2}px 0;
  font-size: 12px;
`;

export const ItemLabel = styled.label<StyledWithThemeProps>``;

export const ItemInput = styled.input<StyledWithThemeProps>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 22px;
  height: 21px;
  margin: 0;

  &:checked ~ label {
    background-color: ${({ disabled, theme }) =>
      disabled ? theme.colors.checkbox.disabledCheckedBg : theme.colors.global.inputPrimary};
    color: ${({ theme }) => theme.colors.text.white};
  }
`;

export const StyledChoiceItem = styled.div<StyleMultipleChoiceProps>`
  margin-right: ${typography.unit}px;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  color: ${({ theme, disabled }) => disabled && theme.colors.text.disabled};
  position: relative;

  label {
    background-color: ${({ theme }) => theme.colors.global.background};
    border: ${({ disabled, theme }) =>
      `1px solid ${disabled ? theme.colors.checkbox.disabledCheckedBg : theme.colors.global.inputPrimary}`};
    border-radius: ${typography.borderRadius}px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 21px;
  }

  input:hover ~ label {
    background-color: ${({ error, theme }) => `${error ? theme.colors.global.error : theme.colors.button.hover}`};
    color: ${({ theme }) => theme.colors.text.white};
  }
`;

export const StyledLabel = styled.label<MultipleChoiceProps>`
  text-transform: capitalize;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text.primary};
  display: flex;
  align-items: center;
  margin-right: ${({ labelIsHorizontal }) => !labelIsHorizontal && `${typography.unit * 3}px`};
  margin-bottom: ${({ labelIsHorizontal }) => labelIsHorizontal && `${typography.unit}px`};
`;

export const StyledError = styled(Error)`
  position: absolute;
  margin-top: ${typography.unit * 9}px;
`;
