import { client } from 'app/apollo';
import { PeriodProps } from 'pages/settings/schedulePeriods/SchedulePeriods.consts';
import { periodsGqls } from 'pages/settings/schedulePeriods/SchedulePeriods.gqls';

export const createNewPeriod = (period: PeriodProps) => {
  return client.mutate({
    mutation: periodsGqls.mutations.add,
    variables: {
      data: { ...period },
    },
    refetchQueries: ['Periods'],
  });
};

export const updatePeriod = async (period: Partial<PeriodProps>) => {
  return client.mutate({
    mutation: periodsGqls.mutations.update,
    variables: {
      data: { ...period },
    },
    refetchQueries: ['Periods'],
  });
};
