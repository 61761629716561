/* eslint-disable import/no-duplicates */
import React from 'react';
import { CalendarProps, EventPropGetter, EventProps } from 'react-big-calendar';
import { dateFnsLocalizer } from 'react-big-calendar';
import { enUS } from 'date-fns/locale';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import { getDateFromDateTime } from 'utils/date';
import { RecurrenceIcon, TriggerEventIcon, BellIcon } from './CalendarView.style';
import { EventInterface } from '../../Calendar.consts';
import { WeekEvent } from '../weekEvent/WeekEvent';
import { DateCellWrapper } from '../dateCellWrapper/DateCellWrapper';
import { MonthHeader } from '../monthHeader/MonthHeader';
import { WeekHeader } from '../weekHeader/WeekHeader';

export type CalenderViewMode = 'month' | 'week';
export interface CalendarViewProps {
  range: Date;
  periods: { startDate: Date; endDate: Date }[];
  campaigns: any[];
  view: CalenderViewMode;
  loading: boolean;
}

export interface ShowMoreDetails {
  date: Date;
  events: EventInterface[];
  top: number;
  left: number;
  width: number;
  height: number;
}

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
localizer.startOfWeek = () => 0;
localizer.formats.weekdayFormat = 'EEE';
localizer.formats.dateFormat = 'd';

export const onShowMore: (
  setShowMore: (showMore: ShowMoreDetails | null) => void,
) => (events: EventInterface[], date: Date) => void = (setShowMore) => {
  return (events, date) => {
    const rect = document.getElementById(`date-${getDateFromDateTime(date, 'default')}`)?.getBoundingClientRect();
    const width = Math.ceil(rect.width) * 1.5;
    const height = Math.ceil(rect.height) * 1.5;
    setShowMore({
      date,
      events,
      top: Math.ceil(rect.top) - 20 - Math.max(Math.ceil(rect.top) - 20 + height - window.innerHeight, 0),
      left: Math.max(
        Math.ceil(rect.left) -
          0.25 * Math.ceil(rect.width) -
          Math.max(Math.ceil(rect.left) - 0.25 * Math.ceil(rect.width) + width - window.innerWidth, 0),
        0,
      ),
      height,
      width,
    });
  };
};

export const eventPropGetter: EventPropGetter<EventInterface> = (e: EventInterface) =>
  e.isLocal ? { className: 'local-event' } : null;

export const eventTitle = (e: EventInterface) => {
  let truncatedTitle;
  if (e.pushNotificationFlag) {
    const title = typeof e?.title === 'string' ? e?.title : '';
    truncatedTitle = title.length > 23 ? title.slice(0, 23) + '...' : title;
  } else {
    truncatedTitle = e?.title;
  }

  return (
    <>
      {e.isTriggerEvent ? <TriggerEventIcon name="basketball" /> : ''}
      {e.recurrence?.length ? <RecurrenceIcon name="sync" /> : ''}
      {e.pushNotificationStartDate ? <BellIcon name="bellgray" /> : ''}
      {truncatedTitle}
    </>
  );
};

export const calendarProps: (
  setShowMore: (showMore: ShowMoreDetails | null) => void,
) => CalendarProps<EventInterface> = (setShowMore) => ({
  localizer,
  eventPropGetter,
  startAccessor: 'start',
  endAccessor: 'end',
  onNavigate: () => null,
  views: ['week', 'month'],
  defaultView: 'month',
  onView: () => null,
  onShowMore: onShowMore(setShowMore),
  messages: {
    showMore: (total) => `+${total} more..`,
  },
  components: {
    toolbar: () => null,
    event: (props: EventProps<EventInterface>) => {
      return eventTitle(props.event);
    },
    dateCellWrapper: DateCellWrapper,
    week: {
      header: WeekHeader,
      event: WeekEvent,
    },
    month: {
      dateHeader: MonthHeader,
    },
  },
});
