import styled from '@emotion/styled';
import typography from 'styles/typography';

export const StyledProductContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-right: ${typography.unit * 2}px;
`;

export const StyledProductTitle = styled.span`
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
