import styled from '@emotion/styled';

export const StyledDatesSelector = styled.div`
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(3, 1fr);
  row-gap: 30px;
  column-gap: 10px;
  justify-items: center;
  overflow: scroll;

  h4 {
    font-weight: 600;
  }
`;
