import { FormMode, StyledWithThemeProps } from 'utils/types';
import { TagProps } from 'pages/settings/tags/Tags.consts';

export const FormTitle: Partial<Record<FormMode, string>> = {
  [FormMode.New]: 'Add Tag',
  [FormMode.View]: 'View Tag',
  [FormMode.Edit]: 'Edit Tag',
};

export interface TagFormProps extends StyledWithThemeProps {
  mode: FormMode;
  tag?: TagProps;
}
