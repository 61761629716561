export enum LocationSetsFilters {
  SearchQuery = 'searchQuery',
  CustomSets = 'customSets',
  SavedOnExternal = 'savedOnExternal',
  OnlyRootSets = 'onlyRootSets',
  LocationSetsIds = 'id',
  ContainsLocationSetsIds = 'containsLocationSetsIds',
  LocationSetsExternalIds = 'locationSetsExternalIds',
  WithoutLocationsIds = 'withoutLocationsIds',
  LocationsIds = 'locationsIds',
  LocationsCodes = 'locationsCodes',
  ByRestaurantEligibility = 'byRestaurantEligibility',
  HideZeroLocations = 'hideZeroLocations',
}

export enum LocationSetFilters {
  id = 'id',
  name = 'name',
}
