import React, { useState } from 'react';
import TextPageTitle from 'components/shared/text/textPageTitle/TextPageTitle';
import { tabName } from 'components/header/Header.consts';
import { store } from 'app/store';
import { audit as auditSlice, getFilters, setOrder } from 'app/slices/audit';
import { useSelector } from 'react-redux';
import { Audit as AuditProps } from 'utils/types/audit';
import { formatDate } from 'utils/date';
import { DateTimeConfig, OrderDirection } from 'utils/types';
import { marketConfig } from 'app/slices/config';
import { auditActionRecord, auditActionRecordUpdates } from 'pages/reports/audit/Audit.consts';
import Table from 'components/shared/table/Table';
import { getAuditTableProps } from 'pages/reports/audit/AuditTableProps';
import { GridSortModel } from '@mui/x-data-grid-pro';
import isEqual from 'lodash/isEqual';
import { Actions, TableContainer, StyledHeader, StyledTotalAmountWithTable } from 'pages/shared/shared.style';
import DownloadCsvButton from 'pages/shared/downloadCsvButton/DownloadCsvButton';
import { useQuery } from '@apollo/client';
import { useToastError } from 'hooks/use-toast-error';
import { mapOrder } from 'utils/mapping';
import PageContainer from '../../shared/pageContainer/PageContainer';
import { AuditFilterBar } from './components/auditFilterBar/AuditFilterBar';
import auditGqls from './Audit.gqls';
import { client } from 'app/apollo';
import { LoaderWrapper } from 'pages/campaigns/calendar/components/calendarView/CalendarView.style';
import { Loader } from 'components/shared/loader';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import { FetchPolicies } from 'utils/types/common';

const Audit = () => {
  const { order, filters } = useSelector(auditSlice);
  const [isLoading, setIsLoading] = useState(false);
  const { data, error, fetchMore, loading } = useQuery(auditGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: getFilters(filters),
        order,
        limit: 25,
        queryKey: 'query1',
      },
    },
  });
  const {
    getAudit: { items: audit, total },
  } = data || { getAudit: { items: [] } };
  const { config } = useSelector(marketConfig);
  const { dateFormat, timeFormat, startTimezone } = config;
  const dateTimeConfig: DateTimeConfig = { dateFormat, timeFormat, startTimezone };
  const [gridSortModel, setGridSortModel] = useState<GridSortModel>(mapOrder(order));
  useToastError(error, 'Error loading audit');

  const createRows = async () => {
    const { data: data2 } = await client.query({
      query: auditGqls.queries.getAll,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          filters: getFilters(filters),
          order,
          limit: null,
          queryKey: 'query2',
        },
      },
    });
    const {
      getAudit: { items: newChanges },
    } = data2 || { getAudit: { items: [] } };
    const rows = [['User Name', 'Time', 'Action', 'Entity Type', 'Entity Name', 'Entity ID']];
    await newChanges?.forEach((auditRow: AuditProps) => {
      const entityType: string = auditRow.entityType === 'Period' ? 'Schedule Period' : auditRow.entityType;
      rows.push([
        `${auditRow.user.firstName} ${auditRow.user.lastName}`,
        `${formatDate(auditRow.createdAt, dateTimeConfig, startTimezone)}`,
        auditActionRecord[auditRow.action]?.name ? auditActionRecord[auditRow.action]?.name : auditActionRecordUpdates[auditRow.action]?.name,
        entityType,
        auditRow.entityName,
        auditRow.entityId,
      ]);
    });
    return rows;
  };

  const fetchNextAudit = async () => {
    if (audit?.length < total) {
      fetchMore({
        variables: {
          data: {
            filters: getFilters(filters),
            offset: audit.length,
            order,
            limit: 25,
            queryKey: 'query1',
          },
        },
      });
    }
  };

  const onSortModelChange = (sortModel: { [key: string]: OrderDirection }, gridSortModal: GridSortModel) => {
    if (!isEqual(sortModel, order)) {
      store.dispatch(setOrder(sortModel));
      setGridSortModel(gridSortModal);
    }
  };

  const updateLoadingState = (isLoadingValue: boolean) => {
    setIsLoading(isLoadingValue);
  };

  return (
    <PageContainer>
      <StyledHeader data-automation-id="header">
        <TextPageTitle text={tabName.Audit} />
        <Actions data-automation-id="actions">
          <AuditFilterBar />
          <DownloadCsvButton createRows={createRows} updateLoadingState={updateLoadingState} fileName="Audit" />
        </Actions>
      </StyledHeader>
      <TableContainer>
        {isLoading && (
          <LoaderWrapper>
            <Loader size={LoaderSize.Large} />
          </LoaderWrapper>
        )}
        <StyledTotalAmountWithTable amount={total} />
        <Table
          data-automation-id="audit-table"
          tableProps={{ ...getAuditTableProps(dateTimeConfig), rows: audit, loading }}
          onRowsScrollEnd={fetchNextAudit}
          getRowId={(row) => row.id}
          onSortModelChange={onSortModelChange}
          gridSortModel={gridSortModel}
        />
      </TableContainer>
    </PageContainer>
  );
};

export default Audit;
