import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AggregationType, Interval, RedemptionFilterType } from 'pages/reports/redemptions/Redemptions.consts';
import { clientQuery } from 'utils/api/clientQuery';
import { redemptionsGqls } from 'pages/reports/redemptions/Redemptions.gqls';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { getYesterday } from 'utils/date';
import type { RootState } from '../store';

export interface Redemptions {
  redemptions: { keys: string[]; data: any[] };
  filters: {
    [RedemptionFilterType.Type]: AggregationType;
    [RedemptionFilterType.CampaignExternalIds]: number[];
    [RedemptionFilterType.DateRangeStart]: string | Date | number;
    [RedemptionFilterType.DateRangeEnd]: string | Date | number;
    [RedemptionFilterType.Interval]?: Interval | null;
  };
  isLoading: boolean;
  shouldLoadRedemptions: boolean;
  isInitialEntrance: boolean;
}

export const initialState: Redemptions = {
  redemptions: null,
  filters: {
    [RedemptionFilterType.Type]: AggregationType.overtime,
    [RedemptionFilterType.CampaignExternalIds]: [],
    [RedemptionFilterType.DateRangeStart]: getYesterday().setHours(0, 0, 0),
    [RedemptionFilterType.DateRangeEnd]: new Date().setHours(23, 59, 59, 999),
    [RedemptionFilterType.Interval]: Interval.Hour,
  },
  isLoading: false,
  shouldLoadRedemptions: false,
  isInitialEntrance: true,
};

const getFilters = (initialFilters: { [key in RedemptionFilterType]?: any }): any => {
  return {
    type: initialFilters[RedemptionFilterType.Type],
    startDate: new Date(initialFilters[RedemptionFilterType.DateRangeStart]),
    endDate: new Date(initialFilters[RedemptionFilterType.DateRangeEnd]),
    campaignExternalIds: initialFilters[RedemptionFilterType.CampaignExternalIds],
    interval:
      initialFilters[RedemptionFilterType.Type] === AggregationType.hourAvg
        ? 'NthFifteenMinOfHour'
        : initialFilters[RedemptionFilterType.Interval],
  };
};

export const loadRedemptions = createAsyncThunk(
  'redemptions/load',
  async (payload, thunkAPI): Promise<{ redemptions: { keys: string[]; data: any[] } }> => {
    const {
      redemptions: { filters },
    } = thunkAPI.getState() as any;
    const redemptions = await clientQuery(redemptionsGqls.queries.getAll, {
      data: getFilters(filters),
    });

    return {
      redemptions: redemptions.data.getCampaignRedemptionsByIds as any as { keys: string[]; data: any[] },
    };
  },
);

export const redemptionsSlice = createSlice({
  name: 'redemptions',
  initialState,
  reducers: {
    setFilter(state, action) {
      state.filters = {
        ...state.filters,
        [action.payload.filter]: action.payload.value,
      };
    },
    setFilters(state, action) {
      const campaignIds = action.payload[RedemptionFilterType.CampaignExternalIds]
        ? (Array.isArray(action.payload[RedemptionFilterType.CampaignExternalIds])
            ? action.payload[RedemptionFilterType.CampaignExternalIds]
            : [action.payload[RedemptionFilterType.CampaignExternalIds]]
          ).map((id: string) => id)
        : [];
      state.filters = {
        [RedemptionFilterType.DateRangeStart]: getYesterday().setHours(0, 0, 0),
        [RedemptionFilterType.DateRangeEnd]: new Date().setHours(0, 0, 0),
        ...action.payload,
        [RedemptionFilterType.CampaignExternalIds]: campaignIds,
      };
    },
    setIsInitialEntrance(state, action) {
      state.isInitialEntrance = action.payload;
    },
    setShouldLoadRedemptions(state, action) {
      state.shouldLoadRedemptions = action.payload;
    },
    resetRedemptions(state) {
      state.redemptions = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadRedemptions.pending, (state) => {
      return {
        ...state,
        redemptions: { keys: [], data: [] },
        isLoading: true,
        shouldLoadRedemptions: false,
      };
    });

    builder.addCase(loadRedemptions.fulfilled, (state, action) => {
      return {
        ...state,
        redemptions: action.payload.redemptions,
        isLoading: false,
        shouldLoadRedemptions: false,
      };
    });

    builder.addCase(loadRedemptions.rejected, (state) => {
      showToast(MessageType.Error, 'Error loading redemptions');
      return { ...state, redemptions: { keys: [], data: [] }, isLoading: false, shouldLoadRedemptions: false };
    });
  },
});

export const redemptions = (state: RootState) => state.redemptions;
export const { resetRedemptions, setFilter, setFilters, setIsInitialEntrance, setShouldLoadRedemptions } =
  redemptionsSlice.actions;

export default redemptionsSlice.reducer;
