import styled from '@emotion/styled';
import typography from 'styles/typography';
import { PeriodStatus } from 'pages/settings/schedulePeriods/SchedulePeriods.consts';
import { StyledWithThemeProps } from 'utils/types';

// region Schedule Periods table
export const StatusWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const StatusCircle = styled.div<StyledWithThemeProps & { status: PeriodStatus }>`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin-right: ${typography.unit * 4}px;
  background: ${({ status, theme }) =>
    status === PeriodStatus.NotStarted
      ? theme.colors.global.background
      : status === PeriodStatus.Live
      ? theme.colors.global.success
      : status === PeriodStatus.Expired && theme.colors.text.disabled};
  border: ${({ status, theme }) => status === PeriodStatus.NotStarted && `1px solid ${theme.colors.global.success}`};
`;

// endregion
