import styled from '@emotion/styled';
import { LoginContainerBody as _LoginContainerBody } from '../../Login.style';

export const LoginContainerBody = styled(_LoginContainerBody)``;
export const PasswordSection = styled.div`
  display: flex;
  flex-direction: column;

  button {
    justify-content: flex-end;
  }
`;
