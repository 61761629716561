import styled from '@emotion/styled';
import typography from 'styles/typography';

export const Footer = styled.footer`
  display: flex;
  margin-top: ${typography.unit * 5}px;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: ${typography.unit * 6}px;
`;
