import React from 'react';
import { ToggleItemProps } from './ToggleItem.consts';
import { Label, ToggleItemWrapper } from './ToggleItem.style';

const ToggleItem = ({ label, isSelected, onClick, disabled, className }: ToggleItemProps) => {
  return (
    <ToggleItemWrapper disabled={disabled} onClick={onClick} className={className} isSelected={isSelected}>
      <Label disabled={disabled}>{label}</Label>
    </ToggleItemWrapper>
  );
};

export default ToggleItem;
