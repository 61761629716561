import { appTheme } from 'styles/themes/defaultTheme';
import { GenericItem } from 'utils/types';

export enum AdornmentPosition {
  Start = 'start',
  End = 'end',
}
export interface DropdownItemProps {
  selected?: boolean;
  theme?: appTheme;
}

export interface StyledDropdownProps extends DropdownItemProps {
  className?: string;
}

export interface DropdownProps<T extends GenericItem> extends StyledDropdownProps {
  items: T[];
  onClick: (item: T) => void;
  selectedItems?: { [key in number | string]: T };
  className?: string;
  multiple?: boolean;
  withSearch?: boolean;
  withAmount?: boolean;
  filterFunction?: (q: string) => (item: T) => boolean;
  onCreateOption?: (s: string) => void;
  titleFormatter?: (item: T) => string;
  onServerSearchChange?: (query: string) => void;
}
