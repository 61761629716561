import React, { useEffect } from 'react';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { CampaignAlert, CampaignAlertLabel } from 'utils/types/campaigns';
import { ApprovalStatus, CampaignApprovalStatusLabel } from 'utils/types';
import { hideTooltip } from 'utils/tooltip';
import {
  AlertIcon,
  CommentIcon,
  IconTooltipWrapper,
  StyledAdditionalStatusIcon,
  StyledAdditionalStatusLabel,
  StyledSeparator,
  StyledStatusLabel,
  SubtitleContainer,
  TriggerEventIcon,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/campaignStatusLabel/CampaignFormSubtitle.style';
import { CampaignStatusLabelProps } from 'pages/campaigns/campaignManagement/components/campaignForm/components/campaignStatusLabel/CampaignFormSubtitle.consts';
import ReactTooltip from 'react-tooltip';

const CampaignFormSubtitle = ({ status, alert, comment, isTriggerEvent }: CampaignStatusLabelProps) => {
  const isStopAssociation = status === ApprovalStatus.AssociationStopped;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <SubtitleContainer>
      <StyledStatusLabel type={isStopAssociation ? ApprovalStatus.Active : status} />
      {alert ? (
        <>
          <IconTooltipWrapper data-for="alert-tooltip" data-tip>
            <AlertIcon name="error" />
            {CampaignAlertLabel[alert as CampaignAlert]}
          </IconTooltipWrapper>
          {isStopAssociation && <StyledSeparator />}
          {comment && (
            <Tooltip
              id="alert-tooltip"
              place="bottom"
              content={comment}
              onClose={() => {
                hideTooltip('#alert-tooltip');
                ReactTooltip.hide();
              }}
            />
          )}
        </>
      ) : (
        <>
          {comment && (
            <IconTooltipWrapper data-for="comment-tooltip" data-tip>
              <CommentIcon name="error" />
            </IconTooltipWrapper>
          )}
          <Tooltip
            id="comment-tooltip"
            place="bottom"
            content={comment}
            onClose={() => {
              hideTooltip('#comment-tooltip');
            }}
          />
        </>
      )}
      {isStopAssociation && (
        <StyledAdditionalStatusLabel>
          <StyledAdditionalStatusIcon name="associationStopped" />
          {CampaignApprovalStatusLabel[status]}
        </StyledAdditionalStatusLabel>
      )}
      {isTriggerEvent && (
        <StyledAdditionalStatusLabel>
          <TriggerEventIcon name="basketball" />
          Trigger Event
        </StyledAdditionalStatusLabel>
      )}
    </SubtitleContainer>
  );
};

export default CampaignFormSubtitle;
