import React from 'react';
import { FieldValues, UseFormGetValues } from 'react-hook-form';
import { store } from 'app/store';
import { Modals, openModal } from 'app/slices/modals';
import { FormMode, GenericItem, GenericSet, ProductSetType, SetOfEntity } from 'utils/types';
import { Product, ProductBagInfo } from 'utils/types/products';
import { ButtonText } from 'components/shared/button';
import ProductItem from 'pages/settings/productSets/components/productItem/ProductItem';
import ProductSetTitle from 'pages/settings/productSets/components/productSetTitle/ProductSetTitle';
import { AccordionContentType } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import { SelectedAccordionProps } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/selectedSetItemsListPanel/SelectedSetItemsListPanel.consts';
import { searchProduct, searchSet } from 'utils/product';
import { hideTooltip } from 'utils/tooltip';
import ReactTooltip from 'react-tooltip';
import SetItemsSelectionForm from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm';
import { getAllItemsRecursively } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.utils';
import { StyledButtonText, StyledEditIcon, StyledViewIcon } from '../Template.style';
import { StyledProductTooltip } from '../../../OfferForm.style';

const getProductsAction = (
  disabled: boolean,
  buyGetProducts: ProductBagInfo,
  getValues: UseFormGetValues<FieldValues>,
  textLabel: string,
  productsKey?: string,
  mapProducts?: any,
  onProductSelection?: any,
  isOptional = false,
  setType?: ProductSetType,
  familyGroup?: string,
) => {
  const selectedProductsSetsSectionProps: SelectedAccordionProps = {
    name: 'selected-products-sets',
    headline: 'Sets',
    contentType: AccordionContentType.Set,
    searchItem: searchProduct as (item: GenericItem, searchQuery: string) => boolean,
    searchItemSet: searchSet as (set: GenericSet, searchQuery: string) => boolean,
    filterItemSet: () => true,
  };
  const selectedProductsSectionProps: SelectedAccordionProps = {
    name: 'selected-products',
    headline: 'Products',
    contentType: AccordionContentType.Items,
    searchItem: searchProduct as (item: GenericItem, searchQuery: string) => boolean,
    searchItemSet: searchSet as (set: GenericSet, searchQuery: string) => boolean,
    filterItemSet: () => true,
  };
  const tooltipKeyID = `products_tooltip_${productsKey.replaceAll('.', '')}`;
  if (buyGetProducts) {
    if (!disabled) {
      return (
        <ButtonText
          key={productsKey}
          disabled={disabled}
          onClick={() => {
            onProductSelection(
              productsKey,
              (data: any) => {
                store.dispatch(
                  openModal({
                    modal: Modals.OfferModal,
                    data: { templateProducts: mapProducts(data) },
                  }),
                );
              },
              isOptional,
              setType,
              familyGroup,
            );
          }}
          data-title="Edit"
        >
          <StyledEditIcon name="edit" />
        </ButtonText>
      );
    }

    const excludedItemsByIdMap = new Map<number, GenericItem>(
      getAllItemsRecursively(buyGetProducts.productSets, SetOfEntity.Products).map((item) => [Number(item.id), item]),
    );

    return (
      <>
        <StyledProductTooltip
          id={tooltipKeyID}
          place="bottom"
          onClose={() => {
            hideTooltip(`#${tooltipKeyID}`);
            ReactTooltip.hide();
          }}
          eventOff={null}
        >
          <SetItemsSelectionForm
            data-automation-id="selected-items-container"
            setOf={SetOfEntity.Products}
            itemSetTitleFormatter={ProductSetTitle}
            selectedAccordionsProps={[selectedProductsSetsSectionProps, selectedProductsSectionProps]}
            itemFormatter={(product: Product) => ProductItem(product, null)}
            formMode={FormMode.QuickView}
            supportSetFringes={false}
            itemSet={
              {
                sets: buyGetProducts.productSets,
                products: buyGetProducts.products,
                excludedItems: buyGetProducts.excludedProductsIds.map((productId) =>
                  excludedItemsByIdMap.get(productId),
                ),
              } as GenericSet
            }
          />
        </StyledProductTooltip>
        <StyledButtonText data-for={tooltipKeyID} data-tip onClick={() => null}>
          <StyledViewIcon name="view" />
        </StyledButtonText>
      </>
    );
  }
  return (
    <ButtonText
      disabled={disabled}
      onClick={() =>
        onProductSelection(
          productsKey,
          (data: any) => {
            store.dispatch(
              openModal({
                modal: Modals.OfferModal,
                data: { templateProducts: mapProducts(data) },
              }),
            );
          },
          isOptional,
          setType,
          familyGroup,
        )
      }
    >
      {textLabel}
    </ButtonText>
  );
};

export default getProductsAction;
