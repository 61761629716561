import { useSelector } from 'react-redux';
import { modal as modalSlice } from 'app/slices/modals';
import { useEffect } from 'react';

export default function useQueryInterval(
  interval: number,
  startPolling: (ms: number) => void,
  stopPolling: () => void,
) {
  const { modal } = useSelector(modalSlice);

  useEffect(() => {
    if (modal === null) {
      startPolling(interval);
    } else {
      stopPolling();
    }
    return () => stopPolling();
  }, [modal]);
}
