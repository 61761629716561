import React, { useState } from 'react';
import { SortingKey } from 'utils/sorting';
import { useFormContext } from 'react-hook-form';
import { SetItemsSelectionFormState } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import { ActionType } from 'utils/types';
import ListItem from 'pages/shared/setItemsSelectionForm/listItem/ListItem';
import { SelectedItemsAccordionProps } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/selectedSetItemsListPanel/selectedItemsAccordion/SelectedItemsAccordion.consts';
import { useItemsFilter } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/selectedSetItemsListPanel/selectedItemsAccordion/hooks/useItemsFilter';
import { Virtuoso } from 'react-virtuoso';
import { StyledItemsSelectionAccordion } from '../../SetItemsListPanel.style';

const SelectedItemsAccordion = ({
  accordion,
  isSingleAccordionPanel,
  itemFormatter,
  onItemSelect,
  removeAll,
  searchValue,
  onExcludeItemClick,
  items,
  disabled: disabled,
}: SelectedItemsAccordionProps) => {
  const [expanded, setExpanded] = useState(true);
  const [sortingKey, setSortingKey] = useState(SortingKey.Az);
  const { watch } = useFormContext<SetItemsSelectionFormState>();
  const selectedItemsById = watch('selectedItemsById') ?? items ?? {};
  const { name, headline, sortOptions, searchItem } = accordion;
  const itemsBySearchValue = useItemsFilter(Object.values(selectedItemsById), searchValue, searchItem, sortingKey);

  return (
    <StyledItemsSelectionAccordion
      listName={name}
      headline={headline}
      showExpandArrow={!isSingleAccordionPanel}
      expanded={expanded}
      onChange={(event, isExpanded) => setExpanded(isExpanded)}
      onSortChange={(listName, key) => setSortingKey(key)}
      removeAll={removeAll ? () => removeAll(itemsBySearchValue) : null}
      sortOptions={sortOptions}
      disabled={disabled}
    >
      <Virtuoso
        data={itemsBySearchValue}
        itemContent={(index, item) => (
          <ListItem
            key={`p-${item.id}`}
            item={item}
            actionType={ActionType.Remove}
            onClick={onItemSelect}
            itemFormatter={itemFormatter}
            onExcludeClick={onExcludeItemClick}
          />
        )}
      />
    </StyledItemsSelectionAccordion>
  );
};

export default SelectedItemsAccordion;
