import React from 'react';
import {
  SetChildrenCounter,
  SetNameContainer,
  StyledSetTitleContainer,
} from 'components/shared/set/SetListItemHeader/SetListItemHeader.style';
import { GenericSetTitleFormatterProps } from 'components/shared/set/SetListItemHeader/SetListItemHeader.consts';

const ProductSetTitle = ({ set }: GenericSetTitleFormatterProps) => {
  return (
    <StyledSetTitleContainer>
      <SetNameContainer>{set.name}</SetNameContainer>
      <SetChildrenCounter>{`${set.totalItems} Product${set.totalItems > 1 ? 's' : ''}`}</SetChildrenCounter>
    </StyledSetTitleContainer>
  );
};

export default ProductSetTitle;
