import styled from '@emotion/styled';
import TextField from 'components/shared/textField/TextField';
import typography from '../../../styles/typography';

export const SetItemsSelectionFormContainer = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
  gap: ${typography.unit * 9}px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledNameInputContainer = styled.div`
  display: flex;
  margin-bottom: ${typography.unit * 4}px;
`;

export const SetName = styled(TextField)`
  width: 490px;
`;
