import React from 'react';
import { StyledTitle } from './TextPageTitle.style';
import { TextTitleProps } from './TextPageTitle.const';

const TextPageTitle = (props: TextTitleProps) => {
  const { text, className } = props;
  return <StyledTitle className={className}>{text}</StyledTitle>;
};

export default TextPageTitle;
