import styled from '@emotion/styled';
import MultipleChoice from 'components/shared/multipleChoose/MultipleChoice';
import typography from 'styles/typography';
import TextField from 'components/shared/textField/TextField';
import { DateTimePicker } from 'components/shared/picker/datetimePicker/DateTimePicker';
import { TimePicker } from 'components/shared/picker/timePicker/TimePicker';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import { StyledWithThemeProps } from 'utils/types';
import { Icon } from 'components/shared/icon';
import ToggleSwitch from 'components/shared/toggleSwitch/ToggleSwitch';

export const SectionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const Section = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: ${typography.unit * 5}px;
`;

export const StyledDateTimePicker = styled(DateTimePicker)`
  margin-right: ${typography.unit * 5}px;

  [data-automation-id~='error-message'] {
    margin-top: ${typography.unit * 5}px;
  }
`;

export const StyledTimePicker = styled(TimePicker)`
  margin-right: ${typography.unit * 5}px;
  align-items: center;

  [data-automation-id~='error-message'] {
    margin-top: ${typography.unit * 5}px;
  }
`;

export const ScheduleContainer = styled.div<{ hasExtraMargin?: boolean }>`
  display: flex;
  margin-bottom: ${({ hasExtraMargin }) => (hasExtraMargin ? `${typography.unit * 7}px` : `${typography.unit * 3}px`)};

  div:last-of-type {
    margin-right: 0;
  }
`;

export const StyledMultipleChoice = styled(MultipleChoice)`
  margin-right: ${typography.unit * 5}px;
`;

export const ExpirationDate = styled(TextField)`
  min-width: 50px;
  input {
    width: 50px;
  }
`;

export const RecurrenceRow = styled.div`
  display: flex;
  align-items: center;
`;

export const SubHeader = styled.div`
  display: flex;
  margin-bottom: ${typography.unit}px;
`;

export const ToggleTitle = styled.div`
  margin-right: ${typography.unit * 2}px;
`;

export const RecurrenceSelect = styled(Selectbox)`
  margin-right: ${typography.unit * 5}px;
`;

export const Redemption = styled.div<StyledWithThemeProps>`
  display: flex;
  color: ${({ theme }) => theme.colors.text.primary};
  align-items: center;
  margin-right: ${typography.unit * 4}px;
`;

export const NumberField = styled(TextField)`
  margin-right: ${typography.unit * 2}px;
  min-width: 50px;
  input {
    width: 50px;
  }
  label {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

export const RedemptionsText = styled.span<StyledWithThemeProps>`
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledIcon = styled(Icon)`
  width: 16px;
  fill: ${({ theme }) => theme.colors.global.inputPrimary};
  margin: ${typography.unit * 4}px 0;
  align-self: center;
`;

export const StyledRemoveIcon = styled(StyledIcon)`
  margin: ${typography.unit * 3}px 0;
`;

export const StyledEditIcon = styled(StyledIcon)`
  margin: 0 ${typography.unit * 3}px 0 0;
`;

export const BulkSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${typography.unit}px;
`;

export const MultipleDatesToggle = styled(ToggleSwitch)`
  margin-bottom: ${typography.unit}px;
`;

export const SelectedDatesSection = styled.div`
  display: flex;
  align-items: center;
`;

export const MultipleDatesSection = styled.div`
  display: flex;
  align-items: center;
  min-height: 29.63px;
  margin-bottom: ${typography.unit * 3}px;
`;

export const DatesSelection = styled.div`
  display: flex;
  align-items: center;
  margin: 0 ${typography.unit * 20}px;
`;
