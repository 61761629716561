import styled from '@emotion/styled';

import { LoginContainerBody as _LoginContainerBody } from '../../Login.style';
import typography from 'styles/typography';
import zIndex from 'styles/zIndex';
import { StyledWithThemeProps } from 'utils/types';

export const LoginContainerBody = styled(_LoginContainerBody)`
  h3 {
    font-weight: 600;
    text-align: center;
  }
`;
