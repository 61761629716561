import styled from '@emotion/styled';
import TextField from 'components/shared/textField/TextField';
import { Icon, IconProps } from '../icon';

export const StyledIcon = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.global.border};
`;

export const StyledTextField = styled(TextField)`
  width: 240px;
`;
