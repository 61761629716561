import { client } from 'app/apollo';
import { GenericEntity } from 'utils/types';
import dealPriorityConfiguration from 'pages/settings/dealPriority/DealPriorityConfiguration.gqls';
import { DealPriority } from 'pages/settings/dealPriority/components/DealPriorityConfiguration.const';

export const getDealPriority = async () => {
  const result = await client.query<{ getDealPriority: GenericEntity }>({
    query: dealPriorityConfiguration.queries.getAllDealPriority,
    fetchPolicy: 'network-only',
  });

  return result.data.getDealPriority;
};

export const updateDealPriority = async (dealPriorities: DealPriority[]) => {
  return client.mutate({
    mutation: dealPriorityConfiguration.mutations.update,
    refetchQueries: ['DealPriority'],
    variables: {
      data: { dealPriorities },
    },
  });
};
