import React, { useEffect, useState } from 'react';
import { ApprovalStatus, ApprovalStatusWithLabel, ImpactApprovalStatusLabel } from 'utils/types';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import { isInArray } from 'utils/array';
import {
  StyledContainer,
  StyledFilterSection,
  StyledImpactCounter,
  StyledImpactList,
  StyledTableContainer,
  StyledTitle,
  StyledTitleSection,
} from './ImpactItem.style';
import { Impact, ImpactItemProps } from './ImpactItem.consts';
import Table from '../../shared/table/Table';
import { store } from 'app/store';
import { ImpactFiltersEum } from 'app/genericSlices/impacts';

const ImpactItem = ({
  impactType,
  impactStatuses,
  impacts,
  setImpacts,
  tableProps,
  className,
  totalLength,
  onRowsScrollEnd,
  impactSlice,
}: ImpactItemProps) => {
  const [filteredImpacts, setFilteredImpacts] = useState<Impact[]>([]);

  const handleStatusFilters = (statuses: { id: string | number; name: string }[]) => {
    const filtered = statuses.length ? impacts.filter((impact: Impact) => isInArray(statuses, impact.status)) : impacts
    setFilteredImpacts(filtered);
    setImpacts(filtered)
  };

  useEffect(() => {
    setFilteredImpacts(impacts);
  }, [impacts]);

  return (
    <StyledImpactList className={className} data-automation-id="impact-item">
      <StyledTitleSection>
        <StyledTitle>{impactType}s</StyledTitle>
      </StyledTitleSection>
      <StyledContainer>
        <StyledFilterSection>
          <Selectbox
            name="impact-item"
            items={
              [
                ...impactStatuses
                  .filter((status) => status !== ApprovalStatus.AssociationStopped)
                  .map((status) => {
                    return {
                      id: status,
                      name: ImpactApprovalStatusLabel[status as ApprovalStatusWithLabel],
                    };
                  }),
              ] as any[]
            }
            selectWidth={180}
            allowSelectAll
            allSelected
            multiple
            maxItems={1}
            onChange={(selected: any) => {
              const selectedStatuses = selected?.map((s: any) => s.id);
              if (impactSlice) {
                store.dispatch(
                  impactSlice.actions.setFilter({ filter: ImpactFiltersEum.Status, value: selectedStatuses ?? [] }),
                );
              } else {
                handleStatusFilters(selectedStatuses);
              }
            }}
          />
          <StyledImpactCounter>Total results of {totalLength || filteredImpacts.length}</StyledImpactCounter>
        </StyledFilterSection>
        <StyledTableContainer data-automation-id="table">
          <Table
            tableProps={{ ...tableProps, ...{ rows: filteredImpacts } }}
            onRowsScrollEnd={onRowsScrollEnd}
            scrollEndThreshold={100}
          />
        </StyledTableContainer>
      </StyledContainer>
    </StyledImpactList>
  );
};

export default ImpactItem;
