const zIndex = {
  hoverBackdrop: 1,
  dropdown: 2,
  backdrop: 100,
  modal: 200,
  modalLoading: 210,
  toast: 230,
  tooltip: 250,
};

export default zIndex;
