import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorProps } from './Error.consts';
import { StyledError } from './Error.style';

const Error = ({ className, errors, name }: ErrorProps) => {
  return typeof errors === 'string' ? (
    <StyledError className={className} data-automation-id="error">
      {errors}
    </StyledError>
  ) : errors !== undefined ? (
    <ErrorMessage
      name={name}
      errors={errors}
      render={({ message }) =>
        message ? (
          <StyledError className={className} data-automation-id="error-message">
            {message}
          </StyledError>
        ) : null
      }
    />
  ) : null;
};

export default Error;
