import { gql } from '@apollo/client';

export const periodsGqls = {
  queries: {
    getAll: gql`
      query Periods($data: GetAllQueryDto) {
        getPeriods(data: $data) {
          items {
            ... on Period {
              id
              name
              startDate
              endDate
            }
          }
          total
        }
      }
    `,
    getPeriodsSchedulePeriodsPage: gql`
      query Periods($data: GetAllQueryDto) {
        getPeriodsSchedulePeriodsPage(data: $data) {
          items {
            ... on Period {
              id
              name
              startDate
              endDate
              status
            }
          }
          total
        }
      }
    `,
    getTotal: gql`
      query Periods($data: GetAllQueryDto) {
        getPeriods(data: $data) {
          total
        }
      }
    `,
    getPeriodsForZone: gql`
      query PeriodsForZone($zoneId: Int) {
        getPeriodsForZone(zoneId: $zoneId) {
          ... on Period {
            id
            name
            startDate
            endDate
            hasCampaigns
          }
        }
      }
    `,
    getPeriodsForNational: gql`
      query PeriodsForNational {
        getPeriodsForNational {
          ... on Period {
            id
            name
            startDate
            endDate
            hasCampaigns
          }
        }
      }
    `,
    getNextPeriod: gql`
      query NextPeriod {
        getNextPeriod {
          id
          name
          startDate
          endDate
          hasCampaigns
        }
      }
    `,
    getById: gql`
      query Period($id: Int!) {
        getPeriod(id: $id) {
          id
          name
          startDate
          endDate
          hasCampaigns
        }
      }
    `,
    getPeriodSchedulePeriodsPage: gql`
      query Period($id: Int!) {
        getPeriodSchedulePeriodsPage(id: $id) {
          id
          name
          startDate
          endDate
          hasCampaigns
        }
      }
    `,
  },
  mutations: {
    add: gql`
      mutation CreatePeriod($data: CreatePeriodDto!) {
        createPeriod(data: $data) {
          id
        }
      }
    `,
    update: gql`
      mutation UpdatePeriod($data: UpdatePeriodDto!) {
        updatePeriod(data: $data) {
          id
        }
      }
    `,
  },
};
