import React, { useState } from 'react';
import {
  CalenderWrapper,
  Container,
  Footer,
  StyledModal,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/multipleDatesSelection/MultipleDatesSelection.style';
import { MultipleDatesSelectionProps } from 'pages/campaigns/campaignManagement/components/campaignForm/components/multipleDatesSelection/MultipleDatesSelection.consts';
import { MessageType } from 'components/shared/notifications/notifications';
import Banner from 'components/shared/notifications/banner/Banner';
import { ButtonContained, ButtonText } from 'components/shared/button';
import { DatesSelector } from 'components/shared/datesSelector';

const MultipleDatesSelection = ({ isTriggerEvent, selectedDates, onSave, onCancel, datesToShow }: MultipleDatesSelectionProps) => {
  const [selected, setSelected] = useState(selectedDates || []);
  return (
    <StyledModal
      title={isTriggerEvent ? 'Event Date Selection' : 'Start Date Selection'}
      subtitle={
        isTriggerEvent && (
          <Banner type={MessageType.Info}>
            <b>Note:</b> Select days where trigger events occur. The Campaign start date will be on the following day.
          </Banner>
        )
      }
    >
      <Container>
        <CalenderWrapper>
          <DatesSelector selectedDates={selectedDates} datesToShow={datesToShow} onChange={(dates) => setSelected(dates)} />
        </CalenderWrapper>
        <Footer>
          <ButtonText onClick={onCancel}>Cancel</ButtonText>
          <ButtonContained disabled={!selected} onClick={() => onSave(selected)}>
            Save
          </ButtonContained>
        </Footer>
      </Container>
    </StyledModal>
  );
};

export default MultipleDatesSelection;
