import React from 'react';
import { Container, StyledButtonClose, StyledIcon, StyledMsg } from './Toast.style';
import { ToastProps } from './Toast.const';
import { IconNameByType } from '../notifications';

const Toast = ({ type, children, closeToast, className, staticImport = false }: ToastProps) => {
  return (
    <Container className={className} type={type} data-automation-id="toast">
      <StyledIcon name={IconNameByType[type]} type={type} staticImport={staticImport}/>
      <StyledMsg>{children}</StyledMsg>
      {closeToast && <StyledButtonClose onClick={closeToast} />}
    </Container>
  );
};

export default Toast;
