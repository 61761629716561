import styled from '@emotion/styled';
import { Icon, IconProps } from 'components/shared/icon';
import { defaultTheme } from 'styles/themes/defaultTheme';
import { ButtonOutlined } from 'components/shared/button';
import typography from 'styles/typography';

export const StyledButton = styled(ButtonOutlined)`
  padding: ${typography.unit * 2}px;
`;

export const StyledDownload = styled(Icon)<IconProps>`
  fill: ${defaultTheme.colors.global.inputPrimary};
  width: 16px;
`;
