import React from 'react';
import { CampaignApprovalStatuses } from 'utils/types/campaigns';
import { OfferApprovalStatuses } from 'utils/types/offers';
import { ArchiveStatus } from 'pages/settings/settings.const';
import { StyledImpactItem, StyledImpactTables, StyledImpactTablesContainer } from './ImpactsTables.style';
import { TermImpactTablesProps } from './ImpactsTables.consts';
import { ImpactType } from '../impactItem/ImpactItem.consts';
import { getColumns } from './ImpactTablesColumns';

const ImpactTables = ({
  campaignImpacts,
  setCampaginImpacts,
  offerImpacts,
  setOfferImpacts,
  imageImpacts,
  setImageImpacts,
  entityType,
  className,
}: TermImpactTablesProps) => {
  return (
    <StyledImpactTablesContainer className={className}>
      <StyledImpactTables>
        {Boolean(campaignImpacts?.length) && (
          <StyledImpactItem
            impactType={ImpactType.Campaign}
            impactStatuses={CampaignApprovalStatuses}
            impacts={campaignImpacts}
            setImpacts={setCampaginImpacts}
            tableProps={{
              columns: getColumns(ImpactType.Campaign, entityType),
              rows: campaignImpacts,
            }}
          />
        )}
        {Boolean(offerImpacts?.length) && (
          <StyledImpactItem
            impactType={ImpactType.Offer}
            impactStatuses={OfferApprovalStatuses}
            impacts={offerImpacts}
            setImpacts={setOfferImpacts}
            tableProps={{
              columns: getColumns(ImpactType.Offer, entityType),
              rows: offerImpacts,
            }}
          />
        )}
        {Boolean(imageImpacts?.length) && (
          <StyledImpactItem
            impactType={ImpactType.Image}
            impactStatuses={Object.values(ArchiveStatus)}
            impacts={imageImpacts}
            setImpacts={setImageImpacts}
            tableProps={{
              columns: getColumns(ImpactType.Image, entityType),
              rows: imageImpacts,
            }}
          />
        )}
      </StyledImpactTables>
    </StyledImpactTablesContainer>
  );
};

export default ImpactTables;
