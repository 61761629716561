import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { ButtonType } from 'components/shared/inputFiles/InputFiles.consts';
import InputFiles from 'components/shared/inputFiles/InputFiles';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { hasValue, pluralize } from 'utils/text';
import { ButtonContained, ButtonText } from 'components/shared/button';
import { bulkUpdateImages, createNewImages } from 'utils/api/images';
import { store } from 'app/store';
import { closeModal } from 'app/slices/modals';
import InfoTooltip from 'components/shared/tooltip/info/InfoTooltip';
import { hideTooltip } from 'utils/tooltip';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import {
  FormContent,
  Forms,
  ImageFormContainer,
  RemoveButton,
  RemoveIcon,
  StyledImagesFormModal,
} from './ImagesForm.style';
import { ImagesFormProps } from './ImagesForm.consts';
import { onTagCreation, preventFormSendOnEnter, readImages } from '../../images.utils';
import {
  ImageContainer,
  ImageDescription,
  ImageName,
  ImagesFormContainer,
  ImageTags,
  ImageUploadContainer,
  StyledButtonsContainer,
  StyledImage,
  StyledImagesBanner,
} from '../shared.style';
import { ImageProps } from '../../Images.consts';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { CheckboxWithTooltip } from '../imageForm/ImageForm.style';
import { UploadError, isNumber } from 'utils/types';
import { findIndex, isString } from 'lodash';
import { ValidationMessages } from 'utils/types/common';

const ImagesForm = ({ uploadedImages, removedImagesCount, className }: ImagesFormProps) => {
  const { config } = useSelector(marketConfig);
  const { load: loadTags, tags } = useTagsQuery([TagsValidEntities.Image]);
  const [images, setImages] = useState(uploadedImages);
  const [isImagesBulk, setIsImagesBulk] = useState(false);
  const [isBulkUploadRetry, setIsBulkUploadRetry] = useState(false);
  const [shouldClearHookForm, setShouldClearHookForm] = useState(false);
  const [indicestoClear, setIndicestoClear] = useState([]);
  const [isBonusCampaign, setIsBonusCampaign] = useState(Array(images.length).fill(false));
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    trigger,
    setValue,
    getValues,
    unregister,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      images: images.reduce((imageRecord, image, index) => ({ ...imageRecord, [index]: image }), {}),
    } as any,
  });

  const onSave = async (data: Record<string, ImageProps[]>) => {
    try {
      const imagesEntries = Object.entries(data.images);
      const imagesData = imagesEntries.map(([i, image]:any) => {
        delete image.src;
        image.file = images[+i].file as any;
        image.isBonusCampaign = isBonusCampaign[+i];
        image.tags = image.tags?.map((tag: any)=>{
          if(isNumber(tag) || isString(tag)){
            return Number(tag);
          }else{
             const id = isString(tag.id) ? parseInt(tag.id, 10) : tag.id;
            return { ...tag, id };
          }
        })
        return image
      });
      if(isImagesBulk || isBulkUploadRetry) {
          const response = await bulkUpdateImages(imagesData);
          const imageResponses = response.data?.bulkUpdateImages;
          processBulkUploadResponse(imageResponses);
      }
      else {
        await createNewImages(imagesData);
        showToast(MessageType.Success, `Image${pluralize(imagesData)} added successfully`);
        store.dispatch(closeModal());
      }
    } catch (e) {
      //  We are adding the full message from the backend since its can be bulk images upload.
      showToast(MessageType.Error, e.message);
    }
  };

  const processBulkUploadResponse = (imageResponse: Array<any>) => {
    let InternalUploadFails = 0;
    let ExternalUploadFails = 0;
    setIsBulkUploadRetry(true);
    imageResponse.forEach((image) => {
      if (image?.error === UploadError.InternalBucketError) {
        InternalUploadFails++;
      }
      if (image?.error === UploadError.ExternalBucketError) {
        ExternalUploadFails++;
      }
    });
    if (InternalUploadFails > 0) {
      showToast(MessageType.Error, 'Below images are not uploaded, Please try again');
      updateImageData(imageResponse);
    } else if (ExternalUploadFails > 0) {
      showToast(
        MessageType.Error,
        'Below images are not uploaded as Bonus campaigns, Please check the Bonus check box and try saving it again',
      );
      updateImageData(imageResponse);
    } else {
      showToast(MessageType.Success, `Image${pluralize(imageResponse)} added successfully`);
      store.dispatch(closeModal());
    }
  };

  const updateImageData = (imageResponse: any[]) => {
    const successfulImages = imageResponse.filter((image) => image.error === UploadError.NoError);
    const partialSuccessImages = imageResponse.filter((image) => image.error !== UploadError.NoError && image.id);
    const imagesFormData = getValues('images');
    const imagesFormDataValues = Object.values(imagesFormData);
    const imagesKeys: any[] = Object.keys(imagesFormData);
    const indicesToRemove: number[] = [];
    successfulImages.forEach((image) => {
      const index = findIndex(imagesFormDataValues, ['name', image.name]);
      if (index !== -1) {
        indicesToRemove.push(imagesKeys[index]);
      }
    });
    removeMultipleImages(indicesToRemove);

    partialSuccessImages.forEach((image) => {
      const index = findIndex(imagesFormDataValues, ['name', image.name]);
      if (index !== -1) {
        const key = imagesKeys[index];
        register(`images.${key}.id`, { value: image.id });
      }
    });
    setIsBonusCampaign(Array(images.length).fill(false));
  };

  const onFilesChange = async (files: FileList, index: number) => {
    const loadedImages = await readImages(files, config.bulkImagesUploadLimit);
    if (loadedImages && loadedImages[0]) {
      const { file } = loadedImages[0];
      const updatedImages = [...images];
      updatedImages[index] = loadedImages[0];
      setImages(updatedImages);
      setValue(`images.${index}.file`, file);
    }
  };

  const onRemoveImageFromBulk = (index: number) => {
    const updatedImages = [...images];
    delete updatedImages[index];
    unregister(`images.${index}`);
    setImages(updatedImages);
    trigger();
  };

  const removeMultipleImages = (indices: number[]) => {
    const updatedImages = [...images];
    indices.forEach((index) => {
      delete updatedImages[index];
    });
    setImages(updatedImages);
    setShouldClearHookForm(true);
    setIndicestoClear(indices);
  };

  useEffect(() => {
    if (shouldClearHookForm && indicestoClear.length > 0) {
      indicestoClear.forEach((index) => {
        unregister(`images.${index}`);
      });
      trigger();
      setShouldClearHookForm(false);
      setIndicestoClear([]);
    }
  }, [images, shouldClearHookForm, indicestoClear]);

  useEffect(() => {
    setIsImagesBulk(images?.filter((i) => i)?.length > 1);
  }, [images]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isDirty]);

  useEffect(() => {
    loadTags();
  }, []);

  return (
    <StyledImagesFormModal title={isImagesBulk ? `Add ${images?.filter((i) => i)?.length} Images` : 'Add Image'}>
      {removedImagesCount > 0 && (
        <StyledImagesBanner type={MessageType.Warning}>
          Limit {config.bulkImagesUploadLimit} images, {removedImagesCount} were not included.
        </StyledImagesBanner>
      )}
      <ImagesFormContainer
        className={className}
        onKeyDown={(e) => preventFormSendOnEnter(e)}
        data-automation-id="images-form"
      >
        <Forms isImagesBulk={isImagesBulk}>
          {images?.map((image, index) => {
            if (!image?.name) {
              return null;
            }
            const { name, src, file } = image;
            const imageId = index;
            setValue(`images.${imageId}.file`, file);

            return (
              <ImageFormContainer key={imageId} isImagesBulk={Boolean(isImagesBulk)}>
                {isImagesBulk && (
                  <RemoveButton onClick={() => onRemoveImageFromBulk(index)}>
                    <RemoveIcon name="remove" />
                  </RemoveButton>
                )}
                <ImageUploadContainer>
                  <ImageContainer>
                    <StyledImage src={src} alt={name} crossOrigin="anonymous" />
                  </ImageContainer>
                  <InputFiles
                    buttonType={ButtonType.Text}
                    handleFiles={(files: FileList) => onFilesChange(files, index)}
                    multiple={false}
                    filesType="image/*"
                  >
                    Upload Image
                    <InfoTooltip content="Image size should be smaller than 100KB. Supported file types: png, jpg, jpeg, gif." />
                  </InputFiles>
                </ImageUploadContainer>
                <FormContent>
                  <ImageName
                    register={register}
                    errors={errors}
                    value={name}
                    name={`images.${imageId}.name`}
                    label="Image Name"
                    placeholder="Enter"
                    validation={{
                      required: ValidationMessages.RequiredField,
                      maxLength: { value: 200, message: 'Up to 200 characters' },
                      validate: (value: string) => (!hasValue(value) ? ValidationMessages.RequiredField : true),
                    }}
                    labelIsHorizontal
                  />
                  <ImageTags
                    key={`${Boolean(tags.length)}`}
                    control={control}
                    errors={errors}
                    name={`images.${imageId}.tags`}
                    label="Tags"
                    placeholder="Select"
                    multiple
                    items={tags}
                    maxItems={2}
                    limit={10}
                    withSearch
                    onCreateOption={(tagName: string) => onTagCreation(tagName)}
                    labelIsHorizontal
                    reset
                  />
                  <ImageDescription
                    register={register}
                    errors={errors}
                    name={`images.${imageId}.description`}
                    label="Description"
                    placeholder="Enter"
                    validation={{
                      maxLength: { value: 200, message: 'Up to 200 characters' },
                    }}
                    rows={3}
                    labelIsHorizontal
                  />
                  <CheckboxWithTooltip>
                    <Checkbox
                      checked={isBonusCampaign[index]}
                      label="Bonus Campaign"
                      onClick={() => {
                        const updatedIsBonusCampaign = [...isBonusCampaign];
                        updatedIsBonusCampaign[index] = !updatedIsBonusCampaign[index];
                        setIsBonusCampaign(updatedIsBonusCampaign);
                      }}
                    />
                    <InfoTooltip
                      id="bonusCampaignTooltip"
                      className="test"
                      content="Bonus campaign images are instantly uploaded and made available, while regular offer images are only available after the offer is approved."
                    />
                  </CheckboxWithTooltip>
                </FormContent>
              </ImageFormContainer>
            );
          })}
        </Forms>
        <StyledButtonsContainer>
          <ButtonText data-tip data-for="cancel-tooltip" onClick={() => null}>
            Cancel
          </ButtonText>
          <Tooltip
            id="cancel-tooltip"
            content="Are you sure you want to cancel?"
            onDisapproveClick={() => {
              hideTooltip('#cancel-tooltip');
            }}
            onApproveClick={() => store.dispatch(closeModal())}
          />
          <ButtonContained onClick={handleSubmit(onSave)} disabled={!isValid}>
            Save
          </ButtonContained>
        </StyledButtonsContainer>
      </ImagesFormContainer>
    </StyledImagesFormModal>
  );
};

export default ImagesForm;
