import styled from '@emotion/styled';
import typography from 'styles/typography';
import { ButtonText } from '../button';
import Chip from '../chip/Chip';
import { IconProps, Icon } from '../icon';
import Tooltip from '../tooltip/Tooltip';

export const StyledTags = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
`;

export const TagsList = styled.div`
  display: flex;
  flex: 0 1;
  flex-direction: column;
  justify-content: flex-end;
`;

export const StyledMore = styled(Icon)<IconProps>`
  height: 14px;
  width: 14px;
  transform: rotate(90deg);
  color: white;
`;

export const StyledButtonText = styled(ButtonText)`
  height: 22px;
  margin-left: ${typography.unit * 2}px;
`;

export const StyledChip = styled(Chip)`
  margin-top: ${typography.unit}px;
  max-width: fit-content;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledTooltip = styled(Tooltip)`
  &[data-id='tooltip'] {
    &::before,
    &::after {
      display: none;
    }

    &.place-bottom {
      margin-top: 0;
    }
  }
`;
