import styled from '@emotion/styled';
import typography from 'styles/typography';
import TextArea from 'components/shared/textArea/TextArea';
import TextField from 'components/shared/textField/TextField';
import RadioGroup from 'components/shared/radioGroup/RadioGroup';
import ControlledCheckbox from 'components/shared/controlledCheckbox/ControlledCheckbox';
import Checkbox from 'components/shared/checkbox/Checkbox';

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${typography.unit * 4}px;
  align-items: baseline;
`;

export const NewFormRow = styled.div`
  display: flex;
  margin-bottom: ${typography.unit * 4}px;
  margin-top: ${typography.unit * 4}px;
  align-items: baseline;
`;

export const CampaignDescription = styled(TextArea)`
  width: 100%;
  display: flex;
`;

export const CampaignName = styled(TextField)<{ isGeneratedName: boolean }>`
  width: 295px;
  opacity: ${({ isGeneratedName }) => (isGeneratedName ? 0.5 : 1)};
`;

export const VoucherDistribution = styled(RadioGroup)`
  width: 295px;
  align-self: center;
`;

export const StyledCheckbox = styled(ControlledCheckbox)<{ nameHasError: boolean }>`
  margin-top: ${({ nameHasError }) => (nameHasError ? `${typography.unit * 4}px` : `${typography.unit}px`)};
`;

export const StyledPriorityCheckbox = styled(Checkbox)`
  margin-top: ${typography.unit * 4}px;
  padding-left: 4rem;
  align-self: flex-end;
`;
