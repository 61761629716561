import React from 'react';
import { ButtonBaseProps } from 'components/shared/button/buttonBase/ButtonBase.consts';
import { StyledButton, StyledPlus } from './AddNewButton.style';

const AddNewButton = ({ onClick, className }: ButtonBaseProps) => {
  return (
    <>
      <StyledButton onClick={onClick} className={className} data-title="Add New">
        <StyledPlus name="plus" />
      </StyledButton>
    </>
  );
};

export default AddNewButton;
