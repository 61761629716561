import styled from '@emotion/styled';
import typography from 'styles/typography';
import TextField from 'components/shared/textField/TextField';

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VoucherConfigRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${typography.unit * 4}px;
  min-height: 45px;
`;

export const GamingTypeWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-left: 75px;
`;

export const Quantity = styled(TextField)`
  width: 295px;
`;
