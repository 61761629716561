import { GenericItem } from 'utils/types';
import { useEffect, useState } from 'react';
import { SortBy, SortingKey } from 'utils/sorting';

export const useItemsFilter = (
  items: GenericItem[],
  searchValue: string,
  searchItem: (item: GenericItem, searchValue: string) => boolean,
  sortingKey: SortingKey,
) => {
  const [filteredIndividualItems, setFilteredIndividualItems] = useState(items);

  const filterItems = (itemsToFilter: GenericItem[]) => {
    return itemsToFilter.filter((item) => searchItem(item, searchValue)).sort(SortBy[sortingKey]);
  };

  useEffect(() => {
    setFilteredIndividualItems(filterItems(items));
  }, [JSON.stringify(items), searchValue]);

  return filteredIndividualItems;
};
