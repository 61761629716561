import {
  LastUpdated,
  Number,
  SideOverviewContent,
  SideOverviewHeader,
  StatusSmallBox,
} from 'pages/dashboard/Dashboard.style';
import { formatDate } from 'utils/date';
import { tabName } from 'components/header/Header.consts';
import React from 'react';
import {
  Separator,
  UpdatesOverviewContainer,
  UpdatesOverviewSubHeader,
  UpdatesSection,
} from 'pages/dashboard/components/updatesOverview/UpdatesOverview.style';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { UpdatesOverviewProps } from 'pages/dashboard/components/updatesOverview/UpdatesOverview.consts';

const UpdatesOverview = ({ updates, routeToSpecificPath }: UpdatesOverviewProps) => {
  const { config } = useSelector(marketConfig);
  const { dateFormat, timeFormat, startTimezone } = config;

  return (
    <UpdatesOverviewContainer data-automation-id="updates">
      <SideOverviewHeader>Updates</SideOverviewHeader>
      <SideOverviewContent>
        <UpdatesSection data-automation-id="updates-products">
          <UpdatesOverviewSubHeader>Products</UpdatesOverviewSubHeader>
          <LastUpdated>
            Last Update:
            {updates.products.lastUpdated &&
              ` ${formatDate(updates.products.lastUpdated, { dateFormat, timeFormat }, startTimezone)}`}
          </LastUpdated>
          <StatusSmallBox color="blue" onClick={() => routeToSpecificPath(tabName.ProductChanges)}>
            <Number>{updates.products.productsUpdated}</Number>
            <span>Product Updates</span>
          </StatusSmallBox>
          <span>{updates.products.campaignsImpacted} Campaigns Impacted</span>
          <span>{updates.products.offersImpacted} Offers Impacted</span>
        </UpdatesSection>
        <Separator />
        <UpdatesSection data-automation-id="updates-locations">
          <UpdatesOverviewSubHeader>Locations</UpdatesOverviewSubHeader>
          <LastUpdated>
            Last Update:
            {updates.locations.lastUpdated &&
              ` ${formatDate(updates.locations.lastUpdated, { dateFormat, timeFormat }, startTimezone)}`}
          </LastUpdated>
          <StatusSmallBox color="blue" onClick={() => routeToSpecificPath(tabName.LocationChanges)}>
            <Number>{updates.locations.locationsUpdated}</Number>
            <span>Location Updates</span>
          </StatusSmallBox>
          <span>{updates.locations.campaignsImpacted} Campaigns Impacted</span>
        </UpdatesSection>
      </SideOverviewContent>
    </UpdatesOverviewContainer>
  );
};
export default UpdatesOverview;
