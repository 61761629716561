import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import { Product, ProductBagInfo, ProductSet } from 'utils/types/products';

export const searchProduct = (product: Product, searchQuery: string): boolean =>
  product.plu?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
  product.name.toLowerCase().includes(searchQuery.toLowerCase());

export const searchSet = (productSet: ProductSet, stringToSearch: string): boolean =>
  productSet.externalId.toString().toLowerCase().includes(stringToSearch.toLowerCase()) ||
  productSet.name.toLowerCase().includes(stringToSearch.toLowerCase());

const getUniqueProducts = (productBag: ProductBagInfo): Product[] => {
  if (!productBag) {
    return undefined;
  }

  let result: Product[] = [];
  if (productBag.productSets?.length > 0) {
    result = [...productBag.productSets.flatMap((set: ProductSet) => set.products)];
  }

  if (productBag.products?.length > 0) {
    result = [...result, ...productBag.products];
  }

  const excludedProductsIdsSet = new Set<number>(productBag.excludedProductsIds);
  return uniqBy(result, (p) => p.id).filter((p) => !excludedProductsIdsSet.has(Number(p.id)));
};

export const calcProductsHint = (productBag: ProductBagInfo) => {
  const products = getUniqueProducts(productBag);
  if (products?.length > 0) {
    const product = products[0];
    return `${product.plu} - ${product.name} ${products.length > 1 ? `+${products.length - 1}` : ''}`;
  }

  return '';
};

export const isSameProducts = (buy: any, get: any) => {
  return !buy || !get ? false : isEqual(buy, get);
};
