import React, { useEffect, useState } from 'react';
import TextPageTitle from 'components/shared/text/textPageTitle/TextPageTitle';
import { tabName } from 'components/header/Header.consts';
import { Loader } from 'components/shared/loader';
import { store } from 'app/store';
import { Modals, openModal } from 'app/slices/modals';
import { useSelector } from 'react-redux';
import { FormMode, OrderDirection } from 'utils/types';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { BaseOffer } from 'utils/types/offers';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import PageContainer from 'pages/shared/pageContainer/PageContainer';
import AddNewButton from 'pages/shared/addNewButton/AddNewButton';
import { termsConditionsSelection } from 'app/genericSlices/termsConditions';
import { offersPage } from 'app/genericSlices/offers';
import { useQuery } from '@apollo/client';
import { useToastError } from 'hooks/use-toast-error';
import { MarketConfigurationGuard } from 'components/zoneGuard/MarketConfigurationGuard';
import { MarketConfigurationKey } from 'pages/configurations/Configurations.consts';
import useQueryInterval from 'hooks/use-query-polling';
import { ButtonsContainer, OffersGrid, OffersGridContainer, ZonesButton, ZonesIcon } from './Offers.style';
import { Actions, StyledHeader, StyledLoader, StyledTotalAmountWithGrid } from '../shared/shared.style';
import OfferItem from './components/offerItem/OfferItem';
import { OfferFilterBar } from './components/offerFilterBar/OfferFilterBar';
import offersGqls from './Offers.gqls';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { FetchPolicies } from 'utils/types/common';
import { LoaderWrapper } from 'pages/campaigns/calendar/components/calendarView/CalendarView.style';

const Offers = () => {
  const { filters } = useSelector(offersPage.offersState);
  const [isOfferArchieving, setIsOfferArchieving] = useState(false);
  const { data, error, fetchMore, startPolling, stopPolling } = useQuery(offersGqls.queries.getOffersScreenData, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: offersPage.getFilters(filters),
        order: { id: OrderDirection.DESC },
        limit: 24,
      },
    },
  });
  const {
    getOffers: { items: offers, total },
  } = data || { getOffers: { items: undefined } };
  useToastError(error, 'Error loading offers');
  useQueryInterval(600000, startPolling, stopPolling);

  const onCreateNewOffer = () => {
    store.dispatch(
      openModal({
        modal: Modals.OfferModal,
        props: { mode: FormMode.New },
      }),
    );
  };

  const onZonesButtonClick = () => {
    store.dispatch(
      openModal({
        modal: Modals.ZonesManagementModal,
      }),
    );
  };

  const fetchNextOffers = async () => {
    if (offers.length < total) {
      await fetchMore({
        variables: {
          data: {
            filters: offersPage.getFilters(filters),
            offset: offers.length,
            limit: 24,
            order: { id: OrderDirection.DESC },
          },
        },
      });
    }
  };

  useEffect(() => {
    store.dispatch(termsConditionsSelection.loadTerms());
  }, []);
  return (
    <PageContainer>
      <StyledHeader data-automation-id="header">
        <TextPageTitle text={tabName.Offers} />
        <Actions data-automation-id="actions">
          <OfferFilterBar />
          <ButtonsContainer>
            <MarketConfigurationGuard
              configurations={[{ configKey: MarketConfigurationKey.EnableManagementByZone, value: true }]}
            >
              <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                <ZonesButton onClick={onZonesButtonClick}>
                  <ZonesIcon name="mapPin" />
                </ZonesButton>
              </RoleGuard>
            </MarketConfigurationGuard>
            <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
              <AddNewButton onClick={onCreateNewOffer} />
            </RoleGuard>
          </ButtonsContainer>
        </Actions>
      </StyledHeader>
      {!offers ? (
        <StyledLoader size={LoaderSize.Large} />
      ) : (
        <>
          <StyledTotalAmountWithGrid amount={total} />
          {Boolean(offers?.length) && (
            <OffersGrid id="offers-scroll">
              {isOfferArchieving && (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              )}
              <OffersGridContainer
                scrollableTarget="offers-scroll"
                dataLength={offers?.length}
                next={fetchNextOffers}
                hasMore={offers?.length < total}
                loader={<Loader />}
              >
                {offers?.map((offer: BaseOffer) => {
                  return <OfferItem key={offer.id} offer={offer} setIsOfferArchieving={setIsOfferArchieving} />;
                })}
              </OffersGridContainer>
              <Tooltip id={"offer-lock-icon"} onHover content="Locked for changes" place="bottom" />
            </OffersGrid>
          )}
        </>
      )}
    </PageContainer>
  );
};

export default Offers;
