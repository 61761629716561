import styled from '@emotion/styled';
import typography from 'styles/typography';
import { ButtonText } from '../button';
import Error from '../error/Error';
import { AdornmentPosition, StyleTextFieldProps } from './TextField.consts';

export const StyledContainer = styled.div<StyleTextFieldProps>`
  display: flex;
  flex-direction: ${({ labelIsHorizontal }) => labelIsHorizontal && 'column'};
  align-items: ${({ labelIsHorizontal }) => !labelIsHorizontal && 'center'};
  width: inherit;
`;

export const InputContainer = styled.div<StyleTextFieldProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.global.background};
`;

export const StyledLabel = styled.label<StyleTextFieldProps>`
  font-size: 13px;
  color: ${({ theme, disabled }) => (!disabled ? theme.colors.text.primary : theme.colors.text.disabled)};
  display: flex;
  align-items: start;
  margin-right: ${typography.unit * 3}px;
  white-space: nowrap;
`;

export const StyledError = styled(Error)`
  position: absolute;
  margin-top: ${typography.unit * 7}px;
  margin-left: ${typography.unit / 2}px;
  white-space: nowrap;
`;

export const InputWrapper = styled.div<StyleTextFieldProps>`
  display: flex;
  flex-direction: ${({ adornmentPosition }) => (adornmentPosition === AdornmentPosition.Start ? 'row' : 'row-reverse')};
  align-items: center;
  border-bottom: 1px solid ${({ theme, error }) => (error ? theme.colors.global.error : theme.colors.global.border)};
  color: ${({ theme }) => theme.colors.text.primary};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  &:hover {
    border-bottom: 1px solid
      ${({ theme, disabled, error }) =>
        disabled ? theme.colors.global.inputDisabled : error ? theme.colors.global.error : theme.colors.text.disabled};
  }

  &:focus-within {
    border-bottom: 1px solid
      ${({ theme, error }) => (error ? theme.colors.global.error : theme.colors.global.inputPrimary)};
  }
`;

export const StyledInput = styled.input<StyleTextFieldProps>`
  border: none;
  background: none;
  outline: none;
  font-size: ${typography.fontSize}px;
  font-family: ${typography.primaryFont};
  flex: 1;
  color: inherit;
  height: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &::placeholder {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`;

export const StyledChildren = styled.div`
  display: flex;
`;

export const StyledViewButton = styled(ButtonText)`
  position: absolute;
  right: ${typography.unit * 2}px;

  svg {
    color: ${({ theme }) => theme.colors.text.secondary};
    width: 18px;
  }
`;
