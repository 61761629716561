import { client } from 'app/apollo';
import { GenericEntity } from 'utils/types';

export const clientQuery = async (query: any, variables: any): Promise<{ data: { [key: string]: GenericEntity } }> => {
  return client.query({
    query,
    fetchPolicy: 'network-only',
    variables,
  });
};
