import { gql } from '@apollo/client';

export const configurationsGqls = {
  queries: {
    getAll: gql`
      query Configurations {
        getConfigurations
      }
    `,
  },
  mutations: {
    update: gql`
      mutation UpdateConfiguration($data: JSONObject!) {
        updateConfiguration(data: $data) {
          id
          key
          value
        }
      }
    `,
  },
};
