import React, { useEffect, useState } from 'react';
import { DiscountCondition, DiscountConditionRecord, DiscountType, DoubleDippingRuleRecord } from 'utils/types/offers';
import { useFormContext } from 'react-hook-form';
import { calcProductsHint } from 'utils/product';
import useDiscountType from 'hooks/use-discount-type';
import { marketConfig } from 'app/slices/config';
import { useSelector } from 'react-redux';
import {
  ProductPicker,
  StyledForSelectbox,
  StyledLabel,
  StyledLabelUpto,
  StyledLightLabel,
  StyledSelectbox,
  StyledTextField,
} from '../Template.style';
import { DiscountTypeRecord, TemplateProps } from '../Template.consts';
import getProductsAction from '../shared/ProductsActionCondition';
import { Currency } from '../shared/Currency/Currency';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { ProductSetType } from 'utils/types';
import { store } from 'app/store';
import { setData } from 'app/slices/modals';
import { checkForEmptyData } from '../shared/ProductsActionCondition.utils';
import InfoTooltip from 'components/shared/tooltip/info/InfoTooltip';

const selectProductsTextLabel = 'Select Products';
const templateValueIncludeNFPC = 'versions.0.templateValues.includeNonFoodProductCodes';

export const OrderDiscount = ({ disabled, onProductSelection }: TemplateProps) => {
  const {
    control,
    register,
    watch,
    getValues,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext();

  const { config } = useSelector(marketConfig);
  const { isPercent, shouldDisplayCurrency } = useDiscountType();
  const [
    orderDiscountType,
    conditionProducts,
    excludeCodes,
    discountCondition,
    conditionValue,
    doubleDipping,
    includeNonFoodProductCodes,
  ] = watch([
    'versions.0.templateValues.orderDiscountType',
    'versions.0.templateValues.conditionProducts',
    'versions.0.templateValues.excludeCodes',
    'versions.0.templateValues.discountCondition',
    'versions.0.templateValues.conditionValue',
    'versions.0.templateValues.doubleDipping',
    templateValueIncludeNFPC,
  ]);
  const [includeNonProduct, setIncludeNonProduct] = useState(watch('versions.0.templateValues.includeNonProduct'));

  useEffect(() => {
    if (discountCondition !== DiscountCondition.MinimumPurchase) {
      return;
    }
    resetField('versions.0.templateValues.conditionValue', { defaultValue: undefined });
  }, [discountCondition]);

  useEffect(() => {
    setValue('versions.0.templateValues.includeNonProduct', includeNonProduct);
    if (includeNonProduct) {
      setValue(templateValueIncludeNFPC, undefined);
    }
  }, [includeNonProduct]);

  return (
    <>
      <div>
        <StyledLabel>Get</StyledLabel>
        <StyledForSelectbox
          name="versions.0.templateValues.orderDiscountType"
          control={control}
          disabled={disabled}
          validation={{
            required: true,
          }}
          items={
            Object.entries(DiscountTypeRecord())
              .filter(([key]) => key === DiscountType.MoneyOff || key === DiscountType.Percent)
              .map(([key, value]) => {
                return {
                  id: key,
                  name: value,
                };
              }) as any[]
          }
          selectWidth={100}
          defaultValue={DiscountType.MoneyOff}
          initialSelectedItems={orderDiscountType ? [orderDiscountType] : [DiscountType.MoneyOff]}
        />
        {shouldDisplayCurrency && <Currency />}
        <StyledTextField
          disabled={disabled}
          register={register}
          validation={{
            required: true,
            min: isPercent ? 1 : 0.01,
            max: isPercent ? 99 : null,
            pattern: isPercent ? /^([1-9][0-9]?|99)$/ : /^\d+(?:\.\d{1,2})?$/,
            validate: (v: string) =>
              (!Number.isNaN(Number(v)) && Number(v) >= 0.01) || (isPercent && Number(v) < 100 && Number(v) >= 1),
          }}
          errors={errors}
          name="versions.0.templateValues.orderDiscountValue"
          placeholder="Enter value"
        />
        {isPercent && (
          <>
            <StyledLabelUpto>Up to</StyledLabelUpto>
            <Currency />
            <StyledTextField
              disabled={disabled}
              register={register}
              validation={{
                required: false,
                min: 0,
                max: null,
                pattern: /^\d+(?:\.\d{1,2})?$/,
                validate: (v: string) => {
                  if (v === '') {
                    return true;
                  }
                  return !Number.isNaN(Number(v)) && Number(v) >= 0;
                },
              }}
              errors={errors}
              name="versions.0.templateValues.orderDiscountLimit"
              placeholder="Enter value"
            />
            <InfoTooltip
              id="uptoTooltip"
              className="test"
              content="If the field remains empty or is set to 0, It will be treated as having no limit."
            />
          </>
        )}
      </div>
      <div>
        <StyledLabel>With</StyledLabel>
        <StyledForSelectbox
          name="versions.0.templateValues.discountCondition"
          control={control}
          disabled={disabled}
          onChange={() => {
            resetField('versions.0.templateValues.doubleDipping');
          }}
          validation={{
            required: true,
          }}
          items={
            Object.entries(DiscountConditionRecord)
              .filter(([key]) => key === DiscountCondition.WithPurchaseOf || key === DiscountCondition.MinimumPurchase)
              .map(([key, value]) => {
                return {
                  id: key,
                  name: value,
                };
              }) as any[]
          }
          defaultValue={DiscountCondition.WithPurchaseOf}
          initialSelectedItems={discountCondition ? [discountCondition] : [DiscountCondition.WithPurchaseOf]}
          selectWidth={150}
        />
        {discountCondition === DiscountCondition.WithPurchaseOf && (
          <ProductPicker>
            <StyledSelectbox
              control={control}
              name="versions.0.templateValues.conditionValue"
              disabled={disabled}
              validation={{
                min: 1,
                max: 15,
                required: true,
              }}
              items={Array.from(
                { length: 15 },
                (x, i) =>
                  ({
                    id: (i + 1).toString(),
                    name: (i + 1).toString(),
                  } as any),
              )}
              defaultValue="1"
              initialSelectedItems={
                conditionValue ? [typeof conditionValue === 'object' ? conditionValue.id : conditionValue] : ['1']
              }
              selectWidth={60}
            />
            {calcProductsHint(conditionProducts)}
            {getProductsAction(
              disabled,
              conditionProducts,
              getValues,
              selectProductsTextLabel,
              'conditionProducts',
              (data: any) => ({
                conditionProducts: data,
              }),
              onProductSelection,
            )}
            <input
              type="hidden"
              {...register('versions.0.templateValues.conditionProducts', {
                value: conditionProducts,
                shouldUnregister: true,
                required: true,
              })}
            />
          </ProductPicker>
        )}
        {discountCondition === DiscountCondition.MinimumPurchase && (
          <>
            <StyledLightLabel>of</StyledLightLabel>
            <Currency />
            <StyledTextField
              disabled={disabled}
              register={register}
              validation={{
                required: true,
                min: 0.01,
                max: null,
                pattern: /^\d+(?:\.\d{1,2})?$/,
                validate: (v: string) => !Number.isNaN(Number(v)) && Number(v) >= 0.01,
              }}
              errors={errors}
              name="versions.0.templateValues.conditionValue"
              placeholder="Enter value"
            />
          </>
        )}
      </div>
      <div>
        <StyledLabel>Non-Product PLUs to Include</StyledLabel>
        <ProductPicker>
          {calcProductsHint(includeNonFoodProductCodes)}
          {getProductsAction(
            disabled || includeNonProduct,
            includeNonFoodProductCodes,
            getValues,
            selectProductsTextLabel,
            'includeNonFoodProductCodes',
            (data: any) => ({
              includeNonFoodProductCodes: !checkForEmptyData(data) ? data : undefined,
            }),
            onProductSelection,
            true,
            ProductSetType.NonFoodProduct,
          )}
          <input
            type="hidden"
            {...register(templateValueIncludeNFPC, {
              value: includeNonFoodProductCodes,
              shouldUnregister: true,
              setValueAs: (v) => (v === '' ? undefined : v),
            })}
          />
        </ProductPicker>
        <Checkbox
          checked={includeNonProduct}
          disabled={disabled}
          onClick={() => {
            setIncludeNonProduct(!includeNonProduct);
            store.dispatch(
              setData({
                data: {
                  templateProducts: {
                    includeNonFoodProductCodes: includeNonProduct ? undefined : includeNonFoodProductCodes,
                  },
                },
              }),
            );
          }}
          label="Include All"
        />
      </div>
      {(discountCondition === DiscountCondition.MinimumPurchase ||
        discountCondition === DiscountCondition.WithAnyPurchase) && (
        <>
          <div>
            <StyledLabel>Exclude</StyledLabel>
            <ProductPicker>
              {calcProductsHint(excludeCodes)}
              {getProductsAction(
                disabled,
                excludeCodes,
                getValues,
                selectProductsTextLabel,
                'excludeCodes',
                (data: any) => ({
                  excludeCodes: !checkForEmptyData(data) ? data : undefined,
                }),
                onProductSelection,
                true,
              )}
              <input
                type="hidden"
                {...register('versions.0.templateValues.excludeCodes', {
                  value: excludeCodes,
                  shouldUnregister: true,
                  setValueAs: (v) => (v === '' ? undefined : v),
                })}
              />
            </ProductPicker>
          </div>
          <div>
            <StyledLabel>Double Dipping</StyledLabel>
            <StyledForSelectbox
              name="versions.0.templateValues.doubleDipping"
              disabled={disabled}
              control={control}
              validation={{
                required: true,
              }}
              items={
                Object.entries(DoubleDippingRuleRecord).map(([key, value]) => {
                  return {
                    id: key,
                    name: value,
                  };
                }) as any[]
              }
              defaultValue={config.defaultDoubleDippingRule}
              initialSelectedItems={
                doubleDipping
                  ? [typeof doubleDipping === 'object' ? doubleDipping.id : doubleDipping]
                  : [config.defaultDoubleDippingRule]
              }
              selectWidth={380}
            />
          </div>
        </>
      )}
    </>
  );
};
