import { gql } from '@apollo/client';

const locationChangesGqls = {
  queries: {
    getAll: gql`
      query Audit($data: GetAllQueryDto) {
        getAudit(data: $data) {
          items {
            ... on EntityAudit {
              id
              entityName
              entityId
              action
              headline
              createdAt
              changes
            }
          }
          total
        }
      }
    `,
    getAllLocationChanges: gql`
      query Audit($data: GetAllQueryDto) {
        getAudit(data: $data) {
          items {
            ... on EntityAudit {
              id
              entityName
              entityId
              headline
              createdAt
            }
          }
          total
        }
      }
    `,
  },
};

export default locationChangesGqls;
