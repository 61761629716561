import React, { useState } from 'react';
import { CopyToClipboardButtonProps } from './CopyButton.props';
import { StyledClipboard, StyledCopyButton } from './CopyButton.style';

const CopyButton = ({ textToCopy }: CopyToClipboardButtonProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
  };

  return (
    <div onMouseLeave={() => setCopied(false)}>
      <StyledCopyButton data-automation-id="copy-image-view"  onClick={handleCopyClick} data-title={copied ? 'Copied!' : 'Copy'}>
        <StyledClipboard name="copy" />
      </StyledCopyButton>
    </div>
  );
};

export default CopyButton;
