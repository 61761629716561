import styled from '@emotion/styled/macro';
import typography from 'styles/typography';
import { Icon } from 'components/shared/icon';
import { StyledWithThemeProps } from 'utils/types';

export const HelpMenuContainer = styled.div`
  display: flex;
`;

export const HelpIcon = styled(Icon)`
  width: 16px;
  fill: ${({ theme }) => theme.colors.text.primary};
  margin-right: ${typography.unit * 4}px;
  cursor: pointer;
`;

export const HelpMenuDropdown = styled.div<StyledWithThemeProps>`
  position: absolute;
  top: calc(100% + ${typography.unit * 2}px);
  right: ${typography.unit * 19}px;
  width: 200px;
  height: 90px;
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  border-radius: ${typography.borderRadius}px;
  box-shadow: ${({ theme }) => theme.colors.global.boxShadow};
  background: ${({ theme }) => theme.colors.global.background};
`;

export const HelpMenuItem = styled.div<StyledWithThemeProps>`
  display: flex;
  height: 45px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  a {
    color: ${({ theme }) => theme.colors.text.primary};
    width: 100%;
  }
`;

export const Divider = styled.div<StyledWithThemeProps>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.global.border};
`;

export const StyledIcon = styled(Icon)`
  width: 14px;
  fill: ${({ theme }) => theme.colors.global.inputPrimary};
  margin: 0 ${typography.unit * 4}px;
`;
