import React from 'react';
import { ControlledToggleSwitchProps } from 'components/shared/controlledToggleSwitch/ControlledToggleSwitch.consts';
import ToggleSwitch from 'components/shared/toggleSwitch/ToggleSwitch';
import { Controller } from 'react-hook-form';

const ControlledToggleSwitch = ({
  label,
  disabled,
  size = 'medium',
  control,
  name,
  defaultValue,
  onChange,
  className,
}: ControlledToggleSwitchProps) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      shouldUnregister
      render={({ field }) => (
        <ToggleSwitch
          label={label}
          checked={Boolean(field.value)}
          disabled={disabled}
          size={size}
          onClick={() => {
            field.onChange(!field.value);
            if (onChange) {
              onChange(!field.value);
            }
          }}
          className={className}
        />
      )}
    />
  );
};

export default ControlledToggleSwitch;
