export enum PeriodsFilterType {
  SearchQuery = 'searchQuery',
  Status = 'status',
}

export enum PeriodStatus {
  Live = 'live',
  NotStarted = 'notStarted',
  Expired = 'expired',
}

export const PeriodStatusLabel: Record<PeriodStatus, string> = {
  [PeriodStatus.Live]: 'Live',
  [PeriodStatus.NotStarted]: 'Not Started',
  [PeriodStatus.Expired]: 'Expired',
};

export interface PeriodProps {
  id: number;
  name: string;
  startDate: string | Date;
  endDate: string | Date;
  hasCampaigns: boolean;
}
