import styled from '@emotion/styled';
import typography from 'styles/typography';
import ControlledToggleSwitch from 'components/shared/controlledToggleSwitch/ControlledToggleSwitch';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { Selectbox } from 'components/shared/selectbox/Selectbox';

export const SectionContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: ${typography.unit * 2}px;
  min-height: 50px;
`;

export const StyledToggleSwitch = styled(ControlledToggleSwitch)`
  margin-right: ${typography.unit * 6}px;
  margin-top: 6px;
`;

export const StyledSelectbox = styled(Selectbox)`
  align-self: flex-end;
`;

export const StyledCheckbox = styled(Checkbox)`
  align-self: flex-end;
  border-bottom: 1px solid #C4C5C8;
  padding-bottom: 4px;
  padding-right: 5px;
`;