import { appTheme } from 'styles/themes/defaultTheme';

export enum LoaderSize {
  ExtraSmall = 'extraSmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum LoaderColor {
  Primary = 'primary',
  Secondary = 'secondary',
}

export const LoaderSizeRecord: Record<LoaderSize, number> = {
  [LoaderSize.ExtraSmall]: 16,
  [LoaderSize.Small]: 24,
  [LoaderSize.Medium]: 40,
  [LoaderSize.Large]: 56,
};

export interface LoaderProps {
  size?: LoaderSize;
  color?: LoaderColor;
  theme?: appTheme;
  className?: string;
}
