import styled from '@emotion/styled/macro';
import typography from 'styles/typography';
import { CheckboxContainerProps, StyledCheckboxProps, StyledLabelProps } from './Checkbox.consts';
import { Icon, IconProps } from '../icon';

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  line-height: 15px;
`;

export const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckmark = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.checkbox.iconColor};
  width: 10px;
`;

export const StyledIntermediate = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.checkbox.iconColor};
  width: 10px;
`;

export const StyledLabel = styled.label<StyledLabelProps>`
  font-size: 13px;
  margin-left: ${typography.unit * 2}px;
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: pointer;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: initial;
`;

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  background-color: ${({ checked, intermediate, disabled, theme }) =>
    checked || intermediate
      ? disabled
        ? theme.colors.checkbox.disabledCheckedBg
        : theme.colors.global.inputPrimary
      : theme.colors.checkbox.uncheckedBg};
  border: 1px solid
    ${({ checked, intermediate, disabled, theme }) =>
      checked || intermediate
        ? 'transparent'
        : disabled
        ? theme.colors.global.inputDisabled
        : theme.colors.global.border};
  border-radius: 3px;
  transition: all 150ms;
`;
