import styled from '@emotion/styled';
import { appTheme } from 'styles/themes/defaultTheme';
import typography from 'styles/typography';

export const ToggleItemWrapper = styled.div<{ disabled: boolean; isSelected: boolean; theme?: appTheme }>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  border-color: ${({ isSelected, theme, disabled }) =>
    isSelected
      ? disabled
        ? theme.colors.checkbox.disabledCheckedBg
        : theme.colors.global.inputPrimary
      : theme.colors.global.border};
  border-style: solid;
  border-width: 1px 0 1px 1px;
  padding: 0 ${typography.unit * 2}px;
  background-color: ${({ isSelected, theme, disabled }) =>
    isSelected
      ? disabled
        ? theme.colors.checkbox.disabledCheckedBg
        : theme.colors.global.inputPrimary
      : 'transparent'};

  &:last-of-type {
    border-width: 1px;
  }

  span {
    color: ${({ isSelected, theme }) => (isSelected ? '#FFF' : `${theme.colors.text.primary}`)};
  }
`;

export const Label = styled.span<{ disabled: boolean; theme?: appTheme }>`
  color: ${({ theme }) => theme.colors.text.primary};
  display: flex;
  align-items: center;
  white-space: nowrap;
`;
