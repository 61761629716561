import { useCallback, useEffect, useMemo, useState } from 'react';
import { tabName } from 'components/header/Header.consts';
import TextPageTitle from 'components/shared/text/textPageTitle/TextPageTitle';
import PageContainer from 'pages/shared/pageContainer/PageContainer';
import { StyledHeader } from 'pages/shared/shared.style';
import { MarketConfigurationGuard } from 'components/zoneGuard/MarketConfigurationGuard';
import { MarketConfigurationKey } from 'pages/configurations/Configurations.consts';
import { periodsGqls } from 'pages/settings/schedulePeriods/SchedulePeriods.gqls';
import { useQuery } from '@apollo/client';
import xorWith from 'lodash/xorWith';
import { Period } from 'utils/types/period';
import { useSelector } from 'react-redux';
import { calendar as calendarSlice, setFilters } from 'app/slices/calendar';
import { store } from 'app/store';
import { calenderCampaigns } from 'app/genericSlices/campaigns';
import { CampaignFilterType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { useUrlFilters } from 'hooks/use-url-filters';
import { isInArray } from 'utils/array';
import { OrderDirection } from 'utils/types';
import { CheckboxWrapper, Main, Side } from './Calendar.style';
import { CalendarFilterBar } from './components/calendarFilterBar/CalendarFilterBar';
import { PeriodsFilter } from './components/periodsFilter/PeriodsFilter';
import { CalendarView } from './components/calendarView/CalendarView';
import { campaignsGqls } from '../campaignManagement/Campaigns.gqls';
import { CalenderViewMode } from './components/calendarView/CalendarView.consts';
import { FetchPolicies } from 'utils/types/common';

export const CalendarPage = () => {
  const { filters: periodfilters } = useSelector(calendarSlice);
  const { filters: campaignFilters } = useSelector(calenderCampaigns.campaignsState);
  const [view, setView] = useState<CalenderViewMode>('month');
  const urlFilters = useUrlFilters((params: any) => {
    store.dispatch(
      calenderCampaigns.actions.setFilters(
        Object.keys(params).reduce(
          (res: any, key: any) => ({
            ...res,
            [key]:
              Array.isArray(params[key]) || isInArray(['true', 'false'], params[key]) ? params[key] : [params[key]],
          }),
          {},
        ),
      ),
    );
  });
  const { data: periodsData } = useQuery(periodsGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        order: { startDate: OrderDirection.DESC },
        limit: 1000,
      },
    },
  });
  const {
    getPeriods: { items: periods },
  } = periodsData || { getPeriods: { items: [] } };
  const [currentRange, setCurrentRange] = useState<Date>(() => {
    if (!periodfilters.periods.length) {
      return new Date();
    }
    return new Date(periodfilters.period.startDate);
  });
  const [selectedPeriods, setSelectedPeriods] = useState<Period[]>(
    periodfilters.period
      ? [
          {
            ...periodfilters.period,
            startDate: new Date(periodfilters.period.startDate),
            endDate: new Date(periodfilters.period.endDate),
          },
        ]
      : [],
  );

  const { data, loading } = useQuery(campaignsGqls.queries.getCampaignsForCalendar, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: calenderCampaigns.getFilters(campaignFilters, currentRange),
        order: { id: OrderDirection.DESC },
        limit: 1000,
        select: [
          'title',
          'schedule.campaignStart',
          'schedule.campaignEnd',
          'schedule.recurrence',
          'id',
          'offerVersion.translations.image.id',
          'offerVersion.translations.image.fileName',
          'localSchedule',
          'isTriggerEvent',
          'push_notification',
        ],
        isBatchingRequired: true,
      },
    },
  });
  const {
    getCampaigns: { items: campaigns },
  } = data || { getCampaigns: { items: [] } };

  const changeMonth = useCallback(
    (month: Date, delta: 1 | -1 | 0 = 0) => {
      if (view === 'month') {
        month.setMonth(month.getMonth() + delta);
      }
      if (view === 'week') {
        month.setDate(month.getDate() + delta * 7);
      }
      setCurrentRange(new Date(month));
    },
    [view],
  );

  const togglePeriod = useCallback(
    (p: Period) => {
      const newPeriods = xorWith([...selectedPeriods], [p], (a, b) => a.id === b.id);
      setSelectedPeriods(newPeriods);
    },
    [selectedPeriods],
  );

  const filteredPeriods = useMemo(() => {
    if (view === 'month') {
      return periods
        .map((p: Period) => ({ ...p, startDate: new Date(p.startDate), endDate: new Date(p.endDate) }))
        .filter(
          (p: Period) =>
            p.startDate.getMonth() === currentRange.getMonth() || p.endDate.getMonth() === currentRange.getMonth(),
        );
    }
    const start = new Date(currentRange);
    start.setDate(start.getDate() - start.getDay());
    const end = new Date(start);
    end.setDate(end.getDate() + 7);
    return periods
      .map((p: Period) => ({ ...p, startDate: new Date(p.startDate), endDate: new Date(p.endDate) }))
      .filter(
        (p: Period) =>
          (p.startDate.getTime() <= start.getTime() && p.endDate.getTime() >= end.getTime()) ||
          (p.startDate.getTime() >= start.getTime() && p.startDate.getTime() <= end.getTime()) ||
          (p.endDate.getTime() >= start.getTime() && p.endDate.getTime() <= end.getTime()),
      );
  }, [periods, currentRange, view]);

  useEffect(() => {
    return () => {
      store.dispatch(setFilters({ periods: [], period: null }));
    };
  }, []);

  useEffect(() => {
    if (!Object.keys(urlFilters.params).length) {
      urlFilters.filterMulti(campaignFilters);
    }
  }, []);

  return (
    <PageContainer>
      <StyledHeader data-automation-id="header">
        <TextPageTitle text={tabName.Calendar} />
        <CalendarFilterBar
          range={currentRange}
          changeRange={changeMonth}
          view={view}
          setView={setView}
          selectedPeriods={selectedPeriods}
          filter={urlFilters.filter}
          filterMulti={urlFilters.filterMulti}
        />
      </StyledHeader>
      <Main>
        <Side>
          <MarketConfigurationGuard
            configurations={[{ configKey: MarketConfigurationKey.EnableSchedulePeriods, value: true }]}
          >
            <PeriodsFilter periods={filteredPeriods} selectedPeriods={selectedPeriods} onClick={togglePeriod} />
          </MarketConfigurationGuard>
          <MarketConfigurationGuard
            configurations={[{ configKey: MarketConfigurationKey.EnableManagementByZone, value: true }]}
          >
            <CheckboxWrapper>
              <Checkbox
                label="Only Bulk Campaigns"
                checked={campaignFilters[CampaignFilterType.OnlyLocal] === 'true'}
                onClick={(e) => {
                  urlFilters.filter(
                    CampaignFilterType.OnlyLocal,
                    campaignFilters[CampaignFilterType.OnlyLocal] !== 'true',
                  );
                  e.stopPropagation();
                }}
              />
            </CheckboxWrapper>
          </MarketConfigurationGuard>
        </Side>

        <CalendarView
          range={currentRange}
          periods={selectedPeriods}
          campaigns={campaigns}
          view={view}
          loading={loading}
        />
      </Main>
    </PageContainer>
  );
};
