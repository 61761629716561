import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { LoaderColor, LoaderSizeRecord, LoaderProps } from './Loader.consts';

const rotate = keyframes`
  0% {transform: rotate(0deg) scale(1)}
  50% {transform: rotate(180deg) scale(0.8)}
  100% {transform: rotate(360deg) scale(1)}
`;

export const StyledLoader = styled.span<LoaderProps>`
  background: transparent;
  width: ${({ size }) => LoaderSizeRecord[size]}px;
  height: ${({ size }) => LoaderSizeRecord[size]}px;
  border-radius: 50%;
  border: 3px solid
    ${({ color, theme }) =>
      color === LoaderColor.Primary ? theme.colors.global.inputPrimary : theme.colors.global.background};
  border-bottom-color: transparent;
  display: inline-block;
  animation: ${rotate} 1.2s 0s infinite linear;
  animation-fill-mode: both;
`;

export const StyledLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
