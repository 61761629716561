import React from 'react';
import { AdornmentPosition, TextFieldProps } from 'components/shared/textField/TextField.consts';
import { StyledIcon, StyledTextField } from 'components/shared/searchTextField/SearchTextField.style';

export const SearchTextField = ({
  adornmentPosition = AdornmentPosition.End,
  placeholder = 'Search',
  debounceTime = 300,
  value,
  onChange,
  className,
  name,
  disabled,
  caretPosition,
  autoFocus = true,
}: TextFieldProps) => {
  return (
    <StyledTextField
      name={name}
      className={className}
      placeholder={placeholder}
      debounceTime={debounceTime}
      adornmentPosition={adornmentPosition}
      value={value}
      onChange={onChange}
      caretPosition={caretPosition}
      disabled={disabled}
      autoFocus={autoFocus}
    >
      <StyledIcon name="search" />
    </StyledTextField>
  );
};
