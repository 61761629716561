import styled from '@emotion/styled';
import typography from 'styles/typography';
import { ButtonOutlined } from 'components/shared/button';
import { Link } from 'react-router-dom';

export const StyledErrorContent = styled.div`
  margin-bottom: ${typography.unit * 6}px;
`;

export const StyledErrorStack = styled.div`
  margin-bottom: ${typography.unit * 6}px;
  font-size: 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-self: flex-end;
`;

export const StyledButtonOutlined = styled(ButtonOutlined)`
  margin-right: ${typography.unit * 2}px;
`;

export const StyledLink = styled(Link)`
  margin-top: ${typography.unit * 2}px;
`;
