/* eslint-disable import/no-duplicates */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar } from 'react-big-calendar';
import some from 'lodash/some';
import { Loader } from 'components/shared/loader';
import { CampaignProps } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { Icon } from 'components/shared/icon';
import './CalendarView.style';
import { getCampaignById } from 'utils/api/campaigns';
import { openModal, Modals } from 'app/slices/modals';
import { store } from 'app/store';
import { FormMode } from 'utils/types';
import { calendarProps, CalendarViewProps, eventTitle, ShowMoreDetails } from './CalendarView.consts';
import {
  CalendarWrapper,
  LoaderWrapper,
  ShowMoreEventItem,
  ShowMoreTooltip,
  StyledCloseButton,
} from './CalendarView.style';
import { EventInterface } from '../../Calendar.consts';
import { marketConfig } from 'app/slices/config';
import { convertUtcDateToTimezoneDate } from 'utils/date';
import { useSelector } from 'react-redux';

export const CalendarView = ({ range, periods, campaigns, loading, view = 'month' }: CalendarViewProps) => {
  const [isLoading, setIsLoading] = useState(loading);
  const [showMore, setShowMore] = useState<ShowMoreDetails>(null);
  const { config } = useSelector(marketConfig);
  const { startTimezone, endTimezone } = config;

  const parsedCampaigns: EventInterface[] = useMemo(() => {
    const uniqueCampaigns = new Set<number>();

    return Array.from(campaigns, (c: CampaignProps) => {
      if (uniqueCampaigns.has(c.id)) {
        return null;
      }
      uniqueCampaigns.add(c.id);
      return {
        title: c?.title,
        start: convertUtcDateToTimezoneDate(c.schedule.campaignStart, startTimezone) as Date,
        end: convertUtcDateToTimezoneDate(c.schedule.campaignEnd, endTimezone) as Date,
        allDay: true,
        id: c.id,
        image: c.offerVersion?.translations[0]?.image?.fileName,
        isLocal: !!c.localSchedule,
        isTriggerEvent: c.isTriggerEvent,
        recurrence: c.schedule.recurrence,
        pushNotificationStartDate: c.push_notification?.startDate,
        pushNotificationFlag: c.push_notification?.startDate ? true : false,
      };
    }).filter(Boolean); // Filter out null values (duplicates)
  }, [campaigns]);


  const props = useMemo(() => calendarProps(setShowMore), []);

  const openCampaign = useCallback(async (e: any) => {
    setIsLoading(true);
    const campaignData = await getCampaignById((e).id);
    setIsLoading(false);
    store.dispatch(
      openModal({
        modal: Modals.CampaignModal,
        props: { mode: FormMode.View, campaign: campaignData?.data?.getCampaign },
        data: { fromCalendar: true },
      }),
    );
  }, []);

  useEffect(() => {
    setShowMore(null);
  }, [range, parsedCampaigns, view]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <>
      {isLoading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      <CalendarWrapper>
        <Calendar
          {...props}
          date={range}
          view={view}
          events={[...parsedCampaigns]}
          dayPropGetter={(date: Date) => {
            if (
              some(periods, (p) => {
                p.startDate.setHours(0, 0, 0, 0);
                p.endDate.setHours(0, 0, 0, 0);
                date.setHours(0, 0, 0, 0);
                return p.startDate.getTime() <= date.getTime() && p.endDate.getTime() >= date.getTime();
              })
            ) {
              return { className: 'period' };
            }
            return null;
          }}
          onSelectEvent={openCampaign}
        />
        {showMore && (
          <ShowMoreTooltip {...showMore}>
            <StyledCloseButton onClick={() => setShowMore(null)}>
              <Icon name="close" />
            </StyledCloseButton>
            <header>
              <h4>{showMore.date.toLocaleDateString('default', { weekday: 'short' })}</h4>
              <h5>{showMore.date.toLocaleDateString('default', { day: 'numeric' })}</h5>
            </header>
            <ul>
              {showMore.events.map((e) => (
                <ShowMoreEventItem isLocal={e.isLocal} key={e?.title.toString()} onClick={() => openCampaign(e)}>
                  {eventTitle(e)}
                </ShowMoreEventItem>
              ))}
            </ul>
          </ShowMoreTooltip>
        )}
      </CalendarWrapper>
    </>
  );
};
