import styled from '@emotion/styled';
import typography from 'styles/typography';
import { SearchTextField } from 'components/shared/searchTextField/SearchTextField';
import Checkbox from 'components/shared/checkbox/Checkbox';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const IconWrapper = styled.div`
  margin-top: ${typography.unit * 1.5}px;
`;

export const StyledSearch = styled(SearchTextField)`
  margin-right: ${typography.unit * 7}px;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-right: ${typography.unit * 7}px;
`;
