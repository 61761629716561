import { FieldErrors, get } from 'react-hook-form';

export const isUndefinedOrEmpty = (val: Record<string, unknown> | string) => {
  if (typeof val === 'string' || Array.isArray(val)) {
    return val.length === 0;
  }
  return !val || Object.keys(val).length === 0;
};

export const getIsControlWithError = (controlName: string, errors: FieldErrors | string): boolean => {
  if (isUndefinedOrEmpty(errors)) {
    return false;
  }
  if (typeof errors === 'string') {
    return errors.length > 0;
  }
  if (typeof errors === 'object') {
    return get(errors, controlName);
  }
};

export const dirtyValues = (dirtyFields: any, allValues: any): Record<string, unknown> => {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])]),
  );
};
