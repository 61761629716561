import styled from '@emotion/styled';
import typography from 'styles/typography';

export const StyledWeekEvent = styled.div`
  display: flex;
  gap: ${typography.unit}px;

  img {
    width: 55px;
    height: 62px;
  }

  > div {
    display: 'flex';
    flex-direction: 'column';
  }
`;
