import { createSlice } from '@reduxjs/toolkit';
import { OrderDirection } from 'utils/types';
import type { RootState } from 'app/store';
import { ProductChangesFilterType } from 'pages/reports/productChanges/ProductChanges.consts';
import { buildBaseAndFilters, generateSearchFilter } from 'utils/serverFilters';
import { AuditChange } from 'pages/reports/shared/shared.consts';

export interface ProductChanges {
  changes: AuditChange[];
  filters?: any;
  order?: any;
  total: number;
  limit: number;
  currentPage: number;
  lastRequestId?: string;
}

export const initialState: ProductChanges = {
  changes: [],
  filters: {},
  order: {
    createdAt: OrderDirection.DESC,
  },
  total: 0,
  limit: 25,
  currentPage: 0,
  lastRequestId: '',
};

export const getFilters = (initialFilters: { [key in ProductChangesFilterType]?: any[] }): any => {
  const ProductChangesFiltersRecord = {
    [ProductChangesFilterType.ChangeDetails]: { serverFilter: 'action', operation: 'in' },
  };

  const filters = buildBaseAndFilters(ProductChangesFiltersRecord, initialFilters);
  filters.AND.push({ entityType: { any_of: ['Product', 'ProductSet'] } });
  filters.AND.push({ action: { not: 'updated' } });

  if (initialFilters[ProductChangesFilterType.SearchQuery]?.length) {
    const searchByFields = ['entityName'];
    const idFields = ['entityId'];
    filters.AND.push(
      generateSearchFilter(searchByFields, idFields, initialFilters[ProductChangesFilterType.SearchQuery]),
    );
  }

  return filters;
};

export const productChangesSlice = createSlice({
  name: 'productChanges',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setOrder(state, action) {
      state.order = action.payload;
    },
  },
});

export const productChanges = (state: RootState) => state.productChanges;
export const { setFilters, setOrder } = productChangesSlice.actions;

export default productChangesSlice.reducer;
