import { client } from 'app/apollo';
import { ApprovalStatus } from 'utils/types';
import { capitalize } from 'utils/text';
import { scheduleManagementGqls } from 'pages/campaigns/scheduleManagement/ScheduleManagement.gqls';

export const updateScheduleApprovalStatus = async (scheduleId: number, approvalStatus: ApprovalStatus) => {
  return client.mutate({
    mutation: scheduleManagementGqls.mutations.updateApprovalStatus,
    variables: {
      data: {
        id: scheduleId,
        approvalStatus: capitalize(approvalStatus),
      },
    },
    refetchQueries: ['CampaignLocalSchedules'],
  });
};
