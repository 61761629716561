import { createSlice } from '@reduxjs/toolkit';
import { OrderDirection } from 'utils/types';
import type { RootState } from 'app/store';
import { AuditFilters, auditTypesArray } from 'pages/reports/audit/Audit.consts';
import { buildBaseAndFilters, generateSearchFilter } from 'utils/serverFilters';

export interface Audit {
  filters?: any;
  order?: {
    [key: string]: OrderDirection.DESC | OrderDirection.ASC | null | undefined;
  };
}

export const initialState: Audit = {
  filters: {},
  order: {
    createdAt: OrderDirection.DESC,
  },
};

export const getFilters = (initialFilters: { [key in AuditFilters]?: any[] }): any => {
  const AuditFiltersRecord = {
    [AuditFilters.Type]: { serverFilter: 'entityType', operation: 'in' },
    [AuditFilters.Action]: { serverFilter: 'action', operation: 'in' },
  };
  const filters = buildBaseAndFilters(AuditFiltersRecord, initialFilters);
  filters.AND.push({ entityType: { any_of: auditTypesArray } });

  if (initialFilters[AuditFilters.SearchQuery]?.length) {
    const searchByFields = ['entityName', 'fullName'];
    const idFields = ['entityId', 'user'];
    filters.AND.push(generateSearchFilter(searchByFields, idFields, initialFilters[AuditFilters.SearchQuery]));
  }

  return filters;
};

export const auditSlice = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setOrder(state, action) {
      state.order = action.payload;
    },
  },
});

export const audit = (state: RootState) => state.audit;
export const { setFilters, setOrder } = auditSlice.actions;

export default auditSlice.reducer;
