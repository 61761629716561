import styled from '@emotion/styled/macro';
import { ButtonText } from 'components/shared/button';
import typography from 'styles/typography';
import zIndex from 'styles/zIndex';
import { StyledWithThemeProps } from 'utils/types';

export const Avatar = styled.div<StyledWithThemeProps>`
  margin: 0 ${typography.unit * 4}px 0 0;
  background: ${({ theme }) => theme.colors.global.brandMain};
  width: ${typography.unit * 6}px;
  height: ${typography.unit * 6}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
`;

export const UserMenuDropdown = styled.div<StyledWithThemeProps>`
  position: absolute;
  top: calc(100% + ${typography.unit * 2}px);
  width: 250px;
  min-height: 224px;
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  border-radius: ${typography.borderRadius}px;
  box-shadow: ${({ theme }) => theme.colors.global.boxShadow};
  padding: ${typography.unit * 3}px 0 0 ${typography.unit * 4}px;
  display: flex;
  flex-direction: column;
  z-index: ${zIndex.dropdown};
  background: white;

  header {
    display: flex;
    align-items: center;

    h5 {
      font-size: 16px;
      font-weight: 400;
    }

    ${Avatar} {
      width: ${typography.unit * 8}px;
      height: ${typography.unit * 8}px;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    margin: ${typography.unit * 4}px 0;
    gap: ${typography.unit * 5}px;

    h6 {
      color: ${({ theme }) => theme.colors.text.secondary};
      font-size: 12px;
      font-weight: 400;
    }
  }

  footer {
    border-top: 1px solid ${({ theme }) => theme.colors.global.border};
    margin-top: auto;
    margin-left: -${typography.unit * 4}px;
    padding-left: ${typography.unit * 4}px;
    display: flex;
    align-items: center;
    height: ${typography.unit * 12}px;

    a {
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
`;

export const Welcome = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 ${typography.unit * 4}px 0 0;
`;

export const StyledLogoutButton = styled(ButtonText)`
  width: 100%;
  justify-content: flex-start;
`;

export const StyledUserRole = styled.div`
  text-transform: capitalize;
`;
