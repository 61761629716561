import { Feature } from 'utils/types/features';
import { RestaurantEligibility } from 'utils/types/locations';

export enum GameStatus {
  Created = 'Created',
  Canceled = 'Canceled',
  Stopped = 'Stopped',
}

export enum GamePlatform {
  iWin = 'iWin',
  Other = 'Other',
}

export const GamePlatformFeatureToggle: Record<GamePlatform, Feature> = {
  [GamePlatform.Other]: null,
  [GamePlatform.iWin]: Feature.GamingIWin,
};

export enum GamingVoucherType {
  Single = 'single',
  Multiple = 'multiple',
}

export interface Game {
  id: number;
  name: string;
  platform: GamePlatform;
  marketId: string;
  restaurantEligibility?: RestaurantEligibility;
  startDateTime: Date;
  endDateTime: Date;
  status: GameStatus;
}

export interface GameNotification {
  id: number;
  name: string;
}
