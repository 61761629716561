import styled from '@emotion/styled';
import zIndex from 'styles/zIndex';
import { BackdropProps } from './Backdrop.const';

export const StyledBackdrop = styled.section<BackdropProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.global.backdrop};
  z-index: ${zIndex.backdrop};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'cursor')};
`;
