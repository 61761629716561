import React from 'react';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { FeatureGuardProps } from './FeatureGuard.consts';

export const FeatureGuard = ({ children, features }: FeatureGuardProps) => {
  const { features: featuresConfig } = useSelector(marketConfig);
  if (
    features?.some(({ feature: f, value = true }) => {
      if (!value && !featuresConfig[f]) {
        return false;
      }
      return !featuresConfig[f] || featuresConfig[f] !== value;
    })
  ) {
    return null;
  }

  return <>{children}</>;
};
