import React from 'react';
import { StyledContent, StyledIconMessage, StyledMessageContainer } from './Message.style';
import { IconNameByType, NotificationsProps } from '../notifications';

const Message = ({ type, children, className }: NotificationsProps) => {
  return (
    <StyledMessageContainer className={className} data-automation-id="message">
      <StyledIconMessage type={type} name={IconNameByType[type]} />
      <StyledContent> {children}</StyledContent>
    </StyledMessageContainer>
  );
};

export default Message;
