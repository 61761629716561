import React from 'react';
import { StyledMessage } from './Banner.style';
import { NotificationsProps } from '../notifications';

const Banner = ({ type, children, className }: NotificationsProps) => {
  return (
    <StyledMessage type={type} className={className}>
      {children}
    </StyledMessage>
  );
};

export default Banner;
