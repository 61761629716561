import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import Tooltip from 'components/shared/tooltip/Tooltip';
import ReactTooltip from 'react-tooltip';
import { hideTooltip } from 'utils/tooltip';
import { BackgroundCircle, ProgressIndicatorWrapper, StyledProgress } from './ProgressIndicator.style';

export interface ContainerProps {
  size?: number;
  message?: string;
  onHover?: boolean;
  marginLeft?: number;
}

const ProgressIndicator = ({ size, message, onHover, marginLeft }: ContainerProps) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress + 10) % 100);
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <StyledProgress marginLeft={marginLeft} data-testid="progress-indicator">
        <ProgressIndicatorWrapper data-for="progress-tooltip" data-tip>
          <BackgroundCircle size={size} />
          <CircularProgress color="inherit" size={size} variant="determinate" value={progress} />
        </ProgressIndicatorWrapper>
        <Tooltip
          id="progress-tooltip"
          place="bottom"
          content={message}
          onHover={onHover}
          onClose={
            !onHover
              ? () => {
                  hideTooltip('#progress-tooltip');
                  ReactTooltip.hide();
                }
              : null
          }
        />
      </StyledProgress>
    </>
  );
};
export default ProgressIndicator;
