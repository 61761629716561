import { gql } from '@apollo/client';

export const termsConditionsGqls = {
  queries: {
    getAll: gql`
      query Terms($data: GetAllQueryDto) {
        getTerms(data: $data) {
          items {
            ... on Term {
              id
              name
              template
              translationsMap
              createdAt
              updatedAt
              isArchive
            }
          }
          total
        }
      }
    `,
    getTermsTermsNCondsPage: gql`
      query Terms($data: GetAllQueryDto) {
        getTermsTermsNCondsPage(data: $data) {
          items {
            ... on Term {
              id
              name
              template
              translationsMap
              createdAt
              updatedAt
              isArchive
            }
          }
          total
        }
      }
    `,
    getById: gql`
      query Term($id: Int!) {
        getTerm(id: $id) {
          name
        }
      }
    `,
  },
  mutations: {
    add: gql`
      mutation CreateTerm($data: CreateTermDto!) {
        createTerm(data: $data) {
          id
        }
      }
    `,
    update: gql`
      mutation UpdateTerm($data: UpdateTermDto!) {
        updateTerm(data: $data) {
          id
          name
          template
          updatedAt
          updatedBy {
            id
          }
        }
      }
    `,
  },
};
