import styled from '@emotion/styled';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import ItemsSelectionAccordion from 'components/shared/accordion/itemsSelectionAccordion/ItemsSelectionAccordion';
import zIndex from 'styles/zIndex';
import { SearchTextField } from 'components/shared/searchTextField/SearchTextField';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const Headline = styled.div`
  font-family: ${typography.primaryFont};
  font-weight: 500;
  margin-bottom: ${typography.unit}px;
`;

export const SelectionContainer = styled.div<StyledWithThemeProps>`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  border-radius: ${typography.borderRadius}px;
`;

export const StyledItemsSelectionAccordion = styled(ItemsSelectionAccordion)<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  flex: ${({ expanded }) => `${expanded ? '1' : '0'})`};
  height: 100%;
  > .MuiAccordionSummary-root {
    background: ${({ theme }) => theme.colors.global.background} !important;
    position: sticky !important;
    top: 0 !important;
    z-index: ${zIndex.dropdown} !important;
  }
  :last-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.global.border};
  }

  .MuiAccordionDetails-root,
  .MuiCollapse-wrapper,
  [role='region'],
  .MuiCollapse-root {
    height: 100% !important;
    position: relative !important;
  }

  .MuiAccordionSummary-root {
    justify-content: flex-start;
  }
`;

export const StyledSearch = styled(SearchTextField)`
  width: 100%;
  margin-bottom: ${typography.unit}px;
`;

export const StyledCenteredLoaderContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: ${zIndex.modalLoading};
`;

export const NoProductText = styled.div<StyledWithThemeProps>`
  margin: auto;
  color: ${({ theme }) => theme.colors.text.secondary};
`;