import styled from '@emotion/styled';
import typography from 'styles/typography';
import zIndex from 'styles/zIndex';
import { ButtonText } from 'components/shared/button';
import { Icon, IconProps } from 'components/shared/icon';
import { StyledWithThemeProps } from 'utils/types';
import StatusLabel from 'components/shared/statusLabel/StatusLabel';
import { ImageItemContainerProps } from 'pages/settings/images/components/imageItem/ImageItem.consts';

export const Container = styled.div<StyledWithThemeProps>`
  width: 260px;
  height: 260px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  display: flex;
  flex-direction: column;
  transition: all ${typography.transition}s ease;

  &:hover {
    box-shadow: ${({ theme }) => theme.colors.global.boxShadow};
  }
`;

export const ImageWrapper = styled.div<ImageItemContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 220px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  word-break: break-all;
`;

export const ImageBackdrop = styled.div<StyledWithThemeProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: ${zIndex.hoverBackdrop};
  background-color: ${({ theme }) => theme.colors.global.backdrop};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: ${typography.unit * 2}px ${typography.unit * 2}px ${typography.unit}px ${typography.unit}px;
  display: flex;
  flex-direction: column;
`;

export const ImageNameWrapper = styled.div`
  flex: 1;
  padding: 0 ${typography.unit * 3}px;
  display: flex;
`;

export const ImageName = styled.div`
  flex: 1;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 40px;
`;

export const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const ImgContainer = styled.div`
  width: 258px;
  height: 205px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${typography.unit}px ${typography.unit * 2}px;
`;

export const StyledStatusLabel = styled(StatusLabel)`
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: ${typography.borderRadius}px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledButtonText = styled(ButtonText)`
  margin-left: ${typography.unit * 2}px;
`;

export const StyledIcon = styled(Icon)<IconProps>`
  width: 18px;
  fill: #fff;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
  transition: all ${typography.transition}s ease;

  :hover {
    fill: ${({ theme }) => theme.colors.global.inputPrimary};
  }
`;

export const StyledArchiveIcon = styled(StyledIcon)`
  width: 22px;
`;

export const StyledView = styled(StyledIcon)<IconProps>`
  width: 24px;
`;

export const ImageId = styled.div`
  font-size: 12px;
  font-weight: 400;
  align-self: end;
  padding-right: ${typography.unit}px;
`;
