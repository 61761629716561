import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { MultipleChoiceProps } from './MultipleChoice.consts';
import {
  StyledContainer,
  StyledChoiceItem,
  StyledMultipleChoiceContainer,
  StyledError,
  StyledLabel,
  ItemInput,
  ItemLabel,
} from './MultipleChoice.style';

const MultipleChoice = ({
  control,
  name,
  validation,
  value,
  disabled = false,
  errors,
  label,
  items,
  className,
  labelIsHorizontal,
}: MultipleChoiceProps) => {
  const [selectedChoice, setSelectedChoice] = useState(value || []);

  return (
    <StyledContainer className={className} labelIsHorizontal={labelIsHorizontal} data-automation-id="multiple-choice">
      {label && (
        <StyledLabel labelIsHorizontal={labelIsHorizontal}>{`${label}${validation?.required ? '*' : ''}`}</StyledLabel>
      )}
      <Controller
        name={name}
        control={control}
        rules={validation}
        defaultValue={value}
        render={({ field }: any) => (
          <StyledMultipleChoiceContainer disabled={disabled}>
            {items.map((item, index) => (
              <StyledChoiceItem key={`${item.id}_${index}`} disabled={disabled}>
                <ItemInput
                  type="checkbox"
                  value={item.name}
                  name={item.id}
                  defaultChecked={value.includes(item.id)}
                  disabled={disabled}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const indexValue = selectedChoice.indexOf(event.target.name);
                    if (indexValue === -1) {
                      selectedChoice.push(event.target.name);
                    } else {
                      selectedChoice.splice(indexValue, 1);
                    }
                    setSelectedChoice(selectedChoice);
                    field.onChange(selectedChoice);
                  }}
                />
                <ItemLabel>{item.name}</ItemLabel>
              </StyledChoiceItem>
            ))}
          </StyledMultipleChoiceContainer>
        )}
      />
      <StyledError name={name} errors={errors} />
    </StyledContainer>
  );
};

export default MultipleChoice;
