import React from 'react';
import { RadioGroupProps } from 'components/shared/radioGroup/RadioGroup.consts';
import { RadioGroupContainer, StyledRadioItem } from 'components/shared/radioGroup/RadioGroup.style';
import { Controller } from 'react-hook-form';

const RadioGroup = ({
  radioList,
  defaultValue,
  direction = 'row',
  disabled = false,
  control,
  name,
  className,
}: RadioGroupProps) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      shouldUnregister={false}
      render={({ field: { onChange, value } }) => (
        <RadioGroupContainer direction={direction} className={className} data-automation-id="radio-group">
          {radioList.map((radioItem) => (
            <StyledRadioItem
              key={`${radioItem.value}_${radioItem.label}`}
              isSelected={radioItem.value === value}
              label={radioItem.label}
              value={radioItem.value}
              disabled={disabled}
              direction={direction}
              onClick={() => onChange(radioItem.value)}
            />
          ))}
        </RadioGroupContainer>
      )}
    />
  );
};

export default RadioGroup;
