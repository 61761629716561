import React from 'react';
import { ButtonBaseProps } from '../buttonBase/ButtonBase.consts';
import { StyledButtonBase, StyledClose } from './ButtonClose.style';

export const ButtonClose = (props: ButtonBaseProps) => {
  return (
    <StyledButtonBase {...props}>
      <StyledClose name="close" />
    </StyledButtonBase>
  );
};
