import styled from '@emotion/styled/macro';
import typography from 'styles/typography';
import TermConditionItem from './components/termConditionItem/TermConditionItem';

export const TermConditionsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 ${typography.unit * 6}px ${typography.unit * 10}px ${typography.unit * 6}px;
  overflow-y: auto;
`;

export const StyledTermConditionItem = styled(TermConditionItem)`
  margin: 0 ${typography.unit * 6}px ${typography.unit * 8}px 0;
`;

export const TermConditionsListWrapper = styled.div`
overflow: auto;
`;