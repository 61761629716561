import React from 'react';
import { StyledDiv, StyledItems } from './PriorityTitle.style';

export interface PriorityItem {
  id: number;
  name: string;
  value: number;
}

const PriorityTitle: React.FC<{ priority: PriorityItem[] }> = ({ priority }) => {
  return (
    <StyledDiv>
      {priority.map((item, index) => (
        <StyledItems key={item.id} color="green">
          {item.name}
        </StyledItems>
      ))}
    </StyledDiv>
  );
};

export default PriorityTitle;
