import { CalendarWeekStartsOn, DateFormats, DateTimeConfig, TimeFormats } from 'utils/types';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import { addDays as addDaysNew } from 'date-fns';

export const getMarketConfigurationTimezoneOffset = (timeZone: string, date = new Date()) => {
  const timezone = date.toLocaleString('en', { timeZone, timeStyle: 'long' }).split(' ').slice(-1)[0];
  return Date.parse(`${date.toString()} UTC`) - Date.parse(`${date.toString()} ${timezone}`);
};

export const getMarketConfigurationTimezoneOffsetLocalTime = (timeZone: string, date = new Date()) => {
  const timezone = date.toLocaleString('en', { timeZone, timeStyle: 'long' }).split(' ').slice(-1)[0];
  return Date.parse(`${date.toString()} ${timezone}`) - Date.parse(date.toUTCString());
};

export const getLocaleTimezoneOffset = (date: Date = new Date()) => {
  return new Date(date).getTimezoneOffset() * 60000;
};

export const formatDate = (
  date: string | Date,
  dateTimeConfig: DateTimeConfig,
  timeZone?: string,
  options?: Intl.DateTimeFormatOptions,
): string =>
  new Date(date).toLocaleDateString(
    dateTimeConfig.dateFormat === DateFormats.MonthDayYear ? 'en-US' : 'fr-FR', // only for date format template - replace dd/MM with MM/dd
    options || {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: dateTimeConfig.timeFormat === TimeFormats.Until12,
      ...(timeZone && { timeZone }), // include timezone for take account market configuration timezone
    },
  );

export const extractDate = (date: string | Date, dateTimeConfig: DateTimeConfig, timeZone?: string): string =>
  new Date(date).toLocaleDateString(
    dateTimeConfig.dateFormat === DateFormats.MonthDayYear ? 'en-US' : 'fr-FR', // only for date format template - replace dd/MM with MM/dd
    {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      ...(timeZone && { timeZone }), // include timezone for take account market configuration timezone
    },
  );

export const extractTime = (date: string | Date, dateTimeConfig: DateTimeConfig, timeZone?: string): string =>
  new Date(date).toLocaleTimeString(
    dateTimeConfig.dateFormat === DateFormats.MonthDayYear ? 'en-US' : 'fr-FR', // only for date format template - replace dd/MM with MM/dd
    {
      hour: '2-digit',
      minute: '2-digit',
      hour12: dateTimeConfig.timeFormat === TimeFormats.Until12,
      ...(timeZone && { timeZone }), // include timezone for take account market configuration timezone
    },
  );

export const formatTimeToHoursMinutes = (time: string | Date) => {
  return new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' });
};

export const convert24To12HoursFormat = (time: string | Date) => {
  return new Date(`2000-01-01T${time}`)
    .toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })
    .toLocaleUpperCase();
};

export const separateDateTime = (
  dateTime: string | Date | undefined,
  dateTimeConfig: DateTimeConfig,
  timeZone?: string,
) => {
  if (dateTime) {
    const date = extractDate(dateTime, dateTimeConfig, timeZone);
    const time = extractTime(dateTime, dateTimeConfig, timeZone);
    return [date.trim(), time.trim()];
  } else {
    return ['', ''];
  }
};

export const getCalenderWeekDays = (weekDays: { id: string; name: string }[], calendarWeekStartsOn: string) => {
  weekDays.push(
    ...weekDays.splice(
      0,
      weekDays.findIndex((day) => day.id === calendarWeekStartsOn),
    ),
  );
  return weekDays;
};

export const getYesterday = (): Date => new Date(new Date().setDate(new Date().getDate() - 1));

export const getLastWeek = (): Date => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
};

const isIsoDate = (date: string | Date) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(date.toString())) {
    return false;
  }
  return new Date(date).toISOString() === date;
};

export const convertDateToUtcAsIsoString = (date: string | Date, timezone: string) => {
  if (date && isIsoDate(date)) {
    return date;
  }

  const dateObj = new Date(date);
  return new Date(
    dateObj.getTime() - getLocaleTimezoneOffset(dateObj) - getMarketConfigurationTimezoneOffset(timezone, dateObj),
  ).toISOString();
};

export const convertDateToUtcAsIsoStringUsingLibrary = (date: string | Date, timezone: string) => {
  if (date && isIsoDate(date)) {
    return date;
  }

  return zonedTimeToUtc(date, timezone).toISOString();
};

export const convertUtcDateToTimezoneDate = (date: string | Date, timezone: string) => {
  const dateObj = new Date(date);
  if (date && isIsoDate(date)) {
    return new Date(
      dateObj.getTime() + getLocaleTimezoneOffset(dateObj) + getMarketConfigurationTimezoneOffset(timezone, dateObj),
    );
  }

  return dateObj;
};

export const convertUtcDateToTimezoneDateUsingLibrary = (date: string | Date, timezone: string) => {
  if (date && isIsoDate(date)) {
    return utcToZonedTime(date, timezone);
  }
  return new Date(date);
};

export const convertUtcDateToTimezoneDateUsingLibraryStartDate = (
  date: string | Date,
  timezone: string,
  daysToAdd: number,
) => {
  if (date && isIsoDate(date)) {
    return startOfDay(addDaysNew(utcToZonedTime(date, timezone), daysToAdd));
  }
  return new Date(date);
};

export const getMarketDatetime = (timezone: string, minuteOffset = 0): Date =>
  new Date(
    new Date().setMinutes(new Date().getMinutes() + minuteOffset) +
      getMarketConfigurationTimezoneOffset(timezone, new Date()) +
      getLocaleTimezoneOffset(),
  );

export const getMarketNextDayDatetime = (timezone: string, start: boolean): Date => {
  const nextDay = addDays(getMarketDatetime(timezone), 1);
  if (start) {
    return new Date(nextDay.setHours(0, 0, 0, 0));
  } else {
    return new Date(nextDay.setHours(23, 59, 0, 0));
  }
};

export const addDays = (date: string | Date, days: number) => {
  const d = new Date(date);
  d.setDate(d.getDate() + days);
  return d;
};

export const getDateFromDateTime = (date: string | Date, dateLocale: string) => {
  const d = new Date(date);
  return d.toLocaleDateString(dateLocale);
};

export const getDateInConfigFormat = (date: string | Date, dateTimeConfig: DateTimeConfig) => {
  return formatDate(new Date(date), dateTimeConfig, null, { timeZone: dateTimeConfig.startTimezone });
};
export const maxDate = (date1: Date, date2: Date) =>{
  return date1.getTime() > date2.getTime() ? date1 : date2;
}

export const dayOfWeekRecord: Record<CalendarWeekStartsOn, 0 | 1 | 2 | 3 | 4 | 5 | 6> = {
  [CalendarWeekStartsOn.Sunday]: 0,
  [CalendarWeekStartsOn.Monday]: 1,
  [CalendarWeekStartsOn.Tuesday]: 2,
  [CalendarWeekStartsOn.Wednesday]: 3,
  [CalendarWeekStartsOn.Thursday]: 4,
  [CalendarWeekStartsOn.Friday]: 5,
  [CalendarWeekStartsOn.Saturday]: 6,
};
