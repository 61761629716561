import styled from '@emotion/styled';
import { Icon, IconProps } from 'components/shared/icon';
import typography from 'styles/typography';
import ButtonBase from '../buttonBase/ButtonBase';

export const StyledClose = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.text.disabled};
  width: 12px;
  transition: all ${typography.transition}s;

  &:hover {
    fill: ${({ theme }) => theme.colors.text.primary};
  }
`;

export const StyledButtonBase = styled(ButtonBase)`
  background: none;
  border: none;
`;
