import styled from '@emotion/styled';
import { Icon, IconProps } from 'components/shared/icon';
import { appTheme } from 'styles/themes/defaultTheme';
import { ButtonText } from 'components/shared/button';
import typography from 'styles/typography';
import ToggleSwitch from 'components/shared/toggleSwitch/ToggleSwitch';

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  line-height: 30px;
  padding: 0 ${typography.unit * 3.5}px 0 0;

  [data-title]:after {
    bottom: 25%;
    right: 100%;
  }
`;

export const RowContainer = styled.div<{ disabled: boolean; theme?: appTheme }>`
  color: ${({ disabled, theme }) => (disabled ? theme.colors.text.disabled : theme.colors.text.primary)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const StyledIconButtonText = styled(ButtonText)`
  width: 14px;
  height: 24px;
`;

export const StyledIcon = styled(Icon)<IconProps & { disabled: boolean }>`
  fill: ${({ disabled, theme }) => (disabled ? theme.colors.global.inputDisabled : theme.colors.global.inputPrimary)};
  width: 10px;
`;

export const StyledToggleSwitch = styled(ToggleSwitch)``;
