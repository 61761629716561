import React from 'react';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { IconContainer, StyledIcon } from './LockIcon.style';

const LockIcon = () => {
  const id = Math.random().toString(32);

  return (
    <IconContainer data-for={id} data-tip>
      <StyledIcon name="lock" />
      <Tooltip id={id} onHover content="Locked for changes" place="bottom" />
    </IconContainer>
  );
};

export default LockIcon;
