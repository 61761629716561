import { combineReducers, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { clientQuery } from 'utils/api/clientQuery';
import type { RootState } from 'app/store';
import { termsConditionsGqls } from 'pages/settings/termsConditions/TermsConditions.gqls';
import { TermConditionProps, TermFilterType } from 'pages/settings/termsConditions/TermsConditions.consts';
import { buildBaseAndFilters, generateSearchFilter } from 'utils/serverFilters';
import { ArchiveStatus } from 'pages/settings/settings.const';
import { OrderDirection } from 'utils/types';

export enum TermsConditionsStateId {
  TermsConditionsPage = 'termsConditionsPage',
  TermsConditionsSelection = 'termsConditionsSelection',
}

export interface TermsFilterType {
  [TermFilterType.Id]: number;
  [TermFilterType.OfferTemplate]: number[];
  [TermFilterType.Archive]: ArchiveStatus[];
  [TermFilterType.SearchQuery]: string;
}

type TermsConditionsGenericSlice = {
  termsConditionsState: (state: RootState) => any;
  reducer: any;
  actions: any;
  loadTerms: any;
  loadTermsPage: any;
};

export interface TermsConditions {
  termsConditions: TermConditionProps[];
  total: number;
  filters: TermsFilterType;
  lastRequestId: string;
}

export const initialState: TermsConditions = {
  termsConditions: undefined,
  total: 0,
  filters: {
    [TermFilterType.Id]: null,
    [TermFilterType.Archive]: [ArchiveStatus.Unarchived],
    [TermFilterType.OfferTemplate]: [],
    [TermFilterType.SearchQuery]: '',
  },
  lastRequestId: '',
};

export const createTermsConditionsGenericSlice = (sliceName: TermsConditionsStateId): TermsConditionsGenericSlice => {
  const getFilters = (initialFilters: TermsFilterType): any => {
    const TermsServerFiltersRecord = {
      [TermFilterType.OfferTemplate]: { serverFilter: 'template', operation: 'in_array' },
      [TermFilterType.Id]: { serverFilter: 'id', operation: 'in' },
    };

    const filters = buildBaseAndFilters(TermsServerFiltersRecord, initialFilters);
    filters.AND.push({ originalTermId: { is_null: null } });

    if (initialFilters[TermFilterType.Archive]?.length === 1) {
      filters.AND.push({
        isArchive: {
          is: initialFilters[TermFilterType.Archive][0] === ArchiveStatus.Archived,
        },
      });
    }

    if (initialFilters[TermFilterType.SearchQuery]?.length) {
      const searchByFields = ['name', 'TermTranslation_content'];
      const idFields = ['id'];
      filters.AND.push(generateSearchFilter(searchByFields, idFields, initialFilters[TermFilterType.SearchQuery]));
    }

    return filters;
  };

  const loadTerms = createAsyncThunk(
    `terms/load/${sliceName}`,
    async (payload, thunkAPI): Promise<{ termsConditions: TermConditionProps[]; total: number }> => {
      const termsState = (thunkAPI.getState() as any).terms[sliceName];
      const { filters } = termsState;

      const terms = await clientQuery(termsConditionsGqls.queries.getAll, {
        data: { filters: getFilters(filters), order: { name: OrderDirection.ASC } },
      });

      return {
        termsConditions: terms.data.getTerms.items,
        total: terms.data.getTerms.total,
      };
    },
  );

  const loadTermsPage = createAsyncThunk(
    `terms/loadPage/${sliceName}`,
    async (payload, thunkAPI): Promise<{ termsConditions: TermConditionProps[]; total: number }> => {
      const termsState = (thunkAPI.getState() as any).terms[sliceName];
      const { filters } = termsState;

      const terms = await clientQuery(termsConditionsGqls.queries.getTermsTermsNCondsPage, {
        data: { filters: getFilters(filters), order: { name: OrderDirection.ASC } },
      });

      return {
        termsConditions: terms.data.getTermsTermsNCondsPage.items,
        total: terms.data.getTermsTermsNCondsPage.total,
      };
    },
  );

  const termsConditionsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      setFilters(state, action) {
        state.filters = action.payload;
      },
      setFilter(state, action) {
        state.filters = {
          ...state.filters,
          [action.payload.filter]: action.payload.value,
        };
      },
    },

    extraReducers: (builder) => {
      builder.addCase(loadTerms.pending, (state, action) => {
        return {
          ...state,
          termsConditions: undefined,
          lastRequestId: action.meta.requestId,
        };
      });
      builder.addCase(loadTerms.fulfilled, (state, action) => {
        if (action.meta.requestId === state.lastRequestId) {
          return {
            ...state,
            ...action.payload,
          };
        }
        return state;
      });

      builder.addCase(loadTerms.rejected, (state) => {
        showToast(MessageType.Error, 'Error loading terms & conditions');
        return state;
      });

      builder.addCase(loadTermsPage.pending, (state, action) => {
        return {
          ...state,
          termsConditions: undefined,
          lastRequestId: action.meta.requestId,
        };
      });
      builder.addCase(loadTermsPage.fulfilled, (state, action) => {
        if (action.meta.requestId === state.lastRequestId) {
          return {
            ...state,
            ...action.payload,
          };
        }
        return state;
      });

      builder.addCase(loadTermsPage.rejected, (state) => {
        showToast(MessageType.Error, 'Error loading terms & conditions');
        return state;
      });
    },
  });

  const termsConditionsState = (state: RootState) => state.terms[sliceName];
  return {
    termsConditionsState,
    reducer: termsConditionsSlice.reducer,
    actions: termsConditionsSlice.actions,
    loadTerms,
    loadTermsPage,
  };
};

export const termsConditionsPage: TermsConditionsGenericSlice = createTermsConditionsGenericSlice(
  TermsConditionsStateId.TermsConditionsPage,
);
export const termsConditionsSelection: TermsConditionsGenericSlice = createTermsConditionsGenericSlice(
  TermsConditionsStateId.TermsConditionsSelection,
);

const termsConditionsReducer = combineReducers({
  [TermsConditionsStateId.TermsConditionsPage]: termsConditionsPage.reducer,
  [TermsConditionsStateId.TermsConditionsSelection]: termsConditionsSelection.reducer,
});

export default termsConditionsReducer;
