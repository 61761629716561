import { UsageEntityType, UsageEntityTypeLabel } from "app/genericSlices/usage";
import { MessageType } from "components/shared/notifications/notifications";
import { showToast } from "components/shared/notifications/toastContainerWrapper/ToastContainerWrapper";
import { getOfferImpactsByIds } from "./api/offers";
import { getCampaignsForReport } from "./api/campaigns";
import { OrderDirection } from "./types";
import { getUsageReportRows } from "./impact";
import { clientQuery } from "./api/clientQuery";
import { configurationsGqls } from "pages/configurations/Configurations.gqls";
import { MarketConfiguration } from "app/slices/config";
import { format, utcToZonedTime } from "date-fns-tz";


export const downloadCSV = async (rows: string[][], fileName: string): Promise<void> => {
  const sanitizedRows = rows
    .map((row: string[]) => {
      return row
        .map((col: string) => {
          return `"${col?.toString().replaceAll('"', '""')}"`;
        })
        .join(',');
    })
    .join('\n');
  const csvContent = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(sanitizedRows)}`;
  const link = document.createElement('a');
  link.setAttribute('href', csvContent);
  const configu:any = await clientQuery(configurationsGqls.queries.getAll, {data:{},})
  const timeZone=configu.data.getConfigurations.config.syncTimeZone;
  const now = new Date();
  const zonedDate = utcToZonedTime(now, timeZone);
  const formattedDate = `${format(zonedDate, 'yyyy-MM-dd')}_${format(zonedDate, 'HH:mm:ss')}`;
  link.setAttribute('download', `${fileName}_${formattedDate}.csv`);
  document.body.appendChild(link); // Required for FF
  link.click();
  document.body.removeChild(link);
};


export const downloadUsageReport = async (entityId: number,config:any, setIsDownloading?: any) => {
  setIsDownloading && setIsDownloading(true);
  const [allCampaignsUsage, allOffersUsage] = await Promise.all(
    [
      getCampaignsForReport({
        filters: {
          bool: {
            must: [
              {
                bool: {
                  should: [
                    {
                      match: {
                        "offerVersion.term.id": entityId
                      }
                    },
                    {
                      match: {
                        "offerVersion.term.originalTermId": entityId
                      }
                    }
                  ]
                }
              }
            ]
          }
        },
        limit: 9999,
        offset: 0,
        order: {
          externalId: OrderDirection.DESC,
        },
        isBatchingRequired: true,
      }),
      getOfferImpactsByIds(
        {
          bool: {
            must: [
              {
                bool: {
                  should: [
                    {
                      match: {
                        "versions.term.id": entityId
                      }
                    },
                    {
                      match: {
                        "versions.term.originalTermId": entityId
                      }
                    }
                  ]
                }
              }
            ]
          }
        }, config)
    ]);
  if (allCampaignsUsage.length > 0 || allOffersUsage.length > 0) {
    const rows = getUsageReportRows(allCampaignsUsage, allOffersUsage, undefined, UsageEntityType.TermCondition);
    downloadCSV(rows, `UsageSummary_${UsageEntityTypeLabel[UsageEntityType.TermCondition]}_ID#${entityId}`);
    setIsDownloading && setIsDownloading(false);
  } else {
    showToast(MessageType.Info, `No Offer or Campaign is using this Terms and Conditions`);
    setIsDownloading && setIsDownloading(false);
  }
};
