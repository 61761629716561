export enum RedemptionOverviewDropdownEnum {
  Today = 'today',
  Yesterday = 'yesterday',
  Last7Days = 'last7Days',
  Last30Days = 'last30Days',
}

export const RedemptionOverviewRecord: Record<RedemptionOverviewDropdownEnum, string> = {
  [RedemptionOverviewDropdownEnum.Today]: 'Today',
  [RedemptionOverviewDropdownEnum.Yesterday]: 'Yesterday',
  [RedemptionOverviewDropdownEnum.Last7Days]: 'Last 7 days',
  [RedemptionOverviewDropdownEnum.Last30Days]: 'Last 30 days',
};
