import React, { useEffect, useRef, useState } from 'react';

export default function useDynamicSVGImport(name: string) {
  const ImportedIconRef = useRef<React.FC<React.SVGProps<SVGSVGElement>>>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const importIcon = async (): Promise<void> => {
      try {
        ImportedIconRef.current = (
          await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../assets/svgs/${name}.svg`)
        ).default;
      } finally {
        setLoading(false);
      }
    };
    importIcon();
    return () => {
      setLoading(false);
    };
  }, [name]);

  return { loading, SvgIcon: ImportedIconRef.current };
}
