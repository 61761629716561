import { client } from 'app/apollo';
import { GenericEntity, OrderDirection } from 'utils/types';
import locationSetsGqls from 'pages/settings/locationSets/LocationSets.gqls';
import { LocationSet, RestaurantEligibility } from 'utils/types/locations';
import { clientQuery } from 'utils/api/clientQuery';
import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import { LocationSetsFilters } from 'pages/settings/locationSets/LocationSets.consts';
import { getAvailableLocations } from 'utils/api/locations';

export const getLocationSets = async (
  filters: any,
  limit?: number,
  currentPage?: number,
  order?: Record<string, OrderDirection>,
) => {
  const result = await client.query<{ getLocationSets: GenericEntity }>({
    query: locationSetsGqls.queries.getAllLocationSets,
    fetchPolicy: 'network-only',
    variables: {
      data: {
        filters,
        order,
        offset: currentPage * limit,
        limit,
      },
    },
  });

  return result.data.getLocationSets;
};

export const getLocationSetById = async (id: number) => {
  const result = await client.query<{ getLocationSets: GenericEntity }>({
    query: locationSetsGqls.queries.getAllLocationSets,
    fetchPolicy: 'network-only',
    variables: {
      data: {
        filters: { [LocationSetsFilters.LocationSetsIds]: [id] },
        offset: 0,
        limit: 1,
      },
    },
  });

  return result.data.getLocationSets.items?.[0];
};

export const getAvailableLocationsCountBySearchValue = async (
  searchValue: string,
  selectedItemsIds: number[],
  restaurantEligibility: RestaurantEligibility,
) => {
  const filters = {
    [LocationSetsFilters.SearchQuery]: searchValue,
    [LocationSetsFilters.WithoutLocationsIds]: selectedItemsIds,
    [LocationSetsFilters.ByRestaurantEligibility]: restaurantEligibility,
  };
  const result = await getAvailableLocations(filters);
  return result.total;
};

export const getRootLocationSetsByIds = async (
  filters: any,
  limit?: number,
  currentPage?: number,
  order?: Record<string, OrderDirection>,
): Promise<LocationSet[]> => {
  const locationSetsIds = filters[LocationSetsFilters.LocationSetsIds];
  if (!locationSetsIds.length) {
    return [];
  }

  const locationSets = await getLocationSets(filters, limit, currentPage, order);
  return locationSets.items;
};

const getImpactFiltersBySetId = (setId: number): { bool: { must: any[] } } => {
  return {
    bool: {
      must: [
        {
          match: {
            'restaurantEligibility.restaurantGroups.id': setId,
          },
        },
        {
          match: { 'restaurantEligibility.restaurantGroups.type': 'custom' },
        },
      ],
    },
  };
};

export const getCampaignsImpactsByLocationSetId = async (externalSetId: number, limit: number = 9999) => {
  const campaignsImpacts = await clientQuery(campaignsGqls.queries.getForImpact, {
    data: {
      filters: getImpactFiltersBySetId(externalSetId),
      order: {
        externalId: 'DESC',
      },
      limit,
      ...(limit === 9999 && { isBatchingRequired: true }),
    },
  });

  return campaignsImpacts.data.getCampaigns.items;
};
