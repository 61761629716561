import styled from '@emotion/styled';
import { StyledWithThemeProps } from 'utils/types';
import typography from 'styles/typography';

type Color = 'green' | 'red' | 'yellow' | 'blue';
export const DragableStyledItem = styled.div<
  StyledWithThemeProps & { color?: Color; disabled?: boolean }
>`
  height: 80px;
  width: 400px;
  background-color: ${({ color, theme }) => color && theme.colors.dashboard[color]};
  padding: ${typography.unit * 2}px ${typography.unit * 3}px;
  border-radius: ${typography.borderRadius}px;
  border: ${({ color, theme }) => !color && `1px solid ${theme.colors.global.border}`};
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  &:hover {
    cursor: ${({ disabled }) => (disabled ? `cursor` : `grab`)};
    box-shadow: ${({disabled, theme }) => !disabled && theme.colors.global.boxShadow};
  }
  &:active {
    cursor: ${({ disabled }) => (disabled ? `cursor` : `grabbing`)};
  }
  margin: 8px;
`;
