import React, { useEffect, useState, useRef } from 'react';
import {
  DropdownButton,
  DropdownContent,
  DropdownItem,
  StyledRedemptionOverviewDropdown,
} from './RedemptionOverviewDropdown.style';
import { Icon } from 'components/shared/icon';
import useOnClickOutside from 'hooks/use-onclick-outside';

export const RedemptionOverviewDropdown = ({ options, onSelect, defaultOption }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption || null);
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const selectOption = (option: any) => {
    setSelectedOption(option);
    onSelect(option.id);
    setIsOpen(false);
  };

  return (
    <StyledRedemptionOverviewDropdown ref={dropdownRef} className={`custom-dropdown ${isOpen ? 'open' : ''}`}>
      <DropdownButton
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {selectedOption ? selectedOption.name : 'Select an option'}
        <Icon name="arrowDown" />
      </DropdownButton>
      {isOpen && (
        <DropdownContent>
          {options.map((option: any) => (
            <DropdownItem key={option.id} onClick={() => selectOption(option)}>
              {option.name}
            </DropdownItem>
          ))}
        </DropdownContent>
      )}
    </StyledRedemptionOverviewDropdown>
  );
};
