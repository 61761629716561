import { store } from 'app/store';
import { closeModal } from 'app/slices/modals';
import { MessageType } from 'components/shared/notifications/notifications';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { createNewPeriod, updatePeriod } from 'utils/api/periods';
import { PeriodProps } from 'pages/settings/schedulePeriods/SchedulePeriods.consts';

export const createPeriod = async (period: PeriodProps) => {
  try {
    await createNewPeriod(period);
    store.dispatch(closeModal());
    showToast(MessageType.Success, `Period added successfully`);
  } catch (e) {
    showToast(
      MessageType.Error,
      `Failed to add period ${e.message.includes('duplication item') ? `${period.name} already exists` : (e.message.includes('already exists with the same dates') ? ` - Period already exists` : '')}`,
    );
  }
};

export const editPeriod = async (period: Partial<PeriodProps>) => {
  try {
    await updatePeriod(period);
    store.dispatch(closeModal());
    showToast(MessageType.Success, `Period updated successfully`);
  } catch (e) {
    showToast(
      MessageType.Error,
      `Failed to update period ${e.message.includes('duplication item') ? ` - ${period.name} already exists` : (e.message.includes('already exists with the same dates') ? ` - Period already exists` : '')}`,
    );
  }
};
