import { gql } from '@apollo/client';

export const notificationsGqls = {
  queries: {
    getAll: gql`
      query getNotifications($data: GetAllQueryDto) {
        getNotifications(data: $data) {
          items {
            ... on Notification {
              id
              action
              entityType
              entitiesData
              isRead
              isArchive
              createdAt
              user {
                firstName
                lastName
              }
              game {
                id
                name
              }
            }
          }
          total
        }
      }
    `,
  },
  mutations: {
    update: gql`
      mutation UpdateNotification($data: UpdateNotificationDto!) {
        updateNotification(data: $data) {
          id
          isRead
          isArchive
        }
      }
    `,
    updateAllNotifications: gql`
      mutation updateAllNotificationsBySubscriberId($data: UpdateNotificationDto!) {
        updateAllNotificationsBySubscriberId(data: $data) {
          id
          isRead
          isArchive
        }
      }
    `,
  },
};
