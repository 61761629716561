import { ApprovalStatus } from 'utils/types';

export enum LocalScheduleAlert {
  SubmitForApprovalFailed = 'submitForApprovalFailed',
  ApprovalFailed = 'approvalFailed',
  Conflicted = 'conflicted',
  NoApprovalFailed = 'noApprovalFailed',
  AllApprovalFailed = 'allApprovalFailed'
}

export const LocalScheduleAlertLabel: Record<LocalScheduleAlert, string> = {
  [LocalScheduleAlert.SubmitForApprovalFailed]: 'Only a portion of the Schedules have been submitted for approval',
  [LocalScheduleAlert.ApprovalFailed]: 'Only a portion of the Schedules have been deployed',
  [LocalScheduleAlert.Conflicted]: 'Conflicted',
  [LocalScheduleAlert.NoApprovalFailed]: 'Only a portion of schedule have been approved',
  [LocalScheduleAlert.AllApprovalFailed]: 'None of the campaigns in the schedule have been deployed',
};

export interface CampaignLocalSchedule {
  id: number;
  approvalStatus: ApprovalStatus;
  zone: {
    id: number;
    name: string;
  };
  period: {
    id: number;
    name: string;
  };
  campaignsNumber: number;
  alerts?: LocalScheduleAlert[];
  campaignsValidNumber: number;
  updateProcessStatus?: string;
}
