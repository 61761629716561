import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { Period } from 'utils/types/period';

export interface CalendarSlice {
  filters: {
    periods: number[];
    period: Period;
  };
}

export const initialState: CalendarSlice = {
  filters: {
    periods: [],
    period: null,
  },
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
  },
});

export const calendar = (state: RootState) => state.calendar;
export const { setFilters } = calendarSlice.actions;

export default calendarSlice.reducer;
