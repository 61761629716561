import styled from '@emotion/styled';
import { ButtonOutlined, ButtonText } from 'components/shared/button';
import { Icon } from 'components/shared/icon';
import { StyledFilterBarSelect } from 'pages/shared/shared.style';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';

export const Filters = styled.div`
  display: flex;
`;

export const MonthSelector = styled.div`
  display: flex;
  margin-right: ${typography.unit * 12}px;
  align-items: center;
`;

export const Month = styled.p`
  font-size: 20px;
  line-height: 24px;
  width: 200px;
  margin-right: ${typography.unit * 3}px;
`;

export const StyledButton = styled(ButtonText)`
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media print {
    display: none;
  }
`;

export const Previous = styled(Icon)<StyledWithThemeProps>`
  transform: rotate(-90deg);
  color: ${({ theme }) => theme.colors.text.disabled};
`;

export const Next = styled(Previous)`
  transform: rotate(90deg);
`;

export const Today = styled(ButtonOutlined)`
  color: ${({ theme }) => theme.colors.text.primary};

  @media print {
    display: none;
  }
`;

export const ViewButton = styled(ButtonOutlined)`
  margin-left: auto;

  @media print {
    display: none;
  }
`;

export const ZonesSelector = styled.div``;

export const ViewSelector = styled(StyledFilterBarSelect)`
  @media print {
    display: none;
  }
`;

export const PrintButton = styled(ButtonOutlined)`
  align-self: flex-end;
  margin: 0 0 0 ${typography.unit * 2}px;
  width: 32px;
  padding-right: 0;
  padding-left: 0;

  @media print {
    display: none;
  }
`;
export const PrintIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.global.inputPrimary};
`;
