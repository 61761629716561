import React from 'react';
import { ActionType } from 'utils/types';
import { StyledToggleSwitch } from 'pages/shared/setItemsSelectionForm/listItem/ListItem.style';
import { Container, StyledIcon, StyledIconButtonText, Title, Action } from './SetListItemHeader.style';
import { SetListItemHeaderProps } from './SetListItemHeader.consts';

const SetListItemHeader = ({
  set,
  titleFormatter,
  actionType,
  onIconClick,
  onExcludeClick,
  className,
  disabled,
  setSize,
  wholeSetSelected,
  supportSetFringes,
}: SetListItemHeaderProps) => {
  const actionTypeToIcon: Record<ActionType, any> = {
    [ActionType.Add]: (
      <StyledIconButtonText disabled={disabled} onClick={onIconClick} data-title="Add">
        <StyledIcon disabled={disabled} name="plus" />
      </StyledIconButtonText>
    ),
    [ActionType.Remove]: (
      <StyledIconButtonText disabled={disabled} onClick={onIconClick} data-title="Remove">
        <StyledIcon disabled={disabled} name="minus" />
      </StyledIconButtonText>
    ),
    [ActionType.AddExcluded]: (
      <StyledToggleSwitch
        checked
        disabled={disabled}
        size="small"
        onClick={(e) => onExcludeClick(e, ActionType.AddExcluded, set)}
      />
    ),
    [ActionType.RemoveExcluded]: (
      <StyledToggleSwitch
        size="small"
        disabled={disabled}
        onClick={(e) => onExcludeClick(e, ActionType.RemoveExcluded, set)}
      />
    ),
  };

  return (
    <Container className={className} data-automation-id="set-list-item-header">
      <Title disabled={disabled}>{titleFormatter({ set, setSize, wholeSetSelected, supportSetFringes })}</Title>
      {actionType && <Action disabled={disabled}>{actionType && actionTypeToIcon[actionType]}</Action>}
    </Container>
  );
};

export default SetListItemHeader;
