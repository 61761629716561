import { createSlice } from '@reduxjs/toolkit';
import { OrderDirection } from 'utils/types';
import type { RootState } from 'app/store';
import { LocationSetsFilters } from 'pages/settings/locationSets/LocationSets.consts';

export interface LocationsState {
  name: string;
  filters: { [LocationSetsFilters.SearchQuery]: string };
  order?: { [fieldName: string]: OrderDirection };
}

export const initialState: LocationsState = {
  name: '',
  filters: null,
  order: { name: OrderDirection.ASC },
};

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setOrder(state, action) {
      state.order = action.payload;
    },
  },
});

export const locations = (state: RootState) => state.locations;
export const { setFilters, setOrder } = locationsSlice.actions;
export default locationsSlice.reducer;
