export const debounce = (func: (...args: any[]) => void, debounceTime: number) => {
  let timeout: any;

  if (debounceTime) {
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), debounceTime);
    };
  }
  return (...args: any[]): void => {
    // eslint-disable-next-line no-unused-expressions
    func && func(...args);
  };
};
