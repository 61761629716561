import React, { useEffect, useState } from 'react';
import { DiscountType } from 'utils/types/offers';
import { useFormContext, useWatch } from 'react-hook-form';
import { calcProductsHint } from 'utils/product';
import useDiscountType from 'hooks/use-discount-type';
import { ProductPicker, StyledCheckbox, StyledForSelectbox, StyledLabel, StyledTextField } from '../Template.style';
import { DiscountTypeRecord, TemplateProps } from '../Template.consts';
import getProductsAction from '../shared/ProductsActionCondition';
import { Currency } from '../shared/Currency/Currency';

export const BuyBuyGet = ({ disabled, onProductSelection }: TemplateProps) => {
  const {
    register,
    control,
    getValues,
    // TODO: Commenting below code for hiding EOLV checkbox until POS work is complete. Comment will be removed as part of VCE-4591
    //watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const selectProducts ='Select Products';
  const { discountType, isFree, isPercent, shouldDisplayCurrency } = useDiscountType();
  // TODO: Commenting below code for hiding EOLV checkbox until POS work is complete. Comment will be removed as part of VCE-4591
  //const [eolv, setEolv] = useState(watch('versions.0.templateValues.eolvEnhancedLogic'));
  const [buyProductsA, buyProductsB, getProducts] = useWatch({
    control,
    name: [
      'versions.0.templateValues.buyProductsA',
      'versions.0.templateValues.buyProductsB',
      'versions.0.templateValues.getProducts',
    ],
  });

  useEffect(() => {
    if (!discountType) {
      setValue('versions.0.templateValues.discountType', DiscountType.MoneyOff);
    }
  }, []);
  // TODO: Commenting below code for hiding EOLV checkbox until POS work is complete. Comment will be removed as part of VCE-4591
  // useEffect(() => {
  //   setValue('versions.0.templateValues.eolvEnhancedLogic', eolv);
  // }, [eolv]);

  return (
    <>
      <div>
        <StyledLabel>Buy</StyledLabel>
        <ProductPicker>
          <span>1</span>
          {calcProductsHint(buyProductsA)}
          {getProductsAction(
            disabled,
            buyProductsA,
            getValues,
            selectProducts,
            'buyProductsA',
            (data: any) => ({
              buyProductsA: data,
            }),
            onProductSelection,
          )}
          <input
            type="hidden"
            {...register('versions.0.templateValues.buyProductsA', {
              value: buyProductsA,
              shouldUnregister: true,
              required: true,
            })}
          />
        </ProductPicker>
        <ProductPicker>
          <span>and 1</span>
          {calcProductsHint(buyProductsB)}
          {getProductsAction(
            disabled,
            buyProductsB,
            getValues,
            selectProducts,
            'buyProductsB',
            (data: any) => ({
              buyProductsB: data,
            }),
            onProductSelection,
          )}
          <input
            type="hidden"
            {...register('versions.0.templateValues.buyProductsB', {
              value: buyProductsB,
              shouldUnregister: true,
              required: true,
            })}
          />
        </ProductPicker>
      </div>
      <div>
        <StyledLabel>Get</StyledLabel>
        <ProductPicker>
          <span>1</span>
          {calcProductsHint(getProducts)}
          {getProductsAction(
            disabled,
            getProducts,
            getValues,
            selectProducts,
            'getProducts',
            (data: any) => ({
              getProducts: data,
            }),
            onProductSelection,
          )}
          <input
            type="hidden"
            {...register('versions.0.templateValues.getProducts', {
              value: getProducts,
              shouldUnregister: true,
              required: true,
            })}
          />
        </ProductPicker>
      </div>
      <div>
        <StyledLabel>For</StyledLabel>
        <StyledForSelectbox
          name="versions.0.templateValues.discountType"
          control={control}
          disabled={disabled}
          validation={{
            required: true,
          }}
          items={
            Object.entries(DiscountTypeRecord()).map(([key, value]) => {
              return {
                id: key,
                name: value,
              };
            }) as any[]
          }
          selectWidth={100}
          defaultValue={DiscountType.MoneyOff}
          initialSelectedItems={discountType ? [discountType] : [DiscountType.MoneyOff]}
        />
        {!isFree && (
          <>
            {shouldDisplayCurrency && <Currency />}
            <StyledTextField
              disabled={disabled}
              register={register}
              validation={{
                required: true,
                min: isPercent ? 1 : 0.01,
                max: isPercent ? 99 : null,
                pattern: isPercent ? /^([1-9][0-9]?|99)$/ : /^\d+(?:\.\d{1,2})?$/,
                validate: (v: string) =>
                  (!Number.isNaN(Number(v)) && Number(v) >= 0.01) || (isPercent && Number(v) < 100 && Number(v) >= 1),
              }}
              errors={errors}
              name="versions.0.templateValues.discountValue"
              placeholder="Enter value"
            />
          </>
        )}
        {
        // TODO: Commenting below code for hiding EOLV checkbox until POS work is complete. Comment will be removed as part of VCE-4591
        /* <StyledCheckbox
          checked={eolv}
          disabled={disabled}
          onClick={() => setEolv(!eolv)}
          label="Equal or Lesser Value"
        /> */}
      </div>
    </>
  );
};
