import React, { useEffect, useState } from 'react';
import { DiscountCondition, DiscountConditionRecord, DiscountType } from 'utils/types/offers';
import { useFormContext } from 'react-hook-form';
import { calcProductsHint } from 'utils/product';
import useDiscountType from 'hooks/use-discount-type';
import { DiscountTypeRecord, TemplateProps } from '../Template.consts';
import {
  ProductPicker,
  StyledBuySelectbox,
  StyledForSelectbox,
  StyledLabel,
  StyledLightLabel,
} from '../Template.style';
import { StyledTextField } from './PriceDeal.style';
import getProductsAction from '../shared/ProductsActionCondition';
import { Currency } from '../shared/Currency/Currency';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { ProductSetType } from 'utils/types';
import { store } from 'app/store';
import { setData } from 'app/slices/modals';
import { checkForEmptyData } from '../shared/ProductsActionCondition.utils';

export const PriceDeal = ({ disabled, onProductSelection }: TemplateProps) => {
  const {
    control,
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const selectProducts ='Select Products';
  const templateValuesIncludeNonFoodProductCodesPath='versions.0.templateValues.includeNonFoodProductCodes'
  const { discountType, isFree, isPercent, shouldDisplayCurrency } = useDiscountType();
  const [discountCondition, products, excludeCodes, get, includeNonFoodProductCodes] = watch([
    'versions.0.templateValues.discountCondition',
    'versions.0.templateValues.products',
    'versions.0.templateValues.excludeCodes',
    'versions.0.templateValues.upToLimit',
    templateValuesIncludeNonFoodProductCodesPath,
  ]);
  const [includeNonProduct, setIncludeNonProduct] = useState(watch('versions.0.templateValues.includeNonProduct'));

  useEffect(() => {
    setValue('versions.0.templateValues.includeNonProduct', includeNonProduct);
    if (includeNonProduct) {
      setValue(templateValuesIncludeNonFoodProductCodesPath, undefined);
    }
  }, [includeNonProduct]);

  return (
    <>
      <div>
        <StyledLabel>Get</StyledLabel>
        <StyledLightLabel>Up to</StyledLightLabel>
        <StyledBuySelectbox
          control={control}
          name="versions.0.templateValues.upToLimit"
          disabled={disabled}
          validation={{
            min: 1,
            max: 15,
            required: true,
          }}
          items={Array.from(
            { length: 15 },
            (x, i) =>
              ({
                id: (i + 1).toString(),
                name: (i + 1).toString(),
              } as any),
          )}
          defaultValue="1"
          initialSelectedItems={get ? [typeof get === 'object' ? get.id : get] : ['1']}
          selectWidth={60}
        />
        <ProductPicker>
          {calcProductsHint(products)}
          {getProductsAction(
            disabled,
            products,
            getValues,
            selectProducts,
            'products',
            (data: any) => ({
              products: data,
            }),
            onProductSelection,
          )}
          <input
            type="hidden"
            {...register('versions.0.templateValues.products', {
              value: products,
              shouldUnregister: true,
              required: true,
            })}
          />
        </ProductPicker>
      </div>
      <div>
        <StyledLabel>For</StyledLabel>
        <StyledForSelectbox
          name="versions.0.templateValues.discountType"
          control={control}
          disabled={disabled}
          validation={{
            required: true,
          }}
          items={
            Object.entries(DiscountTypeRecord()).map(([key, value]) => {
              return {
                id: key,
                name: value,
              };
            }) as any[]
          }
          selectWidth={100}
          defaultValue={DiscountType.MoneyOff}
          initialSelectedItems={discountType ? [discountType] : [DiscountType.MoneyOff]}
        />
        {!isFree && (
          <>
            {shouldDisplayCurrency && <Currency />}
            <StyledTextField
              disabled={disabled}
              register={register}
              validation={{
                required: true,
                min: isPercent ? 1 : 0.01,
                max: isPercent ? 99 : null,
                pattern: isPercent ? /^([1-9][0-9]?|99)$/ : /^\d+(?:\.\d{1,2})?$/,
                validate: (v: string) =>
                  (!Number.isNaN(Number(v)) && Number(v) >= 0.01) || (isPercent && Number(v) < 100 && Number(v) >= 1),
              }}
              errors={errors}
              name="versions.0.templateValues.discountValue"
              placeholder="Enter value"
            />
          </>
        )}
        <>
          <StyledLightLabel>with</StyledLightLabel>
          <StyledForSelectbox
            name="versions.0.templateValues.discountCondition"
            control={control}
            disabled={disabled}
            validation={{
              required: true,
            }}
            items={
              Object.entries(DiscountConditionRecord)
                .filter(([key]) => key !== DiscountCondition.None && key !== DiscountCondition.WithPurchaseOf)
                .map(([key, value]) => {
                  return {
                    id: key,
                    name: value,
                  };
                }) as any[]
            }
            defaultValue={DiscountCondition.MinimumPurchase}
            initialSelectedItems={
              discountCondition && discountCondition !== DiscountCondition.None
                ? [discountCondition]
                : [DiscountCondition.MinimumPurchase]
            }
            selectWidth={150}
          />
          {discountCondition === DiscountCondition.MinimumPurchase && (
            <>
              <StyledLightLabel>of</StyledLightLabel>
              <Currency />
              <StyledTextField
                disabled={disabled}
                register={register}
                validation={{
                  required: true,
                  min: 0.01,
                  max: null,
                  pattern: /^\d+(?:\.\d{1,2})?$/,
                  validate: (v: string) => !Number.isNaN(Number(v)) && Number(v) >= 0.01,
                }}
                errors={errors}
                name="versions.0.templateValues.conditionValue"
                placeholder="Enter value"
              />
            </>
          )}
        </>
      </div>
      <div>
        <StyledLabel>Non-Product PLUs to Include</StyledLabel>
        <ProductPicker>
          {calcProductsHint(includeNonFoodProductCodes)}
          {getProductsAction(
            disabled || includeNonProduct,
            includeNonFoodProductCodes,
            getValues,
            selectProducts,
            'includeNonFoodProductCodes',
            (data: any) => ({
              includeNonFoodProductCodes: !checkForEmptyData(data) ? data : undefined,
            }),
            onProductSelection,
            true,
            ProductSetType.NonFoodProduct,
          )}
          <input
            type="hidden"
            {...register(templateValuesIncludeNonFoodProductCodesPath, {
              value: includeNonFoodProductCodes,
              shouldUnregister: true,
              setValueAs: (v) => (v === '' ? undefined : v),
            })}
          />
        </ProductPicker>
        <Checkbox
          checked={includeNonProduct}
          disabled={disabled}
          onClick={() => {
            setIncludeNonProduct(!includeNonProduct);
            store.dispatch(
              setData({
                data: {
                  templateProducts: {
                    includeNonFoodProductCodes: includeNonProduct ? undefined : includeNonFoodProductCodes,
                  },
                },
              }),
            );
          }}
          label="Include All"
        />
      </div>
      {(discountCondition === DiscountCondition.MinimumPurchase ||
        discountCondition === DiscountCondition.WithAnyPurchase) && (
        <>
          <div>
            <StyledLabel>Exclude</StyledLabel>
            <ProductPicker>
              {calcProductsHint(excludeCodes)}
              {getProductsAction(
                disabled,
                excludeCodes,
                getValues,
                selectProducts,
                'excludeCodes',
                (data: any) => ({
                  excludeCodes: !checkForEmptyData(data) ? data : undefined,
                }),
                onProductSelection,
                true,
              )}
              <input
                type="hidden"
                {...register('versions.0.templateValues.excludeCodes', {
                  value: excludeCodes,
                  shouldUnregister: true,
                  setValueAs: (v) => (v === '' ? undefined : v),
                })}
              />
            </ProductPicker>
          </div>
        </>
      )}
    </>
  );
};
