import { FormMode, StyledWithThemeProps } from 'utils/types';
import { TagProps } from 'pages/settings/tags/Tags.consts';

export interface TagFormFooterProps extends StyledWithThemeProps {
  tag: TagProps;
  mode: FormMode;
  isSubmitting: boolean;
  isLocked: boolean;
  tagImpacts: any;
  onSubmit: () => any;
}

export enum TagActions {
  Edit = 'edit',
  ViewUsage = 'view usage',
  Delete = 'delete',
  Save = 'save',
}
