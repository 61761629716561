import { gql } from '@apollo/client';

export const redemptionsGqls = {
  queries: {
    getAll: gql`
      query Redemptions($data: GetCampaignRedemptionReportDto!) {
        getCampaignRedemptionsByIds(data: $data) {
          keys
          data
        }
      }
    `,
  },
};
