import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { OrderDirection } from 'utils/types';
import type { RootState } from 'app/store';
import { PeriodStatus, PeriodsFilterType } from 'pages/settings/schedulePeriods/SchedulePeriods.consts';
import { generateSearchFilter } from 'utils/serverFilters';

export enum PeriodsStateId {
  PeriodsPage = 'periodsPage',
}

type PeriodsGenericSlice = {
  periodsState: (state: RootState) => any;
  reducer: any;
  actions: any;
  getFilters: any;
};

export interface Periods {
  filters: { [PeriodsFilterType.SearchQuery]: string; [PeriodsFilterType.Status]: PeriodStatus[] };
  order: {
    [key: string]: OrderDirection;
  };
}

export const initialState: Periods = {
  filters: {
    [PeriodsFilterType.SearchQuery]: '',
    [PeriodsFilterType.Status]: [PeriodStatus.Live, PeriodStatus.NotStarted],
  },
  order: {
    dates: OrderDirection.DESC,
    id: OrderDirection.DESC,
  },
};

export const createPeriodsGenericSlice = (sliceName: PeriodsStateId): PeriodsGenericSlice => {
  const getFilters = (initialFilters: { [key in PeriodsFilterType]?: any[] }): any => {
    const filters: { [key: string]: any[] } = { AND: [] };
    const statusArray = initialFilters[PeriodsFilterType.Status];
    if (statusArray && statusArray?.length && statusArray?.length !== 3) {
      filters.AND.push({ statusArray });
    }
    if (initialFilters[PeriodsFilterType.SearchQuery]?.length) {
      const searchByFields = ['name'];
      filters.AND.push(generateSearchFilter(searchByFields, [], initialFilters[PeriodsFilterType.SearchQuery]));
    }

    return filters;
  };

  const periodsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      setFilter(state, action) {
        state.filters = {
          ...state.filters,
          [action.payload.filter]: action.payload.value,
        };
      },
      setFilters(state, action) {
        state.filters = action.payload;
      },
      setOrder(state, action) {
        state.order = action.payload;
      },
    },
  });

  const periodsState = (state: RootState) => state.periods[sliceName];
  return {
    periodsState,
    reducer: periodsSlice.reducer,
    actions: periodsSlice.actions,
    getFilters,
  };
};

export const periodsPage: PeriodsGenericSlice = createPeriodsGenericSlice(PeriodsStateId.PeriodsPage);

const periodsReducer = combineReducers({
  [PeriodsStateId.PeriodsPage]: periodsPage.reducer,
});

export default periodsReducer;
