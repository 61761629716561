import React from 'react';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { MarketConfigurationGuardProps } from 'components/zoneGuard/MarketConfigurationGuard.consts';

export const MarketConfigurationGuard = ({ children, configurations }: MarketConfigurationGuardProps) => {
  const { config } = useSelector(marketConfig);
  if (configurations?.some((configToCheck) => config[configToCheck.configKey] !== configToCheck.value)) {
    return null;
  }

  return <>{children}</>;
};
