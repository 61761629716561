import styled from '@emotion/styled';

export const SectionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  min-height: 20px;
`;

export const SelectOfferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  position: absolute;
  top: 0;
  left: 0;
`;
