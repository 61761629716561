import styled from '@emotion/styled';
import typography from 'styles/typography';
import 'react-toastify/dist/ReactToastify.css';
import { StyledWithThemeProps } from 'utils/types';
import { Icon } from '../../icon';
import { StyledNotificationWithTheme } from '../notifications';
import { ButtonClose } from '../../button';

export const Container = styled.div<StyledNotificationWithTheme>`
  width: auto;
  min-height: 48px;
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.colors.global.boxShadow};
  border: 1px solid ${({ theme, type }) => theme.colors.notifications.primary[type]};
  background-color: ${({ theme, type }) => theme.colors.notifications.background[type]};
  padding: ${typography.unit * 4}px ${typography.unit * 5}px;
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)<StyledNotificationWithTheme>`
  fill: ${({ theme, type }) => theme.colors.notifications.primary[type]};
  width: 16px;
  margin-right: ${typography.unit * 5}px;
`;

export const StyledMsg = styled.div<StyledWithThemeProps>`
  font-size: ${typography.fontSize}px;
  font-family: ${typography.primaryFont};
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledButtonClose = styled(ButtonClose)`
  margin-left: auto;
`;
