import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { generateCampaignStatusFilters, generateOfferStatusFilters } from 'utils/serverFilters';
import { ApprovalStatus } from 'utils/types';

export enum ImpactStateId {
  CampaignImpact = 'campaignImpact',
  OfferImpact = 'offerImpact',
}

export type ImpactGenericSlice = {
  impactsState: (state: RootState) => any;
  reducer: any;
  actions: any;
  getFilters: any;
};
export enum ImpactFiltersEum {
  Status = 'status',
  Id = 'id',
}

export interface ImpactFilters {
  [ImpactFiltersEum.Id]: number[];
  [ImpactFiltersEum.Status]: ApprovalStatus[];
}

export interface GenericImpactFilters {
  filters: ImpactFilters;
}
export const initialState: GenericImpactFilters = {
  filters: {
    id: [],
    status: [],
  },
};

const filterGenerationFunctions: { [key: string]: (initialFilters: any) => Object } = {
  [ImpactStateId.OfferImpact]: generateOfferStatusFilters,
  [ImpactStateId.CampaignImpact]: generateCampaignStatusFilters,
};

export const createImpactGenericSlice = (sliceName: ImpactStateId): ImpactGenericSlice => {
  const getFilters = (initialFilters: ImpactFilters): any => {
    const filters: any = { bool: { must: [] } };
    const { id, status } = initialFilters;
    filters.bool.must.push({ terms: { id: id ?? [] } });
    if (status?.length) {
      const statusFilter: any = filterGenerationFunctions[sliceName]?.(initialFilters) || {};
      if (Object.keys(statusFilter).length > 0) {
        filters.bool.must.push({ bool: { should: statusFilter } });
      }
    }
    return filters;
  };

  const impactSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      setFilter(state, action) {
        state.filters = {
          ...state.filters,
          [action.payload.filter]: action.payload.value,
        };
      },
      resetFilters(state) {
        state.filters = initialState.filters;
      },
    },
  });

  const impactsState = (state: RootState) => state.impacts[sliceName];
  return {
    impactsState,
    reducer: impactSlice.reducer,
    actions: impactSlice.actions,
    getFilters,
  };
};

export const campaignImpact: ImpactGenericSlice = createImpactGenericSlice(ImpactStateId.CampaignImpact);
export const offerImpact: ImpactGenericSlice = createImpactGenericSlice(ImpactStateId.OfferImpact);

const impactsReducer = combineReducers({
  [ImpactStateId.CampaignImpact]: campaignImpact.reducer,
  [ImpactStateId.OfferImpact]: offerImpact.reducer,
});

export default impactsReducer;
