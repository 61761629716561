import React from 'react';
import { IconContainer, StyledIcon } from './LockIcon.style';

const NewLockIcon = ({ dataFor }: {dataFor?:string}) => {
  return (
    <IconContainer data-for={dataFor} data-tip>
      <StyledIcon name="lock" />
    </IconContainer>
  );
};

export default NewLockIcon;
