import styled from '@emotion/styled';
import { ApprovalStatus, ApprovalStatusWithLabel } from 'utils/types';
import typography from 'styles/typography';
import { StatusLabelProps } from './StatusLabel.consts';

export const StyledStatusLabel = styled.span<StatusLabelProps>`
  height: 18px;
  padding: 0 ${typography.unit * 2}px;
  background-color: ${({ theme, type }) => theme.colors.status[type as ApprovalStatusWithLabel]};
  font-family: ${typography.primaryFont};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ theme, type }) => type === ApprovalStatus.Draft && `1px solid ${theme.colors.global.border}`};
`;
