import styled from '@emotion/styled';
import typography from 'styles/typography';
import { StyledNotificationWithTheme } from '../notifications';
import Message from '../message/Message';

export const StyledMessage = styled(Message)<StyledNotificationWithTheme>`
  padding: ${typography.unit * 2}px;
  background-color: ${({ type, theme }) => theme.colors.notifications.background[type]};
  border-radius: ${typography.borderRadius}px;
`;
