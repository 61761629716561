import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DiscountType } from 'utils/types/offers';

export default function useDiscountType() {
  const { trigger, getValues, watch } = useFormContext();
  const [discountType, orderDiscountType] = watch([
    'versions.0.templateValues.discountType',
    'versions.0.templateValues.orderDiscountType',
  ]);
  const isFree = discountType === DiscountType.Free;
  const isPercent = discountType === DiscountType.Percent || orderDiscountType === DiscountType.Percent;
  const shouldDisplayCurrency =
    discountType === DiscountType.FixedPrice ||
    discountType === DiscountType.MoneyOff ||
    (orderDiscountType && orderDiscountType === DiscountType.MoneyOff);

  useEffect(() => {
    const val = getValues('versions.0.templateValues.discountValue');
    if (val !== '') {
      trigger('versions.0.templateValues.discountValue');
    }
  }, [discountType]);

  useEffect(() => {
    const val = getValues('versions.0.templateValues.orderDiscountValue');
    if (val !== '') {
      trigger('versions.0.templateValues.orderDiscountValue');
    }
  }, [orderDiscountType]);

  return { discountType, isFree, isPercent, shouldDisplayCurrency };
}
