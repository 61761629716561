import React, { useEffect, useState } from 'react';
import { store } from 'app/store';
import { Modals, openModal } from 'app/slices/modals';
import { BaseOffer } from 'utils/types/offers';
import { useFormContext, useWatch } from 'react-hook-form';
import { offersSelection } from 'app/genericSlices/offers';
import { OfferFilters } from 'pages/offers/Offers.const';
import { ButtonText } from 'components/shared/button';
import Error from 'components/shared/error/Error';
import OfferVersionItem from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerVersionItem/OfferVersionItem';
import {
  SectionContainer,
  SelectOfferWrapper,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerSection/OfferSection.style';
import { OfferSectionProps } from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerSection/OfferSection.consts';

const OfferSection = ({ mode, isDisabled, className }: OfferSectionProps) => {
  const { getValues, control } = useFormContext();
  const campaign = getValues();
  const [isLocalCampaign, zoneSelection, isGeneratedName, isNational] = useWatch({
    control,
    name: ['isLocalCampaign', 'localSchedule.zone', 'isGeneratedName', 'isNational'],
  });
  const [shouldShowZoneError, setShouldShowZoneError] = useState(false);

  useEffect(() => {
    store.dispatch(
      offersSelection.actions.setFilter({
        filter: [OfferFilters.Zone],
        value: zoneSelection ? [zoneSelection] : null,
      }),
    );
  }, [zoneSelection]);

  useEffect(() => {
    if (!isLocalCampaign) {
      setShouldShowZoneError(false);
    }
  }, [isLocalCampaign]);

  useEffect(() => {
    if (zoneSelection || isNational) {
      setShouldShowZoneError(false);
    }
  }, [zoneSelection, isNational]);

  const onSelectOfferClick = () => {
    if (zoneSelection) {
      store.dispatch(
        offersSelection.actions.setFilter({
          filter: [OfferFilters.Zone],
          value: zoneSelection ? [zoneSelection] : null,
        }),
      );
    }
    if (isLocalCampaign && !zoneSelection && !isNational) {
      setShouldShowZoneError(true);
    } else {
      setShouldShowZoneError(false);
      store.dispatch(
        openModal({
          modal: Modals.OfferSelectionModal,
          props: {
            selectedOffer: campaign?.offerVersion,
            onSave: (offerVersion: BaseOffer) =>
              store.dispatch(
                openModal({
                  modal: Modals.CampaignModal,
                  props: { mode, campaign: { ...getValues(), offerVersion, isLocalCampaign, isGeneratedName } },
                  data: {
                    clickSelectOffer: false,
                  },
                }),
              ),
            onCancel: () =>
              store.dispatch(
                openModal({
                  modal: Modals.CampaignModal,
                  props: { mode, campaign: { ...getValues(), isLocalCampaign, isGeneratedName } },
                  data: {
                    clickSelectOffer: false,
                  },
                }),
              ),
          },
          data: {
            campaign: { ...getValues(), isLocalCampaign, isGeneratedName },
            mode,
            clickSelectOffer: true,
          },
        }),
      );
    }
  };

  return (
    <SectionContainer className={className}>
      {!isDisabled && (
        <SelectOfferWrapper>
          <ButtonText onClick={onSelectOfferClick}>Select Offer</ButtonText>
          {shouldShowZoneError && <Error errors="Zone selection is required" />}
        </SelectOfferWrapper>
      )}
      <OfferVersionItem offer={campaign?.offerVersion} campaign={getValues()} />
    </SectionContainer>
  );
};

export default OfferSection;
