import React, { useMemo } from 'react';
import { store } from 'app/store';
import { Modals, openModal } from 'app/slices/modals';
import { FormMode } from 'utils/types';
import { OfferTemplates } from 'utils/types/offers';
import { marketConfig } from 'app/slices/config';
import { useSelector } from 'react-redux';
import { NoImageText } from 'pages/offers/Offers.style';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { getOfferStatus } from 'utils/offer';
import { Tags } from 'components/shared/tags/Tags';
import { archiveOffer, onOfferArchive } from '../offerActions/OfferActions';
import {
  OffersGridItem,
  OffersGridItemActions,
  OffersGridItemBackdrop,
  OffersGridItemFooter,
  OffersGridItemHeader,
  OffersGridItemImage,
  OffersGridItemImageContainer,
  OffersGridItemTemplate,
  StyledButtonText,
  StyledIcon,
  StyledStatusLabel,
  StyledView,
  StyledArchiveIcon,
  OfferNameWrapper,
  OffersGridItemWrapper,
  StyledAddIcon,
  StyledRemoveIcon,
} from './OfferItem.style';
import { OfferItemProps } from './OfferItem.consts';
import { getOfferById } from 'utils/api/offers';
import NewLockIcon from 'pages/shared/lockIcon/NewLockIcon';

const OfferItem = ({
  offer,
  isSelectMode = false,
  isSelected,
  onAddClick,
  onRemoveClick,
  onClick,
  onViewClick,
  className,
  setIsOfferArchieving,
}: OfferItemProps) => {
  const { config } = useSelector(marketConfig);
  const id = useMemo(() => Math.random().toString(8), [offer.updatedAt]);
  const primaryTranslation = offer?.versions[0]?.translationsMap[config.primaryLanguage];

  const onViewOfferClicked = async () => {
    const { data } = await getOfferById(offer.id);
    const offerData = data.getOffer;
    store.dispatch(openModal({ modal: Modals.OfferModal, props: { offer: offerData }, data: { mode: FormMode.View } }));
  };

  const onEditOfferClicked = async () => {
    const { data } = await getOfferById(offer.id);
    const offerData = data.getOffer;
    store.dispatch(
      openModal({
        modal: Modals.OfferModal,
        props: { offer: offerData, mode: FormMode.Edit },
        data: { mode: FormMode.Edit },
      }),
    );
  };

  const onOfferClick = () => {
    // eslint-disable-next-line no-unused-expressions
    onClick ? onClick() : onViewOfferClicked();
  };

  return (
    <OffersGridItem className={className} data-automation-id="offer-item">
      <OffersGridItemWrapper onClick={onOfferClick} role="button" tabIndex={0} onKeyDown={onOfferClick}>
        <OffersGridItemBackdrop>
          <OffersGridItemActions>
            <StyledButtonText onClick={!onViewClick ? onViewOfferClicked : onViewClick} data-title="View">
              <StyledView name="view" />
            </StyledButtonText>
            {isSelectMode ? (
              <>
                {isSelected ? (
                  <StyledButtonText onClick={onRemoveClick} data-title="Remove">
                    <StyledRemoveIcon name="minusVector" />
                  </StyledButtonText>
                ) : (
                  <StyledButtonText onClick={onAddClick} data-title="Add">
                    <StyledAddIcon name="plusVector" />
                  </StyledButtonText>
                )}
              </>
            ) : (
              <>
                {!offer?.isLocked && (
                  <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                    {!offer.isArchive && (
                      <StyledButtonText
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onEditOfferClicked();
                        }}
                        data-title="Edit"
                      >
                        <StyledIcon name="edit" />
                      </StyledButtonText>
                    )}
                    <StyledButtonText
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        return offer.isArchive ? archiveOffer(offer, false) : onOfferArchive(offer,setIsOfferArchieving);
                      }}
                      data-title={offer.isArchive ? 'Unarchive' : 'Archive'}
                    >
                      <StyledArchiveIcon name={offer.isArchive ? 'unarchive' : 'archive'} />
                    </StyledButtonText>
                  </RoleGuard>
                )}
              </>
            )}
          </OffersGridItemActions>
          <Tags id={offer.id} tags={offer.tags} limit={5} />
        </OffersGridItemBackdrop>
        <OffersGridItemHeader>
          <StyledStatusLabel type={getOfferStatus(offer)} />
          <OffersGridItemTemplate>
            {OfferTemplates[offer.templateType as keyof typeof OfferTemplates]?.name}
          </OffersGridItemTemplate>
          <span>{`ID ${offer?.id}`}</span>
        </OffersGridItemHeader>
        <OffersGridItemImageContainer>
          {primaryTranslation?.image ? (
            <OffersGridItemImage
              src={`${primaryTranslation?.image?.file}?${id}`}
              alt={`${primaryTranslation?.image?.name}`}
              loading="lazy"
              crossOrigin="anonymous"
            />
          ) : (
            <NoImageText>No Image Defined</NoImageText>
          )}
        </OffersGridItemImageContainer>
      </OffersGridItemWrapper>
      <OffersGridItemFooter>
        <OfferNameWrapper>
          <h6 title={primaryTranslation?.title}>{primaryTranslation?.title}</h6>
          {offer.isLocked && <NewLockIcon dataFor={"offer-lock-icon"}/>}
        </OfferNameWrapper>
        <p title={primaryTranslation?.subtitle}>{primaryTranslation?.subtitle}</p>
      </OffersGridItemFooter>
    </OffersGridItem>
  );
};

export default OfferItem;
