import styled from '@emotion/styled/macro';
import typography from 'styles/typography';
import zIndex from 'styles/zIndex';
import { StyledWithThemeProps } from 'utils/types';

export const DropdownButton = styled.div<StyledWithThemeProps>`
  background-color: transparent;
  cursor: auto;
  border-bottom: 1px solid black;
  transition: border-color 0.3s ease;
  justify-content: space-between;
  padding-right: 20px;
  user-select: none;

  svg {
    margin-top: 6px;
    fill: ${({ theme }) => theme.colors.text.secondary};
    width: 10px;
    position: absolute;
    left: 187px;
  }
`;

export const DropdownContent = styled.div<StyledWithThemeProps>`
  position: absolute;
  top: 150%;
  right: -10px;
  width: 220px;
  margin: 0;
  background: white;
  overflow: auto;
  padding: 0;
  border: ${({ theme }) => `1px solid ${theme.colors.global.inputDisabled}`};
  border-radius: ${typography.borderRadius}px;
  box-shadow: ${({ theme }) => theme.colors.global.boxShadow};
  z-index: ${zIndex.dropdown};
  &:disabled {
    pointer-events: none;
    color: ${({ theme }) => theme.colors.global.inputDisabled};
  }
`;

export const DropdownItem = styled.div<StyledWithThemeProps>`
  padding: ${typography.unit * 1}px ${typography.unit * 3}px;
  cursor: default;
  font-size: ${typography.fontSize}px;
  display: flex;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.colors.global.selectedBackground};
  }
`;

export const StyledRedemptionOverviewDropdown = styled.div`
  position: relative;
  flex-grow: 1;

  .open {
    ${DropdownContent} {
      display: block;
    }
  }
`;
