import React from 'react';
import { getDateFromDateTime } from 'utils/date';

export const DateCellWrapper = (props: any) => {
  const { children, value } = props;
  return (
    <div
      className={children?.props?.className}
      style={{ position: 'relative' }}
      id={`date-${getDateFromDateTime(value, 'default')}`}
    />
  );
};
