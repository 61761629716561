import styled from '@emotion/styled';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import Error from 'components/shared/error/Error';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { InnerSelectbox } from 'components/shared/selectbox/InnerSelectbox';

export const SelectboxContainer = styled.div<{ labelIsHorizontal: boolean }>`
  display: flex;
  flex-direction: ${({ labelIsHorizontal }) => labelIsHorizontal && 'column'};
  align-items: ${({ labelIsHorizontal }) => !labelIsHorizontal && 'center'};
`;

export const StyledLabel = styled.label<StyledWithThemeProps & { disabled: boolean }>`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text.primary};
  display: flex;
  align-items: start;
  margin-right: ${typography.unit * 3}px;
  white-space: nowrap;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
`;

export const StyledSelect = styled(InnerSelectbox)<any>`
  flex: 1;
  font-size: ${typography.fontSize}px;
  color: ${({ theme }) => theme.colors.text.primary};
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
`;

export const StyledError = styled(Error)`
  position: absolute;
  top: ${typography.unit * 7}px;
  margin-left: ${typography.unit / 2}px;
  white-space: nowrap;
`;

export const StyledOption = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  label {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-right: ${typography.unit * 2}px;
`;

export const LimitIndication = styled.div`
  font-size: 12px;
`;
