import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import React, { useEffect, useRef, useState } from 'react';
import { isInArray } from 'utils/array';
import { User, UserRole } from 'utils/types/users';
import type { RootState } from '../store';
import { usersGqlsDefault } from './users.gqls';
import { client } from 'app/apollo';

export interface UsersState {
  user: User;
}

export interface Action {
  payload: {
    user: User;
  };
}

const getDefaultUser = () => {
  // The Fetch Request that connects to resolver and grabs our env variables
  const fetchEnvVar = async () => {
    return client.query({ query: usersGqlsDefault.queries.getFirstLastEmail, fetchPolicy: 'no-cache' });
  };

  const data = fetchEnvVar();
  return data.then((res) => {
    //Checks if theres data
    if (res.data && res.data?.getEnvironments) {
      return {
        id: 1,
        firstName: res.data.getEnvironments.firstName,
        lastName: res.data.getEnvironments.lastName,
        company: 'Other',
        role: UserRole.SysAdmin,
        email: res.data.getEnvironments.adminEmail,
      };
    }
  });
};


export const initUser = createAsyncThunk('users/init', async (payload, thunkAPI) => {
  const headers = new Headers({
    "Cache-Control": "no-store",
  });
  if (!isInArray(['development'], process.env.NODE_ENV)) {
    const res = await fetch('/user', { credentials: 'include', cache:
      'no-store', headers: headers});
    if (!res.ok) {
      return thunkAPI.rejectWithValue(null);
    }
    return res.json();
  }
  return getDefaultUser();
});

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    user: undefined,
  } as UsersState,
  reducers: {
    logout(state) {
      state.user = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initUser.pending, (state) => {
      return state;
    });
    builder.addCase(initUser.fulfilled, (state, action) => {
      state.user = action.payload as User;
      return state;
    });

    builder.addCase(initUser.rejected, () => {
      return null;
    });
  },
});

export const users = (state: RootState) => state.users;
export const { logout } = usersSlice.actions;

export default usersSlice.reducer;
