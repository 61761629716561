import React from 'react';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { store } from 'app/store';
import { createImageTag, getCampaignsImpactsByImageId, getOffersImpactsByImageId, updateImage } from 'utils/api/images';
import { handleOfferImpactChange } from 'utils/offer';
import { HeaderComponentType } from 'components/impact/impactModal/ImpactModal.consts';
import { ApprovalStatus, GenericItem } from 'utils/types';
import { closeModal, Modals, openModal } from 'app/slices/modals';
import { Impact } from 'components/impact/impactItem/ImpactItem.consts';
import { handleCampaignImpactChange } from 'utils/campaign';
import { ImageProps, ImagePropsToSave } from './Images.consts';

export const removeFileExtension = (fileName: string) => fileName.replace(/\.[^/.]+$/, '');

export const getFileExtension = (fileName: string) => fileName.split('.').pop();

type readImagesResult = { name: string; src: string; file: File; fileExtension: string }[];
export const readImages = async (images: FileList, bulkImagesUploadLimit: number): Promise<readImagesResult> => {
  const errors: string[] = [];
  const filesArr = Array.from(images);
  if (filesArr.length > bulkImagesUploadLimit) {
    filesArr.length = bulkImagesUploadLimit;
  }

  filesArr.forEach(
    (image) => image.size / 1000 > 100 && errors.push(`The image ${image.name} should be less than 100KB`),
  );
  if (errors.length > 0) {
    errors.forEach((fileError) => showToast(MessageType.Error, fileError));
    return;
  }

  const promises = filesArr.map((file) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    return new Promise((resolve) => {
      fileReader.onload = () => {
        window.URL = window.URL || window.webkitURL;
        const blobURL = window.URL.createObjectURL(file);
        resolve({
          name: removeFileExtension(file.name),
          fileExtension: getFileExtension(file.name),
          src: blobURL,
          file,
        });
      };
    });
  });

  return Promise.all(promises as unknown as readImagesResult);
};

export const onImageArchiveChange = async (image: ImageProps, isArchive: boolean) => {
  try {
    const archivedImageData: ImagePropsToSave = {
      isArchive,
      id: Number(image.id),
      tags: image.tags.map((tag: GenericItem) => Number(tag.id)),
      isBonusCampaign: image.isBonusCampaign
    };
    await updateImage(archivedImageData);
    store.dispatch(closeModal());
    showToast(MessageType.Success, `Image ${isArchive ? 'archived' : 'unarchived'} successfully`);
  } catch (e) {
    showToast(MessageType.Error, `Failed to ${isArchive ? 'archive' : 'unarchive'} image`);
  }
};

export const archiveImageClicked = async (image: ImageProps, setIsImageArchieving?:any) => {
  setIsImageArchieving && setIsImageArchieving(true);
  image  = {
    ...image,
    id:Number(image.id)
  }
  const [offerImpacts, campaignImpacts] = await Promise.all([
    getOffersImpactsByImageId(image.id),
    getCampaignsImpactsByImageId(image.id),
  ]);
  if (offerImpacts.length || campaignImpacts.length) {
    const offerImpactProps = handleOfferImpactChange(offerImpacts);
    const campaignsImpactProps = handleCampaignImpactChange(campaignImpacts);
    const allOffersAreArchive = !offerImpactProps.some((impact: Impact) => impact.status !== ApprovalStatus.Archived);
    const canSave = offerImpactProps && allOffersAreArchive;
    setIsImageArchieving && setIsImageArchieving(false);
    store.dispatch(
      openModal({
        modal: Modals.ImpactModal,
        props: {
          title: 'Archive Notification',
          headerComponentType: canSave
            ? HeaderComponentType.ImageArchiveNotificationType
            : HeaderComponentType.ImageCantArchiveNotificationType,
          offerImpacts: offerImpactProps,
          campaignImpacts: campaignsImpactProps,
          entityType: 'Image',
          entityId: image.id,
          onCancel: () => store.dispatch(closeModal()),
          ...(canSave && {
            submitButtonString: 'Yes, Archive',
            onSubmit: () => onImageArchiveChange(image, true),
          }),
        },
      }),
    );
  } else {
    await onImageArchiveChange(image, true);
    setIsImageArchieving && setIsImageArchieving(false);
  }
};

export const unarchiveImageClicked = async (image: ImageProps) => {
  await onImageArchiveChange(image, false);
};

export const onTagCreation = async (tagName: string) => {
  try {
    const tag = await createImageTag(tagName);
    return tag?.data?.createTag;
  } catch (e) {
    showToast(
      MessageType.Error,
      `Failed to add tag${e.message.includes('duplication item') ? ` - ${tagName} already exists in another entity. Please update tag entity or contact Admin`: ''} 
      ${e.message.includes('char limit') ? ` - Exceeds 50 Character limit`: ''}`,
    );
    return null;
  }
};

export const preventFormSendOnEnter = (e: React.KeyboardEvent<HTMLFormElement>) => {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
};
