import { OverviewBase, StatusSmallBox } from 'pages/dashboard/Dashboard.style';
import styled from '@emotion/styled';
import typography from 'styles/typography';

export const RedemptionsOverviewStyle = styled(OverviewBase)`
  width: 284px;
  height: 252px;
  padding: ${typography.unit * 5}px ${typography.unit * 7}px;
  margin-bottom: ${typography.unit * 8}px;
`;

export const StyledStatusSmallBox = styled(StatusSmallBox)`
  cursor: auto;
  &:hover {
    box-shadow: none;
  }
`;
