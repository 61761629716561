import { isPositiveInteger } from 'utils/text';

export interface DBFilters {
  OR?: (FieldFilterExpression | DBFilters)[];
  AND?: (FieldFilterExpression | DBFilters)[];
}

export interface FieldFilterExpression {
  name: string;
  type?: string;
  operator: string;
  value?: any;
}

const isDbFilters = (obj: DBFilters | FieldFilterExpression): obj is DBFilters => {
  return (<DBFilters>obj).OR !== undefined || (<DBFilters>obj).AND !== undefined;
};

const getFieldFilter = (obj: FieldFilterExpression) => {
  const shouldContainValue = obj.operator?.toLowerCase() !== 'is_null';
  if (shouldContainValue) {
    if (obj.value === undefined || obj.value === null || obj.value === '') {
      return null;
    }

    if (obj?.type !== 'number') {
      return { [obj.name]: { [obj.operator]: obj.value } };
    }

    if (isPositiveInteger(obj?.value)) {
      return { [obj.name]: { [obj.operator]: parseInt(obj.value, 10) } };
    }

    if (Array.isArray(obj.value) && isPositiveInteger(obj?.value?.[0])) {
      return { [obj.name]: { [obj.operator]: parseInt(obj.value?.[0], 10) } };
    }

    return null;
  }

  return { [obj.name]: obj.operator };
};

export const generateFilters = (dbFilters: DBFilters): any => ({
  OR: dbFilters.OR?.map((obj) => (isDbFilters(obj) ? generateFilters(obj) : getFieldFilter(obj))).filter((obj) => obj),
  AND: dbFilters.AND?.map((obj) => (isDbFilters(obj) ? generateFilters(obj) : getFieldFilter(obj))).filter(
    (obj) => obj,
  ),
});
