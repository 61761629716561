import React from 'react';
import {
  SetChildrenCounter,
  SetNameContainer,
  StyledSetTitleContainer,
  StyledWholeGroupIcon,
} from 'components/shared/set/SetListItemHeader/SetListItemHeader.style';
import { GenericSetTitleFormatterProps } from 'components/shared/set/SetListItemHeader/SetListItemHeader.consts';
import { getAllItemsRecursively } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.utils';
import { SetOfEntity } from 'utils/types';
import { LocationSet } from 'utils/types/locations';

const LocationSetTitle = ({ set, setSize, wholeSetSelected, supportSetFringes }: GenericSetTitleFormatterProps) => {
  const totalSets = (set as LocationSet).totalSets;
  const getCounter = () => {
    if (!set.custom) {
      return `${setSize}/${totalSets > 0 && !set.dummy ? totalSets : set.totalItems}`;
    }

    const totalItems = getAllItemsRecursively([set], SetOfEntity.Locations, supportSetFringes).length;
    return `${totalItems} Location${totalItems > 1 ? 's' : ''}`;
  };

  return (
    <StyledSetTitleContainer>
      <SetNameContainer>
        {set.name}
        {wholeSetSelected && !set.custom && <StyledWholeGroupIcon name="group" />}
      </SetNameContainer>
      <SetChildrenCounter>{getCounter()}</SetChildrenCounter>
    </StyledSetTitleContainer>
  );
};

export default LocationSetTitle;
