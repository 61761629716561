import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import { EnvironmentType, styledHeaderContainer } from './Header.consts';

export const Container = styled.div<styledHeaderContainer>`
  display: flex;
  height: 52px;
  width: 100%;
  align-items: center;
  background: ${({ theme }) => theme.colors.global.headerBackground};
  background: ${({ environment }) =>
    environment === EnvironmentType.Local
      ? '#d6e2ff'
      : environment === EnvironmentType.Dev
      ? '#e7ffdd'
      : environment === EnvironmentType.Test && '#fff0e0'};
  position: relative;

  @media print {
    display: none;
  }
`;

export const StyledEnvironmentLabel = styled.div<StyledWithThemeProps>`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 0 ${typography.unit * 2}px;
  font-weight: 600;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const StyledTitle = styled.h1<StyledWithThemeProps>`
  font-size: ${typography.fontSize}px;
  font-family: ${typography.secondaryFont};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.text.primary};
  margin-left: 10px;
`;

// this should always be in the center of the header
export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 215px;

  @media screen and (max-width: 1300px) {
    position: initial;
  }

  @media screen and (max-width: 1000px) {
    position: absolute;
    top: 75px;
  }
`;
