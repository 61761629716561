import styled from '@emotion/styled/macro';
import typography from 'styles/typography';
import zIndex from 'styles/zIndex';
import { StyledWithThemeProps } from 'utils/types';
import { ButtonText } from 'components/shared/button';
import { Icon } from 'components/shared/icon';
import InfiniteScroll from 'react-infinite-scroll-component';

export const NotificationsMenuDropdown = styled.div<StyledWithThemeProps>`
  position: absolute;
  top: calc(100% + ${typography.unit * 2}px);
  right: ${typography.unit * 25}px;
  width: 400px;
  height: 410px;
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  border-radius: ${typography.borderRadius}px;
  box-shadow: ${({ theme }) => theme.colors.global.boxShadow};
  padding: ${typography.unit * 3}px ${typography.unit * 4}px;
  display: flex;
  flex-direction: column;
  z-index: ${zIndex.dropdown};
  background: ${({ theme }) => theme.colors.global.background};

  header {
    display: flex;
    align-items: center;

    h5 {
      font-family: ${typography.secondaryFont};
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

export const NotificationsListWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: -${typography.unit * 5}px;

  ::-webkit-scrollbar {
    width: 21px;
  }

  ::-webkit-scrollbar-thumb {
    border: 7px transparent solid;
    background-clip: padding-box;
    border-radius: 10px;
  }
`;

export const NotificationsList = styled(InfiniteScroll)`
  display: flex;
  overflow-y: auto;
  flex-wrap: wrap;
  gap: ${typography.unit * 5}px;
  padding-bottom: 20px;
`;

export const StyledActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${typography.unit}px;
`;

export const StyledSeparator = styled.div<StyledWithThemeProps>`
  height: 10px;
  width: 0;
  align-self: center;
  margin: 0 ${typography.unit}px;
  border: 1px solid ${({ theme }) => theme.colors.global.border};
`;

export const StyledAction = styled(ButtonText)`
  padding-left: ${typography.unit}px;
  font-size: 12px;
  cursor: pointer;
`;

export const NotificationContainer = styled.div`
  display: flex;

  [data-title]:after {
    left: -150%;
  }
`;

export const StyledIconContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const StyledUnreadNotificationIcon = styled(Icon)`
  width: 22px;
  fill: ${({ theme }) => theme.colors.text.primary};
  margin: ${typography.unit}px ${typography.unit * 3}px;
`;

export const StyledBellIcon = styled(Icon)`
  width: 16px;
  fill: ${({ theme }) => theme.colors.text.primary};
  margin: ${typography.unit}px ${typography.unit * 3}px ${typography.unit}px ${typography.unit}px;
`;

export const StyledNonNotification = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
