import styled from '@emotion/styled';
import { StyledWithThemeProps } from 'utils/types';
import typography from 'styles/typography';
import { Icon, IconProps } from 'components/shared/icon';
import { ThemeProps } from 'pages/settings/termsConditions/TermsConditions.consts';
import { ButtonText } from 'components/shared/button';

export const StyledHeader = styled.header<ThemeProps>`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.global.border};
  padding: ${typography.unit * 6}px;
  height: 68px;
  justify-content: space-between;
  align-items: baseline;
`;

export const LastUpdated = styled.span<{ align?: 'right' | 'left' }>`
  margin-bottom: ${typography.unit * 2}px;
  display: flex;
  justify-content: ${({ align }) => align || 'left'};
  font-size: 11px;
`;

export const Board = styled.div<StyledWithThemeProps>`
  background-color: ${({ theme }) => theme.colors.global.selectedBackground};
  flex: 1;
  display: flex;
  overflow: auto;
`;

export const WrapperBoard = styled.div<StyledWithThemeProps>`
  margin: auto;
`;

export const InnerBoard = styled.div`
  display: flex;
  margin: ${typography.unit * 8}px;
`;

export const OverviewBase = styled.div<StyledWithThemeProps>`
  background-color: ${({ theme }) => theme.colors.global.background};
  border-radius: ${typography.borderRadius}px;
  display: flex;
  flex-direction: column;
`;

export const OverviewHeader = styled.span`
  font-size: 20px;
`;

export const OverviewSubHeader = styled.span`
  font-size: 16px;
`;

export const SideOverviewHeader = styled(OverviewHeader)`
  margin-bottom: ${typography.unit}px;
`;

export const CampaignsOverview = styled(OverviewBase)`
  width: 1068px;
  height: 1045px;
  margin-right: ${typography.unit * 8}px;
  padding: ${typography.unit * 5}px ${typography.unit * 4}px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OffersOverview = styled(OverviewBase)`
  width: 284px;
  height: 252px;
  padding: ${typography.unit * 5}px ${typography.unit * 7}px;
  margin-bottom: ${typography.unit * 8}px;
`;

export const SideOverviewContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Box = styled.div<StyledWithThemeProps & { color?: 'green' | 'red' | 'yellow' | 'blue' }>`
  background-color: ${({ color, theme }) => color && theme.colors.dashboard[color]};
  width: 224px;
  display: flex;
  flex-direction: column;
  padding: ${typography.unit * 2}px ${typography.unit * 3}px;
  border-radius: ${typography.borderRadius}px;
  border: ${({ color, theme }) => !color && `1px solid ${theme.colors.global.border}`};
  cursor: pointer;
  transition: all ${typography.transition}s;

  &:hover {
    box-shadow: ${({ theme }) => theme.colors.global.boxShadow};
  }
`;

export const StatusBigBox = styled(Box)`
  height: 122px;
`;

export const StatusSmallBox = styled(Box)`
  height: 60px;
`;

export const Number = styled.span<StyledWithThemeProps & { isAlert?: boolean }>`
  font-size: 24px;
  color: ${({ isAlert, theme }) => isAlert && theme.colors.global.error};
`;

export const CampaignSection = styled.div<StyledWithThemeProps>`
  width: 1038px;
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  border-radius: ${typography.borderRadius}px;
  padding: ${typography.unit * 4}px ${typography.unit * 7}px;
  margin-top: ${typography.unit * 4}px;
  display: flex;
  flex-direction: column;
`;

export const StatusRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PendingApprovalStartsInBox = styled(Box)`
  width: 200px;
  height: 60px;
  display: flex;
  flex-direction: column;
  padding: ${typography.unit}px ${typography.unit * 2}px;
  border-radius: ${typography.borderRadius}px;
  border: ${({ theme }) => `1px dotted ${theme.colors.text.secondary}`};
  cursor: pointer;
  margin-top: ${typography.unit}px;

  &:hover {
    box-shadow: ${({ theme }) => theme.colors.global.boxShadow};
  }
`;

export const AlertsHeader = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: ${typography.unit * 4}px;
`;

export const AlertIcon = styled(Icon)<IconProps>`
  width: 16px;
  margin-right: ${typography.unit * 2}px;
  fill: ${({ theme }) => theme.colors.global.error};
`;

export const RedemptionsHeader = styled(OverviewSubHeader)`
  margin-bottom: ${typography.unit * 4}px;
`;

export const NoRedemptionsSection = styled(CampaignSection)`
  flex: 1;

  .MuiDataGrid-root {
    border: none;

    .MuiDataGrid-columnHeader {
      padding-left: 0 !important;
    }

    .MuiDataGrid-columnHeaderTitleContainer {
      padding-left: 0 !important;
    }

    .MuiDataGrid-row {
      min-height: 35px !important;
      max-height: 35px !important;
    }

    .MuiDataGrid-cell {
      padding-left: 0 !important;
      padding-right: 0 !important;
      line-height: 35px !important;
      min-height: 35px !important;
      max-height: 35px !important;
    }
  }
`;

export const CampaignNameButton = styled(ButtonText)`
  max-width: 300px;
`;
