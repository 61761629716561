import React from 'react';
import { TabStripItemProps } from './TabStripItem.consts';
import { StyledArrowUp, StyledLink, StyledTitle } from './TabStripItem.style';

const TabStripItem = ({ tab, isSelected, isExpandable, isOpen, onClick }: TabStripItemProps) => {
  const TitleContent = () => (
    <StyledTitle key={tab.id} isSelected={isSelected} onClick={onClick} data-automation-id="tab-item">
      {tab.title}
      {isExpandable && <StyledArrowUp name="arrowUp" isOpen={isOpen} />}
    </StyledTitle>
  );
  return (
    <>
      {tab.link ? (
        <StyledLink to={tab.link} isSelected={isSelected}>
          <TitleContent />
        </StyledLink>
      ) : (
        <TitleContent />
      )}
    </>
  );
};

export default TabStripItem;
