import React from 'react';
import { FilterIcon } from 'pages/shared/shared.style';
import { CampaignApprovalStatusLabel } from 'utils/types';
import { CampaignType, CampaignTypeLabel } from 'utils/types/campaigns';
import {
  Filters,
  StyledSelect,
} from 'pages/reports/redemptions/components/redemptionsFilterBar/components/campaignSelection/components/campaignFilterBar/CampaignFilterBar.style';
import { AvailableStatuses } from 'pages/reports/redemptions/components/redemptionsFilterBar/components/campaignSelection/CampaignSelection.consts';
import { store } from 'app/store';
import { CampaignFilterType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { campaignsSelection } from 'app/genericSlices/campaigns';

const CampaignFilterBar = () => {
  return (
    <Filters>
      <FilterIcon name="filter" />
      <StyledSelect
        placeholder="Select"
        label="Status"
        name="status"
        multiple
        items={
          AvailableStatuses.map((status) => {
            return {
              id: status,
              name: CampaignApprovalStatusLabel[status],
            };
          }) as any
        }
        onChange={(selectedItems: any) => {
          store.dispatch(
            campaignsSelection.actions.setFilter({
              filter: CampaignFilterType.Status,
              value: Object.values(selectedItems).length
                ? Object.values(selectedItems).map((i: any) => i.id)
                : AvailableStatuses,
            }),
          );
        }}
        maxItems={1}
        selectWidth={130}
      />
      <StyledSelect
        placeholder="Select"
        label="Type"
        name="type"
        multiple
        items={
          Object.values(CampaignType).map((type) => {
            return {
              id: type,
              name: CampaignTypeLabel[type],
            };
          }) as any[]
        }
        onChange={(selectedItems: any) => {
          store.dispatch(
            campaignsSelection.actions.setFilter({
              filter: CampaignFilterType.Type,
              value: Object.values(selectedItems).map((i: any) => i.id),
            }),
          );
        }}
        maxItems={1}
        selectWidth={160}
      />
    </Filters>
  );
};

export default CampaignFilterBar;
