import React from 'react';
import { InfoTooltipProps } from './InfoTooltip.consts';
import Tooltip from '../Tooltip';
import { InfoIcon, InfoIconContainer } from './InfoTooltip.style';

const InfoTooltip = ({
  id,
  content,
  onClose,
  place = 'bottom',
  onHover = true,
  className,
  children,
  ...rest
}: InfoTooltipProps) => {
  return (
    <>
      <InfoIconContainer className={className} data-tip data-for={`${id}-tooltip`}>
        <InfoIcon name="info" data-tip />
      </InfoIconContainer>
      <Tooltip id={`${id}-tooltip`} content={content} onHover={onHover} onClose={onClose} place={place} {...rest}>
        {children}
      </Tooltip>
    </>
  );
};

export default InfoTooltip;
