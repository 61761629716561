import styled from '@emotion/styled';
import { ButtonDropdown } from 'components/shared/button/buttonDropdown';
import { StyledWithThemeProps } from 'utils/types';

export const StyledButtonDropdown = styled(ButtonDropdown)<StyledWithThemeProps>`
  height: 32px;
  width: 56px;
  padding: 0;

  button {
    padding: 0 6px;
  }
`;
