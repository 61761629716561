import { gql } from '@apollo/client';

const productSetsGqls = {
  queries: {
    getAllProductSets: gql`
      query ProductSets($data: GetAllQueryDto) {
        getProductSets(data: $data) {
          items {
            ... on ProductSet {
              id
              name
              description
              externalId
              totalItems
              type
              products {
                id
                name
                plu
                nextStatusChangeWillBeAt
                status
              }
            }
          }
          total
        }
      }
    `,
    getProductSetsProductSetsPage: gql`
      query ProductSets($data: GetAllQueryDto) {
        getProductSetsProductSetsPage(data: $data) {
          items {
            ... on ProductSet {
              id
              name
              description
              externalId
              totalItems
              type
              products {
                id
                name
                plu
                nextStatusChangeWillBeAt
                status
              }
            }
          }
          total
        }
      }
    `,
    getAllProducts: gql`
      query Products($data: GetAllQueryDto) {
        getProducts(data: $data) {
          items {
            ... on Product {
              id
              name
              plu
              nextStatusChangeWillBeAt
              status
            }
          }
          total
        }
      }
    `,
    getTotalProductsCount: gql`
      query Products($data: GetAllQueryDto) {
        getProducts(data: $data) {
          total
        }
      }
    `,
    getById: gql`
      query Products($id: Int!) {
        getProducts(id: $id) {
          title
        }
      }
    `,
    getCategories: gql`
      query GetCategories($data: GetAllQueryDto) {
        getCategories(data: $data) {
          items {
            ... on ProductSet {
              id
              name
              externalId
              dummy
              totalItems
              sets {
                id
                name
                externalId
                dummy
                totalItems
                products {
                  id
                  name
                  plu
                  nextStatusChangeWillBeAt
                  status
                }
                sets {
                  id
                  name
                  externalId
                  dummy
                  totalItems
                  products {
                    id
                    name
                    plu
                    nextStatusChangeWillBeAt
                    status
                  }
                  sets {
                    id
                    name
                    externalId
                    dummy
                    totalItems
                    products {
                      id
                      name
                      plu
                      nextStatusChangeWillBeAt
                      status
                    }
                  }
                }
              }
              products {
                id
                name
                plu
                nextStatusChangeWillBeAt
                status
              }
            }
          }
          total
        }
        getNonCategorizedProducts(data: $data) {
          items {
            ... on Product {
              id
              name
              plu
              nextStatusChangeWillBeAt
              status
            }
          }
          total
        }
      }
    `,
    getCategoriesProductSetsPage: gql`
      query GetCategories($data: GetAllQueryDto) {
        getCategories(data: $data) {
          items {
            ... on ProductSet {
              id
              name
              externalId
              dummy
              totalItems
              sets {
                id
                name
                externalId
                dummy
                totalItems
                products {
                  id
                  name
                  plu
                  nextStatusChangeWillBeAt
                  status
                }
                sets {
                  id
                  name
                  externalId
                  dummy
                  totalItems
                  products {
                    id
                    name
                    plu
                    nextStatusChangeWillBeAt
                    status
                  }
                  sets {
                    id
                    name
                    externalId
                    dummy
                    totalItems
                    products {
                      id
                      name
                      plu
                      nextStatusChangeWillBeAt
                      status
                    }
                  }
                }
              }
              products {
                id
                name
                plu
                nextStatusChangeWillBeAt
                status
              }
            }
          }
          total
        }
        getNonCategorizedProducts(data: $data) {
          items {
            ... on Product {
              id
              name
              plu
              nextStatusChangeWillBeAt
              status
            }
          }
          total
        }
      }
    `,
    getAvailableProducts: gql`
      query GetCategories($data: GetAllQueryDto) {
        getAvailableProducts(data: $data) {
          total
        }
      }
    `,
  },
  mutations: {
    createProductSet: gql`
      mutation createProductSet($data: CreateProductSetDto!) {
        createProductSet(data: $data) {
          id
          name
        }
      }
    `,
    updateProductSet: gql`
      mutation updateProductSet($data: UpdateProductSetDto!) {
        updateProductSet(data: $data) {
          id
          name
        }
      }
    `,
    deleteProductSet: gql`
      mutation deleteProductSet($id: Int!) {
        deleteProductSet(id: $id) {
          id
        }
      }
    `,
    add: gql`
      mutation CreateProducts($data: CreateProductsDto!) {
        createProducts(data: $data) {
          id
        }
      }
    `,
    update: gql`
      mutation UpdateProducts($data: UpdateProductsDto!) {
        updateProducts(data: $data) {
          id
          title
        }
      }
    `,
  },
};

export default productSetsGqls;
