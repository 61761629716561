import { OrderDirection } from 'utils/types';

export const sortByIdAsc = <T extends { id: number }>(a: T, b: T) => a.id - b.id;

export const sortByIdDesc = <T extends { id: number }>(a: T, b: T) => b.id - a.id;

export const sortByPluAsc = <T extends { plu: number }>(a: T, b: T) => a.plu - b.plu;

export const sortByPluDesc = <T extends { plu: number }>(a: T, b: T) => b.plu - a.plu;

export const sortByNameAsc = <T extends { name: string }>(a: T, b: T) => {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();
  return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
};

export const sortByNameDesc = <T extends { name: string }>(a: T, b: T) => {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();
  return nameA < nameB ? 1 : nameA > nameB ? -1 : 0;
};

export enum SortingKey {
  Az = 'A - Z',
  Za = 'Z - A',
  IdAsc = 'ID ASC',
  IdDesc = 'ID DESC',
  PluAsc = 'PLU ASC',
  PluDesc = 'PLU DESC',
}

export const SortBy: Record<SortingKey, any> = {
  [SortingKey.Az]: sortByNameAsc,
  [SortingKey.Za]: sortByNameDesc,
  [SortingKey.IdAsc]: sortByIdAsc,
  [SortingKey.IdDesc]: sortByIdDesc,
  [SortingKey.PluAsc]: sortByPluAsc,
  [SortingKey.PluDesc]: sortByPluDesc,
};

export const SortByDB: Record<SortingKey, { [fieldName: string]: OrderDirection }> = {
  [SortingKey.Az]: { name: OrderDirection.ASC },
  [SortingKey.Za]: { name: OrderDirection.DESC },
  [SortingKey.IdAsc]: { id: OrderDirection.ASC },
  [SortingKey.IdDesc]: { id: OrderDirection.DESC },
  [SortingKey.PluAsc]: { plu: OrderDirection.ASC },
  [SortingKey.PluDesc]: { plu: OrderDirection.DESC },
};
