import { ApolloLink, Operation, NextLink } from '@apollo/client';
import { OperationDefinitionNode } from 'graphql';
import { useEffect, useReducer } from 'react';
import Dispatcher from './dispatcher';

class OperationCounterLink extends ApolloLink {
  private readonly dispatcher: Dispatcher;

  constructor(d: Dispatcher) {
    super();
    this.dispatcher = d;
  }

  request(operation: Operation, forward: NextLink) {
    if ((operation.query.definitions[0] as OperationDefinitionNode).operation === 'subscription') {
      return forward(operation);
    }
    this.dispatcher.dispatch({ type: 'increment', name: operation.operationName });
    return forward(operation).map((data: any) => {
      this.dispatcher.dispatch({ type: 'decrement', name: operation.operationName });
      return data;
    });
  }
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'increment':
      return { reqs: [...state.reqs, action.name] };
    case 'decrement':
      return { reqs: state.reqs.filter((r: string) => r !== action.name) };
    default:
      throw new Error();
  }
};

const useConfiguredReducer = (dispatcher: Dispatcher) => {
  const [state, dispatch] = useReducer(reducer, { reqs: [] });

  useEffect(() => {
    dispatcher.addListener(dispatch);

    return () => {
      dispatcher.removeListener(dispatch);
    };
  }, [dispatcher]);

  return state;
};

const createOperationCounterLink = () => {
  const dispatcher = new Dispatcher();
  const link = new OperationCounterLink(dispatcher);

  const useOperationCounterReducer = () => {
    return useConfiguredReducer(dispatcher);
  };

  return {
    link,
    useOperationCounterReducer,
  };
};

const { useOperationCounterReducer, link } = createOperationCounterLink();
export { link as OperationCounterLink, useOperationCounterReducer as useOperationCounter };
