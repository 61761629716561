import { gql } from '@apollo/client';

const locationSetsGqls = {
  queries: {
    getAllLocationSets: gql`
      query LocationSets($data: GetAllQueryDto) {
        getLocationSets(data: $data) {
          items {
            ... on LocationSet {
              id
              originalId
              name
              custom
              dummy
              isRoot
              nonCustomParentSetId
              totalItems
              totalSets
              externalId
              isReadOnly
              sets {
                id
                originalId
                name
                custom
                dummy
                totalItems
                totalSets
                nonCustomParentSetId
                externalId
                locations {
                  id
                  name
                  code
                  setId
                }
                fringes {
                  id
                  name
                  code
                  setId
                }
                sets {
                  id
                  originalId
                  name
                  custom
                  dummy
                  totalItems
                  totalSets
                  nonCustomParentSetId
                  externalId
                  locations {
                    id
                    name
                    code
                    setId
                  }
                  fringes {
                    id
                    name
                    code
                    setId
                  }
                  sets {
                    id
                    originalId
                    name
                    custom
                    dummy
                    totalItems
                    totalSets
                    nonCustomParentSetId
                    externalId
                    locations {
                      id
                      name
                      code
                      setId
                    }
                    fringes {
                      id
                      name
                      code
                      setId
                    }
                  }
                }
              }
              locations {
                id
                name
                code
                setId
              }
              fringes {
                id
                name
                code
                setId
              }
            }
          }
          total
        }
      }
    `,
    getLocationSetsLocationSetsPage: gql`
      query LocationSets($data: GetAllQueryDto) {
        getLocationSetsLocationSetsPage(data: $data) {
          items {
            ... on LocationSet {
              id
              originalId
              name
              custom
              dummy
              isRoot
              nonCustomParentSetId
              totalItems
              totalSets
              externalId
              isReadOnly
              sets {
                id
                originalId
                name
                custom
                dummy
                totalItems
                totalSets
                nonCustomParentSetId
                externalId
                locations {
                  id
                  name
                  code
                  setId
                }
                fringes {
                  id
                  name
                  code
                  setId
                }
                sets {
                  id
                  originalId
                  name
                  custom
                  dummy
                  totalItems
                  totalSets
                  nonCustomParentSetId
                  externalId
                  locations {
                    id
                    name
                    code
                    setId
                  }
                  fringes {
                    id
                    name
                    code
                    setId
                  }
                  sets {
                    id
                    originalId
                    name
                    custom
                    dummy
                    totalItems
                    totalSets
                    nonCustomParentSetId
                    externalId
                    locations {
                      id
                      name
                      code
                      setId
                    }
                    fringes {
                      id
                      name
                      code
                      setId
                    }
                  }
                }
              }
              locations {
                id
                name
                code
                setId
              }
              fringes {
                id
                name
                code
                setId
              }
            }
          }
          total
        }
      }
    `,
    getAllLocations: gql`
      query Locations($data: GetAllQueryDto) {
        getLocations(data: $data) {
          items {
            ... on Location {
              id
              name
              code
              setId
            }
          }
          total
        }
      }
    `,
    getLocationsLocationSetsPage: gql`
      query Locations($data: GetAllQueryDto) {
        getLocationsLocationSetsPage(data: $data) {
          items {
            ... on Location {
              id
              name
              code
              setId
            }
          }
          total
        }
      }
    `,
    getAvailableLocations: gql`
      query LocationSets($data: GetAllQueryDto) {
        getAvailableLocations(data: $data) {
          total
        }
      }
    `,
    getZones: gql`
      query LocationSets($data: GetAllQueryDto) {
        getLocationSets(data: $data) {
          items {
            ... on LocationSet {
              id
              name
            }
          }
          total
        }
      }
    `,
  },
  mutations: {
    createLocationSet: gql`
      mutation createLocationSet($data: CreateLocationSetDto!) {
        createLocationSet(data: $data) {
          id
        }
      }
    `,
    updateLocationSet: gql`
      mutation updateLocationSet($data: UpdateLocationSetDto!) {
        updateLocationSet(data: $data) {
          id
        }
      }
    `,
    deleteLocationSet: gql`
      mutation deleteLocationSet($id: Int!) {
        deleteLocationSet(id: $id) {
          id
        }
      }
    `,
  },
};

export default locationSetsGqls;
