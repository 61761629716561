import styled from '@emotion/styled';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import Modal from 'components/shared/modal/Modal';

export const ImageFormContent = styled.div`
  display: flex;
  border-radius: ${typography.borderRadius}px;
  margin-bottom: ${typography.unit}px;
  padding: ${typography.unit * 2}px 0;
  text-overflow: ellipsis;
`;

export const FileDetailsContainer = styled.div`
  width: 252px;
`;

export const StyledModal = styled(Modal)`
  width: 660px;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 0 0 ${typography.unit * 4}px;
  overflow: hidden;
`;

export const FileDetails = styled.div`
  display: grid;
  grid-template-columns: 65px auto 35px;
  font-weight: 400;
  // margin-bottom: ${typography.unit}px;
`;

export const PropName = styled.span<StyledWithThemeProps>`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 13px;
`;

export const PropValue = styled.span<StyledWithThemeProps>`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 12px;
  white-space: nowrap;
`;

export const StyledText = styled.span<StyledWithThemeProps>`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 12px;
`;

export const CheckboxContainer = styled.div`
  position: relative;
`;

export const BonusCampaignTooltipContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
`;

export const CheckboxWithTooltip = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxLabel = styled.label`
  margin-left: ${typography.unit}px;
`;
