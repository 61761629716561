import { client } from 'app/apollo';
import { notificationsGqls } from '../../components/header/components/notificationsMenu/Notifications.gqls';
import { userNotificationFilters } from '../types/notification';

export interface UpdateNotificationProps {
  id: number;
  isRead?: boolean;
  isArchive?: boolean;
}

export interface UpdateAllUserNotificationsProps {
  isRead?: boolean;
  isArchive?: boolean;
}

export const getUserNotificationFilters = (): { [key: string]: any[] } => {
  const filters: { [key: string]: any[] } = { AND: [], OR: [] };
  filters.AND.push(
    { isArchive: { is: false } },
    {
      Notification_action: {
        any_of: userNotificationFilters,
      },
    },
  );

  return filters;
};

export const updateNotification = async (notification: UpdateNotificationProps) => {
  return client.mutate({
    mutation: notificationsGqls.mutations.update,
    variables: { data: notification },
  });
};

export const updateAllNotificationsBySubscriberId = async (notification: UpdateAllUserNotificationsProps) => {
  return client.mutate({
    mutation: notificationsGqls.mutations.updateAllNotifications,
    variables: { data: notification },
  });
};
