import React, { useEffect } from 'react';
import { ButtonContained } from 'components/shared/button';
import TextField from 'components/shared/textField/TextField';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { ResetPasswordFormProps } from './ResetPasswordForm.consts';
import {
  ChangePasswordSection,
  LoginContainerBody,
  StyledInfoTooltip,
  StyledError,
  ErrorContainer,
} from './ResetPasswordForm.style';

export const ResetPasswordForm = ({ state }: ResetPasswordFormProps) => {
  const {
    handleSubmit,
    formState: { isValid, errors },
    register,
    getValues,
    watch, 
    trigger
  } = useForm({
    mode: 'onBlur',
  });

   useEffect(() => {
    const subscription = watch(() => {
      trigger(['password', 'passwordConfirm']);
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);


  const history = useHistory();

  const onSubmit = async (formData: any) => {
    const { username, session } = state;
    const res = await fetch(`/resetpw`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...formData, username, session }),
    });
    if (res.ok) {
      showToast(MessageType.Success, `Password successfully updated`);
      history.push('/');
      history.go(0);
    }
  };

  const handleKeyPress = async (e: KeyboardEvent) => {
    const password = getValues('password');
    const passwordConfirm = getValues('passwordConfirm'); 
    if (e.key === 'Enter') {
      if (isValid) {
        await onSubmit({ ['password']: password, ['passwordConfirm']: passwordConfirm  }); 
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
    return () => 
      window.removeEventListener('keydown', handleKeyPress); 
  }, [isValid])

  return (
    <>
      <LoginContainerBody>
        <div>
          <p>Welcome to VCE!</p>
          <p>Please change password to continue</p>
        </div>
        <ChangePasswordSection>
          <label htmlFor="password">New Password</label>
          <StyledInfoTooltip>
            Password must include:
            <ul>
              <li>At least 6 Characters</li>
              <li>Upper and lower case letters</li>
              <li>One or more numbers</li>
              <li>Special character: ^ $ * . [ ] {} ( ) ? ! @ # % &amp; / \ , &gt; &lt; &quot; : ; | _ ~ ` = + -</li>
              <li>No spaces</li>
            </ul>
          </StyledInfoTooltip>
          <TextField
            register={register}
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            validation={{
              required: true,
              validate: {
                valid: (v: string) =>
                  v.match(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&^()\\/[\]{}<>:;`~_+\-="'|.,])[A-Za-z\d@$!%*#?&^()\\/[\]{}<>:;`~_+\-="'|.,]{6,}$/,
                  )
                    ? true
                    : 'Password does not meet the minimum requirements.',
              },
            }}
          />
          <label htmlFor="passwordConfirm">Confirm Password</label>
          <TextField
            register={register}
            id="passwordConfirm"
            name="passwordConfirm"
            type="password"
            placeholder="Confirm Password"
            validation={{
              required: true,
              validate: {
                valid: (v: string) => (v === getValues('password') ? true : 'Passwords do not match'),
              },
            }}
          />
        </ChangePasswordSection>
        <ButtonContained onClick={handleSubmit(onSubmit)} disabled={!isValid}>
          Reset Password
        </ButtonContained>
      </LoginContainerBody>
      <ErrorContainer>
        {errors.password ? (
          <StyledError errors={errors} name="password" />
        ) : errors.passwordConfirm ? (
          <StyledError errors={errors} name="passwordConfirm" />
        ) : null}
      </ErrorContainer>
    </>
  );
};
