import React from 'react';
import {
  GridCellParams,
  GridColumns,
  GridRowModel,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { formatDate } from 'utils/date';
import { TableProps } from 'components/shared/table/Table.consts';
import { auditActionRecord, auditTypesRecord } from 'pages/reports/audit/Audit.consts';
import { UsernameDisplay } from 'pages/shared/usernameDisplay/UsernameDisplay';
import { User } from 'utils/types/users';
import { DateTimeConfig } from 'utils/types';
import CellTitleWrapper from 'components/shared/table/components/cellTitleWrapper/CellTitleWrapper';

export const getAuditTableProps = (dateTimeConfig: DateTimeConfig): TableProps => ({
  columns: [
    {
      field: `fullName`,
      headerName: `User Name`,
      width: 220,
      renderCell: (params: GridCellParams) => {
        return <UsernameDisplay user={params.row?.user as User} fullName={params.row?.fullName}/>;
      },
    },
    {
      field: `createdAt`,
      headerName: `Time`,
      width: 220,
      valueGetter: (params: GridValueGetterParams) => params.row?.createdAt,
      valueFormatter: (params: GridValueFormatterParams) =>
        formatDate(params.value.toString(), dateTimeConfig, dateTimeConfig.startTimezone),
    },
    {
      field: `action`,
      headerName: `Action`,
      width: 220,
      valueFormatter: (params: GridValueFormatterParams) => auditActionRecord[params.value as string]?.name,
    },
    {
      field: `entityType`,
      headerName: `Entity Type`,
      width: 220,
      valueFormatter: (params: GridValueFormatterParams) =>
        auditTypesRecord[params.value.toString()]?.name || params.value,
    },
    {
      field: `entityName`,
      headerName: `Entity Name`,
      width: 220,
      renderCell: (params: GridCellParams) => {
        return <CellTitleWrapper title={params.value as string} />;
      },
    },
    { field: `entityId`, headerName: `Entity ID`, width: 220 },
  ] as GridColumns,
  rows: [] as GridRowModel[],
  sortingMode: 'server',
  sortingOrder: ['desc', 'asc'],
});
