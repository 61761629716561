import { gql } from '@apollo/client';

export const tagsGqls = {
  queries: {
    getAll: gql`
      query Tags($data: GetAllQueryWithUsageDto) {
        getTags(data: $data) {
          items {
            ... on Tag {
              id
              name
              amount
              isMasterTag
              isLocked
              createdBy {
                id
                firstName
                lastName
                email
                active
              }
              validEntities
              amountByEntities
            }
          }
          total
        }
      }
    `,
    getTagsTagsPage: gql`
      query Tags($data: GetAllQueryWithUsageDto) {
        getTagsTagsPage(data: $data) {
          items {
            ... on Tag {
              id
              name
              amount
              isMasterTag
              isLocked
              createdBy {
                id
                firstName
                lastName
                email
                active
              }
              validEntities
              amountByEntities
            }
          }
          total
        }
      }
    `,
    getById: gql`
      query Tag($id: Int!) {
        getTag(id: $id) {
          id
          name
          amount
          validEntities
          images {
            id
            name
            isArchive
            fileName
          }
          offers {
            id
            isArchive
            versions {
              status
              translations {
                title
              }
            }
          }
          campaigns {
            id
            title
            status
            isArchive
          }
        }
      }
    `,
  },
  mutations: {
    add: gql`
      mutation CreateTag($data: CreateTagDto!) {
        createTag(data: $data) {
          id
          name
          amount
          isMasterTag
          isLocked
          createdBy {
            id
            firstName
            lastName
            email
            active
          }
          validEntities
          amountByEntities
        }
      }
    `,
    update: gql`
      mutation UpdateTag($data: UpdateTagDto!) {
        updateTag(data: $data) {
          id
        }
      }
    `,
    delete: gql`
      mutation DeleteTag($data: DeleteTagDto!) {
        deleteTag(data: $data) {
          id
        }
      }
    `,
  },
};
