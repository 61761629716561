import styled from '@emotion/styled';
import typography from 'styles/typography';
import { OverviewBase, OverviewSubHeader } from 'pages/dashboard/Dashboard.style';
import { StyledWithThemeProps } from 'utils/types';

export const UpdatesOverviewContainer = styled(OverviewBase)`
  width: 284px;
  height: 478px;
  padding: ${typography.unit * 5}px ${typography.unit * 7}px;
`;

export const UpdatesOverviewSubHeader = styled(OverviewSubHeader)`
  margin-bottom: ${typography.unit * 2}px;
`;

export const UpdatesSection = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: ${typography.unit * 4}px;
  }
`;

export const Separator = styled.div<StyledWithThemeProps>`
  width: 224px;
  border-top: 1px solid ${({ theme }) => theme.colors.global.border};
`;
