import React from 'react';
import { StyledTitle } from './TextModalTitle.style';
import { TextTitleProps } from './TextModalTitle.const';

const TextModalTitle = (props: TextTitleProps) => {
  const { text, className } = props;
  return <StyledTitle className={className}>{text}</StyledTitle>;
};

export default TextModalTitle;
