/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
import React from 'react';
import { DateHeaderProps } from 'react-big-calendar';

export const MonthHeader = (props: DateHeaderProps) => {
  const { label, date } = props;
  return (
    <button type="button" className={`rbc-button-link ${label === '1' ? 'big-now' : ''}`} role="cell">
      {`${label === '1' ? `${date.toLocaleDateString('default', { month: 'short' })} ` : ''}${label}`}
    </button>
  );
};
