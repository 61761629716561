import React from 'react';
import {
  GridCellParams,
  GridColumns,
  GridFeatureMode,
  GridRowModel,
  GridSortDirection,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { formatDate } from 'utils/date';
import { TableProps } from 'components/shared/table/Table.consts';
import { DateTimeConfig } from 'utils/types';
import CellTitleWrapper from 'components/shared/table/components/cellTitleWrapper/CellTitleWrapper';
import { AuditChange } from 'pages/reports/shared/shared.consts';
import { StyledUsage, StyledUsageIcon } from '../shared/shared.style';

export const getProductChangesTableProps = (
  onUsageClick: (row: AuditChange) => void,
  dateTimeConfig: DateTimeConfig,
): TableProps => ({
  columns: [
    {
      field: `entityName`,
      headerName: `Product Name`,
      width: 240,
      renderCell: (params: GridCellParams) => {
        return <CellTitleWrapper title={params.value as string} />;
      },
    },
    { field: `entityId`, headerName: `ID`, width: 100 },
    {
      field: `headline`,
      headerName: `Change Type`,
      width: 300,
      renderCell: (params: GridCellParams) => {
        return <CellTitleWrapper title={params.value as string} />;
      },
    },
    {
      field: `createdAt`,
      headerName: `Time`,
      width: 220,
      valueFormatter: (params: GridValueFormatterParams) =>
        formatDate(params.value.toString(), dateTimeConfig, dateTimeConfig.startTimezone),
    },
    {
      field: `changes`,
      headerName: `Impact`,
      width: 300,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <>
            {(row?.changes?.usages?.offers?.length > 0 || row?.changes?.usages?.campaigns?.length > 0) && (
              <StyledUsage>
                <StyledUsageIcon name="export" onClick={() => onUsageClick(row as AuditChange)} />
                {row?.changes?.usages?.offers?.length || 0} Offers and {row?.changes?.usages?.campaigns?.length || 0}{' '}
                Campaigns
              </StyledUsage>
            )}
          </>
        );
      },
    },
  ] as GridColumns,
  rows: [] as GridRowModel[],
  sortingMode: 'server' as GridFeatureMode,
  sortingOrder: ['desc', 'asc'] as GridSortDirection[],
});
