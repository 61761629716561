import styled from '@emotion/styled/macro';
import { Icon, StyledClickableIconProps } from 'components/shared/icon';
import typography from 'styles/typography';

export const StyledUsage = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledUsageIcon = styled(Icon)<StyledClickableIconProps>`
  width: 14px;
  fill: ${({ theme }) => theme.colors.global.inputPrimary};
  margin-right: ${typography.unit * 2}px;
  cursor: pointer;

  &:hover {
    fill: ${({ theme }) => theme.colors.button.hover};
  }
`;
