import React from 'react';
import { downloadCSV } from 'utils/download';
import { DownloadCsvButtonProps } from 'pages/shared/downloadCsvButton/DownloadCsvButton.props';
import { StyledButton, StyledDownload } from 'pages/shared/downloadCsvButton/DownloadCsvButton.style';

const DownloadCsvButton = ({ createRows, updateLoadingState, fileName, className }: DownloadCsvButtonProps) => {
  const downloadAuditReport = async () => {
    if (updateLoadingState) {
      updateLoadingState(true);
    }
    downloadCSV(await createRows(), fileName);
    if (updateLoadingState) {
      updateLoadingState(false);
    }
  };

  return (
    <StyledButton onClick={downloadAuditReport} className={className} data-title="Download CSV">
      <StyledDownload name="download" />
    </StyledButton>
  );
};

export default DownloadCsvButton;
