import React, { useState, useEffect } from 'react';
import { LastUpdated, Number, SideOverviewContent, SideOverviewHeader } from 'pages/dashboard/Dashboard.style';
import { formatDate } from 'utils/date';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { RedemptionsOverview } from 'app/slices/dashboard';
import { RedemptionsOverviewStyle, StyledStatusSmallBox } from './RedemptionOverview.style';
import { RedemptionOverviewDropdownEnum, RedemptionOverviewRecord } from './RedemptionOverview.const';
import { RedemptionOverviewDropdown } from 'pages/dashboard/components/redemptionOverview/components/RedemptionOverviewDropdown';
import { startSSEConnection } from 'app/slices/dashboard';
import { useAppDispatch } from 'app/hooks';

const RedemptionOverview = ({ redemptions }: { redemptions: RedemptionsOverview }) => {
  const { config } = useSelector(marketConfig);
  const { dateFormat, timeFormat, startTimezone } = config;
  const [redemptionCount, setRedemptionCount] = useState<number>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const cleanup = dispatch(startSSEConnection());
    return cleanup;
  }, []);

  const getSelectedTotalRedemptions = (selectedOption: string = RedemptionOverviewDropdownEnum.Today) => {
    const selectedCount = {
      [RedemptionOverviewDropdownEnum.Yesterday]: redemptions.totalRedemptions.yesterday,
      [RedemptionOverviewDropdownEnum.Last7Days]: redemptions.totalRedemptions.last7Days,
      [RedemptionOverviewDropdownEnum.Last30Days]: redemptions.totalRedemptions.last30Days,
      [RedemptionOverviewDropdownEnum.Today]: redemptions.totalRedemptions.today,
    }[selectedOption];

    setRedemptionCount(selectedCount);
  };

  const options = Object.values(RedemptionOverviewDropdownEnum).map((dropdown) => ({
    id: dropdown,
    name: RedemptionOverviewRecord[dropdown],
  }));

  return (
    <RedemptionsOverviewStyle data-automation-id="redemptions-overview">
      <SideOverviewHeader>Redemptions Overview</SideOverviewHeader>
      <LastUpdated>
        Last Updated:
        {redemptions.lastUpdated &&
          ` ${formatDate(redemptions.lastUpdated, { dateFormat, timeFormat }, startTimezone)}`}
      </LastUpdated>
      <SideOverviewContent>
        <StyledStatusSmallBox color="blue" data-automation-id="selected-days-redemptions-overview">
          <Number>{redemptionCount || redemptions.totalRedemptions.today}</Number>
          <RedemptionOverviewDropdown
            options={options}
            onSelect={getSelectedTotalRedemptions}
            defaultOption={options[0]}
          />
        </StyledStatusSmallBox>
        <StyledStatusSmallBox color="blue" data-automation-id="total-redemptions-overview">
          <Number>{redemptions.totalRedemptions.overall}</Number>
          <span>Total Redemptions</span>
        </StyledStatusSmallBox>
      </SideOverviewContent>
    </RedemptionsOverviewStyle>
  );
};
export default RedemptionOverview;
