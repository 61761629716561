import { client } from 'app/apollo';
import productSetsGqls from 'pages/settings/productSets/ProductSets.gqls';
import offersGqls from 'pages/offers/Offers.gqls';
import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import { Product, ProductSet } from 'utils/types/products';
import { GenericEntity, OrderDirection } from 'utils/types';
import { clientQuery } from './clientQuery';

const getImpactFiltersBySetId = (setId: number): { bool: { must: [{ match: { [key: string]: number } }] } } => {
  return {
    bool: {
      must: [
        {
          match: {
            'productsFromSets.productSetExternalId': setId,
          },
        },
      ],
    },
  };
};

export const createProductSet = async (name: string, selectedProductsIds: Product[]) => {
  const products = selectedProductsIds.map((product) => Number(product.id));
  return client.mutate({
    mutation: productSetsGqls.mutations.createProductSet,
    variables: { data: { name, description: '', products } },
    refetchQueries: ['ProductSets'],
  });
};

export const updateProductSet = async (
  id: number,
  name: string,
  description: string,
  selectedProductsIds: Product[],
) => {
  const products = selectedProductsIds.map((product) => Number(product.id));
  return client.mutate({
    mutation: productSetsGqls.mutations.updateProductSet,
    variables: { data: { id: Number(id), name, description, products } },
    refetchQueries: ['ProductSets'],
  });
};

export const deleteProductSet = async (id: string | number) => {
  return client.mutate({
    mutation: productSetsGqls.mutations.deleteProductSet,
    variables: { id },
    refetchQueries: ['ProductSets'],
  });
};

export const getOffersImpactsByProductSetId = async (externalSetId: number, limit: number = 9999) => {
  const offersImpacts = await clientQuery(offersGqls.queries.getForImpact, {
    data: {
      filters: getImpactFiltersBySetId(externalSetId),
      order: {
        id: OrderDirection.DESC,
      },
      limit,
    },
  });

  return offersImpacts.data.getOffers.items;
};

export const getCampaignsImpactsByProductSetId = async (externalSetId: number, limit: number = 9999) => {
  const campaignsImpacts = await clientQuery(campaignsGqls.queries.getForImpact, {
    data: {
      filters: getImpactFiltersBySetId(externalSetId),
      order: {
        externalId: OrderDirection.DESC,
      },
      limit,
      ...(limit === 9999 && { isBatchingRequired: true }),
    },
  });

  return campaignsImpacts.data.getCampaigns.items;
};

export const getAvailableProductCount = async (filters: any) => {
  const categories = await client.query<{ getAvailableProducts: GenericEntity<ProductSet> }>({
    query: productSetsGqls.queries.getAvailableProducts,
    fetchPolicy: 'network-only',
    variables: {
      data: {
        filters,
      },
    },
  });

  return {
    total: categories.data.getAvailableProducts.total,
  };
};
