import styled from '@emotion/styled';
import { Icon } from 'components/shared/icon';
import typography from 'styles/typography';

// region Tags table
export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${typography.unit * 2}px;
`;
// endregion
