const typography = {
  primaryFont: 'Proxima Nova, -apple-system, sans-serif',
  secondaryFont: 'Montserrat',
  fontSize: 14,
  unit: 4,
  transition: 0.3,
  inputComponentHeight: 28,
  borderRadius: 3,
};

export default typography;
