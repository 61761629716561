import styled from '@emotion/styled';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { StyledWithThemeProps } from 'utils/types';
import typography from 'styles/typography';
import { appTheme } from 'styles/themes/defaultTheme';
import SetListItemHeader from 'components/shared/set/SetListItemHeader/SetListItemHeader';
import ListItem from 'pages/shared/setItemsSelectionForm/listItem/ListItem';
import { isInArray } from 'utils/array';
import { AccordionArrow } from 'components/shared/accordion';
import { StyledClickableIconProps } from 'components/shared/icon';

export const StyledListItem = styled(ListItem)`
  padding: 0 !important;
  margin-left: ${typography.unit * 5}px;
`;

export const StyledAccordion = styled(Accordion, { shouldForwardProp: (prop) => prop !== 'nestedSet' })<
  StyledWithThemeProps & { nestedSet?: boolean }
>`
  width: 100%;
  margin: 0 !important;
  box-shadow: none !important;
  border: ${({ theme, nestedSet }) => (nestedSet ? `none` : `1px solid ${theme.colors.global.border};`)};
  padding: 0 ${typography.unit * 3}px;
  padding-right: ${({ nestedSet }) => (nestedSet ? `0px` : `${typography.unit * 3}px`)};
  border-radius: ${typography.borderRadius}px !important;
  margin-bottom: ${typography.unit * 2}px !important;
  margin-right: ${typography.unit * 7}px !important;

  &::before {
    display: none;
  }

  [data-title]:after {
    bottom: 25%;
    right: 100%;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<StyledWithThemeProps>`
  padding: 0 !important;
  min-height: 30px !important;

  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`;

export const AccordionHeader = styled('div', {
  shouldForwardProp: (prop) => !isInArray(['expanded', 'nestedSet'], prop),
})<{ expanded: boolean; theme?: appTheme; nestedSet?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.text.primary};
  border-bottom: ${({ expanded, theme, nestedSet }) =>
    expanded && !nestedSet && `1px solid ${theme.colors.global.border};`};
`;

export const StyledSetListItemHeader = styled(SetListItemHeader)`
  flex: 1;
`;

export const StyledAccordionArrow = styled(AccordionArrow)<StyledClickableIconProps>`
  margin-right: ${typography.unit * 2}px;
  width: 12px;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  padding: ${typography.unit}px 0 !important;
`;
