import { FormMode, GenericItem, GenericSet, SetOfEntity, isViewMode } from 'utils/types';
import uniqBy from 'lodash/uniqBy';
import { AccordionContentType, ListType } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import { SelectedAccordionProps } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/selectedSetItemsListPanel/SelectedSetItemsListPanel.consts';
import { buildObjectById } from 'utils/mapping';
import { SelectedDisplayMode } from 'pages/shared/setItemsSelectionForm/setListItem/SetListItem.consts';

export const getSetNestedSetsRecursively = (set: GenericSet, includeGivenSet = true): GenericSet[] => {
  const sets = includeGivenSet ? [set] : [];

  if (set?.sets) {
    const nestedSets = set.sets.flatMap((nestedSet) => getSetNestedSetsRecursively(nestedSet));
    sets.push(...nestedSets);
  }

  return sets;
};

export const getSetsNestedSetsRecursively = (sets: GenericSet[]): GenericSet[] =>
  (sets || []).flatMap((nestedSet) => getSetNestedSetsRecursively(nestedSet));

export const getSetsFringesItems = (sets: GenericSet[]) =>
  uniqBy(
    getSetsNestedSetsRecursively(sets)
      .filter((s: GenericSet) => s?.fringes?.length)
      .flatMap((s: GenericSet) => s.fringes),
    'id',
  );

export const getSetItems = (set: GenericSet, setOf: SetOfEntity, includeFringes = false): GenericItem[] => {
  const items = set[setOf]?.length ? [...set[setOf]] : [];
  if (includeFringes && set.fringes?.length) {
    items.push(...set.fringes);
  }

  return items;
};

export const getAllItemsRecursively = (
  sets: GenericSet[],
  setOf: SetOfEntity,
  includeFringes = false,
): GenericItem[] => {
  const result = getSetsNestedSetsRecursively(sets)
    .flatMap((set) => getSetItems(set, setOf, includeFringes))
    .filter((item) => item != null);

  return uniqBy(result, 'id');
};

export const hasNestedSet = (set: GenericSet, nestedSetId: number): boolean =>
  getSetNestedSetsRecursively(set, false).some((nestedSet) => nestedSet.id === nestedSetId);

export const getSelectedItems = (
  selectedItemSets: GenericSet[],
  selectedItems: GenericItem[],
  setOf: SetOfEntity,
  includeFringes: boolean,
) => {
  const allSelectedItems = [
    ...getAllItemsRecursively(selectedItemSets || [], setOf, includeFringes),
    ...Object.values(selectedItems),
  ];

  return uniqBy(allSelectedItems, 'id');
};

const getExcludedItemSetsIds = (selectedItemSets: GenericSet[], excludedItemSetsIds: number[]) => {
  const selectedSetsAndNestedSets = getSetsNestedSetsRecursively(selectedItemSets);
  const excludedItemSetsIdsRecords = new Set<number>(excludedItemSetsIds);
  const excludedSets = selectedSetsAndNestedSets.filter((set) => excludedItemSetsIdsRecords.has(set.id));
  const excludedNestedSets = getSetsNestedSetsRecursively(excludedSets);
  return new Set<number>(excludedNestedSets.map((set) => set.id));
};

export const getTotalSelectedItems = (
  selectedItemSets: GenericSet[],
  selectedItems: GenericItem[],
  excludedItemSets: GenericSet[],
  excludedItems: GenericItem[],
  setOf: SetOfEntity,
  includeFringes: boolean,
): number => {
  const excludedItemsIdsRecords = new Set<number>(excludedItems?.map((item) => Number(item.id)));
  const excludedItemSetsIdsRecords = getExcludedItemSetsIds(
    selectedItemSets,
    excludedItemSets?.map((set) => set.id),
  );
  const allSelectedItems = getSelectedItems(selectedItemSets, selectedItems, setOf, includeFringes);
  const nonExcludedItems = allSelectedItems.filter(
    (item) => !excludedItemsIdsRecords.has(Number(item.id)) && !excludedItemSetsIdsRecords.has(item?.setId),
  );
  return nonExcludedItems?.length ?? 0;
};

export const hasOnlyItemsAccordions = (accordions: SelectedAccordionProps[]) =>
  accordions.every((accordion) => accordion.contentType === AccordionContentType.Items);

export const getInitialSelectedItems = (
  set: GenericSet,
  setOf: SetOfEntity,
  includeDummySets: boolean,
): Record<number | string, GenericItem> => {
  const dummySets = includeDummySets ? getSetNestedSetsRecursively(set, false)?.filter((s) => s.dummy) : [];
  const itemsInDummySets = dummySets.length ? getAllItemsRecursively(dummySets, setOf) : [];
  return buildObjectById(uniqBy([...(set?.[setOf] ?? []), ...itemsInDummySets], 'id'));
};

export const getClosetNonDummySets = (set: GenericSet): GenericSet[] => {
  if (set.dummy) {
    return set.sets?.flatMap((nestedSet) => getClosetNonDummySets(nestedSet)) ?? [];
  }
  return [set];
};

export const getInitialSelectedSets = (sets: GenericSet[]): Record<number, GenericSet> => {
  const selectedSets = sets?.flatMap((set) => getClosetNonDummySets(set)) ?? [];
  return buildObjectById(selectedSets);
};

export const isGenericSet = (obj: any, setOf: SetOfEntity): obj is GenericSet => obj[setOf] || obj.externalId >= 0;

export const hasAnyItem = (set: GenericSet, setOf: SetOfEntity, includeFringes: boolean): boolean => {
  return (
    set[setOf]?.length > 0 ||
    (includeFringes && set.fringes?.length > 0) ||
    set.sets?.some((s) => hasAnyItem(s, setOf, includeFringes))
  );
};

export const isInitiallyNonCustomEmptySet = (set: GenericSet, includeNestedSets = true): boolean => {
  if (set.custom) {
    return !set.sets && set.totalItems === 0;
  }
  return (
    (!set.custom && set.totalItems === 0) ||
    (includeNestedSets && set.sets?.some((nestedSet) => isInitiallyNonCustomEmptySet(nestedSet)))
  );
};

export const removeAllDisable = (accordionName: string, formMode: string, customSetsLength: number, setsLength: number, totalSelectedItems: number, totalSelectedProductsLength: number): boolean => {
  if (formMode === 'createNew' || formMode === 'edit') {
    return (totalSelectedItems === 0);
  
  } else if (formMode === 'select') {
      return (accordionName === 'selected-sets' && customSetsLength === 0) ||
      (accordionName === 'selected-locations' && setsLength === 0) ||
      (accordionName === 'selected-products' && totalSelectedProductsLength === 0) || 
      (accordionName === 'selected-product-sets' && setsLength === 0)
  
  } else {
    return false;
  }
};

export const searchDisable = (formMode: FormMode, totalSelectedItems: number, searchValue: string): boolean => {
  if (!isViewMode(formMode)) {
    if (searchValue) {
      return false;
    } else {
      return (totalSelectedItems === 0);
    }
  }
};

export const shouldDisplayItem = (
  item: GenericItem,
  listType: ListType,
  selectedItemsIds: Set<number>,
  selectedItemsDisplayMode?: SelectedDisplayMode,
) => {
  if (listType === ListType.Available) {
    return selectedItemsDisplayMode !== SelectedDisplayMode.Hide || !selectedItemsIds.has(Number(item.id));
  }

  if (listType === ListType.Selected) {
    return selectedItemsIds.has(Number(item.id));
  }
};

export const shouldDisplayAvailableSet = (
  set: GenericSet,
  isNestedSet: boolean,
  setOf: SetOfEntity,
  supportSetFringes: boolean,
  selectedItemSetsIds: Set<number>,
  selectedItemsIds: Set<number>,
  selectedSetsDisplayMode: SelectedDisplayMode,
  selectedNestedSetsDisplayMode: SelectedDisplayMode,
  selectedItemsDisplayMode: SelectedDisplayMode,
) => {
  if (isInitiallyNonCustomEmptySet(set) && !selectedItemSetsIds.has(set.id)) {
    return true;
  }

  if (!hasAnyItem(set, setOf, false)) {
    return false;
  }

  if (set.dummy) {
    const setItems = getAllItemsRecursively([set], setOf, supportSetFringes);
    return setItems.some((item) =>
      shouldDisplayItem(item, ListType.Available, selectedItemsIds, selectedItemsDisplayMode),
    );
  }

  if (isNestedSet) {
    return selectedNestedSetsDisplayMode !== SelectedDisplayMode.Hide || !selectedItemSetsIds.has(set.id);
  }
  return selectedSetsDisplayMode !== SelectedDisplayMode.Hide || !selectedItemSetsIds.has(set.id);
};

export const shouldDisplaySelectedSet = (
  set: GenericSet,
  setOf: SetOfEntity,
  supportSetFringes: boolean,
  selectedItemSetsIds: Set<number>,
  selectedItemsIds: Set<number>,
) => {
  if (isInitiallyNonCustomEmptySet(set)) {
    return true;
  }

  if (!hasAnyItem(set, setOf, false)) {
    return false;
  }

  const setItems = getAllItemsRecursively([set], setOf, false);

  return (
    selectedItemSetsIds.has(set.id) ||
    set.dummy ||
    setItems.some((item) => shouldDisplayItem(item, ListType.Selected, selectedItemsIds))
  );
};

export const getSetsToDisplay = (
  sets: GenericSet[],
  setOf: SetOfEntity,
  isNested: boolean,
  listType: ListType,
  supportSetFringes: boolean,
  selectedItemSetsIds: Set<number>,
  selectedItemsIds: Set<number>,
  selectedSetsDisplayMode?: SelectedDisplayMode,
  selectedNestedSetsDisplayMode?: SelectedDisplayMode,
  selectedItemsDisplayMode?: SelectedDisplayMode,
): GenericSet[] =>
  sets
    ?.map((s) =>
      getSetToDisplay(
        s,
        setOf,
        true,
        listType,
        supportSetFringes,
        selectedItemSetsIds,
        selectedItemsIds,
        selectedSetsDisplayMode,
        selectedNestedSetsDisplayMode,
        selectedItemsDisplayMode,
      ),
    )
    .filter((s) =>
      listType === ListType.Selected
        ? shouldDisplaySelectedSet(s, setOf, supportSetFringes, selectedItemSetsIds, selectedItemsIds)
        : shouldDisplayAvailableSet(
            s,
            true,
            setOf,
            supportSetFringes,
            selectedItemSetsIds,
            selectedItemsIds,
            selectedSetsDisplayMode,
            selectedNestedSetsDisplayMode,
            selectedItemsDisplayMode,
          ),
    );

// @ts-ignore
export const getSetToDisplay = (
  set: GenericSet,
  setOf: SetOfEntity,
  isNested: boolean,
  listType: ListType,
  supportSetFringes: boolean,
  selectedItemSetsIds: Set<number>,
  selectedItemsIds: Set<number>,
  selectedSetsDisplayMode?: SelectedDisplayMode,
  selectedNestedSetsDisplayMode?: SelectedDisplayMode,
  selectedItemsDisplayMode?: SelectedDisplayMode,
) => {
  return {
    ...set,
    sets: getSetsToDisplay(
      set.sets,
      setOf,
      isNested,
      listType,
      supportSetFringes,
      selectedItemSetsIds,
      selectedItemsIds,
      selectedSetsDisplayMode,
      selectedNestedSetsDisplayMode,
      selectedItemsDisplayMode,
    ),
    [setOf]: set[setOf]?.filter((i) => shouldDisplayItem(i, listType, selectedItemsIds, selectedItemsDisplayMode)),
  } as GenericSet;
};
