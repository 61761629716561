import React from 'react';
import useDynamicSVGImport from 'hooks/use-dynamic-svg-import';
import { IconProps } from './Icon.consts';
import { ReactComponent as InfoIcon } from 'assets/svgs/info.svg';

const svgIcons: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
  info: InfoIcon,
};
export const Icon = ({ name, width = 12, staticImport = false, ...rest }: IconProps) => {
  let SvgIcon;
  if (staticImport) {
    ({ SvgIcon } = useStaticSVGImport(name));
  } else {
    ({ SvgIcon } = useDynamicSVGImport(name));
  }
  return <>{SvgIcon && <SvgIcon width={width} data-automation-id={`icon-${name}`} {...rest} />}</>;
};

export default function useStaticSVGImport(name: string) {
  const SvgIcon = svgIcons[name];
  return { SvgIcon };
}