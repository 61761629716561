import { useEffect } from 'react';
import { MessageType } from 'components/shared/notifications/notifications';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';

export const useToastError = (error: any, msg: string) => {
  useEffect(() => {
    if (error) {
      showToast(MessageType.Error, msg);
    }
  }, [error]);
};
