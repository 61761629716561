import { gql } from '@apollo/client';

export const helpMenuGqls = {
  queries: {
    getEnv: gql`
      query {
        getEnvironments {
          marketId
          mcdEnv
          USERGUIDE_PDF_SUFFIX
        }
      }
    `,
  },
};
