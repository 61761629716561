import React from 'react';
import { StyledWeekEvent } from './WeekEvent.style';
import { WeekEventProps } from './WeekEvent.consts';
import { BellIcon, RecurrenceIcon, TriggerEventIcon } from '../calendarView/CalendarView.style';

export const WeekEvent = ({ event }: WeekEventProps)  => {

  return (
    <StyledWeekEvent>
    <img src={`/images/${event.image}`} alt="img" crossOrigin="anonymous" />
    {event.pushNotificationStartDate ? <BellIcon name="bellgray" /> : ''}
    {event.recurrence?.length ? <RecurrenceIcon name="sync" /> : ''}
    {event.isTriggerEvent ? <TriggerEventIcon name="basketball" /> : ''}
    <div>
      <div>ID {event.id}</div>
      <div>{event.title}</div>
    </div>
  </StyledWeekEvent>
  )
}

