import React from 'react';
import { Controller } from 'react-hook-form';
import { ToggleGroupProps } from './ToggleGroup.consts';
import { ToggleGroupContainer } from './ToggleGroup.style';
import ToggleItem from './toggleItem/ToggleItem';

const ToggleGroup = ({ itemList, defaultValue, disabled = false, control, name, className }: ToggleGroupProps) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      shouldUnregister
      render={({ field: { onChange, value } }) => (
        <ToggleGroupContainer className={className} data-automation-id="toggle-group">
          {itemList.map((toggleItem) => (
            <ToggleItem
              key={`${toggleItem.value}_${toggleItem.label}`}
              isSelected={toggleItem.value === value}
              label={toggleItem.label}
              value={toggleItem.value}
              disabled={disabled}
              onClick={() => onChange(toggleItem.value)}
            />
          ))}
        </ToggleGroupContainer>
      )}
    />
  );
};

export default ToggleGroup;
