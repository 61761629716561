import React, { useEffect, useState } from 'react';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { ButtonContained, ButtonOutlined, ButtonText } from 'components/shared/button';
import ReactTooltip from 'react-tooltip';
import { store } from 'app/store';
import { closeModal, Modals, openModal } from 'app/slices/modals';
import { ApprovalStatus, FormMode } from 'utils/types';
import { CampaignAlert, CampaignType } from 'utils/types/campaigns';
import { isInArray } from 'utils/array';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { DistributionType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import {
  archiveCampaign,
  handleDeployOrApproveActionClick,
  rejectCampaign,
  revokeCampaign,
  stopCampaignAssociation,
  submitCampaignForApproval,
} from 'pages/campaigns/campaignManagement/components/campaignForm/utils/CampaignActions';
import { StyledApproveButton, StyledRejectButton, StyledRevokeButton } from 'pages/shared/shared.style';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import { hideTooltip } from 'utils/tooltip';
import { EntityApproveButton } from 'pages/shared/entityApproveButton/EntityApproveButton';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { EntityType } from 'pages/shared/entityApproveButton/EntityApproveButton.consts';
import { offersSelection } from 'app/genericSlices/offers';
import { OfferFilters } from 'pages/offers/Offers.const';
import { FormFooter } from 'pages/campaigns/campaignManagement/components/campaignForm/components/campaignFormFooter/CampaignFormFooter.style';
import {
  CampaignActions,
  CampaignExtendedProps,
  CampaignFormFooterProps,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/campaignFormFooter/CampaignFormFooter.consts';
import { useHistory } from 'react-router-dom';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { getMarketDatetime } from 'utils/date';
import { shouldReDeploy } from 'pages/campaigns/campaignManagement/components/campaignForm/CampaignForm.utils';

const getIsApproveDisabled = (campaignStatus: ApprovalStatus, alert: CampaignAlert, isFormValid: any) => {
  return (
    (campaignStatus === ApprovalStatus.PendingApproval && alert && alert === CampaignAlert.Conflicted) ||
    !isFormValid
  );
};

const getHasStopAssociation = (campaignType: CampaignType, campaignVoucherDistribution: DistributionType) => {
  return (
    isInArray(
      [CampaignType.WelcomeOffer, CampaignType.Segmented, CampaignType.Ned, CampaignType.CustomerServiceOffer],
      campaignType,
    ) ||
    (campaignType === CampaignType.Voucher && campaignVoucherDistribution === DistributionType.Digital)
  );
};

const getStoppedAssociationVoucher = (campaignType: CampaignType, campaignVoucherDistribution: DistributionType, campaignStatus: ApprovalStatus) => {
  return (
    campaignType === CampaignType.Voucher &&
    campaignVoucherDistribution === DistributionType.Digital &&
    campaignStatus === ApprovalStatus.AssociationStopped
  );
};

const getHasRevoke = (campaignType: CampaignType, campaignVoucherDistribution: DistributionType, campaignStatus: ApprovalStatus) => {
  return !(
    campaignType === CampaignType.Loyalty ||
    (campaignType === CampaignType.Voucher && campaignVoucherDistribution === DistributionType.Print) ||
    (campaignType === CampaignType.Voucher && campaignStatus !== ApprovalStatus.Deployed)
  );
};

const CampaignFormFooter = ({
  mode,
  campaignStatus,
  alert,
  isSubmitting,
  setIsSubmitting,
  isLocked,
  onSubmit,
  className,
  fromCalendar = false,
  dateTimeConfig,
  inProgress = false,
}: CampaignFormFooterProps) => {
  const { control, getValues, reset, watch } = useFormContext();
  const { endTimezone, startTimezone } = dateTimeConfig;
  const { isDirty, isValid } = useFormState();
  const [isFormValid, setIsFormValid] = useState(isValid && !isSubmitting);
  const [campaignType, campaignVoucherDistribution, offerVersion] = useWatch({
    control,
    name: ['type', 'voucherConfig.distribution', 'offerVersion'],
  });
  const history = useHistory();
  const isLocalCampaign = watch('isLocalCampaign');

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isDirty, mode]);

  useEffect(() => {
    setIsFormValid(isValid && !isSubmitting);
  }, [isValid, isSubmitting]);

  const [isRejectButtonClicked, setIsRejectButtonClicked] = useState(false);

  const handleRejectClick = (rejectionComment: any) => {
    if (!isRejectButtonClicked) {
      setIsRejectButtonClicked(true);
      onActionClicked(CampaignActions.Reject, rejectionComment);
      hideTooltip('#reject-tooltip');
      ReactTooltip.hide();
    }
  };

  const [isRevokeButtonClicked, setIsRevokeButtonClicked] = useState(false);

  const handleRevokeClick = () => {
    if (!isRevokeButtonClicked) {
      setIsRevokeButtonClicked(true);
      onActionClicked(CampaignActions.Revoke);
      hideTooltip('#revoke-tooltip');
    }
  };

  const onActionClicked = async (actionName: CampaignActions, extraData?: any) => {
    const campaign = getValues() as CampaignExtendedProps;
    store.dispatch(offersSelection.actions.setFilter({ filter: [OfferFilters.Zone], value: null }));
    switch (actionName) {
      case CampaignActions.Edit:
        store.dispatch(
          openModal({
            modal: Modals.CampaignModal,
            props: { mode: FormMode.Edit, campaign: cloneDeep(campaign) },
          }),
        );
        reset(campaign);
        break;
      case CampaignActions.SubmitForApproval: {
        await submitCampaignForApproval(campaign);
        break;
      }
      case CampaignActions.Archive: {
        await archiveCampaign(campaign, true);
        break;
      }
      case CampaignActions.Duplicate: {
        const dupCampaign = {
          ...pick(campaign, [
            'id',
            'type',
            'title',
            'description',
            'schedule',
            'locations',
            'voucherConfig',
            'restaurantEligibility',
            'isNational',
            'localSchedule',
            'isPriority',
            'isTopPriority',
          ]),
          isLocalCampaign: campaign.status === ApprovalStatus.Draft ? campaign.isLocalCampaign : false,
          offerVersion,
          tags: campaign.tags.map((t) => t.id),
          ...(campaign.isLocalCampaign
            ? {
                localSchedule: {
                  zone:
                    campaign.status === ApprovalStatus.Draft && campaign.localSchedule.zone
                      ? campaign.localSchedule.zone?.id
                      : null,
                  period: campaign.status === ApprovalStatus.Draft ? campaign.localSchedule.period : null,
                },
              }
            : {}),
        };
        store.dispatch(
          openModal({
            modal: Modals.CampaignModal,
            props: { mode: FormMode.Duplicate, campaign: cloneDeep(dupCampaign) },
          }),
        );
        reset(dupCampaign);
        break;
      }
      case CampaignActions.ReDeploy:
      case CampaignActions.Deploy:
      case CampaignActions.Approve: {
        if (campaign.schedule?.campaignEnd <= getMarketDatetime(endTimezone)) {
          showToast(MessageType.Error, `Campaign End time is in the past`);
          break;
        }
        setIsSubmitting(true);
        await handleDeployOrApproveActionClick(campaign, actionName);
        setIsSubmitting(false);
        break;
      }
      case CampaignActions.Reject: {
        await rejectCampaign(campaign, extraData);
        break;
      }
      case CampaignActions.Revoke: {
        await revokeCampaign(campaign);
        break;
      }
      case CampaignActions.StopAssociation: {
        await stopCampaignAssociation(campaign);
        break;
      }
      case CampaignActions.Unarchive: {
        await archiveCampaign(campaign, false);
        break;
      }
      default:
    }
  };

  const isApproveDisabled = getIsApproveDisabled(campaignStatus, alert, isFormValid);

  const hasStopAssociation = getHasStopAssociation(campaignType, campaignVoucherDistribution);
  
  const stoppedAssociationVoucher = getStoppedAssociationVoucher(campaignType, campaignVoucherDistribution, campaignStatus);

  const hasRevoke = getHasRevoke(campaignType, campaignVoucherDistribution, campaignStatus);

  const hasReDeploy = shouldReDeploy(getValues() as CampaignExtendedProps, startTimezone);

  const hasDeploy = campaignStatus === ApprovalStatus.Approved && getValues().isTriggerEvent;

  const OutlinedArchiveButton = (
    <ButtonOutlined onClick={() => onActionClicked(CampaignActions.Archive)}>Archive</ButtonOutlined>
  );
  const ContainedArchiveButton = (
    <ButtonContained onClick={() => onActionClicked(CampaignActions.Archive)}>Archive</ButtonContained>
  );
  const DuplicateButton = (
    <ButtonOutlined onClick={() => onActionClicked(CampaignActions.Duplicate)}>Duplicate</ButtonOutlined>
  );
  const ReDeployButton = (
    <ButtonOutlined onClick={() => onActionClicked(CampaignActions.ReDeploy)}>Re-Deploy</ButtonOutlined>
  );
  const StopAssociationButton = (
    <ButtonOutlined onClick={() => null} data-tip data-for="stop-association-tooltip">
      Stop Association
    </ButtonOutlined>
  );
  const UnarchiveButton = (
    <ButtonContained onClick={() => onActionClicked(CampaignActions.Unarchive)}>Unarchive</ButtonContained>
  );
  const EditButton = <ButtonContained onClick={() => onActionClicked(CampaignActions.Edit)}>Edit</ButtonContained>;
  const SubmitForApprovalButton = (
    <ButtonContained
      onClick={() => onActionClicked(CampaignActions.SubmitForApproval)}
      disabled={!isFormValid || (offerVersion && offerVersion.status !== ApprovalStatus.Approved)}
    >
      Submit for Approval
    </ButtonContained>
  );
  const ApproveButton = (
    <EntityApproveButton entity={getValues()} entityType={EntityType.Campaign}>
      <StyledApproveButton disabled={isApproveDisabled} onClick={() => onActionClicked(CampaignActions.Approve)}>
        Approve
      </StyledApproveButton>
    </EntityApproveButton>
  );
  const DeployButton = (
    <EntityApproveButton entity={getValues()} entityType={EntityType.Campaign}>
      <StyledApproveButton disabled={!isFormValid} onClick={() => onActionClicked(CampaignActions.Deploy)}>
        Deploy
      </StyledApproveButton>
    </EntityApproveButton>
  );
  const RejectButton = (
    <StyledRejectButton onClick={() => null} data-tip data-for="reject-tooltip">
      Reject
    </StyledRejectButton>
  );
  const RevokeButton = (
    <StyledRevokeButton
      data-for={stoppedAssociationVoucher ? 'show-stop-association-msg' : 'revoke-tooltip'}
      data-tip
      onClick={() => null}
    >
      Revoke
    </StyledRevokeButton>
  );

  const DeployedButtons = (
    <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
      {DuplicateButton}
      {EditButton}
      {hasRevoke && RevokeButton}
    </RoleGuard>
  );

  const ActiveButtons = (
    <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
      {hasStopAssociation && StopAssociationButton}
      {DuplicateButton}
      {EditButton}
      {hasRevoke && RevokeButton}
    </RoleGuard>
  );

  const DraftOrRejectedButtons = (
    <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
      {OutlinedArchiveButton}
      {DuplicateButton}
      {!isLocalCampaign && SubmitForApprovalButton}
      {EditButton}
    </RoleGuard>
  );

  const ExpiredOrRevokedOrDeploymentFailedButtons = (
    <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
      {hasReDeploy && ReDeployButton}
      {DuplicateButton}
      {ContainedArchiveButton}
    </RoleGuard>
  );

  const ApprovedButtons = (
    <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
      {OutlinedArchiveButton}
      {DuplicateButton}
      {EditButton}
      {!isLocalCampaign && RejectButton}
      {hasDeploy && DeployButton}
    </RoleGuard>
  );

  const StopAssociationButtons = (
    <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
      {DuplicateButton}
      {EditButton}
      {RevokeButton}
    </RoleGuard>
  );

  const inProgressButtons = (
    <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
      {DuplicateButton}
    </RoleGuard>
  );

  const viewModeButtonsByStatus: Partial<Record<ApprovalStatus, JSX.Element>> = {
    [ApprovalStatus.Draft]: DraftOrRejectedButtons,
    [ApprovalStatus.Rejected]: DraftOrRejectedButtons,
    [ApprovalStatus.Deployed]: DeployedButtons,
    [ApprovalStatus.Active]: ActiveButtons,
    [ApprovalStatus.Expired]: ExpiredOrRevokedOrDeploymentFailedButtons,
    [ApprovalStatus.Revoked]: ExpiredOrRevokedOrDeploymentFailedButtons,
    [ApprovalStatus.DeploymentFailed]: ExpiredOrRevokedOrDeploymentFailedButtons,
    [ApprovalStatus.AssociationStopped]: StopAssociationButtons,
    [ApprovalStatus.PendingApproval]: (
      <>
        {ApprovedButtons}
        {!isLocalCampaign && ApproveButton}
      </>
    ),
    [ApprovalStatus.Approved]: ApprovedButtons,
    [ApprovalStatus.Archived]: UnarchiveButton,
  };

  const renderViewModeButtons = (): JSX.Element => {
    if (fromCalendar) {
      return (
        <ButtonContained
          onClick={() => {
            history.push(`/campaigns?id=${getValues().externalId}`);
            store.dispatch(closeModal());
          }}
        >
          Go to Campaign Management
        </ButtonContained>
      );
    }
    return inProgress ? inProgressButtons : viewModeButtonsByStatus[campaignStatus];
  };

  const getButtonsByModeAndStatus = (): JSX.Element => {
    switch (mode) {
      case FormMode.New:
      case FormMode.Duplicate:
        return (
          <>
            {!isLocalCampaign && (
              <ButtonContained
                onClick={onSubmit(false)}
                disabled={!isFormValid || (offerVersion && offerVersion.status !== ApprovalStatus.Approved)}
              >
                Save & Submit for Approval
              </ButtonContained>
            )}
            <ButtonContained onClick={onSubmit(true)} disabled={!isFormValid}>
              Save as Draft
            </ButtonContained>
          </>
        );
      case FormMode.Edit:
        if (
          isInArray([ApprovalStatus.Active, ApprovalStatus.Deployed, ApprovalStatus.AssociationStopped], campaignStatus)
        ) {
          return (
            <ButtonContained
              onClick={() => null}
              disabled={!isFormValid || (offerVersion && offerVersion.status !== ApprovalStatus.Approved)}
              data-tip
              data-for="deployed-active-tooltip"
            >
              Save
            </ButtonContained>
          );
        }
        if (isLocalCampaign) {
          return (
            <ButtonContained onClick={onSubmit(campaignStatus === ApprovalStatus.Draft)} disabled={!isFormValid}>
              Save
            </ButtonContained>
          );
        }
        return (
          <>
            <ButtonContained onClick={onSubmit(true)} disabled={!isFormValid}>
              Save as Draft
            </ButtonContained>
            <ButtonContained
              onClick={onSubmit(false)}
              disabled={!isFormValid || (offerVersion && offerVersion.status !== ApprovalStatus.Approved)}
            >
              Save & Submit for Approval
            </ButtonContained>
          </>
        );
      case FormMode.View:
        return renderViewModeButtons();
      default:
        return null;
    }
  };

  return (
    <FormFooter className={className}>
      <ButtonText
        data-for="cancel-tooltip"
        {...(isDirty || mode === FormMode.Duplicate
          ? { 'data-tip': true, onClick: () => null }
          : { onClick: () => store.dispatch(closeModal()) })}
      >
        {mode === FormMode.View ? 'Close' : 'Cancel'}
      </ButtonText>
      {!isLocked && getButtonsByModeAndStatus()}
      <Tooltip
        id="cancel-tooltip"
        content="Are you sure you want to cancel?"
        onDisapproveClick={() => {
          hideTooltip('#cancel-tooltip');
        }}
        onApproveClick={() => {
          store.dispatch(offersSelection.actions.setFilter({ filter: [OfferFilters.Zone], value: null }));
          store.dispatch(closeModal());
        }}
      />
      <Tooltip
        id="revoke-tooltip"
        content={
          campaignType === CampaignType.Voucher
            ? 'Please consult with legal prior to revoking voucher campaign'
            : 'Are you sure you want to revoke this campaign?'
        }
        approveMsg="Yes, Revoke"
        onDisapproveClick={() => {
          hideTooltip('#revoke-tooltip');
          setIsRevokeButtonClicked(false);
        }}
        onApproveClick={handleRevokeClick}
      />
      <Tooltip
        id="show-stop-association-msg"
        content={'Unable to revoke the digital voucher as the association has already been stopped.'}
        approveMsg="Close"
        onApproveClick={() => {
          hideTooltip('#show-stop-association-msg');
        }}
      />
      <Tooltip
        id="stop-association-tooltip"
        content="Are you sure you want to stop association of this campaign?"
        onDisapproveClick={() => {
          hideTooltip('#stop-association-tooltip');
        }}
        approveMsg="Yes, Stop Association"
        onApproveClick={() => onActionClicked(CampaignActions.StopAssociation)}
      />
      <Tooltip
        id="reject-tooltip"
        content="Are you sure you want to reject?"
        onDisapproveClick={() => {
          hideTooltip('#reject-tooltip');
          ReactTooltip.hide();
          setIsRejectButtonClicked(false);
        }}
        eventOff={null}
        approveMsg="Yes, Reject"
        isWithResponse
        responsePlaceholder="Enter reject reason"
        onApproveWithResponseClick={(rejectionComment) => handleRejectClick(rejectionComment)}
      />
      <Tooltip
        id="deployed-active-tooltip"
        content={
          getValues('type') === CampaignType.Voucher
            ? `You are about to update ${
                campaignStatus === ApprovalStatus.Deployed ? 'a deployed' : 'an active'
              } voucher campaign. Please consult the legal department before proceeding.`
            : `Are you sure you want to update ${
                campaignStatus === ApprovalStatus.Active ? 'an active' : 'a deployed'
              } campaign?`
        }
        onDisapproveClick={() => {
          hideTooltip('#deployed-active-tooltip');
        }}
        approveMsg="OK, Save"
        onApproveClick={onSubmit(false)}
      />
    </FormFooter>
  );
};

export default CampaignFormFooter;
