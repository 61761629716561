import styled from '@emotion/styled/macro';
import typography from 'styles/typography';
export const Actions = styled.footer`
  display: flex;
  justify-content: flex-start;
  gap: ${typography.unit * 5}px;
  bottom: 0;
  padding-top: ${typography.unit * 2}px;
`;

export const StyledDnDItems = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  overflow-y: scroll;
`;

export const PriorityColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PriorityRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
`;
