export enum ProductSetsFilters {
  SearchQuery = 'searchQuery',
  FamilyGroup = 'familyGroup',
  WithoutProductsIds = 'withoutProductsIds',
  SetType = 'setType'
}

export enum ProductSetFilters {
  id = 'id',
  name = 'name',
}
