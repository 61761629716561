import { ApprovalStatus, FormMode } from 'utils/types';
import { isUndefinedOrEmpty } from 'utils/form';
import { CampaignProps } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { getMarketDatetime } from 'utils/date';

export const shouldShowFieldInViewMode = (mode: FormMode, fieldValue: any) => {
  if (typeof fieldValue === 'boolean' && mode === FormMode.View) {
    return fieldValue;
  }
  return mode !== FormMode.View || !isUndefinedOrEmpty(fieldValue);
};

export const shouldReDeploy = (campaign: CampaignProps, startTimezone: string): boolean => {
  const deploymentFailedCount = campaign?.approvals?.[0]?.deploymentFailedCount;
  return (
    deploymentFailedCount !== null &&
    campaign?.status === ApprovalStatus.DeploymentFailed &&
    deploymentFailedCount < 3 &&
    campaign?.totalLocations > 0 &&
    campaign?.schedule?.campaignStart > getMarketDatetime(startTimezone)
  );
};
