import styled from '@emotion/styled';
import typography from 'styles/typography';

export const ProgressIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: #1f5cf6;
`;

export const StyledProgress = styled.div<{ marginLeft: number }>`
  margin-left: ${(props) => props.marginLeft || typography.unit * 2}px;
  cursor: pointer;
`;

export const BackgroundCircle = styled.div<{ size: number }>`
  position: absolute;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  border: 3px solid rgba(31, 92, 246, 0.4);
`;
