import styled from '@emotion/styled';
import typography from 'styles/typography';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Icon, IconProps } from 'components/shared/icon';
import { defaultTheme } from 'styles/themes/defaultTheme';
import InputFiles from 'components/shared/inputFiles/InputFiles';

export const ImagesListWrapper = styled.div`
  overflow: auto;
`;

export const ImagesList = styled(InfiniteScroll)`
  display: flex;
  flex-wrap: wrap;
  padding: 0 ${typography.unit * 5}px ${typography.unit * 5}px ${typography.unit * 5}px;
  overflow-y: auto;
  column-gap: ${typography.unit * 10}px;
  row-gap: ${typography.unit * 12}px;
`;

export const StyledInputFiles = styled(InputFiles)`
  padding: ${typography.unit * 3}px;
`;

export const StyledPlus = styled(Icon)<IconProps>`
  fill: ${defaultTheme.colors.text.white};
  width: 10px;
`;
