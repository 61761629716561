import { useLazyQuery } from '@apollo/client';
import { getTagsFilter } from 'app/genericSlices/tags';
import { MessageType } from 'components/shared/notifications/notifications';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { TagFilterType } from 'pages/settings/tags/Tags.consts';
import { tagsGqls } from 'pages/settings/tags/Tags.gqls';
import { useEffect } from 'react';
import { OrderDirection } from 'utils/types';
import { FetchPolicies } from 'utils/types/common';
import { TagsValidEntities } from 'utils/types/tags';

export const useTagsQuery = (
  entities: TagsValidEntities[] = [],
  order: any = { name: OrderDirection.ASC },
  filters?: any,
) => {
  const [load, { data: { getTags: { items: tags = [], total = 0 } = {} } = {}, loading, startPolling, stopPolling }] =
    useLazyQuery(tagsGqls.queries.getAll, {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      variables: {
        data: {
          filters: { ...getTagsFilter({ ...filters, ...(!filters && { [TagFilterType.EntityType]: entities }) }) },
          entities,
          order,
          path: 'tags',
          attribute: 'id',
        },
      },
    });

  return { load, tags, total, loading, startPolling, stopPolling };
};

export const useTagsPageQuery = (
  entities: TagsValidEntities[] = [],
  order: any = { name: OrderDirection.ASC },
  filters?: any,
) => {
  const [load, { data: { getTagsTagsPage: { items: tags = [], total = 0 } = {} } = {}, loading, error, startPolling, stopPolling }] =
    useLazyQuery(tagsGqls.queries.getTagsTagsPage, {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      variables: {
        data: {
          filters: { ...getTagsFilter({ ...filters, ...(!filters && { [TagFilterType.EntityType]: entities }) }) },
          entities,
          order,
          path: 'tags',
          attribute: 'id',
        },
      },
    });

  useEffect(() => {
    if (error) {
      showToast(MessageType.Error, 'Error loading tags');
    }
  }, [error]);

  return { load, tags, total, loading, startPolling, stopPolling };
};