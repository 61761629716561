import { gql } from '@apollo/client';

const auditGqls = {
  queries: {
    getAll: gql`
      query Audit($data: GetAllQueryDto) {
        getAudit(data: $data) {
          items {
            ... on EntityAudit {
              id
              action
              fullName
              user {
                firstName
                lastName
                active
              }
              createdAt
              entityType
              entityName
              entityId
            }
          }
          total
        }
      }
    `,
  },
};

export default auditGqls;
