import styled from '@emotion/styled/macro';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import { Icon } from 'components/shared/icon';
import { ButtonText } from 'components/shared/button';

export const StyledNotificationMessageContainer = styled.div<StyledWithThemeProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  border-radius: ${typography.unit}px;
`;

export const StyledMessageContainer = styled.div<{ isRead: boolean }>`
  display: flex;
  flex-wrap: wrap;
  font-weight: ${({ isRead }) => (isRead ? `400` : `500`)};
  padding: ${typography.unit}px ${typography.unit}px 0 ${typography.unit}px;
`;

export const StyledMessage = styled.span<StyledWithThemeProps>`
  margin-right: ${typography.unit}px;
`;

export const StyledLink = styled.div<StyledWithThemeProps>`
  color: ${({ theme }) => `${theme.colors.global.inputPrimary}`};
  margin-right: ${typography.unit}px;
  cursor: pointer;

  a:link:not(#exclude),
  a:visited:not(#exclude) {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
`;

export const StyledMessageFooter = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: ${typography.unit * 4}px ${typography.unit}px ${typography.unit * 2}px ${typography.unit}px;
`;

export const StyledActionsContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const StyledAction = styled(ButtonText)`
  margin-left: ${typography.unit}px;
  font-size: 12px;
`;

export const StyledIcon = styled(Icon)<StyledWithThemeProps>`
  color: ${({ theme }) => theme.colors.global.inputPrimary};
  margin-left: ${typography.unit}px;
`;
