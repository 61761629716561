import { ApprovalStatus } from 'utils/types';

export enum RedemptionFilterType {
  Type = 'type',
  CampaignExternalIds = 'campaignExternalIds',
  DateRangeStart = 'dateRangeStart',
  DateRangeEnd = 'dateRangeEnd',
  Interval = 'interval',
}

export const aggregationTypes = [
  { id: 'overtime', name: 'Overtime' },
  { id: 'hourAvg', name: '24H Average' },
];

export enum AggregationType {
  overtime = 'overtime',
  hourAvg = 'hourAvg',
}

export const AggregationTypeLabel: Record<AggregationType, string> = {
  [AggregationType.overtime]: 'Overtime',
  [AggregationType.hourAvg]: '24H Average',
};

export enum Interval {
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}

export interface SelectedCampaignProps {
  id: number;
  externalId: number;
  title: string;
  status: ApprovalStatus;
}

export enum RedemptionDownloadAction {
  CSV = 'csv',
  JPEG = 'jpeg',
}

export const RedemptionDownloadActionsList: { id: RedemptionDownloadAction; name: string }[] = [
  { id: RedemptionDownloadAction.CSV, name: 'Download CSV' },
  { id: RedemptionDownloadAction.JPEG, name: 'Download JPEG' },
];

export const overtimeColors = [
  '#436A70',
  '#59C4AE',
  '#C2CE70',
  '#E5B540',
  '#D67E35',
  '#DB6E76',
  '#F1C1A8',
  '#E3A1CA',
  '#BEC8EB',
  '#7E66AC',
];

export const hourAvgColors = ['#BEC8EB', '#7E66AC', '#59C4AE'];
