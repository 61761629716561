import { createSlice } from '@reduxjs/toolkit';
import { OrderDirection } from 'utils/types';
import type { RootState } from 'app/store';
import { LocationChangesFilterType } from 'pages/reports/locationChanges/LocationChanges.consts';
import { buildBaseAndFilters, generateSearchFilter } from 'utils/serverFilters';

export interface LocationChanges {
  filters?: any;
  order?: any;
}

export const initialState: LocationChanges = {
  filters: {},
  order: {
    createdAt: OrderDirection.DESC,
  },
};

export const getFilters = (initialFilters: { [key in LocationChangesFilterType]?: any[] }): any => {
  const LocationChangesFiltersRecord = {
    [LocationChangesFilterType.ChangeDetails]: { serverFilter: 'action', operation: 'in' },
  };

  const filters = buildBaseAndFilters(LocationChangesFiltersRecord, initialFilters);
  filters.AND.push({ entityType: { any_of: ['Location', 'LocationSet'] } });
  filters.AND.push({ action: { not: 'updated' } });

  if (initialFilters[LocationChangesFilterType.SearchQuery]?.length) {
    const searchByFields = ['entityName'];
    const idFields = ['entityId'];
    filters.AND.push(
      generateSearchFilter(searchByFields, idFields, initialFilters[LocationChangesFilterType.SearchQuery]),
    );
  }

  return filters;
};

export const locationChangesSlice = createSlice({
  name: 'locationChanges',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setOrder(state, action) {
      state.order = action.payload;
    },
  },
});

export const locationChanges = (state: RootState) => state.locationChanges;
export const { setFilters, setOrder } = locationChangesSlice.actions;

export default locationChangesSlice.reducer;
