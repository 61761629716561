export enum Feature {
  TermConditionArchive = 'ft-archive-t&c',
  GamingIWin = 'ft-iwin',
  DraftCampaignOfferEnhancement = 'ft-draft-campaign-offer-enhancement',
  CampaignBulk = 'ft-campaign-bulk',
  Calendar = 'ft-calendar',
  GeneratedName = 'ft-generated-name',
  SegmentId = 'ft-segment-id',
  CampaignBulkActions = 'ft-campaign-bulk-actions',
  Ned = 'ft-ned',
}
