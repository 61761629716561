/**
 * This method converts an array of strings into a map where each key is a string and the value is item in the form of {id: XX, name: XX}
 * @param arr array of strings
 * @returns { [string: item]: {id: item, name: item}}
 */
import { GridSortItem } from '@mui/x-data-grid-pro';
import { GenericItem, OrderDirection } from 'utils/types';

export const convertStringArrayToObjectArray = (arr: string[], idField: string, nameField: string): any[] => {
  return arr
    .filter((item) => !!item)
    .map((item) => {
      return {
        [idField]: item,
        [nameField]: item,
      };
    });
};

export const convertStringArrayToGenericItemArray = (arr: string[]): GenericItem[] => {
  return convertStringArrayToObjectArray(arr, 'id', 'name');
};

export const convertEnumToObjectArray = (en: any, idField: string, nameField: string): any[] => {
  return convertStringArrayToObjectArray(Object.values(en), idField, nameField);
};

export const convertEnumToGenericItemArray = (en: any) => {
  return convertEnumToObjectArray(en, 'id', 'name');
};

export const convertIdArrayToGenericItemRecord = <T extends GenericItem>(
  ar: any[],
  items?: any,
  valueField: keyof T = 'id',
): { [key in number | string]: T } => {
  return (ar || []).reduce(
    (res, item) =>
      item
        ? {
            ...res,
            [item]: items
              ? items.find((i: any) => (i[valueField] || i).toString() === item.toString())
              : { [valueField]: item, name: item },
          }
        : res,
    {},
  );
};

export const convertIdsArrayToGenericItemsArray = (idsArray: any[], itemsArray?: any, idField = 'id'): any[] => {
  return (idsArray || [])
    .map((id) => (itemsArray || []).find((item: any) => item[idField].toString() === id.toString()))
    .filter((item) => item !== undefined);
};

export const convertGenericItemArrayToRecord = <T extends GenericItem>(ar: any[]): { [key in number | string]: T } =>
  (ar || []).reduce((res, item) => (item ? { ...res, [item.id]: { id: item.id, name: item.name } } : res), {});

export const convertStringArrayToBooleanTrueRecord = (ar: string[]): Record<string, boolean> => {
  return (ar || []).reduce((res, item) => (item ? { ...res, [item]: true } : res), {});
};

export const buildObjectBy = <T>(keyField: keyof T, items: T[]): Record<string | number, T> => {
  return (items || [])
    .filter((item) => item)
    .reduce((res, item) => {
      res[(item as any)[keyField]] = item;
      return res;
    }, {} as Record<string | number, T>);
};

export const buildObjectById = <T extends { id: number | string }>(items: T[]): Record<number, T> => {
  return buildObjectBy('id', items);
};

export const mapOrder = (order: { [key: string]: OrderDirection.DESC | OrderDirection.ASC | null | undefined }) => {
  return [
    {
      field: Object.keys(order)[0],
      sort: Object.values(order)[0].toLowerCase(),
    } as unknown as GridSortItem,
  ];
};
