import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import auditReducer from 'app/slices/audit';
import termsConditionsReducer from 'app/genericSlices/termsConditions';
import imagesReducer from 'app/genericSlices/images';
import configReducer, { getConfiguration } from './slices/config';
import modalReducer from './slices/modals';
import productsReducer from './slices/products';
import locationsReducer from './slices/locations';
import usersReducer, { initUser } from './slices/users';
import tagsReducer from './genericSlices/tags';
import productChangesReducer from './slices/productChanges';
import locationChangesReducer from './slices/locationChanges';
import dashboardReducer from './slices/dashboard';
import userManagementReducer from './slices/userManagement';
import campaignsReducer from './genericSlices/campaigns';
import offersReducer from './genericSlices/offers';
import redemptionsReducer from './slices/redemptions';
import periodsReducer from './genericSlices/periods';
import scheduleReducer from './slices/schedule';
import calendarReducer from './slices/calendar';
import impactsReducer from './genericSlices/impacts';
import usageReducer from './genericSlices/usage';

export const store = configureStore({
  reducer: {
    config: configReducer,
    terms: termsConditionsReducer,
    modals: modalReducer,
    offers: offersReducer,
    images: imagesReducer,
    products: productsReducer,
    locations: locationsReducer,
    campaigns: campaignsReducer,
    users: usersReducer,
    userManagement: userManagementReducer,
    audit: auditReducer,
    tags: tagsReducer,
    productChanges: productChangesReducer,
    locationChanges: locationChangesReducer,
    dashboard: dashboardReducer,
    redemptions: redemptionsReducer,
    periods: periodsReducer,
    schedule: scheduleReducer,
    calendar: calendarReducer,
    impacts: impactsReducer,
    usage: usageReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

store
  .dispatch(initUser())
  .unwrap()
  .then(() => {
    store.dispatch(getConfiguration());
  });

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch