import styled from '@emotion/styled';
import { ButtonText } from 'components/shared/button';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { Icon } from 'components/shared/icon';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import TextField from 'components/shared/textField/TextField';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';

export const TemplateContainer = styled.div<StyledWithThemeProps>`
  border: ${({ theme }) => `1px solid ${theme.colors.global.border};`};
  border-radius: ${typography.borderRadius}px;
  padding: ${typography.unit * 2}px ${typography.unit * 3}px;
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  gap: ${typography.unit}px;

  & > div {
    display: flex;
  }
`;
export const StyledBuySelectbox = styled(Selectbox)`
  margin: 0 ${typography.unit * 4}px 0 0;
`;

export const StyledForSelectbox = styled(Selectbox)`
  margin-right: ${typography.unit * 4}px;
`;

export const StyledLabel = styled.label`
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${typography.inputComponentHeight}px;
  padding-right: ${typography.unit * 4}px;
  min-width: ${typography.unit * 10}px;
`;

export const StyledLightLabel = styled(StyledLabel)`
  font-weight: 400;
`;

export const StyledLabelUpto = styled(StyledLabel)`
  padding-left: ${typography.unit * 4}px;
`;

export const StyledSelectbox = styled(Selectbox)`
  width: auto;
  margin-right: ${typography.unit * 2}px;
`;

export const StyledTextField = styled(TextField)`
  min-width: 75px;
  input {
    width: 75px;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  margin: 0 0 0 ${typography.unit * 3}px;
`;

export const ProductPicker = styled.div`
  display: flex;
  align-items: center;
  margin: 0 ${typography.unit * 4}px 0 0;

  span {
    margin-right: ${typography.unit * 2}px;
  }
`;

export const ProductName = styled.span``;

export const StyledEditIcon = styled(Icon)`
  margin: 0 0 0 ${typography.unit * 2}px;
`;

export const StyledButtonText = styled(ButtonText)`
  margin-left: ${typography.unit * 2}px;
`;

export const StyledViewIcon = styled(Icon)`
  width: 18px;
  fill: ${({ theme }) => `${theme.colors.global.inputPrimary};`};
`;
