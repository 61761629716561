import styled from '@emotion/styled';
import typography from 'styles/typography';
import { Icon } from 'components/shared/icon';
import Dropdown from '../../dropdown/Dropdown';
import { ButtonOutlined } from '../buttonOutlined';

export const StyledButtonDropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledButtonBase = styled(ButtonOutlined)`
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  justify-content: space-between;
  padding: ${typography.unit}px ${typography.unit * 3}px;
`;

export const StyledDropdown = styled(Dropdown)<{ position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' }>`
  text-transform: capitalize;
  position: absolute;
  top: ${({ position }) => (position.startsWith('top') ? 'unset' : '32px')};
  bottom: ${({ position }) => (position.startsWith('top') ? '32px' : 'unset')};
  right: ${({ position }) => (position.endsWith('left') ? '0px' : 'unset')};
  left: ${({ position }) => (position.endsWith('right') ? '0px' : 'unset')};

  & li:hover {
    color: ${({ theme }) => theme.colors.global.inputPrimary};
  }
`;

export const DropdownArrow = styled(Icon)<{ open: boolean }>`
  transition: transform ${typography.transition}s;
  transform: ${({ open }) => `rotate(${open ? '0' : '180deg'})`};
`;
