export enum ActionType {
  View = 'view',
  Edit = 'edit',
  Trash = 'trash',
  Usage = 'usage',
  Duplicate = 'duplicate',
}

export const ActionTypeToIconName: Record<ActionType, string> = {
  [ActionType.View]: 'view',
  [ActionType.Edit]: 'edit',
  [ActionType.Trash]: 'trash',
  [ActionType.Usage]: 'usage',
  [ActionType.Duplicate]: 'duplicate',
};

export const ActionTypeToLabel: Record<ActionType, string> = {
  [ActionType.View]: 'View',
  [ActionType.Edit]: 'Edit',
  [ActionType.Trash]: 'Trash',
  [ActionType.Usage]: 'Usage',
  [ActionType.Duplicate]: 'Duplicate',
};

export const ActionTypeToIconWidth: Record<ActionType, number> = {
  [ActionType.View]: 24,
  [ActionType.Edit]: 16,
  [ActionType.Trash]: 16,
  [ActionType.Usage]: 18,
  [ActionType.Duplicate]: 17,
};

export interface ActionsCellProps {
  actionTypes: ActionType[];
  onActionClick: (actionType: ActionType) => void;
  className?: string;
  theme?: string;
}
