import React from 'react';
import { MessageType } from '../../shared/notifications/notifications';
import { StyledBanner } from './shared.style';

export const OfferSaveNotificationInfo = (props: any) => {
  const ifVoucherCampaignImpact: boolean = props?.campaignImpacts?.some(
    (campaign: { type: string; status: string }) => campaign.type === 'voucher' && campaign.status !== 'expired' && campaign.status !== 'archived',
  );
  const msg = ifVoucherCampaignImpact ? (
    <StyledBanner type={MessageType.Info}>
      Campaigns are updated only upon Offer approval.<br/>
      There is an impact on voucher campaign, Please consult legal
      department before proceeding.
    </StyledBanner>
  ) : (
    <StyledBanner type={MessageType.Info}>Campaigns are updated only upon Offer approval</StyledBanner>
  );

  return msg;
};