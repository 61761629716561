import React from 'react';
import { HeaderProps } from 'react-big-calendar';

export const WeekHeader = (props: HeaderProps) => {
  const { date } = props;

  return (
    <>
      {date.toLocaleDateString('default', { weekday: 'short' })}
      <span className={`${date.getDate() === 1 ? 'big-now' : ''}`}>{`${
        date.getDate() === 1 ? `${date.toLocaleDateString('default', { month: 'short' })} ` : ''
      }${date.toLocaleDateString('default', { day: 'numeric' })}`}</span>
    </>
  );
};
