import { client } from 'app/apollo';
import { GenericEntity, OrderDirection } from 'utils/types';
import locationSetsGqls from 'pages/settings/locationSets/LocationSets.gqls';

import {
  CreateLocationSetDto,
  UpdateLocationSetDto,
} from 'pages/settings/locationSets/components/locationSetModal/LocationSetModal.consts';
import { LocationSetsFilters } from 'pages/settings/locationSets/LocationSets.consts';

export const getLocations = async (
  filters: any,
  limit?: number,
  currentPage?: number,
  order?: Record<string, OrderDirection>,
) => {
  const result = await client.query<{ getLocations: GenericEntity }>({
    query: locationSetsGqls.queries.getAllLocations,
    fetchPolicy: 'network-only',
    variables: {
      data: {
        filters,
        order,
        offset: currentPage * limit,
        limit,
      },
    },
  });

  return result.data.getLocations;
};

export const getLocationsByIds = async (locationsIds: number[], order?: Record<string, OrderDirection>) => {
  const result = await getLocations(
    { [LocationSetsFilters.LocationsIds]: locationsIds },
    locationsIds.length,
    0,
    order,
  );
  return result.items;
};

export const getLocationsByCodes = async (locationsCodes: number[], order?: Record<string, OrderDirection>) => {
  const result = await getLocations(
    { [LocationSetsFilters.LocationsCodes]: locationsCodes },
    locationsCodes.length,
    0,
    order,
  );
  return result.items;
};

export const getAvailableLocations = async (filters: any) => {
  const result = await client.query<{ getAvailableLocations: GenericEntity }>({
    query: locationSetsGqls.queries.getAvailableLocations,
    fetchPolicy: 'network-only',
    variables: {
      data: {
        filters,
      },
    },
  });

  return result.data.getAvailableLocations;
};

export const createLocationSet = async (createLocationSetDto: CreateLocationSetDto) => {
  return client.mutate({
    mutation: locationSetsGqls.mutations.createLocationSet,
    variables: { data: createLocationSetDto },
    refetchQueries: ['LocationSets'],
  });
};

export const updateLocationSet = async (updateLocationSetDto: UpdateLocationSetDto) => {
  return client.mutate({
    mutation: locationSetsGqls.mutations.updateLocationSet,
    variables: { data: updateLocationSetDto },
    refetchQueries: ['LocationSets'],
  });
};

export const deleteLocationSet = async (id: number) => {
  return client.mutate({
    mutation: locationSetsGqls.mutations.deleteLocationSet,
    variables: { id },
    refetchQueries: ['LocationSets'],
  });
};
