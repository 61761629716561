import styled from '@emotion/styled';
import { appTheme } from 'styles/themes/defaultTheme';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';

export const QuickFilterContainer = styled.div<StyledWithThemeProps>`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 ${typography.unit * 4}px;
  transition: background-color ${typography.transition}s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.tabStrip.backgroundHover};
    font-weight: 600;
  }
`;

export const Number = styled.span<{ isError: boolean; theme?: appTheme }>`
  font-size: 20px;
  color: ${({ isError, theme }) => isError && theme.colors.global.error};
  margin-right: ${typography.unit * 2}px;
`;
