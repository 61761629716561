import { CampaignFilterType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { Location, LocationSet } from './types/locations';

export const searchLocation = (location: Location, searchQuery: string): boolean =>
  location.code.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
  location.name.toLowerCase().includes(searchQuery.toLowerCase());

export const searchLocationSet = (locationSet: LocationSet, stringToSearch: string): boolean =>
  locationSet.id.toString().toLowerCase().includes(stringToSearch.toLowerCase()) ||
  locationSet.name.toLowerCase().includes(stringToSearch.toLowerCase());

export const nationalSelectItem: LocationSet = {
  id: 999,
  name: 'National',
  custom: false,
  totalSets: 0,
  originalId: 0,
  dummy: false,
  externalId: 0,
};

export const getInitialCampaignZones = (filters: any) => {
  if (filters[CampaignFilterType.National] === 'true' && filters[CampaignFilterType.Zone]) {
    return [...filters[CampaignFilterType.Zone], '999'];
  } else if (filters[CampaignFilterType.Zone]) {
    return filters[CampaignFilterType.Zone];
  } else if (filters[CampaignFilterType.National] === 'true') {
    return ['999'];
  } else {
    return [];
  }
};

export const onCampaignZoneChange = (selectedItems: any, filters: any, filterMulti: (filters: any) => void) => {
  let nationalValue = filters[CampaignFilterType.National] === 'true';
  if (selectedItems.some((item: LocationSet) => item.name === 'National')) {
    const zones = selectedItems.filter((item: LocationSet) => {
      if (item.name === 'National' && !nationalValue) {
        nationalValue = true;
        return false;
      } else if (item.name === 'National') {
        return false;
      }
      return true;
    });
    filterMulti({
      ...filters,
      [CampaignFilterType.Zone]: Object.values(zones).map((i: any) => i.id),
      [CampaignFilterType.National]: nationalValue,
    });
  } else {
    if (nationalValue) {
      nationalValue = false;
    }
    filterMulti({
      ...filters,
      [CampaignFilterType.Zone]: Object.values(selectedItems).map((i: any) => i.id),
      [CampaignFilterType.National]: nationalValue,
    });
  }
};
