import React from 'react';
import { getDateInConfigFormat } from 'utils/date';
import { PeriodsFilterProps } from './PeriodsFilter.consts';
import { Period, Periods as StyledPeriods, StyledCheckbox } from './PeriodsFilter.style';
import { useSelector } from 'react-redux';
import { DateTimeConfig } from 'utils/types';
import { marketConfig } from 'app/slices/config';

export const PeriodsFilter = ({ periods, selectedPeriods, onClick }: PeriodsFilterProps) => {
  const { config } = useSelector(marketConfig);
  const { dateFormat, startTimezone, endTimezone } = config;
  const dateTimeConfig: DateTimeConfig = { dateFormat, startTimezone, endTimezone };
  return (
    <StyledPeriods>
      <h5>Periods</h5>
      {periods.map((p) => {
        return (
          <Period key={`period_${p.id}`}>
            <StyledCheckbox
              onClick={() => onClick(p)}
              checked={!!selectedPeriods.find((period) => period.id === p.id)}
              label={
                <>
                  {p.name}
                  <br />
                  {getDateInConfigFormat(new Date(p.startDate), dateTimeConfig)} - {getDateInConfigFormat(new Date(p.endDate), dateTimeConfig)}
                </>
              }
            />
          </Period>
        );
      })}
    </StyledPeriods>
  );
};
