import styled from '@emotion/styled';
import { appTheme } from 'styles/themes/defaultTheme';
import typography from 'styles/typography';

export const RadioItemWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;

export const Label = styled.span<{ disabled: boolean; theme?: appTheme }>`
  color: ${({ theme }) => theme.colors.text.primary};
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const OuterCircle = styled.div<{ isSelected: boolean; disabled: boolean; theme?: appTheme }>`
  width: 15px;
  height: 15px;
  border: 2px solid
    ${({ isSelected, disabled, theme }) =>
      isSelected
        ? disabled
          ? theme.colors.checkbox.disabledCheckedBg
          : theme.colors.global.inputPrimary
        : theme.colors.global.border};

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ${typography.transition}s ease;
  margin-right: ${typography.unit * 2}px;
`;

export const InnerCircle = styled.div<{ disabled: boolean; theme?: appTheme }>`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.checkbox.disabledCheckedBg : theme.colors.global.inputPrimary};
  transition: all ${typography.transition}s ease;
`;
