import { FormProvider, useForm, useWatch } from 'react-hook-form';
import {
  EntityCheckboxFormRow,
  FormContainer,
  SectionTitle,
  StyledModal,
  TagName,
} from 'pages/settings/tags/components/tagForm/TagForm.style';
import React, { useEffect, useState } from 'react';
import { FormTitle, TagFormProps } from 'pages/settings/tags/components/tagForm/TagForm.consts';
import pick from 'lodash/pick';
import { FormMode } from 'utils/types';
import TagFormFooter from 'pages/settings/tags/components/tagFormFooter/TagFormFooter';
import { Tag, TagsValidEntities } from 'utils/types/tags';
import { createTag, editTag, showImpactModalAction } from 'pages/settings/tags/components/tagForm/utils/TagActions';
import { capitalize } from 'utils/text';
import { TagProps } from 'pages/settings/tags/Tags.consts';
import { getTagsImpactsById } from 'utils/api/tags';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { isInArray } from 'utils/array';
import { defaultTheme } from 'styles/themes/defaultTheme';
import { ThemeProvider } from '@emotion/react';
import { TagActions } from 'pages/settings/tags/components/tagFormFooter/TagFormFooter.consts';
import { ValidationMessages } from 'utils/types/common';

const TagForm = ({ mode, tag }: TagFormProps) => {
  const getModalTitle = () => `${FormTitle[mode]} ${tag?.id ? ` ID ${tag.id}` : ''}`;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tagImpacts, setTagImpacts] = useState(null);
  const isEditDisabled = mode === FormMode.View;

  const availableEntities = [TagsValidEntities.Campaign, TagsValidEntities.Image, TagsValidEntities.Offer];
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      id: tag?.id,
      name: tag?.name || '',
      validEntities: tag?.validEntities || [],
    } as any,
  });

  const validEntities: any[] = useWatch({ control: formMethods.control, name: 'validEntities' });

  const validateCheckboxChoose = () => {
    if (validEntities?.length === 0) {
      return 'You must choose at least one entity';
    }
    return null;
  };

  formMethods.register('validEntities', {
    validate: validateCheckboxChoose,
  });

  const getShowImpact = (tagToSave: TagProps, currUsedTag: string): boolean => {
    const sanitizedTagToSave = tagToSave.name.toLowerCase().trim();
    const sanitizedCurrTag = currUsedTag.toLowerCase().trim();
    return (
      !tagToSave.validEntities.includes(capitalize(currUsedTag) as TagsValidEntities) ||
      sanitizedTagToSave !== sanitizedCurrTag
    );
  };

  const onSubmit = () => {
    return async (formData: any) => {
      setIsSubmitting(true);
      const tagToSave = { ...pick(formData, ['name', 'validEntities']) } as any as TagProps;
      if (mode === FormMode.New) {
        tagToSave.isMasterTag = true;
        await createTag(tagToSave);
      } else if (mode === FormMode.Edit) {
        let shouldShowImpact = false;
        tagToSave.id = Number(tag.id);
        tagToSave.isMasterTag = formData.isMasterTag;
        if (tag.amountByEntities) {
          Object.entries(tag.amountByEntities).forEach((usedTag) => {
            const currUsedTag = Object.values(usedTag)[0].toString();
            shouldShowImpact = getShowImpact(tagToSave, currUsedTag);
          });
        }
        if (shouldShowImpact) {
          showImpactModalAction(tag, tagImpacts, TagActions.Edit, tagToSave, editTag);
        } else {
          await editTag(tagToSave);
        }
      }
      setIsSubmitting(false);
    };
  };

  const handleEntityChoose = (existEntity: boolean, currEntity: string) => {
    const updatedValidEntities = !existEntity
      ? [...validEntities, currEntity]
      : validEntities.filter((item: string) => item !== currEntity);

    formMethods.setValue('validEntities', updatedValidEntities);
  };

  useEffect(() => {
    formMethods.trigger('validEntities');
  }, [validEntities]);

  useEffect(() => {
    const checkTagImpacts = async () => {
      const currTagImpacts: Tag = await getTagsImpactsById(tag.id);
      if (currTagImpacts?.offers.length || currTagImpacts?.campaigns.length || currTagImpacts?.images.length) {
        setTagImpacts(currTagImpacts);
      }
    };
    if (mode !== FormMode.New) {
      checkTagImpacts();
    }
  }, []);

  return (
    <StyledModal title={getModalTitle()} isLocked={tag?.isLocked}>
      <FormProvider {...formMethods}>
        <FormContainer>
          <div>
            <TagName
              register={formMethods.register}
              errors={formMethods.formState.errors}
              value={tag?.name}
              name="name"
              label="Name"
              placeholder="Enter"
              disabled={isEditDisabled}
              validation={{
                required: ValidationMessages.RequiredField,
                maxLength: { value: 50, message: 'Up to 50 characters' },
              }}
              labelIsHorizontal
            />
          </div>
          <div>
            <SectionTitle>Entity</SectionTitle>
            {availableEntities.map((entity, index) => {
              const currEntity = capitalize(entity);
              const existEntity = isInArray(validEntities, currEntity);
              return (
                <EntityCheckboxFormRow key={index}>
                  <ThemeProvider theme={defaultTheme}>
                    <Checkbox
                      checked={existEntity}
                      label={currEntity}
                      onClick={() => handleEntityChoose(existEntity, currEntity)}
                      disabled={isEditDisabled}
                    />
                  </ThemeProvider>
                </EntityCheckboxFormRow>
              );
            })}
          </div>
        </FormContainer>
        <TagFormFooter
          tag={tag}
          mode={mode}
          tagImpacts={tagImpacts}
          isSubmitting={isSubmitting}
          isLocked={tag?.isLocked}
          onSubmit={() => formMethods.handleSubmit(onSubmit())}
        />
      </FormProvider>
    </StyledModal>
  );
};

export default TagForm;
