import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { TagsProps } from './Tags.consts';
import { StyledButtonText, StyledChip, StyledMore, StyledTags, StyledTooltip, TagsList } from './Tags.style';

export const Tags = ({ id, tags, limit = 7 }: TagsProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <StyledTags data-automation-id="tags">
      <TagsList>
        {tags?.slice(0, limit).map((tag, index) => (
          <StyledChip key={`tagChip_${index}_${id}`}>{tag.name}</StyledChip>
        ))}
      </TagsList>
      {tags.length > limit && (
        <>
          <StyledButtonText
            onClick={(e) => {
              e.stopPropagation();
            }}
            data-for={`tags_tooltip_${id}`}
            data-tip
          >
            <StyledMore name="more" />
          </StyledButtonText>
          <StyledTooltip id={`tags_tooltip_${id}`} place="bottom">
            {tags?.slice(limit)?.map((tag, index) => (
              <StyledChip key={`tagChip_${index + limit}_${id}`}>{tag.name}</StyledChip>
            ))}
          </StyledTooltip>
        </>
      )}
    </StyledTags>
  );
};
