import { timeZonesSelectionList } from 'utils/timezones';
import {
  CalendarWeekStartsOn as CalendarWeekStartsOnOptions,
  CalendarWeekStartsOnRecord,
  Currency as CurrencyOptions,
  DateFormats,
  TimeFormats,
} from 'utils/types';
import { DoubleDippingRule, DoubleDippingRuleRecord } from 'utils/types/offers';
import { LanguageCodesSelectBoxValues } from 'utils/languages';

export interface ConfigurationDefinition {
  label: string;
}

export const enum ComponentType {
  Select = 'select',
  Text = 'text',
  Boolean = 'boolean',
  TimesArray = 'timesArray',
}

export interface SelectConfig extends ConfigurationDefinition {
  componentType: ComponentType.Select;
  items: any;
  initialSelectedItems?: any;
  withSearch?: boolean;
}

export interface TextConfig extends ConfigurationDefinition {
  componentType: ComponentType.Text;
  inputType: 'string' | 'number';
}

export interface BooleanConfig extends ConfigurationDefinition {
  componentType: ComponentType.Boolean;
}

export interface TimesArrayConfig extends ConfigurationDefinition {
  componentType: ComponentType.TimesArray;
  selectMultiple: boolean;
}

export type ConfigurationsDefinition = SelectConfig | TextConfig | BooleanConfig | TimesArrayConfig;

export enum MarketConfigurationKey {
  PrimaryLanguage = 'primaryLanguage',
  SecondaryLanguage = 'secondaryLanguage',
  IsSecondaryLanguageEnforced = 'isSecondaryLanguageEnforced',
  DateFormat = 'dateFormat',
  TimeFormat = 'timeFormat',
  CalendarWeekStartsOn = 'calendarWeekStartsOn',
  Currency = 'currency',
  EnableOfferSelfApproval = 'enableOfferSelfApproval',
  EnableCampaignSelfApproval = 'enableCampaignSelfApproval',
  DefaultDoubleDippingRule = 'defaultDoubleDippingRule',
  StartTimezone = 'startTimezone',
  EndTimezone = 'endTimezone',
  ApprovalRequiredNotificationThreshold = 'approvalRequiredNotificationThreshold',
  NoRedemptionsNotificationThreshold = 'noRedemptionsNotificationThreshold',
  NoRedemptionsNotificationTimezone = 'noRedemptionsNotificationTimezone',
  NoRedemptionsNotificationTime = 'noRedemptionsNotificationTime',
  CampaignArchivePeriod = 'campaignArchivePeriod',
  OfferArchivePeriod = 'offerArchivePeriod',
  EnableManagementByZone = 'enableManagementByZone',
  EnableSchedulePeriods = 'enableSchedulePeriods',
  SyncTimeZone = 'syncTimeZone',
  ProductSyncTime = 'productSyncTime',
  LocationSyncTime = 'locationSyncTime',
  AutoDeleteLocationCutOff = 'autoDeleteLocationCutOff',
  AutoDeleteProductCutOff = 'autoDeleteProductCutOff',
  AutoDeleteAuditCutOff = 'autoDeleteAuditCutOff',
  RedemptionsDataRetentionLimit ='autoDeleteRedemptionsDataCutOff',
}

export const ConfigurationsDefinitions: Record<MarketConfigurationKey, ConfigurationsDefinition> = {
  [MarketConfigurationKey.PrimaryLanguage]: {
    label: 'Primary Language',
    componentType: ComponentType.Select,
    items: LanguageCodesSelectBoxValues,
    withSearch: true,
  },
  [MarketConfigurationKey.SecondaryLanguage]: {
    label: 'Secondary Language',
    componentType: ComponentType.Select,
    items: [{ id: 'none', name: 'None' }, ...LanguageCodesSelectBoxValues],
    initialSelectedItems: ['none'],
    withSearch: true,
  },
  [MarketConfigurationKey.IsSecondaryLanguageEnforced]: {
    label: 'Enforce Second Language',
    componentType: ComponentType.Boolean,
  },

  [MarketConfigurationKey.DateFormat]: {
    label: 'Date Format',
    componentType: ComponentType.Select,
    items: Object.values(DateFormats).map((format) => {
      return {
        id: format,
        name: format,
      };
    }),
  },
  [MarketConfigurationKey.TimeFormat]: {
    label: 'Time Format',
    componentType: ComponentType.Select,
    items: Object.values(TimeFormats).map((format) => {
      return {
        id: format,
        name: format,
      };
    }),
  },
  [MarketConfigurationKey.CalendarWeekStartsOn]: {
    label: 'Calendar Week Starts On',
    componentType: ComponentType.Select,
    items: Object.values(CalendarWeekStartsOnOptions).map((day) => {
      return {
        id: day,
        name: CalendarWeekStartsOnRecord[day],
      };
    }),
  },
  [MarketConfigurationKey.Currency]: {
    label: 'Currency',
    componentType: ComponentType.Select,
    items: Object.values(CurrencyOptions).map((cur) => {
      return {
        id: cur,
        name: cur,
      };
    }),
  },

  [MarketConfigurationKey.EnableOfferSelfApproval]: {
    label: 'Enable Self Approval for Offers',
    componentType: ComponentType.Boolean,
  },
  [MarketConfigurationKey.EnableCampaignSelfApproval]: {
    label: 'Enable Self Approval for Campaigns',
    componentType: ComponentType.Boolean,
  },
  [MarketConfigurationKey.DefaultDoubleDippingRule]: {
    label: 'Double Dipping Default Rule',
    componentType: ComponentType.Select,
    items: Object.values(DoubleDippingRule).map((ddr) => {
      return {
        id: ddr,
        name: DoubleDippingRuleRecord[ddr],
      };
    }),
  },
  [MarketConfigurationKey.StartTimezone]: {
    label: 'Start Time Zone',
    componentType: ComponentType.Select,
    items: timeZonesSelectionList,
    withSearch: true,
  },
  [MarketConfigurationKey.EndTimezone]: {
    label: 'End Time Zone',
    componentType: ComponentType.Select,
    items: timeZonesSelectionList,
    withSearch: true,
  },
  [MarketConfigurationKey.ApprovalRequiredNotificationThreshold]: {
    label: 'Approval Required Notification Threshold',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.NoRedemptionsNotificationThreshold]: {
    label: 'No Redemptions Notification Threshold',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.NoRedemptionsNotificationTimezone]: {
    label: 'No Redemptions Notification Time Zone',
    componentType: ComponentType.Select,
    items: timeZonesSelectionList,
    withSearch: true,
  },
  [MarketConfigurationKey.NoRedemptionsNotificationTime]: {
    label: 'No Redemptions Notification Times',
    componentType: ComponentType.TimesArray,
    selectMultiple: true,
  },
  [MarketConfigurationKey.CampaignArchivePeriod]: {
    label: 'Campaign Archive Period',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.OfferArchivePeriod]: {
    label: 'Offer Archive Period',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.EnableManagementByZone]: {
    label: 'Enable Management by Zone',
    componentType: ComponentType.Boolean,
  },
  [MarketConfigurationKey.EnableSchedulePeriods]: {
    label: 'Enable Schedule Periods',
    componentType: ComponentType.Boolean,
  },
  [MarketConfigurationKey.SyncTimeZone]: {
    label: "Sync Time Zone",
    componentType: ComponentType.Select,
    items: timeZonesSelectionList,
    withSearch: true,
  },
  [MarketConfigurationKey.ProductSyncTime]: {
    label: 'Product Changes Sync Time',
    componentType: ComponentType.TimesArray,
    selectMultiple: false,
  },
  [MarketConfigurationKey.LocationSyncTime]: {
    label: 'Location Changes Sync Time',
    componentType: ComponentType.TimesArray,
    selectMultiple: false,
  },
  [MarketConfigurationKey.AutoDeleteLocationCutOff]: {
    label: 'Location changes data - Retention Limit',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.AutoDeleteProductCutOff]: {
    label: 'Product changes data - Retention Limit',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.AutoDeleteAuditCutOff]: {
    label: 'Audit report - Retention Limit',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.RedemptionsDataRetentionLimit]: {
    label: 'Redemptions data - Retention Limit',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
};
