import styled from '@emotion/styled';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';

export const Month = styled.div<StyledWithThemeProps>`
  display: grid;
  grid-template-rows: 2fr;
  grid-template-columns: repeat(7, 1fr);
  row-gap: ${typography.unit * 2}px;
  column-gap: ${typography.unit * 2}px;
  justify-items: center;
  margin-top: 4px;
`;

export const Day = styled.span<StyledWithThemeProps & { checked?: boolean; disabled?: boolean; day?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  background: ${({ theme, checked }) => (checked ? theme.colors.checkbox.disabledCheckedBg : 'initial')};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.global.inputDisabled : 'inherit')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  grid-column: ${({ day }) => `${day} / ${day}`};
`;

export const WeekDay = styled.span<StyledWithThemeProps>`
  cursor: initial;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.primary};
`;
