import React from 'react';
import { StyledButton } from './ButtonBase.style';
import { ButtonBaseProps } from './ButtonBase.consts';

const ButtonBase = ({ disabled = false, type = 'button', onClick, children, className,hide=false, ...rest }: ButtonBaseProps) => {
  return (
    <StyledButton
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={className}
      data-automation-id="button"
      hide={hide}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export default ButtonBase;
