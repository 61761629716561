import { ApprovalStatus } from 'utils/types';
import { SelectedCampaignProps } from 'pages/reports/redemptions/Redemptions.consts';

export interface CampaignSelectionProps {
  selectedCampaigns: SelectedCampaignProps[];
  maxSelections: number;
  onSave: (data?: any) => void;
}

export const AvailableStatuses = [ApprovalStatus.Active, ApprovalStatus.Expired, ApprovalStatus.Revoked];
