import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CalendarWeekStartsOn, Currency, DateFormats, OrderDirection, TimeFormats } from 'utils/types';
import { Language } from 'utils/languages';
import { DoubleDippingRule } from 'utils/types/offers';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { clientQuery } from 'utils/api/clientQuery';
import { configurationsGqls } from 'pages/configurations/Configurations.gqls';
import { isNumber } from 'utils/text';
import type { RootState } from '../store';

export interface MarketConfiguration {
  primaryLanguage: Language;
  secondaryLanguage: Language;
  isSecondaryLanguageEnforced: boolean;
  startTimezone: string;
  endTimezone: string;
  dateLocale: string;
  dateFormat: DateFormats;
  timeFormat: TimeFormats;
  calendarWeekStartsOn: CalendarWeekStartsOn;
  currency: Currency;
  defaultDoubleDippingRule: DoubleDippingRule;
  enableOfferSelfApproval: boolean;
  enableCampaignSelfApproval: boolean;
  noRedemptionsNotificationThreshold: number;
  noRedemptionsNotificationTime: string;
  noRedemptionsNotificationTimezone: string;
  approvalRequiredNotificationThreshold: number;
  campaignArchivePeriod: number;
  offerArchivePeriod: number;
  enableManagementByZone: boolean;
  bulkImagesUploadLimit: number;
  enableSchedulePeriods: boolean;
  syncTimeZone: string;
  productSyncTime: string;
  locationSyncTime: string;
  autoDeleteAuditCutOff:number;
  autoDeleteProductCutOff:number;
  autoDeleteLocationCutOff:number;
  autoDeleteRedemptionsDataCutOff:number;
}

export interface Configuration {
  config: MarketConfiguration;
  features: { [key: string]: boolean };
  languages: Language[];
  total: number;
  order: { [key: string]: OrderDirection };
}

export const initialState: Configuration = {
  config: {} as any,
  languages: [],
  features: {},
  total: 0,
  order: {
    key: OrderDirection.DESC,
  },
};

export const getConfiguration = createAsyncThunk('config/init', async (payload, thunkAPI) => {
  const {
    config: { order },
  } = thunkAPI.getState() as any;

  const config = (await clientQuery(configurationsGqls.queries.getAll, {
    data: {
      order,
    },
  })) as any;

  return {
    config: config.data.getConfigurations.config,
    features: config.data.getConfigurations.features,
    languages: config.data.getConfigurations.languages,
    total: config.data.getConfigurations.total,
  };
});

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setOrder(state, action) {
      state.order = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getConfiguration.fulfilled, (state, action) => {
      const config = Object.entries(action.payload.config).reduce((m, [key, val]) => {
        let typedVal = val as any;
        if (val === 'true' || val === 'false') {
          typedVal = val === 'true';
        } else if (isNumber(typedVal)) {
          typedVal = Number.parseFloat(val.toString());
        }
        return { ...m, [key]: typedVal };
      }, {});

      return {
        ...state,
        config: config as any,
        features: action.payload.features,
        languages: action.payload.languages,
        total: action.payload.total,
      };
    });
    builder.addCase(getConfiguration.rejected, (state) => {
      showToast(MessageType.Error, 'Error loading configurations');
      return state;
    });
    builder.addCase(getConfiguration.pending, (state) => {
      return {
        ...state,
        config: {} as any,
        languages: [],
      };
    });
  },
});

export const marketConfig = (state: RootState) => state.config;
export const { setOrder } = configSlice.actions;

export default configSlice.reducer;
