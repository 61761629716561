import React, { useState } from 'react';
import { GenericItem } from 'utils/types';
import { ButtonDropdownProps } from './ButtonDropdown.consts';
import { DropdownArrow, StyledButtonBase, StyledButtonDropdownContainer, StyledDropdown } from './ButtonDropdown.style';

export function ButtonDropdown({
  className,
  position = 'top-right',
  items,
  onItemClick,
  children,
  ...rest
}: ButtonDropdownProps) {
  const [open, setOpen] = useState(false);

  const onItemChange = (item: GenericItem) => {
    if (onItemClick) {
      setOpen(false);
      onItemClick(item);
    }
  };

  const toggleDropdown = () => {
    setOpen(!open);
  };

  return (
    <StyledButtonDropdownContainer className={className} {...rest}>
      {open && <StyledDropdown items={items} onClick={onItemChange} position={position} />}
      <StyledButtonBase onClick={() => toggleDropdown()}>
        {children}
        <DropdownArrow name="arrowUp" open={open} />
      </StyledButtonBase>
    </StyledButtonDropdownContainer>
  );
}
