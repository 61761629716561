import { FetchResult } from '@apollo/client';

export interface CampaignBulkActionsProps {
  bulkAction: CampaignBulkAction;
  onBulkActionChange: (bulkAction: CampaignBulkAction) => void;
  onBulkActionSubmit: () => Promise<FetchResult<BulkCampaignMutationResult>>;
  selectedCampaigns: {
    total: number;
    local: number;
    conflicted: number;
    notEligibleForSelfApproval: number;
    invalid: number;
  };
  bulkUpdateInProgress: boolean;
}

export enum CampaignBulkAction {
  BulkSubmitForApproval = 'bulk submit for approval',
  BulkApprove = 'bulk approve',
}

export interface BulkCampaignMutationResult {
  bulkCampaignsStatusUpdate: {
    isProcessed: boolean;
  };
}
