import styled from '@emotion/styled';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import Modal from 'components/shared/modal/Modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchTextField } from 'components/shared/searchTextField/SearchTextField';
import { ButtonText } from 'components/shared/button';
import { Icon, IconProps } from 'components/shared/icon';

export const StyledModal = styled(Modal)`
  width: 1250px;
  height: 85vh;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

export const InfoIcon = styled(Icon)<IconProps>`
  margin-left: ${typography.unit * 2}px;
  cursor: pointer;
  width: 15px;
  fill: ${({ theme }) => theme.colors.global.inputPrimary};
`;

export const Sections = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-top: ${typography.unit * 6}px;
  min-height: 0;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.span`
  font-weight: 600;
  line-height: 20px;
  margin-bottom: ${typography.unit * 2}px;
`;

export const SectionContainer = styled.div<StyledWithThemeProps>`
  flex: 1;
  width: 595px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  border-radius: ${typography.borderRadius}px;
  min-height: 0;
`;

export const StyledSearchTextField = styled(SearchTextField)`
  margin: 0 ${typography.unit * 2}px;
  max-width: 578px;
`;

export const FiltersRow = styled.div<StyledWithThemeProps>`
  display: flex;
  align-items: center;
  padding: 0 ${typography.unit * 2}px;
  height: 45px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.global.border};
`;

export const StyledSelect = styled(Selectbox)`
  margin-right: ${typography.unit * 4}px;
  label {
    margin-right: 8px;
  }
` as unknown as typeof Selectbox;

export const ActionButton = styled(ButtonText)`
  margin-left: auto;
`;

export const ZoneMsg = styled.div`
  margin: ${typography.unit * 5}px auto;
`;

export const OffersContainer = styled.div<StyledWithThemeProps>`
  overflow-y: auto;
  display: flex;
  flex: 1;
  min-height: 0;
`;

export const OffersList = styled(InfiniteScroll)`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${typography.unit * 2}px;
  row-gap: ${typography.unit * 2}px;
  padding: ${typography.unit * 2}px;
  overflow-y: auto;
`;

export const SelectedOffersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${typography.unit * 2}px;
  row-gap: ${typography.unit * 2}px;
  padding: ${typography.unit * 2}px;
  overflow-y: auto;
`;

export const NoOffers = styled.div`
  margin: ${typography.unit * 10}px auto;
  display: flex;
`;

export const Footer = styled.footer`
  display: flex;
  margin-top: ${typography.unit * 5}px;
  justify-content: flex-end;
  gap: ${typography.unit * 7}px;
`;
