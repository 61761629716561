import { GenericItem, GenericSet, SetOfEntity } from 'utils/types';
import { useEffect, useState } from 'react';
import { SortBy, SortingKey } from 'utils/sorting';
import {
  hasAnyItem,
  isInitiallyNonCustomEmptySet,
} from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.utils';

export const useSetsFilter = (
  itemSets: GenericSet[],
  searchValue: string,
  searchItemSet: (itemSet: GenericSet, searchValue: string) => boolean,
  searchItem: (item: GenericItem, searchValue: string) => boolean,
  setOf: SetOfEntity,
  sortingKey: SortingKey,
  filterItemSet?: (set: GenericSet) => boolean,
) => {
  const [filteredItemSets, setFilteredItemSets] = useState(itemSets);
  const searchSetRecursively = (set: GenericSet): boolean =>
    searchItemSet(set, searchValue) || set.sets?.some(searchSetRecursively);

  const searchSet = (set: GenericSet): boolean => searchItemSet(set, searchValue);

  const filterItems = (itemsToFilter: GenericItem[]) => {
    return itemsToFilter.filter((item) => searchItem(item, searchValue));
  };

  const getFilteredSet = (set: GenericSet, isMatchedByParentSet = false) => {
    const searchInSet = searchSet(set)
    const isMatchedBySet = isMatchedByParentSet || searchInSet;
    const res = {
      ...set,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      sets: searchInSet ? set.sets : filterSets(set.sets, isMatchedByParentSet && searchSetRecursively(set)),
      [setOf]: isMatchedBySet ? set[setOf] : filterItems(set[setOf]),
    };
    return res;
  };

  const filterSets = (sets: GenericSet[], isMatchedByParentSet = false): GenericSet[] =>
    sets
      ?.map((set) => getFilteredSet(set, isMatchedByParentSet))
      .filter((set) => (searchValue.length === 0 && isInitiallyNonCustomEmptySet(set)) || hasAnyItem(set, setOf, false));

  useEffect(() => {
    setFilteredItemSets(
      filterSets(itemSets)
        .filter((set) => !filterItemSet || filterItemSet(set))
        .sort(SortBy[sortingKey]),
    );
  }, [JSON.stringify(itemSets), searchValue, sortingKey]);

  return filteredItemSets;
};
