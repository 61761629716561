import styled from '@emotion/styled';
import typography from 'styles/typography';

export const StyledTitle = styled.h1`
  font-size: 22px;
  text-transform: capitalize;
  font-family: ${typography.secondaryFont};
  margin: 0;
  margin-bottom: ${typography.unit * 10}px;
  font-weight: 400;
`;
