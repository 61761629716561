import styled from '@emotion/styled';
import typography from 'styles/typography';
import TextField from 'components/shared/textField/TextField';
import { DateTimePicker } from 'components/shared/picker/datetimePicker/DateTimePicker';
import {
  LanguageNavigationItemProps,
  StyledNotificationLanguageProps,
  StyledNotificationTextFields,
} from './PushNotificationSection.consts';
import TextArea from 'components/shared/textArea/TextArea';
import { appTheme } from 'styles/themes/defaultTheme';
import { TimePicker } from 'components/shared/picker/timePicker/TimePicker';

export const SectionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const Section = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: ${typography.unit * 5}px;
`;

export const StyledDateTimePicker = styled(DateTimePicker)`
  margin-right: ${typography.unit * 5}px;
  width: 100%;

  [data-automation-id~='error-message'] {
    margin-top: ${typography.unit * 5}px;
    left: 110px;
  }
`;

export const PushNotificationContainer = styled.div<{ hasExtraMargin?: boolean }>`
  display: flex;
  margin-bottom: ${({ hasExtraMargin }) => (hasExtraMargin ? `${typography.unit * 7}px` : `${typography.unit * 3}px`)};

  div:last-of-type {
    margin-right: 0;
  }
`;

export const ExpirationDate = styled(TextField)`
  min-width: 50px;
  input {
    width: 50px;
  }
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${typography.unit * 4}px;
  align-items: baseline;
`;

export const SubHeader = styled.div`
  display: flex;
  margin-bottom: ${typography.unit}px;
`;

export const ToggleTitle = styled.div`
  margin-right: ${typography.unit * 2}px;
  font-weight: 600;
`;

export const LanguageNavigationContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const LanguageContentContainer = styled.div<{ theme?: appTheme }>`
  flex-direction: row;
  width: 100%;

  &.selected {
    border: ${({ theme }) => `1px solid ${theme.colors.global.border}`};
    border-radius: 5px 5px 5px 5px;
    padding: ${typography.unit * 2}px;
  }
`;

export const StyledContainer = styled.div`
  width: 100%;
`;

export const LanguageNavigationItem = styled.div<LanguageNavigationItemProps>`
  display: flex;
  padding: ${typography.unit}px 0;
  margin-right: ${typography.unit * 3}px;
  border-bottom: ${({ isActive, theme }) => isActive && `4px solid ${theme.colors.global.brandMain}`};
  font-weight: ${({ isActive }) => isActive && 500};
  cursor: pointer;
  font-size: ${typography.fontSize}px;
  text-transform: capitalize;
`;

export const StyledLabel = styled.label<LanguageNavigationItemProps>`
  font-size: 13px;
  margin-bottom: ${typography.unit * 2}px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const NotificationLanguage = styled(TextArea)<StyledNotificationLanguageProps>`
  width: 100%;
  padding-top: 5px;
  display: none;
`;

export const NotificationTitleStyledTextField = styled(TextField)<StyledNotificationTextFields>`
  margin-bottom: ${typography.unit * 5}px;
  width: 295px;
  label {
    margin-bottom: 0;
  }
  display: ${({ show }) => !show && 'none'};
`;

export const StyledTextArea = styled(TextArea)<StyledNotificationLanguageProps>`
  margin-bottom: ${typography.unit * 4}px;
  margin-top: ${typography.unit * 2}px;
  display: ${({ show }) => !show && 'none'};
`;

export const StyledTimePicker = styled(TimePicker)`
  margin-right: ${typography.unit * 5}px;
  align-items: center;
  
  [data-automation-id~='error-message'] {
    margin-top: ${typography.unit * 5}px;
    left: 110px;
  }
`;
