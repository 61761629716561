import React, { useEffect, useState } from 'react';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { ButtonContained, ButtonOutlined, ButtonText } from 'components/shared/button';
import { store } from 'app/store';
import { closeModal, Modals, openModal } from 'app/slices/modals';
import { FormMode } from 'utils/types';
import { useFormState } from 'react-hook-form';
import { FormFooter } from 'pages/settings/tags/components/tagFormFooter/TagFormFooter.style';
import { TagActions, TagFormFooterProps } from 'pages/settings/tags/components/tagFormFooter/TagFormFooter.consts';
import { createNewTag } from 'utils/api/tags';
import { handleDeleteTag, showImpactModalAction } from 'pages/settings/tags/components/tagForm/utils/TagActions';
import { hideTooltip } from 'utils/tooltip';
import ReactTooltip from 'react-tooltip';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';

const TagFormFooter = ({ tag, mode, isSubmitting, isLocked, onSubmit, className, tagImpacts }: TagFormFooterProps) => {
  const { isDirty, isValid } = useFormState();
  const [isFormValid, setIsFormValid] = useState(isValid && !isSubmitting);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [tagImpacts, isDirty, mode !== FormMode.View]);

  useEffect(() => {
    setIsFormValid(isValid && !isSubmitting);
  }, [isValid, isSubmitting]);

  const showImpactModal = async () => {
    if (tagImpacts) {
      showImpactModalAction(tag, tagImpacts);
    } else {
      showToast(MessageType.Info, `The tag is not being used in campaigns, offers or images`);
    }
  };

  const [isDeleteTooltipVisible, setIsDeleteTooltipVisible] = useState(true);

  const onActionClicked = async (actionName: TagActions) => {
    switch (actionName) {
      case TagActions.Save:
        await createNewTag(tag);
        break;
      case TagActions.Edit:
        store.dispatch(
          openModal({
            modal: Modals.TagModal,
            props: { mode: FormMode.Edit, tag },
          }),
        );
        break;
      case TagActions.Delete: {
        if (tagImpacts) {
          showImpactModalAction(tag, tagImpacts, TagActions.Delete, tag.id, handleDeleteTag);
        } else {
          setIsDeleteTooltipVisible(false);
          await handleDeleteTag(tag.id);
        }
        break;
      }
      default:
    }
  };

  const getButtonsByModeAndStatus = (): JSX.Element => {
    switch (mode) {
      case FormMode.Edit:
      case FormMode.New:
        return (
          <>
            <ButtonContained onClick={onSubmit()} disabled={!isFormValid}>
              Save
            </ButtonContained>
          </>
        );
      case FormMode.View:
        return (
          <>
            <ButtonOutlined onClick={() => showImpactModal()}>View Usage</ButtonOutlined>
            <ButtonOutlined
              data-for="delete-tooltip"
              {...(tagImpacts !== null
                ? { 'data-tip': false, onClick: () => onActionClicked(TagActions.Delete) }
                : { 'data-tip': true, onClick: () => null })}
            >
              Delete
            </ButtonOutlined>
            {!tagImpacts && isDeleteTooltipVisible &&(
              <Tooltip
                id="delete-tooltip"
                content="Are you sure you want to delete?"
                approveMsg="Yes, Delete"
                onDisapproveClick={() => {
                  hideTooltip('#delete-tooltip');
                }}
                onApproveClick={() => onActionClicked(TagActions.Delete)}
              />
            )}
            <ButtonContained onClick={() => onActionClicked(TagActions.Edit)}>Edit</ButtonContained>
          </>
        );
      default:
        break;
    }
  };

  return (
    <FormFooter className={className}>
      <ButtonText
        {...(mode !== FormMode.View && isDirty
          ? { 'data-tip': true, onClick: () => null }
          : { onClick: () => store.dispatch(closeModal()) })}
        data-for="cancel-tooltip"
      >
        {mode === FormMode.View ? 'Close' : 'Cancel'}
      </ButtonText>
      {!isLocked && getButtonsByModeAndStatus()}
      <Tooltip
        id="cancel-tooltip"
        content="Are you sure you want to cancel?"
        onDisapproveClick={() => {
          hideTooltip('#cancel-tooltip');
        }}
        onApproveClick={() => store.dispatch(closeModal())}
      />
    </FormFooter>
  );
};

export default TagFormFooter;
