import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DiscountType } from 'utils/types/offers';
import { ButtonText } from 'components/shared/button';
import { calcProductsHint } from 'utils/product';
import useDiscountType from 'hooks/use-discount-type';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { ProductPicker, StyledBuySelectbox, StyledForSelectbox, StyledLabel } from '../Template.style';
import { DiscountTypeRecord, TemplateProps } from '../Template.consts';
import { StyledButtonText, StyledDiscountValueField, StyledIcon, StyledTextField } from './ProductCombo.style';
import { Currency } from '../shared/Currency/Currency';
import getProductsAction from '../shared/ProductsActionCondition';

export const ProductCombo = ({ disabled, onProductSelection }: TemplateProps) => {
  const {
    control,
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext();
  const { config } = useSelector(marketConfig);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'versions.0.templateValues.offerTemplates',
  });

  const MAX_LENGTH = 4;

  const TotalDiscount='total-discount';
  const { discountType: templateDiscountType, shouldDisplayCurrency } = useDiscountType();
  const [templates] = watch(['versions.0.templateValues.offerTemplates']) as any;
  const discountTypeKey = JSON.stringify(templateDiscountType);
  const [discountArray, setDiscountArray] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState('0');
  const [isAmountChanged, setIsAmountChanged] = useState(false);
  const [isTypeChanged, setIsTypeChanged] = useState(false);

  const setAllDiscountValues = (value: string) => {
    templates.forEach((template: any, index: number) => {
      setValue(
        `versions.0.templateValues.offerTemplates.${index}.discountValue`,
        !Number.isNaN(Number(value)) ? Number(value) : '',
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
    });
  };

  const setAllTemplateTypes = () => {
    templates.forEach((template: any, index: number) => {
      setValue(`versions.0.templateValues.offerTemplates.${index}.templateType`, '3', {
        shouldValidate: true,
        shouldDirty: true,
      });
    });
  };

  const calculateAndSetTotalDiscount = (offerTemplate: any) => {
    const newTotalDiscount =
      templateDiscountType === DiscountType.MoneyOff || templateDiscountType === DiscountType.FixedPrice
        ? `${discountArray.reduce((a, b) => {
            a = !Number.isNaN(Number.parseFloat(a)) ? Number.parseFloat(a) : 0;
            b = !Number.isNaN(Number.parseFloat(b)) ? Number.parseFloat(b) : 0;
            return +(a + b).toFixed(2);
          }, 0)}`
        : `${offerTemplate[0].discountValue}`;
    setTotalDiscount(newTotalDiscount);
    setValue(TotalDiscount, newTotalDiscount, {
      shouldValidate: offerTemplate.some((template: any) => template.discountValue),
    });
  };

  useEffect(() => {
    if (isTypeChanged) {
      switch (templateDiscountType) {
        case DiscountType.Free:
          setAllDiscountValues('0');
          break;
        case DiscountType.Percent:
        case DiscountType.MoneyOff:
        case DiscountType.FixedPrice:
          setAllDiscountValues(undefined);
          break;
        default:
          setIsTypeChanged(false);
          break;
      }
      setTotalDiscount('0');
      setValue(TotalDiscount, undefined);
      setIsTypeChanged(false);
    }
  }, [isTypeChanged]);

  useEffect(() => {
    if (templates?.length && Number.isFinite(Number(+templates[0].discountValue))) {
      setAllTemplateTypes();
      calculateAndSetTotalDiscount(templates);
    }
  }, [discountArray]);

  useEffect(() => {
    if (templates?.length) {
      setDiscountArray(templates.map((template: any) => +template.discountValue));
      calculateAndSetTotalDiscount(templates);
    }
    if (isAmountChanged) {
      setIsAmountChanged(false);
    }
  }, [isAmountChanged, templates]);

  useEffect(() => {
    const isEditMode = templates?.length;
    if (!isEditMode) {
      append({ buy: '1', buyProducts: undefined, discountValue: undefined });
      append({ buy: '1', buyProducts: undefined, discountValue: undefined });
    } else {
      setDiscountArray(templates.map((template: any) => +template.discountValue));
    }
  }, []);

  return (
    <>
      <div>
        <StyledLabel>Discount Type</StyledLabel>
        <StyledForSelectbox
          control={control}
          name="versions.0.templateValues.discountType"
          disabled={disabled}
          onChange={() => {
            setIsTypeChanged(true);
            setTotalDiscount('0');
          }}
          validation={{
            required: true,
          }}
          items={
            Object.entries(DiscountTypeRecord()).map(([key, value]) => {
              return {
                id: key,
                name: value,
              };
            }) as any[]
          }
          selectWidth={100}
          defaultValue={DiscountType.MoneyOff}
          initialSelectedItems={templateDiscountType ? [templateDiscountType] : [DiscountType.MoneyOff]}
        />
        {(templateDiscountType === DiscountType.MoneyOff || templateDiscountType === DiscountType.FixedPrice) && (
          <StyledLabel>{templateDiscountType === DiscountType.MoneyOff ? 'Total Discount' : 'Total Cost'}</StyledLabel>
        )}
        {templateDiscountType !== DiscountType.Free && (
          <>
            {shouldDisplayCurrency && <Currency />}
            <StyledTextField
              register={register}
              key={`totalDiscount_${totalDiscount}_${templateDiscountType}`}
              errors={errors}
              name="total-discount"
              disabled={templateDiscountType !== DiscountType.Percent}
              onChange={(e) => {
                if (templateDiscountType === DiscountType.Percent) {
                  setAllDiscountValues(e.target.value);
                  setValue(TotalDiscount, e.target.value, {
                    shouldValidate: true,
                  });
                }
              }}
              validation={{
                min: 0.01,
                max: templateDiscountType === DiscountType.Percent ? 99 : null,
                pattern: /^\d+(?:\.\d{1,2})?$/,
                validate: (v: string) =>
                  !Number.isNaN(Number(v)) && templateDiscountType === DiscountType.Percent
                    ? Number(v) >= 0.01 && Number(v) < 100
                    : Number(v) >= 0.01,
              }}
              value={totalDiscount}
            />
          </>
        )}
      </div>
      {fields.map((item, index) => (
        <div key={`${item.id}_${index}`}>
          <StyledLabel>{index ? 'And' : 'Buy'}</StyledLabel>
          <StyledBuySelectbox
            disabled={disabled}
            control={control}
            name={`versions.0.templateValues.offerTemplates.${index}.buy` as const}
            validation={{
              min: 1,
              max: 15,
              required: true,
            }}
            items={Array.from(
              { length: 15 },
              (x, i) =>
                ({
                  id: (i + 1).toString(),
                  name: (i + 1).toString(),
                } as any),
            )}
            defaultValue="1"
            initialSelectedItems={
              templates[index]?.buy
                ? [typeof templates[index]?.buy === 'object' ? templates[index]?.buy.id : templates[index]?.buy]
                : ['1']
            }
            selectWidth={60}
            onChange={() => trigger(`versions.0.templateValues.offerTemplates.${index}.discountValue`)}
          />
          {templates && (
            <ProductPicker>
              {calcProductsHint(templates[index]?.buyProducts) ?? ''}
              {getProductsAction(
                disabled,
                templates[index]?.buyProducts,
                getValues,
                'Select Products',
                `offerTemplates.${index}.buyProducts`,
                (data: any) => ({ productCombo: { [index]: data } }),
                onProductSelection,
              )}
            </ProductPicker>
          )}
          <input
            type="hidden"
            {...register(`versions.0.templateValues.offerTemplates.${index}.buyProducts` as const, {
              value: templates ? templates[index]?.buyProducts : undefined,
              required: true,
              shouldUnregister: true,
            })}
          />
          <StyledLabel>For</StyledLabel>
          <StyledForSelectbox
            key={templateDiscountType}
            disabled
            name="templateDiscountType"
            items={
              templateDiscountType
                ? ([
                    {
                      id: templateDiscountType,
                      name: DiscountTypeRecord()[templateDiscountType as DiscountType],
                    },
                  ] as any[])
                : []
            }
            defaultValue={templateDiscountType}
            initialSelectedItems={templateDiscountType ? [templateDiscountType] : []}
            selectWidth={110}
          />
          {shouldDisplayCurrency && <Currency />}
          <StyledDiscountValueField
            key={`versions.0.templateValues.offerTemplates.${index}.discountValue-${discountTypeKey}` as const}
            disabled={templateDiscountType === DiscountType.Percent || templateDiscountType === DiscountType.Free}
            type={templateDiscountType}
            register={register}
            name={`versions.0.templateValues.offerTemplates.${index}.discountValue` as const}
            placeholder="Enter value"
            validation={{
              required: true,
              min: templateDiscountType === DiscountType.Percent ? 1 : DiscountType.Free ? 0 : 0.01,
              max: templateDiscountType === DiscountType.Percent ? 99 : null,
              pattern: /^\d+(?:\.\d{1,2})?$/,
              validate: (v: string) => {
                const buy = getValues(`versions.0.templateValues.offerTemplates.${index}.buy`);
                const isNonExactPenny = Math.ceil(+(Number(v) * 100).toFixed(2)) % Number(buy) !== 0;
                if (templateDiscountType === DiscountType.MoneyOff && isNonExactPenny) {
                  return `${config.currency} off divided by number of items does not result in exact penny value for selected option`;
                }
                if (templateDiscountType === DiscountType.FixedPrice && isNonExactPenny) {
                  return 'Fixed Price total divided by number of items does not result in exact penny value for selected option';
                }
                return !Number.isNaN(Number(v)) && templateDiscountType === DiscountType.Percent
                  ? Number(v) >= 1 && Number(v) < 100
                  : templateDiscountType === DiscountType.FixedPrice
                  ? Number(v) >= 0.01
                  : true;
              },
            }}
            errors={errors}
            onChange={(e) => {
              setValue(`versions.0.templateValues.offerTemplates.${index}.discountValue`, e.target.value, {
                shouldValidate: true,
                shouldDirty: true,
              });
              setIsAmountChanged(true);
            }}
            showError={false}
          />
          {index > 1 && !disabled && (
            <ButtonText
              onClick={() => {
                remove(index);
                templates.splice(index, 1);
              }}
            >
              <StyledIcon name="minusVector" width={16} />
            </ButtonText>
          )}
        </div>
      ))}
      {fields.length < MAX_LENGTH && !disabled && (
        <StyledButtonText
          disabled={disabled}
          onClick={() => {
            append({
              buy: '1',
              buyProducts: undefined,
              discountType: templateDiscountType,
              discountValue:
                templateDiscountType === DiscountType.Percent || templateDiscountType === DiscountType.Free
                  ? totalDiscount
                  : undefined,
            });
            if (templateDiscountType === DiscountType.Percent || templateDiscountType === DiscountType.Free) {
              setAllDiscountValues(totalDiscount);
            }
          }}
        >
          <StyledIcon name="plusVector" width={16} />
        </StyledButtonText>
      )}
    </>
  );
};
