import React, { useEffect, useState } from 'react';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import Table from 'components/shared/table/Table';
import { hideActionsCell, showActionsCell } from 'components/shared/table/Table.util';
import { ActionType } from 'components/shared/actionsCell/ActionsCell.consts';
import { store } from 'app/store';
import { useSelector } from 'react-redux';
import TextPageTitle from 'components/shared/text/textPageTitle/TextPageTitle';
import { tabName } from 'components/header/Header.consts';
import {
  Actions,
  StyledLoader,
  StyledHeader,
  TableContainer,
  StyledTotalAmountWithTable,
  StyledFilterBarSelect,
  Filters,
  FilterIcon,
} from 'pages/shared/shared.style';
import AddNewButton from 'pages/shared/addNewButton/AddNewButton';
import PageContainer from 'pages/shared/pageContainer/PageContainer';
import { useUrlFilters } from 'hooks/use-url-filters';
import { getSchedulePeriodsTableProps } from 'pages/settings/schedulePeriods/SchedulePeriodsTableProps';
import { SearchTextField } from 'components/shared/searchTextField/SearchTextField';
import { Modals, openModal } from 'app/slices/modals';
import { FormMode, OrderDirection } from 'utils/types';
import { useToastError } from 'hooks/use-toast-error';
import { periodsGqls } from 'pages/settings/schedulePeriods/SchedulePeriods.gqls';
import { useQuery } from '@apollo/client';
import { periodsPage } from 'app/genericSlices/periods';
import { mapOrder } from 'utils/mapping';
import { GridSortModel } from '@mui/x-data-grid-pro';
import isEqual from 'lodash/isEqual';
import { PeriodProps, PeriodStatus, PeriodStatusLabel, PeriodsFilterType } from './SchedulePeriods.consts';
import { usePersistCaretPosition } from 'hooks/use-persist-caret-position';
import { FetchPolicies } from 'utils/types/common';

const SchedulePeriods = () => {
  const { filters, order } = useSelector(periodsPage.periodsState);
  const [caretPosition, setCaretPosition] = usePersistCaretPosition(filters[PeriodsFilterType.SearchQuery]);
  const [gridSortModel, setGridSortModel] = useState<GridSortModel>(mapOrder(order));
  const getServerOrder = (orderToMap: any) => {
    const keyMap: { [key: string]: string } = {
      dates: 'startDate',
      status: 'endDate',
    };
    const newOrder: { [key: string]: string } = {};
    for (const key in orderToMap) {
      if (keyMap[key]) {
        newOrder[keyMap[key]] = orderToMap[key];
      } else {
        newOrder[key] = orderToMap[key];
      }
    }
    return newOrder;
  };
  const [serverOrder, setServerOrder] = useState(getServerOrder(order));
  const [offset, setOffset] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [periods, setPeriods] = useState<PeriodProps[]>([]);

  const { data, error, loading, fetchMore, refetch } = useQuery(periodsGqls.queries.getPeriodsSchedulePeriodsPage, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: periodsPage.getFilters(filters),
        order: serverOrder,
        limit: 24,
        offset: 0,
      },
    },
  });

  const total = data?.getPeriodsSchedulePeriodsPage?.total || 0;

  useToastError(error, 'Error loading schedule periods');

  useEffect(() => {
    if (data?.getPeriodsSchedulePeriodsPage?.items) {
      setPeriods(data.getPeriodsSchedulePeriodsPage.items);
    }
  }, [data]);

  const urlFilters = useUrlFilters((params: any) => {
    store.dispatch(
      periodsPage.actions.setFilters(
        Object.keys(params).reduce(
          (res: any, key: any) => ({ ...res, [key]: Array.isArray(params[key]) ? params[key] : [params[key]] }),
          {},
        ),
      ),
    );
  });

  useEffect(() => {
    setPeriods([]);
    setOffset(0);
    refetch({
      data: {
        filters: periodsPage.getFilters(filters),
        order: serverOrder,
        limit: 24,
        offset: 0,
      },
    });
  }, [filters, serverOrder]);

  const fetchNextPeriods = async () => {
    if (periods.length < total && !loadingMore) {
      setLoadingMore(true);
      await fetchMore({
        variables: {
          data: {
            filters: periodsPage.getFilters(filters),
            order: serverOrder,
            limit: 24,
            offset: periods.length,
          },
        },
      }).then(({ data: fetchMoreResult }) => {
        if (fetchMoreResult) {
          setPeriods((prevPeriods) => [
            ...prevPeriods,
            ...fetchMoreResult.getPeriodsSchedulePeriodsPage.items,
          ]);
        }
      });
      setLoadingMore(false);
      setOffset((prevOffset) => prevOffset + 24);
    }
  };

  const onActionClick = (actionType: ActionType, period: PeriodProps) => {
    store.dispatch(
      openModal({
        modal: Modals.PeriodModal,
        props: { mode: ActionType.Edit, period, periods },
      }),
    );
  };

  const onCreateNewPeriod = () => {
    store.dispatch(
      openModal({
        modal: Modals.PeriodModal,
        props: { mode: FormMode.New, periods },
      }),
    );
  };

  const onSortModelChange = (sortModel: { [key: string]: OrderDirection }, gridSortModal: GridSortModel) => {
    if (!isEqual(sortModel, order)) {
      store.dispatch(periodsPage.actions.setOrder(sortModel));
      setGridSortModel(gridSortModal);
      setServerOrder(getServerOrder(sortModel));
    }
  };

  useEffect(() => {
    if (!Object.keys(urlFilters.params).length) {
      urlFilters.filterMulti(filters);
    }
  }, []);

  return (
    <PageContainer>
      <StyledHeader data-automation-id="header">
        <TextPageTitle text={tabName.SchedulePeriods} />
        <Actions data-automation-id="actions">
          <Filters>
            <FilterIcon name="filter" />
            <StyledFilterBarSelect
              placeholder="Select"
              label="Status"
              name="status"
              multiple
              items={
                Object.values(PeriodStatus).map((status) => {
                  return {
                    id: status,
                    name: PeriodStatusLabel[status],
                  };
                }) as any[]
              }
              onChange={(selectedItems: any) => {
                urlFilters.filter(
                  PeriodsFilterType.Status,
                  Object.values(selectedItems).map((i: any) => i.id),
                );
              }}
              initialSelectedItems={filters[PeriodsFilterType.Status]}
              reset
              maxItems={1}
              selectWidth={160}
            />
            <SearchTextField
              key={`${JSON.stringify(filters[PeriodsFilterType.SearchQuery])}_SearchQuery`}
              name="schedule-periods-search"
              caretPosition={caretPosition}
              value={filters[PeriodsFilterType.SearchQuery] ?? ''}
              onChange={(e) => {
                urlFilters.filter('searchQuery', e.target.value);
                setCaretPosition(e);
              }}
            />
          </Filters>
          <AddNewButton onClick={onCreateNewPeriod} />
        </Actions>
      </StyledHeader>
      {!periods.length && loading ? (
        <StyledLoader size={LoaderSize.Large} />
      ) : (
        <TableContainer>
          <StyledTotalAmountWithTable amount={total} />
          {Boolean(total) && (
            <Table
              tableProps={{ ...getSchedulePeriodsTableProps(onActionClick), rows: periods, loading }}
              onRowOver={(e: React.SyntheticEvent<HTMLDivElement>) => showActionsCell(e.currentTarget.dataset.id)}
              onRowOut={(e: React.SyntheticEvent<HTMLDivElement>) => hideActionsCell(e.currentTarget.dataset.id)}
              onSortModelChange={onSortModelChange}
              gridSortModel={gridSortModel}
              onRowsScrollEnd={fetchNextPeriods}
            />
          )}
        </TableContainer>
      )}
    </PageContainer>
  );
};

export default SchedulePeriods;
