import React from 'react';
import { RadioItemProps } from 'components/shared/radioGroup/radioItem/RadioItem.consts';
import {
  InnerCircle,
  Label,
  OuterCircle,
  RadioItemWrapper,
} from 'components/shared/radioGroup/radioItem/RadioItem.style';

const RadioItem = ({ label, isSelected, onClick, disabled, className }: RadioItemProps) => {
  return (
    <RadioItemWrapper disabled={disabled} onClick={onClick} className={className}>
      <OuterCircle disabled={disabled} isSelected={isSelected}>
        {isSelected && <InnerCircle disabled={disabled} />}
      </OuterCircle>
      <Label disabled={disabled}>{label}</Label>
    </RadioItemWrapper>
  );
};

export default RadioItem;
