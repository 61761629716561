import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ListType, SetItemsSelectionFormState } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import { ActionType, GenericEntity, GenericItem, GenericSet, OrderDirection, ProductSetType } from 'utils/types';
import { AvailableSetsAndItemsAccordionProps } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/availableSetItemsListPanel/availableSetsAndItemsAccordion/AvailableSetsAndItemsAccordion.consts';
import {
  getSetsToDisplay,
  getAllItemsRecursively,
  getSetsFringesItems,
  getSetsNestedSetsRecursively,
  isGenericSet,
  shouldDisplayItem,
} from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.utils';
import SetListItem from 'pages/shared/setItemsSelectionForm/setListItem/SetListItem';
import ListItem from 'pages/shared/setItemsSelectionForm/listItem/ListItem';
import { SelectedDisplayMode } from 'pages/shared/setItemsSelectionForm/setListItem/SetListItem.consts';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import { Virtuoso } from 'react-virtuoso';
import { useLazyQuery } from '@apollo/client';
import { StyledLoader } from 'pages/shared/shared.style';
import {
  NoProductText,
  StyledCenteredLoaderContainer,
  StyledItemsSelectionAccordion,
} from '../../SetItemsListPanel.style';
import { FetchPolicies } from 'utils/types/common';

const AvailableSetsAndItemsAccordion = ({
  accordion,
  isSingleAccordionPanel,
  setOf,
  itemFormatter,
  itemSetTitleFormatter,
  onExcludeItemClick,
  onItemSelect,
  onItemSetActionClick,
  selectAll,
  searchValue,
  fetchRootSetsByIds,
  forcedExcludedSetsIds,
  forcedExcludedItemsIds,
  supportSetFringes,
  setType,
}: AvailableSetsAndItemsAccordionProps) => {
  const [expanded, setExpanded] = useState(true);
  const [forceLoading, setForceLoading] = useState(false);
  const [expandedSetsIds, setExpandedSetsIds] = useState<Set<number>>(new Set<number>());
  const limit = 10;
  const { watch } = useFormContext<SetItemsSelectionFormState>();
  const {
    name,
    headline,
    gqlName,
    gqlQuery,
    getFilters,
    sortOptions,
    selectedItemsDisplayMode,
    selectedSetsDisplayMode,
    selectedNestedSetsDisplayMode,
  } = accordion;

  const gqlNames = Array.isArray(gqlName) ? gqlName : [gqlName];
  const [loadItems, { data, fetchMore, loading }] = useLazyQuery<{ [key: string]: GenericEntity }>(gqlQuery, {
    variables: { data: { filters: getFilters(searchValue), order: { name: OrderDirection.ASC }, limit } },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
  });

  const modifiedSetId = watch('id');
  const excludedItemsById = watch('excludedItemsById');
  const excludedItemSetsById = watch('excludedItemSetsById');
  const selectedItemsByIdSet = new Set(Object.keys(watch('selectedItemsById')).map(Number));
  const selectedItemsIds = new Set([
    ...Array.from(selectedItemsByIdSet),
    ...getAllItemsRecursively(Object.values(watch('selectedItemSetsById')), setOf, true).map((item) => Number(item.id)),
  ]);

  const selectedFringesIds = new Set(
    getSetsFringesItems(Object.values(watch('selectedItemSetsById'))).map((item) => Number(item.id)),
  );

  const selectedItemSetsIds = new Set(
    getSetsNestedSetsRecursively(Object.values(watch('selectedItemSetsById')))
      .filter((set) => !set.dummy)
      .map((set) => Number(set.id)),
  );

  const onExpandSetClick = useCallback(
    (setId: number) => {
      if (expandedSetsIds.has(setId)) {
        expandedSetsIds.delete(setId);
        setExpandedSetsIds(new Set<number>(expandedSetsIds));
      } else {
        setExpandedSetsIds(new Set<number>(expandedSetsIds.add(setId)));
      }
    },
    [expandedSetsIds],
  );

  const setsAndItemsFromQuery = useMemo(
    () => gqlNames.reduce((prev, gqlN) => [...prev, ...(data?.[gqlN]?.items ?? [])], []),
    [JSON.stringify(data)],
  );

  const setsAndItems = setsAndItemsFromQuery?.map((levelOneSet: any) => {
    if (levelOneSet?.custom) {
      if (levelOneSet?.sets) {
        const updatedLevelOneSets = levelOneSet?.sets?.map((levelTwoSet: any) => {
          if (levelTwoSet?.fringes?.length !== 0 && levelTwoSet?.locations?.length === 0) {
            return {
              ...levelTwoSet,
              locations: [...levelTwoSet?.locations, ...levelTwoSet?.fringes],
              fringes: [],
            };
          }
          else{
            const updatedLevelTwoSets = levelTwoSet?.sets?.map((set: any) => {
              if (set?.fringes?.length !== 0 && set?.locations?.length === 0) {
                return {
                  ...set,
                  locations: [...set.locations, ...set.fringes],
                  fringes: [],
                };
              }
              return set;
            });
            if(updatedLevelTwoSets?.length){
              return {
                ...levelTwoSet,
                sets: updatedLevelTwoSets,
              };
            }
            return levelTwoSet;
          }
        });
        return {
          ...levelOneSet,
          sets: updatedLevelOneSets,
        };
      }
    }
    return levelOneSet;
  });

  const setsAndItemsToDisplay = useMemo(() => {
    const setsToDisplay = getSetsToDisplay(
      setsAndItems.filter((obj: any) => isGenericSet(obj, setOf) && obj.id !== modifiedSetId),
      setOf,
      false,
      ListType.Available,
      supportSetFringes,
      selectedItemSetsIds,
      selectedItemsIds,
      selectedSetsDisplayMode,
      selectedNestedSetsDisplayMode,
      selectedItemsDisplayMode,
    );

    const itemsToDisplay = setsAndItems.filter(
      (obj: any) =>
        !isGenericSet(obj, setOf) &&
        shouldDisplayItem(obj, ListType.Available, selectedItemsIds, selectedItemsDisplayMode),
    );

    return [...setsToDisplay, ...itemsToDisplay];
  }, [
    JSON.stringify(Object.keys(watch('selectedItemSetsById'))),
    JSON.stringify(Object.keys(watch('selectedItemsById'))),
    setsAndItems,
  ]);

  const fetchSetById = async (set: GenericSet) => {
    const sets = await fetchRootSetsByIds([set]);
    return getSetsNestedSetsRecursively(sets).find((s) => s.id === set.id);
  };

  const handleItemClick = async (action: ActionType, item: GenericItem) => {
    onItemSelect(action, item);
  };

  const fetchNext = () => {
    const offset = gqlNames.reduce((prevMax, gqlN) => Math.max(prevMax, data?.[gqlN]?.items?.length ?? 0), 0);
    const hasMore = gqlNames.reduce((prev, gqlN) => prev || data?.[gqlN]?.total > data?.[gqlN]?.items?.length, false);
    if (!loading && hasMore) {
      return fetchMore({
        variables: {
          data: {
            filters: getFilters(searchValue),
            offset,
            limit,
            order: { name: OrderDirection.ASC },
          },
        },
      });
    }
  };

  useEffect(() => {
    let cancel = false;
    loadItems({
      variables: { data: { filters: getFilters(searchValue), offset: 0, limit, order: { name: OrderDirection.ASC } } },
    }).then(() => {
      if (cancel) {
        return;
      }
      const scrollerElement = document.getElementById(name);
      if (scrollerElement) {
        scrollerElement.scrollTop = 0;
      }
    });

    return () => {
      cancel = true;
    };
  }, [searchValue]);

  useEffect(() => {
    setExpandedSetsIds(
      new Set<number>(
        setsAndItemsToDisplay
          .filter((obj) => isGenericSet(obj, setOf))
          .map((set) => set.id)
          .filter((setId) => expandedSetsIds.has(setId)),
      ),
    );
  }, [JSON.stringify(Object.keys(watch('selectedItemSetsById')))]);

  // Define a recursive function to filter sets based on conditions
  const filterSetsWithConditions = (obj: GenericSet<GenericItem>) => {
    if (isGenericSet(obj, setOf)) {
      obj.sets = obj?.sets?.filter(childSet => filterSetsWithConditions(childSet));
      return ((obj?.totalItems !== 0 || obj?.sets?.length > 0));
    } else {
      return true;
    }
  };
  // Use the recursive function to filter the array
  const filteredSetsAndItemsToDisplay = setsAndItemsToDisplay.filter(obj => filterSetsWithConditions(obj)).filter(obj => (obj?.sets?.length > 0 && obj?.locations?.length === 0) || (obj?.sets?.length === 0 && obj?.locations?.length > 0));

  return (
    <StyledItemsSelectionAccordion
      id={name}
      listName={name}
      headline={headline}
      showExpandArrow={!isSingleAccordionPanel}
      expanded={expanded}
      onChange={(event, isExpanded) => setExpanded(isExpanded)}
      onSortChange={() => null}
      selectAll={async () => {
        setForceLoading(true);
        const max = gqlNames.reduce((prevMax, gqlN) => Math.max(prevMax, data?.[gqlN]?.total ?? 0), 0);
        fetchMore({
          variables: {
            data: {
              filters: getFilters(searchValue),
              offset: 0,
              limit: max,
              order: { name: OrderDirection.ASC },
            },
          },
        }).then((res) => {
          const setsToDisplay = gqlNames.reduce((a, i) => [...a, ...res.data[i].items], []);
          selectAll(setsToDisplay);
          setForceLoading(false);
        });
      }}
      sortOptions={sortOptions}
    >
      <>
        {((loading && !setsAndItemsToDisplay?.length) || forceLoading) && (
          <StyledCenteredLoaderContainer>
            <StyledLoader size={LoaderSize.Medium} />
          </StyledCenteredLoaderContainer>
        )}
        {setType && setType === ProductSetType.NonFoodProduct && data?.getProductSets?.total === 0 && (
          <NoProductText>No Product Available</NoProductText>
        )}
        <Virtuoso
          id="virtuoso-items-list"
          data={accordion.name === 'non-custom-sets' ? filteredSetsAndItemsToDisplay :setsAndItemsToDisplay}
          endReached={fetchNext}
          atBottomThreshold={0}
          components={{
            Footer: () =>
              setsAndItemsToDisplay?.length && loading && !forceLoading ? (
                <StyledLoader size={LoaderSize.Small} />
              ) : (
                <></>
              ),
          }}
          itemContent={(index, obj) => 
             isGenericSet(obj, setOf) ? (
              <SetListItem
                key={`s-${obj.id}`}
                itemSet={obj}
                setOf={setOf}
                listType={ListType.Available}
                onItemSetActionClick={async (action, s) =>
                  onItemSetActionClick(
                    action,
                    getSetsNestedSetsRecursively(setsAndItems).find((set: GenericSet) => s.id === set.id),
                  )
                }
                onItemActionClick={onItemSelect && handleItemClick}
                onExcludeItemClick={onExcludeItemClick}
                onExpandSetClick={onExpandSetClick}
                titleFormatter={itemSetTitleFormatter}
                selectedItemsIds={selectedItemsIds}
                selectedFringesIds={selectedFringesIds}
                selectedItemSetsIds={selectedItemSetsIds}
                expandedSetsIds={expandedSetsIds}
                selectedSetsDisplayMode={selectedSetsDisplayMode}
                selectedNestedSetsDisplayMode={selectedNestedSetsDisplayMode}
                selectedItemsDisplayMode={selectedItemsDisplayMode}
                itemFormatter={itemFormatter}
                excludedItemsIds={new Set(Object.keys(excludedItemsById).map(Number))}
                excludedSetsIds={new Set(Object.keys(excludedItemSetsById).map(Number))}
                forcedExcludedItemsIds={forcedExcludedItemsIds}
                forcedExcludedSetsIds={forcedExcludedSetsIds}
                supportSetFringes={supportSetFringes}
              />
            ) : 
              <ListItem
                key={`p-${obj.id}`}
                item={obj}
                actionType={ActionType.Add}
                onClick={onItemSelect}
                disabled={
                  forcedExcludedItemsIds?.has(obj.id) ||
                  (selectedItemsDisplayMode === SelectedDisplayMode.Disable && selectedItemsIds?.has(Number(obj.id)))
                }
                itemFormatter={itemFormatter}
                onExcludeClick={onExcludeItemClick}
              />
            
            }
        />
      </>
    </StyledItemsSelectionAccordion>
  );
};

export default AvailableSetsAndItemsAccordion;


