import { Tag, TagDefault, TagsValidEntities } from 'utils/types/tags';
import { TagFilterType } from 'pages/settings/tags/Tags.consts';
import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { OrderDirection } from 'utils/types';
import type { RootState } from 'app/store';

export enum TagStateId {
  AllTags = 'allTags',
}

export interface TagsFilters {
  [TagFilterType.EntityType]: TagsValidEntities[];
  [TagFilterType.Default]: TagDefault[];
  [TagFilterType.SearchQuery]: string;
}

type TagsGenericSlice = {
  tagsState: (state: RootState) => any;
  reducer: any;
  actions: any;
};

export interface TagsState {
  tags: Tag[];
  total: number;
  filters: TagsFilters;
  order: {
    [key: string]: OrderDirection;
  };
  lastRequestId: string;
}

export const initialState: TagsState = {
  tags: [],
  total: 0,
  filters: null,
  order: {
    name: OrderDirection.ASC,
  },
  lastRequestId: '',
};

export const getTagsFilter = (initialFilters: { [key in TagFilterType]?: any[] }): any => {
  const filters: { [key: string]: any[] } = { AND: [], OR: [] };
  Object.entries(initialFilters).forEach(([type, values]) => {
    if (values.length) {
      switch (type) {
        case TagFilterType.EntityType:
          filters.AND.push({
            validEntities: {
              in_array: values,
            },
          });
          break;
        case TagFilterType.Default:
          if (values.length === 1) {
            filters.AND.push({
              isMasterTag: {
                is: values[0] === TagDefault.Default,
              },
            });
          }
          break;
        case TagFilterType.SearchQuery:
          filters.AND.push({
            name: {
              contains: values[0],
            },
          });
          break;
        default:
          break;
      }
    }
  });
  return filters;
};

export const createTagsGenericSlice = (sliceName: TagStateId): TagsGenericSlice => {
  const tagsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      setFilter(state, action) {
        state.filters = {
          ...state.filters,
          [action.payload.filter]: action.payload.value,
        };
      },
      setFilters(state, action) {
        state.filters = action.payload;
      },
      setOrder(state, action) {
        state.order = action.payload;
      },
    },
  });

  const tagsState = (state: RootState) => state.tags[sliceName];

  return { tagsState, reducer: tagsSlice.reducer, actions: tagsSlice.actions };
};

export const allTags: TagsGenericSlice = createTagsGenericSlice(TagStateId.AllTags);

const tagsReducer = combineReducers({
  [TagStateId.AllTags]: allTags.reducer,
});

export default tagsReducer;
