import styled from '@emotion/styled';
import typography from 'styles/typography';
import { ButtonContained, ButtonText } from 'components/shared/button';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import TextField from 'components/shared/textField/TextField';
import TermConditionTranslations from './termConditionTranslations/TermConditionTranslations';
import Modal from '../../../../../components/shared/modal/Modal';

export const TermConditionName = styled(TextField)`
  width: 100%;
  display: flex;
  margin-bottom: ${typography.unit * 7}px;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSelectbox = styled(Selectbox)`
  display: flex;
  width: 100%;
`;

export const StyledButtonContained = styled(ButtonContained)`
  margin-left: ${typography.unit * 5}px;
`;

export const StyledButtonText = styled(ButtonText)`
  margin-left: ${typography.unit * 8}px;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${typography.unit * 7}px;
`;

export const TermConditionFormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const StyledTermConditionTranslations = styled(TermConditionTranslations)`
  margin-top: ${typography.unit * 7}px;
`;

export const StyledModal = styled(Modal)`
  width: 660px;
`;
