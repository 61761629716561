import { gql } from '@apollo/client';

export const campaignsGqls = {
  queries: {
    getAll: gql`
      query Campaigns($data: GetCampaignsQueryDto) {
        getCampaigns(data: $data) {
          items {
            ... on Campaign {
              id
              externalId
              type
              title
              status
              alert
              schedule
              isLocked
              redemptions
              voucherConfig
              isTriggerEvent
              priority
              approvals {
                deploymentFailedCount
              }
              createdBy {
                id
              }
              updatedBy {
                id
              }
              localSchedule {
                period {
                  id
                  name
                  endDate
                }
                zone {
                  id
                  name
                }
              }
              offerVersion {
                translations {
                  subtitle
                  image {
                    id
                  }
                }
              }
              push_notification {
                id
                startDate
                push_notification_translation {
                  id
                  language
                  title
                  message
                }
              }
              inProgress
            }
          }
          total
          totalLocal
          totalNonLocalConflicted
          totalNotEligibleForSelfApproval
          totalInvalid
        }
      }
    `,
    getImpactedByJobUpdate: gql`
      query Campaigns($data: GetCampaignsQueryDto) {
        getImpactedByJobUpdate(data: $data) {
          items {
            ... on Campaign {
              id
              externalId
              title
              status
            }
          }
          total
          totalLocal
          totalNonLocalConflicted
          totalNotEligibleForSelfApproval
          totalInvalid
        }
      }
    `,
    getTotalCount: gql`
      query Campaigns($data: GetCampaignsQueryDto) {
        getCampaigns(data: $data) {
          total
        }
      }
    `,
    getForImpact: gql`
      query Campaigns($data: GetCampaignsQueryDto) {
        getCampaigns(data: $data) {
          items {
            ... on Campaign {
              id
              externalId
              title
              status
              restaurantEligibility {
                restaurantGroups {
                  id
                  type
                }
                isNationwide
              }
            }
          }
          total
        }
      }
    `,
    getForOfferImpact: gql`
      query Campaigns($data: GetCampaignsQueryDto) {
        getCampaigns(data: $data) {
          items {
            ... on Campaign {
              id
              externalId
              title
              status
              type
            }
          }
          total
        }
      }
    `,
    getById: gql`
      query Campaign($id: BigInt!) {
        getCampaign(id: $id) {
          id
          type
          title
          description
          status
          isLocked
          alert
          isDraft
          isArchive
          priority
          schedule
          voucherConfig
          externalId
          isTriggerEvent
          approvals {
            id
            approvalStatus
            comment
            deploymentFailedCount
          }
          tags {
            id
            name
          }
          restaurantEligibility {
            restaurants
            restaurantGroups {
              id
              type
            }
            excludeRestaurants
            excludeRestaurantGroups {
              id
              type
            }
            isNationwide
          }
          offerVersion {
            id
            baseOfferId
            approvals {
              id
              approvalStatus
              comment
            }
            templateValues
            translations {
              title
              posTitle
              subtitle
              image {
                fileName
                file
                id
              }
            }
            isDraft
            status
          }
          localSchedule {
            period {
              id
              name
              endDate
            }
            zone {
              id
              name
            }
          }
          updatedAt
          updatedBy {
            id
          }
          createdBy {
            id
          }
          push_notification {
            id
            startDate
            push_notification_translation {
              id
              language
              title
              message
            }
          }
          inProgress
          totalLocations
        }
      }
    `,
    getCampaignTypesForName: gql`
      query Campaigns {
        getTypesFromConfig
      }
    `,
    getCampaignQuickFilters: gql`
      query GetCampaignsQuickFilters($data: GetAllQueryDto) {
        getCampaignsQuickFilters(data: $data) {
          quickFilterCounts
        }
      }
    `,
    getGames: gql`
      query Games($data: GetAllQueryDto) {
        getGames(data: $data) {
          items {
            ... on Game {
              id
              name
              restaurantEligibility {
                restaurants
                restaurantGroups {
                  id
                  type
                }
                excludeRestaurants
                excludeRestaurantGroups {
                  id
                  type
                }
                isNationwide
              }
              marketId
              platform
              startDateTime
              endDateTime
              status
            }
          }
          total
        }
      }
    `,
    getCampaingsForReport: gql`
      query Campaigns($data: GetCampaignsQueryDto) {
        getCampaignsReport(data: $data) {
          items {
            ... on Campaign {
              id
              externalId
              type
              title
              status
              schedule
              localSchedule {
                approvalStatus
                period {
                  name
                  startDate
                  endDate
                }
                zone {
                  name
                }
              }
              offerVersion {
                id
                baseOfferId
                translationsMap
                translations {
                  title
                  subtitle
                }
              }
              push_notification {
                startDate
                push_notification_translation {
                  language
                  title
                  message
                }
              }
            }
          }
        }
      }
    `,
    getCampaignsForCalendar: gql`
      query Campaigns($data: GetCampaignsQueryDto) {
        getCampaigns(data: $data) {
          items {
            ... on Campaign {
              id
              title
              schedule
              isTriggerEvent
              localSchedule {
                period {
                  id
                  name
                  endDate
                }
                zone {
                  id
                  name
                }
              }
              offerVersion {
                translations {
                  image {
                    id
                    fileName
                  }
                }
              }
              push_notification {
                id
                startDate
                push_notification_translation {
                  id
                  language
                  title
                  message
                }
              }
            }
          }
          total
          totalLocal
          totalNonLocalConflicted
          totalNotEligibleForSelfApproval
          totalInvalid
        }
      }
    `,
  },
  mutations: {
    add: gql`
      mutation CreateCampaign($data: CreateCampaignDto!) {
        createCampaign(data: $data) {
          externalId
          title
        }
      }
    `,
    addBulk: gql`
      mutation CreateCampaigns($data: CreateCampaignDto!) {
        createCampaigns(data: $data) {
          isProcessed
          message
          items {
            ... on Campaign {
              externalId
              title
            }
          }
        }
      }
    `,
    update: gql`
      mutation UpdateCampaign($data: UpdateCampaignDto!) {
        updateCampaign(data: $data) {
          externalId
          alert
        }
      }
    `,
    updateApproval: gql`
      mutation UpdateCampaignApproval($data: UpdateCampaignDto!) {
        updateCampaign(data: $data) {
          id
          status
          alert
        }
      }
    `,
    deployCampaign: gql`
      mutation DeployCampaign($data: UpdateCampaignDto!) {
        deployCampaign(data: $data) {
          id
          status
          alert
        }
      }
    `,
    reDeployCampaign: gql`
    mutation RedeployCampaign($data: UpdateCampaignDto!) {
      reDeployCampaign(data: $data) {
        id
        status
        alert
      }
    }
  `,
    removeOfferVersionFromCampaigns: gql`
      mutation RemoveOfferVersionFromCampaigns($data: RemoveOfferVersionDto!) {
        removeOfferVersionFromCampaigns(data: $data) {
          id
        }
      }
    `,
    bulkCampaignsStatusUpdate: gql`
      mutation BulkCampaignsStatusUpdate($data: BulkCampaignsStatusUpdateDto!) {
        bulkCampaignsStatusUpdate(data: $data) {
          isProcessed
        }
      }
    `,
  },
};
