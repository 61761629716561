import { GameNotification } from './games';
import { UserNameNotification } from './users';

export enum NotificationEntityType {
  Product = 'product',
  Location = 'location',
  Offer = 'offer',
  Campaign = 'campaign',
  User = 'user',
}

export enum NotificationActionType {
  NoProducts = 'noProducts',
  ProductUpdate = 'productUpdate',
  NoLocations = 'noLocations',
  LocationUpdate = 'locationUpdate',
  NoRedemptions = 'noRedemptions',
  CampaignErrors = 'campaignErrors',
  CampaignApprovalRequired = 'campaignApprovalRequired',
  NewUser = 'newUser',
  UserUpdate = 'userUpdate',
  GameLocationUpdateFailed = 'gameLocationUpdateFailed',
  GameLocationUpdateSuccess = 'gameLocationUpdateSuccess',
  BulkCampaignCreation = 'bulkCampaignCreation',
  BulkSubmissionForApproval = 'bulkSubmissionForApproval',
  BulkCampaignApproval = 'bulkCampaignApproval',
}

export const userNotificationFilters = [
  NotificationActionType.GameLocationUpdateFailed,
  NotificationActionType.GameLocationUpdateSuccess,
  NotificationActionType.LocationUpdate,
  NotificationActionType.ProductUpdate,
  NotificationActionType.NoLocations,
  NotificationActionType.NoProducts,
  NotificationActionType.BulkCampaignCreation,
  NotificationActionType.BulkSubmissionForApproval,
  NotificationActionType.BulkCampaignApproval,
];

export interface UserNotification {
  id: number;
  createdAt: string;
  action: NotificationActionType;
  entityType: NotificationEntityType;
  entitiesData: any[];
  isRead: boolean;
  isArchive: boolean;
  game: GameNotification;
  user: UserNameNotification;
}

export interface UserNotificationMessage {
  id: number;
  message: string;
  linksString?: string[];
  links?: string[];
  entityType: NotificationEntityType;
  createdAt: string;
  isRead: boolean;
  isArchive: boolean;
}
