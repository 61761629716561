export enum SharedChangeDetailsOptions {
  Added = 'added',
  Removed = 'removed',
  AddedToGroup = 'addedToGroup',
  RemovedFromGroup = 'removedFromGroup',
  AddedToCustomSet = 'addedToCustomSet',
  RemovedFromCustomSet = 'removedFromCustomSet',
  NameChange = 'nameChange',
}

export enum LocationChangeDetailsExtraOptions {
  AddedToGame = 'addedToGame',
  RemovedFromGame = 'removedFromGame',
}

export type AllChangeDetailsOptions = SharedChangeDetailsOptions | LocationChangeDetailsExtraOptions;

export const ChangeDetailsOptionsLabel: Record<AllChangeDetailsOptions, string> = {
  [SharedChangeDetailsOptions.Added]: 'Added',
  [SharedChangeDetailsOptions.Removed]: 'Removed',
  [SharedChangeDetailsOptions.AddedToGroup]: 'Added to Group',
  [SharedChangeDetailsOptions.RemovedFromGroup]: 'Removed from Group',
  [SharedChangeDetailsOptions.AddedToCustomSet]: 'Added to Custom Set',
  [SharedChangeDetailsOptions.RemovedFromCustomSet]: 'Removed from Custom Set',
  [SharedChangeDetailsOptions.NameChange]: 'Name Change',
  [LocationChangeDetailsExtraOptions.AddedToGame]: 'Added to Game',
  [LocationChangeDetailsExtraOptions.RemovedFromGame]: 'Removed from Game',
};

export interface AuditChange {
  entityId: number;
  name: string;
  id: number;
  action: AllChangeDetailsOptions;
  headline: string;
  createdAt: string | Date;
  changes: {
    usages: {
      campaigns?: number[];
      offers?: number[];
    };
  };
}
