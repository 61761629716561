export interface Tag {
  id: number;
  name: string;
  amount?: number;
  amountByEntities?: any;
  validEntities?: any;
  offers?: any;
  images?: any;
  campaigns?: any;
}

export enum TagsValidEntities {
  Campaign = 'campaign',
  Offer = 'offer',
  Image = 'image',
}

export enum TagDefault {
  Default = 'default',
  NonDefault = 'nonDefault',
}
