import React from 'react';
import { ApprovalStatusLabel, ApprovalStatusWithLabel } from 'utils/types';
import { StatusLabelProps } from './StatusLabel.consts';
import { StyledStatusLabel } from './StatusLabel.style';

const StatusLabel = ({ type, className }: StatusLabelProps) => {
  return (
    <StyledStatusLabel className={className} type={type} data-automation-id="status-label">
      {ApprovalStatusLabel[type as ApprovalStatusWithLabel]}
    </StyledStatusLabel>
  );
};

export default StatusLabel;
