import React, { useEffect } from 'react';
import { DiscountCondition, DiscountConditionRecord, DiscountType } from 'utils/types/offers';
import { useFormContext } from 'react-hook-form';
import { calcProductsHint } from 'utils/product';
import useDiscountType from 'hooks/use-discount-type';
import { DiscountTypeRecord, TemplateProps } from '../Template.consts';
import { StyledLabel, StyledForSelectbox, StyledTextField, StyledLightLabel, ProductPicker } from '../Template.style';
import getProductsAction from '../shared/ProductsActionCondition';
import { Currency } from '../shared/Currency/Currency';

export const SingleItemDiscount = ({ disabled, onProductSelection }: TemplateProps) => {
  const {
    control,
    register,
    getValues,
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext();

  const templateValuesDiscountConditionPath='versions.0.templateValues.discountCondition'
  const { discountType, isFree, isPercent, shouldDisplayCurrency } = useDiscountType();
  const [discountCondition, products] = watch([
    templateValuesDiscountConditionPath,
    'versions.0.templateValues.products',
  ]);

  useEffect(() => {
    if (!isFree) {
      setValue(templateValuesDiscountConditionPath, DiscountCondition.None);
    } else {
      resetField(templateValuesDiscountConditionPath, {
        defaultValue:
          discountCondition === DiscountCondition.None ? DiscountCondition.MinimumPurchase : discountCondition,
      });
    }
  }, [isFree]);

  return (
    <>
      <div>
        <StyledLabel>Buy</StyledLabel>
        <ProductPicker>
          <span>1</span>
          {calcProductsHint(products)}
          {getProductsAction(
            disabled,
            products,
            getValues,
            'Select Products',
            'products',
            (data: any) => ({
              products: data,
            }),
            onProductSelection,
          )}
          <input
            type="hidden"
            {...register('versions.0.templateValues.products', {
              value: products,
              shouldUnregister: true,
              required: true,
            })}
          />
        </ProductPicker>
      </div>
      <div>
        <StyledLabel>For</StyledLabel>
        <StyledForSelectbox
          name="versions.0.templateValues.discountType"
          control={control}
          disabled={disabled}
          validation={{
            required: true,
          }}
          items={
            Object.entries(DiscountTypeRecord()).map(([key, value]) => {
              return {
                id: key,
                name: value,
              };
            }) as any[]
          }
          selectWidth={100}
          defaultValue={DiscountType.MoneyOff}
          initialSelectedItems={discountType ? [discountType] : [DiscountType.MoneyOff]}
        />
        {!isFree && (
          <>
            {shouldDisplayCurrency && <Currency />}
            <StyledTextField
              disabled={disabled}
              register={register}
              validation={{
                required: true,
                min: isPercent ? 1 : 0.01,
                max: isPercent ? 99 : null,
                pattern: isPercent ? /^([1-9][0-9]?|99)$/ : /^\d+(?:\.\d{1,2})?$/,
                validate: (v: string) =>
                  (!Number.isNaN(Number(v)) && Number(v) >= 0.01) || (isPercent && Number(v) < 100 && Number(v) >= 1),
              }}
              errors={errors}
              name="versions.0.templateValues.discountValue"
              placeholder="Enter value"
            />
          </>
        )}
        {isFree ? (
          <>
            <StyledLightLabel>with</StyledLightLabel>
            <StyledForSelectbox
              name="versions.0.templateValues.discountCondition"
              control={control}
              disabled={disabled}
              validation={{
                required: true,
              }}
              items={
                Object.entries(DiscountConditionRecord)
                  .filter(([key]) => key !== DiscountCondition.None && key !== DiscountCondition.WithPurchaseOf)
                  .map(([key, value]) => {
                    return {
                      id: key,
                      name: value,
                    };
                  }) as any[]
              }
              defaultValue={DiscountCondition.MinimumPurchase}
              initialSelectedItems={
                discountCondition && discountCondition !== DiscountCondition.None
                  ? [discountCondition]
                  : [DiscountCondition.MinimumPurchase]
              }
              selectWidth={150}
            />
            {discountCondition === DiscountCondition.MinimumPurchase && (
              <>
                <StyledLightLabel>of</StyledLightLabel>
                <Currency />
                <StyledTextField
                  disabled={disabled}
                  register={register}
                  validation={{
                    required: true,
                    min: 0.01,
                    max: null,
                    pattern: /^\d+(?:\.\d{1,2})?$/,
                    validate: (v: string) => !Number.isNaN(Number(v)) && Number(v) >= 0.01,
                  }}
                  errors={errors}
                  name="versions.0.templateValues.conditionValue"
                  placeholder="Enter value"
                />
              </>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};
