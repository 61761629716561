import styled from '@emotion/styled';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';

export const Main = styled.main`
  display: flex;
  height: 100%;
  overflow: hidden;

  @media print {
    -webkit-print-color-adjust: exact;
    overflow: visible;
  }
`;

export const Side = styled.aside<StyledWithThemeProps>`
  display: flex;
  flex-direction: column;
  padding: ${typography.unit * 2}px ${typography.unit * 4}px 0 ${typography.unit * 6}px;
  border-right: ${({ theme }) => `1px solid ${theme.colors.global.border}`};
  flex-basis: 300px;

  @media print {
    display: none;
  }
`;

export const CheckboxWrapper = styled.div<StyledWithThemeProps>`
  padding: ${typography.unit * 5}px 0;
  border-top: ${({ theme }) => `1px solid ${theme.colors.global.border};`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.global.border};`};
`;
