import { client } from 'app/apollo';
import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import offersGqls from 'pages/offers/Offers.gqls';
import { imagesGqls } from 'pages/settings/images/Images.gqls';
import { tagsGqls } from 'pages/settings/tags/Tags.gqls';
import { capitalize } from 'utils/text';
import { TagsValidEntities } from 'utils/types/tags';
import { TagProps } from 'pages/settings/tags/Tags.consts';
import { clientQuery } from './clientQuery';

export const createNewImages = async (images: any) => {
  return client.mutate({
    mutation: imagesGqls.mutations.add,
    variables: { data: images },
    refetchQueries: ['Images'],
  });
};

export const bulkUpdateImages = async (images: any) => {
  return client.mutate({
    mutation: imagesGqls.mutations.bulkUpdate,
    variables: { data: images },
    refetchQueries: ['Images'],
  })
}

export const updateImage = async (image: any) => {
  return client.mutate({
    mutation: imagesGqls.mutations.update,
    variables: { data: [image] },
    refetchQueries: ['Images'],
  });
};

export const createImageTag = async (name: string) => {
  const tag = {
    name,
    validEntities: [capitalize(TagsValidEntities.Image)],
    isMasterTag: false,
  } as any as TagProps;

  return client.mutate({
    mutation: tagsGqls.mutations.add,
    refetchQueries: ['Tags'],
    variables: {
      data: { ...tag },
    },
  });
};

export const getOffersImpactsByImageId = async (imageId: number) => {
  const filters = {
    match: {
      'versions.translations.image.id': imageId,
    },
  };
  const offersImpacts = await clientQuery(offersGqls.queries.getForImpact, {
    data: { filters, limit: 9999 },
  });

  return offersImpacts.data.getOffers.items;
};

export const getCampaignsImpactsByImageId = async (imageId: number) => {
  const filters = {
    match: {
      'offerVersion.translations.image.id': imageId,
    },
  };
  const campaignsImpacts = await clientQuery(campaignsGqls.queries.getForImpact, {
    data: { filters, limit: 9999 },
  });

  return campaignsImpacts.data.getCampaigns.items;
};
