import React from 'react';
import InfoTooltip from 'components/shared/tooltip/info/InfoTooltip';
import {
  StyledProductContainer,
  StyledProductTitle,
} from 'pages/settings/productSets/components/productItem/ProductItem.style';
import { Product } from 'utils/types/products';
import { formatDate } from 'utils/date';
import { DateFormats } from 'utils/types';

const ProductItem = (product: Product, dateFormat: DateFormats) => {
  const { plu, name, status, nextStatusChangeWillBeAt } = product;
  return (
    <StyledProductContainer>
      <StyledProductTitle>{`${plu} - ${name}`}</StyledProductTitle>
      {status && nextStatusChangeWillBeAt && (
        <InfoTooltip
          id={Math.random().toString(32)}
          place="left"
          content={`${status === 'inactive' ? 'Activation' : 'Inactivation'} date: ${formatDate(
            nextStatusChangeWillBeAt,
            { dateFormat },
            undefined,
            { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' },
          )}`}
        />
      )}
    </StyledProductContainer>
  );
};

export default ProductItem;
