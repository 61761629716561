import React from 'react';
import { CheckboxProps } from './Checkbox.consts';
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  StyledCheckmark,
  StyledIntermediate,
  StyledLabel,
} from './Checkbox.style';

const getUEID = () => {
  return Math.random().toString(32).slice(2);
};

const Checkbox = ({ checked, intermediate, disabled, label, className, onClick, id }: CheckboxProps) => {
  const generatedId = getUEID();
  return (
    <CheckboxContainer
      className={className}
      onClick={(e) => {
        onClick(e);
        e.preventDefault();
      }}
      disabled={disabled}
      data-automation-id="checkbox-container"
    >
      <HiddenCheckbox type="checkbox" id={id || generatedId} defaultChecked={checked} disabled={disabled} />
      <StyledCheckbox checked={checked} intermediate={intermediate} disabled={disabled} data-automation-id="checkbox">
        {checked && <StyledCheckmark name="checkmark" />}
        {intermediate && <StyledIntermediate name="minus" />}
      </StyledCheckbox>
      {label && (
        <StyledLabel htmlFor={id || generatedId} disabled={disabled}>
          {label}
        </StyledLabel>
      )}
    </CheckboxContainer>
  );
};

export default Checkbox;
