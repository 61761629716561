import styled from '@emotion/styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import { ButtonOutlined } from 'components/shared/button';
import { Icon, IconProps } from 'components/shared/icon';
import { defaultTheme } from 'styles/themes/defaultTheme';

// region Offers
export const ButtonsContainer = styled.div`
  display: flex;
`;

export const ZonesButton = styled(ButtonOutlined)`
  padding: ${typography.unit * 2}px;
  margin-right: ${typography.unit * 2}px;
`;

export const ZonesIcon = styled(Icon)<IconProps>`
  fill: ${defaultTheme.colors.global.inputPrimary};
  width: 14px;
`;

export const OffersGrid = styled.div`
  overflow: auto;
`;

export const OffersGridContainer = styled(InfiniteScroll)`
  display: flex;
  margin-left: ${typography.unit * 5}px;
  margin-bottom: ${typography.unit * 5}px;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: ${typography.unit * 10}px;
  row-gap: ${typography.unit * 12}px;
  padding-bottom: ${typography.unit * 5}px;
  word-break: break-all;
`;
// endregion

// region Offer shared
export const NoImageText = styled.div<StyledWithThemeProps>`
  display: flex;
  align-self: center;
  color: ${({ theme }) => theme.colors.text.secondary};
`;
// endregion
