import styled from '@emotion/styled';
import { StyledWithThemeProps } from 'utils/types';
import typography from 'styles/typography';

type Color = 'green' | 'red' | 'yellow' | 'blue';
export const StyledItems = styled.div<StyledWithThemeProps & { color?: Color }>`
  height: 80px;
  width: 150px;
  background-color: ${({ color, theme }) => color && theme.colors.dashboard[color]};
  padding: ${typography.unit * 2}px ${typography.unit * 3}px;
  border-radius: ${typography.borderRadius}px;
  border: ${({ color, theme }) => !color && `1px solid ${theme.colors.global.border}`};
  transition: all ${typography.transition}s;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 8px;
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
