import React from 'react';
import { UsernameDisplayProps } from './UsernameDisplay.consts';

export const UsernameDisplay = ({ user, fullName }: UsernameDisplayProps) => {
  if (fullName) {
    return <>{fullName}</>;
  }
  return (
    <>
      {user?.firstName ?? 'Unknown'} {user?.lastName ?? 'Unknown'} {!user?.active ? `(inactive)` : ''}
    </>
  );
};
