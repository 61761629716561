import { gql } from '@apollo/client';

const dealPriorityConfiguration = {
  queries: {
    getAllDealPriority: gql`
      query DealPriority {
        getDealPriority {
          ... on DealPriority {
            id
            campaignType
            priority
          }
        }
      }
    `,
  },
  mutations: {
    update: gql`
      mutation UpdateDealPriority($data: JSONObject!) {
        updateDealPriority(data: $data) {
          id
          campaignType
          priority
        }
      }
    `,
  },
};

export default dealPriorityConfiguration;
