import styled from '@emotion/styled';
import Modal from 'components/shared/modal/Modal';
import typography from 'styles/typography';
import { ButtonOutlined, ButtonText } from '../../shared/button';

export const StyledModal = styled(Modal)`
  min-width: initial;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: min-content;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${typography.unit * 4}px;
`;

export const StyledButtonText = styled(ButtonText)`
  margin-right: ${typography.unit * 5}px;
`;

export const StyledButtonOutlined = styled(ButtonOutlined)`
  margin-right: ${typography.unit * 5}px;
`;
