export enum ButtonType {
  Contained = 'contained',
  Outlined = 'outlined',
  Text = 'text',
}

export interface InputFileProps {
  handleFiles: (files: FileList) => void;
  buttonType?: ButtonType;
  filesType?: string;
  multiple?: boolean;
  children?: any;
  className?: string;
}
