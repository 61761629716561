import React, { useState } from 'react';
import { FormMode } from 'utils/types';
import { SetItemsSelectionFormFooterProps } from 'pages/shared/setItemsSelectionForm/setItemsSelectionFormFooter/SetItemsSelectionFormFooter.consts';
import Error from 'components/shared/error/Error';
import { ButtonContained, ButtonOutlined, ButtonText } from 'components/shared/button';
import { store } from 'app/store';
import { closeModal } from 'app/slices/modals';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { hideTooltip } from 'utils/tooltip';
import { ButtonsContainer, Footer } from './SetItemsSelectionFormFooter.style';

const DELETE_TOOLTIP_ID = '#delete-tooltip';

const SetItemsSelectionFormFooter = ({
  onSave,
  formMode,
  onDelete,
  onDuplicate,
  onViewUsage,
  onEdit,
  onClose,
  formState,
  isReadOnly = false,
  isNonProduct = false,
}: SetItemsSelectionFormFooterProps) => {
  const { isDirty, isValid, errors } = formState;
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const handleAction = (actionFn: () => void) => {
    setButtonsDisabled(true);
    try {
      actionFn();
    } finally {
      setButtonsDisabled(false);
    }
  };

  const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);

      const handleDelete = (actionFn: () => void) => {
        if (!isDeleteButtonClicked) {
          setIsDeleteButtonClicked(true);
          actionFn();
          hideTooltip(DELETE_TOOLTIP_ID);
        }
      };

  const GeneralButtons = (
    <>
      {isDirty && (
        <Tooltip
          id="cancel-tooltip"
          content="Are you sure you want to cancel?"
          onDisapproveClick={() => hideTooltip('#cancel-tooltip')}
          onApproveClick={() => (onClose ? onClose() : store.dispatch(closeModal()))}
        />
      )}
      <ButtonText
        data-tip
        data-for="cancel-tooltip"
        onClick={() => (!isDirty ? (onClose ? onClose() : store.dispatch(closeModal())) : null)}
      >
        Cancel
      </ButtonText>
      <ButtonContained disabled={!isDirty || !isValid || buttonsDisabled} onClick={() => handleAction(onSave)}>
        Save
      </ButtonContained>
    </>
  );

  const FormSectionToComponent: Record<FormMode, JSX.Element> = {
    [FormMode.View]: (
      <>
        <ButtonText onClick={() => (onClose ? onClose() : store.dispatch(closeModal()))}>Close</ButtonText>
        <ButtonOutlined
          data-tip
          data-for="delete-tooltip"
          disabled={buttonsDisabled || isReadOnly}
          onClick={() => null}
          hide= {isNonProduct}
        >
          Delete
        </ButtonOutlined>
        <Tooltip
          id="delete-tooltip"
          content="Are you sure you want to delete?"
          approveMsg="Yes, Delete"
          onDisapproveClick={() => {
            hideTooltip(DELETE_TOOLTIP_ID);
          }}
          onApproveClick={() => {
            hideTooltip(DELETE_TOOLTIP_ID);
            handleDelete(onDelete)}
          }
        />
        <ButtonOutlined disabled={buttonsDisabled} onClick={onDuplicate} hide= {isNonProduct}>
          Duplicate
        </ButtonOutlined>
        <ButtonOutlined disabled={buttonsDisabled} onClick={onViewUsage}>
          View Usage
        </ButtonOutlined>
        <ButtonContained disabled={buttonsDisabled || isReadOnly} onClick={onEdit}>
          Edit
        </ButtonContained>
      </>
    ),
    [FormMode.New]: GeneralButtons,
    [FormMode.Select]: GeneralButtons,
    [FormMode.Edit]: GeneralButtons,
    [FormMode.Duplicate]: GeneralButtons,
    [FormMode.SelectionView]: (
      <ButtonText onClick={() => (onClose ? onClose() : store.dispatch(closeModal()))}>Close</ButtonText>
    ),
    [FormMode.QuickView]: null,
  };
  return (
    <Footer>
      <div>
        {Object.keys(errors)
          .filter((field) => field !== 'name')
          .map((errorKey) => (
            <Error name={errorKey} key={errorKey} errors={errors} />
          ))}
      </div>
      <ButtonsContainer>{FormSectionToComponent[formMode]}</ButtonsContainer>
    </Footer>
  );
};
export default SetItemsSelectionFormFooter;
