import React from 'react';
import { CampaignListItemProps } from 'pages/reports/redemptions/components/redemptionsFilterBar/components/campaignSelection/components/campaignListItem/CampaignListItem.consts';
import StatusLabel from 'components/shared/statusLabel/StatusLabel';
import { ActionType } from 'utils/types';
import { Item, StyledIconButton, StyledLabelWrapper, StyledMinus, StyledPlus, Title } from './CampaignListItem.style';

const CampaignListItem = ({ externalId, title, status, action, disabled, onActionClick }: CampaignListItemProps) => {
  return (
    <Item>
      <Title disabled={disabled}>{`${externalId} - ${title}`}</Title>
      <StyledLabelWrapper disabled={disabled}>
        <StatusLabel type={status} />
      </StyledLabelWrapper>
      <StyledIconButton onClick={onActionClick} disabled={disabled}>
        {action === ActionType.Add ? (
          <StyledPlus name="plus" disabled={disabled} />
        ) : (
          <StyledMinus name="minus" disabled={disabled} />
        )}
      </StyledIconButton>
    </Item>
  );
};

export default CampaignListItem;
