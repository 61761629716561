import { FeatureGuard } from 'components/featureGuard/FeatureGuard';
import { Feature } from 'utils/types/features';
import { ButtonContained, ButtonOutlined } from 'components/shared/button';
import { StyledBulkIcon } from 'pages/campaigns/campaignManagement/Campaigns.style';
import { ApprovalStatus, ApprovalStatusLabel, GenericItem } from 'utils/types';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import React from 'react';
import { StyledApproveButton } from 'pages/shared/shared.style';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { hideTooltip } from 'utils/tooltip';
import { UserRole } from 'utils/types/users';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { useSelector } from 'react-redux';
import { users } from 'app/slices/users';
import { CampaignBulkAction, CampaignBulkActionsProps } from './CampaignBulkActions.consts';
import { StyledButtonDropdown } from './CampaignBulkActions.style';

const CampaignBulkActions = ({
  bulkAction,
  onBulkActionChange,
  onBulkActionSubmit,
  selectedCampaigns: { total, local, conflicted, notEligibleForSelfApproval, invalid },
  bulkUpdateInProgress,
}: CampaignBulkActionsProps) => {
  const { user } = useSelector(users);
  const validCampaigns = total - local - conflicted - notEligibleForSelfApproval - invalid;

  const onMenuActionSelect = (action: CampaignBulkAction) => {
    onBulkActionChange(action);
    const statusFilterValue =
      action === CampaignBulkAction.BulkSubmitForApproval ? ApprovalStatus.Draft : ApprovalStatus.PendingApproval;
    showToast(MessageType.Info, `A ${ApprovalStatusLabel[statusFilterValue]} status filter has been applied`);
  };

  const generateSkippedCampaignsMessage = () => {
    const skippedCampaigns = [];
    local > 0 && skippedCampaigns.push(`${local} ${local > 1 ? 'are' : 'is'} local`);
    conflicted > 0 && skippedCampaigns.push(`${conflicted} ${conflicted > 1 ? 'are' : 'is'} conflicted`);
    notEligibleForSelfApproval > 0 && skippedCampaigns.push(`${notEligibleForSelfApproval} cannot be self-approved`);
    invalid > 0 && skippedCampaigns.push(`${invalid} ${local > 1 ? 'are' : 'is'} invalid`);
    if (skippedCampaigns.length > 0) {
      let skippedCampaignMessage = `.\n${skippedCampaigns.join(', ')}.\n`;
      skippedCampaignMessage += validCampaigns ? `${validCampaigns} campaigns will` : 'No campaign can';
      return skippedCampaignMessage;
    }
    return '';
  };

  const generateTooltipMessage = () => {
    let message = `${total} campaigns are selected`;
    if (local || conflicted || notEligibleForSelfApproval || invalid) {
      message += generateSkippedCampaignsMessage();
    } else {
      message += ` and will`;
    }

    message += ` be ${bulkAction === CampaignBulkAction.BulkSubmitForApproval ? 'submitted for approval' : 'approved'}`;
    if (validCampaigns) {
      message += `\nAre you sure?`;
    }
    return message;
  };

  const onCampaignStatusChangeClick = async (action: CampaignBulkAction) => {
    const actionDescription =
      action === CampaignBulkAction.BulkSubmitForApproval ? 'submitted for approval' : 'approved';
    try {
      const {
        data: { bulkCampaignsStatusUpdate },
      } = await onBulkActionSubmit();

      const toastMessage = action === CampaignBulkAction.BulkSubmitForApproval ? 'submission for ' : '';
      !bulkCampaignsStatusUpdate?.isProcessed
        ? showToast(
            MessageType.Info,
            `Your campaign ${toastMessage}approval request has been received, we are working on it, and you'll be notified once it's ready.`,
          )
        : showToast(MessageType.Success, `Campaigns successfully ${actionDescription}`);
    } catch (e) {
      let message = `Campaigns failed to be ${actionDescription}`;
      if (e?.message?.includes('Failed to Bulk Approve')) {
        message = `One or more campaigns not deployed successfully`;
      }
      showToast(MessageType.Error, message);
    }
    onBulkActionChange(null);
  };

  return (
    <FeatureGuard features={[{ feature: Feature.CampaignBulkActions }]}>
      {bulkAction && (
        <ButtonOutlined data-automation-id="cancel-button" onClick={() => onBulkActionChange(null)}>
          Cancel
        </ButtonOutlined>
      )}
      {bulkAction === CampaignBulkAction.BulkApprove && (
        <>
          <StyledApproveButton
            data-for="approve-tooltip"
            data-automation-id="approve-button"
            data-tip
            disabled={bulkUpdateInProgress || total === 0}
            onClick={() => null}
          >
            Approve
          </StyledApproveButton>
          <Tooltip
            id="approve-tooltip"
            content={generateTooltipMessage()}
            onDisapproveClick={() => {
              hideTooltip('#approve-tooltip');
            }}
            approveMsg={validCampaigns ? 'Yes, Approve' : ''}
            disapproveMsg={validCampaigns ? 'No' : 'OK'}
            onApproveClick={() => {
              hideTooltip('#approve-tooltip');
              onCampaignStatusChangeClick(CampaignBulkAction.BulkApprove);
            }}
          />
        </>
      )}
      {bulkAction === CampaignBulkAction.BulkSubmitForApproval && (
        <>
          <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
            <ButtonContained
              data-for="submit-for-approval-tooltip"
              data-automation-id="submit-for-approval-button"
              data-tip
              onClick={() => null}
              disabled={bulkUpdateInProgress || total === 0}
            >
              Submit for Approval
            </ButtonContained>
          </RoleGuard>
          <Tooltip
            id="submit-for-approval-tooltip"
            content={generateTooltipMessage()}
            onDisapproveClick={() => {
              hideTooltip('#submit-for-approval-tooltip');
            }}
            approveMsg={validCampaigns ? 'Yes, Submit for Approval' : ''}
            disapproveMsg={validCampaigns ? 'No' : 'OK'}
            onApproveClick={() => {
              hideTooltip('#submit-for-approval-tooltip');
              onCampaignStatusChangeClick(CampaignBulkAction.BulkSubmitForApproval);
            }}
          />
        </>
      )}
      {!bulkAction && (
        <StyledButtonDropdown
          items={[
            {
              id: CampaignBulkAction.BulkSubmitForApproval,
              name: CampaignBulkAction.BulkSubmitForApproval,
              acceptedRoles: [UserRole.Trainee, UserRole.Creator, UserRole.Admin, UserRole.SysAdmin],
            },
            {
              id: CampaignBulkAction.BulkApprove,
              name: CampaignBulkAction.BulkApprove,
              acceptedRoles: [UserRole.Creator, UserRole.Admin, UserRole.SysAdmin],
            },
          ].filter((item) => item.acceptedRoles.includes(user.role))}
          onItemClick={(action: GenericItem) => onMenuActionSelect(action.id as CampaignBulkAction)}
          position="bottom-left"
          data-automation-id="bulk-actions-dropdown"
        >
          <StyledBulkIcon name="factCheck" />
        </StyledButtonDropdown>
      )}
    </FeatureGuard>
  );
};
export default CampaignBulkActions;
