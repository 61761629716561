class SSEService {
    public sse: EventSource | null;
  
    constructor() {
      this.sse = null;
    }
  
    openConnection(url: string) {
        try {
            if (!this.sse) {
                this.sse = new EventSource(url);
            }
        } catch (error) {
            console.error(`Error in openConnection: ${error}`);
            throw error;
        }
    }
    
    closeConnection = () => {
        try {
            if (this.sse) {
                this.sse.close();
                this.sse = null;
            }
        } catch (error) {
            console.error(`Error in closeConnection: ${error}`);
            throw error;
        }
    };
}
  
const sseService = new SSEService();
export default sseService;