import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { buildBaseAndFilters } from 'utils/serverFilters';

export enum ScheduleFilterType {
  Zone = 'zone',
  Period = 'period',
  Status = 'status',
  National = 'national'
}

export interface Schedule {
  filters?: any;
}

export const initialState: Schedule = {
  filters: {},
};

export const getFilters = (initialFilters: { [key in ScheduleFilterType]?: any[] }): any => {
  const ScheduleFiltersRecord = {
    [ScheduleFilterType.Zone]: { serverFilter: 'zoneId', operation: 'in' },
    [ScheduleFilterType.Period]: { serverFilter: 'periodId', operation: 'in' },
    [ScheduleFilterType.Status]: { serverFilter: 'approvalStatus', operation: 'in' },
  };
  return buildBaseAndFilters(ScheduleFiltersRecord, initialFilters);
};

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
  },
});

export const schedule = (state: RootState) => state.schedule;
export const { setFilters } = scheduleSlice.actions;

export default scheduleSlice.reducer;
