import styled from '@emotion/styled';
import Modal from 'components/shared/modal/Modal';
import { SearchTextField } from 'components/shared/searchTextField/SearchTextField';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';

export const CampaignsSelectionModal = styled(Modal)`
  width: 1080px;
  height: 85vh;
  padding: ${typography.unit * 5}px ${typography.unit * 8}px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CampaignsContainer = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionHeader = styled.div`
  font-weight: 500;
  margin-bottom: ${typography.unit * 4}px;
`;

export const SectionSelect = styled.div<StyledWithThemeProps>`
  width: 495px;
  padding: ${typography.unit * 3}px;
  border: ${({ theme }) => `1px solid ${theme.colors.global.border};`};
  border-radius: ${typography.borderRadius}px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StyledSearchTextField = styled(SearchTextField)`
  max-width: 465px;
`;

export const Footer = styled.footer`
  display: flex;
  margin-top: ${typography.unit * 5}px;
  justify-content: flex-end;
  gap: ${typography.unit * 8}px;
`;

export const CampaignsList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;
