import React, { useMemo, useState } from 'react';
import { ApprovalStatus } from 'utils/types';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { Tags } from 'components/shared/tags/Tags';
import { ImageItemProps } from './ImageItem.consts';
import {
  Actions,
  Container,
  ImageBackdrop,
  ImageName,
  ImageWrapper,
  StyledButtonText,
  StyledIcon,
  StyledImage,
  StyledView,
  StyledArchiveIcon,
  StyledStatusLabel,
  ImageId,
  ImgContainer,
  ImageNameWrapper,
} from './ImageItem.style';
import NewLockIcon from 'pages/shared/lockIcon/NewLockIcon';

const ImageItem = ({ image, className, viewImage, editImage, archiveImage, unarchiveImage }: ImageItemProps) => {
  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);
  const id = useMemo(() => Math.random().toString(8), [image.updatedAt]);

  return (
    <Container className={className} data-automation-id="image-container">
      <ImageWrapper
        onMouseEnter={() => setShowBackdrop(true)}
        onMouseLeave={() => setShowBackdrop(false)}
        onClick={() => viewImage(image)}
      >
        {image.isArchive && <StyledStatusLabel type={ApprovalStatus.Archived} />}
        <ImageId>ID {image.id}</ImageId>
        <ImgContainer>
          <StyledImage
            src={`${image.file}?${id}`}
            alt={image.name}
            data-automation-id="image"
            crossOrigin="anonymous"
          />
        </ImgContainer>
        {showBackdrop && (
          <ImageBackdrop data-automation-id="backdrop">
            <Actions data-automation-id="actions">
              <StyledButtonText onClick={() => viewImage(image)} data-title="View">
                <StyledView name="view" />
              </StyledButtonText>
              {!image.isLocked && (
                <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                  {image.isArchive ? (
                    <StyledButtonText
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        unarchiveImage(image);
                      }}
                      data-title="Unarchive"
                    >
                      <StyledArchiveIcon name="unarchive" />
                    </StyledButtonText>
                  ) : (
                    <>
                      <StyledButtonText
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          editImage(image);
                        }}
                        data-title="Edit"
                      >
                        <StyledIcon name="edit" />
                      </StyledButtonText>
                      <StyledButtonText
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          archiveImage(image);
                        }}
                        data-title="Archive"
                      >
                        <StyledArchiveIcon name="archive" />
                      </StyledButtonText>
                    </>
                  )}
                </RoleGuard>
              )}
            </Actions>
            <Tags id={image.id} tags={image.tags} />
          </ImageBackdrop>
        )}
      </ImageWrapper>
      <ImageNameWrapper>
        <ImageName title={image.name}>{image.name}</ImageName>
        {image.isLocked && <NewLockIcon dataFor='image-lock-icon' />}
      </ImageNameWrapper>
    </Container>
  );
};

export default ImageItem;
