import React, { useEffect } from 'react';

const SESSION_TIMEOUT = 900000; //Idle time duration in milliseconds
const SESSION_TIMEOUT_KEY = 'sessionTimeout';

let timeout: any;
let lastActiveTime: number = Date.now();

function resetTimeout() {
  clearTimeout(timeout);
  lastActiveTime = Date.now();
  localStorage.removeItem(SESSION_TIMEOUT_KEY);
  timeout = setTimeout(checkIdleTime, SESSION_TIMEOUT);
  localStorage.setItem(SESSION_TIMEOUT_KEY, String(lastActiveTime));
}

function checkIdleTime() {
  const storedTime = localStorage.getItem(SESSION_TIMEOUT_KEY);
  const storedTimeNumber = storedTime ? parseInt(storedTime, 10) : null;
  const currentTime = Date.now();

  if (storedTimeNumber && (currentTime - storedTimeNumber) >= SESSION_TIMEOUT) {
    broadcastLogout();
  }
}

function logout() {
  // Clear the local storage and perform the logout action
  localStorage.removeItem(SESSION_TIMEOUT_KEY);
  document.location.href = '/logout';
}


function broadcastLogout() {
  // Send a message using the Broadcast Channel to notify other tabs to perform logout
  const broadcastChannel = new BroadcastChannel('sessionTimeoutChannel');
  broadcastChannel.postMessage('logout');
  broadcastChannel.close();
}

function SessionTimeOut() {
  useEffect(() => {
    resetTimeout();

    // Add event listeners to detect user activity and visibility change
    document.addEventListener('mousemove', resetTimeout);
    document.addEventListener('keydown', resetTimeout);
    document.addEventListener('click', resetTimeout);
    // Register a Broadcast Channel to communicate between tabs
    const broadcastChannel = new BroadcastChannel('sessionTimeoutChannel');

    broadcastChannel.addEventListener('message', (event) => {
      if (event.data === 'logout') {
        logout();
      }
    });

    return () => {
      // Clean up the event listeners and broadcast channel when the component is unmounted
      document.removeEventListener('mousemove', resetTimeout);
      document.removeEventListener('keydown', resetTimeout);
      document.removeEventListener('click', resetTimeout);
      broadcastChannel.close();
    };
  }, []);

  return <></>;
}

export default SessionTimeOut;
