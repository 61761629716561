import styled from '@emotion/styled';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import Modal from 'components/shared/modal/Modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Icon, IconProps } from 'components/shared/icon';
import { ButtonText } from 'components/shared/button';

export const StyledModal = styled(Modal)`
  width: 1030px;
`;

export const ImagesFilterBar = styled.div`
  display: flex;
  margin: 0 0 ${typography.unit * 4}px 0;
  gap: ${typography.unit * 10}px;
`;

export const ImagesList = styled(InfiniteScroll)`
  display: flex;
  flex-wrap: wrap;
  gap: ${typography.unit * 3}px;
  padding: ${typography.unit * 3}px;
  overflow-y: auto;
`;

export const ImagesContainer = styled.div<StyledWithThemeProps>`
  border: ${({ theme }) => `1px solid ${theme.colors.global.border}`};
  overflow: auto;
  height: 60vh;
  display: flex;
`;

export const ImageItem = styled.div<StyledWithThemeProps & { selected: boolean }>`
  width: 180px;
  height: 240px;
  border: ${({ theme, selected }) => `1px solid ${selected ? 'blue' : theme.colors.global.border}`};
  border-radius: ${typography.borderRadius}px;
  padding: ${typography.unit}px ${typography.unit * 2}px;
  box-shadow: ${({ theme, selected }) => selected && theme.colors.global.boxShadow};
  display: flex;
  flex-direction: column;

  img {
    width: 160px;
    height: 170px;
    object-fit: scale-down;
    margin-bottom: ${typography.unit * 2}px;
    white-space: nowrap;
  }

  button{
  
  position: relative;
    top: ${typography.unit}px;
    left: ${typography.unit * 15}px;
    opacity: 0; /* Initially hidden */
    transition: opacity ${typography.transition}s ease;
  
  }
   &:hover  {
    button {
      opacity: 1;
    }
  }

  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const StyledIcon = styled(Icon) <IconProps>`
  width: 18px;
  fill: #fff;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
  transition: all ${typography.transition}s ease;
  :hover {
    fill: ${({ theme }) => theme.colors.global.inputPrimary};
  }
`;

export const StyledViewImageSelection = styled(StyledIcon)`
 width: 24px;

`;


export const StyledImageButtonText = styled(ButtonText)`
 position:relative;

`;
export const ImageSelectionFormFooter = styled.footer`
  display: flex;
  margin-top: ${typography.unit * 11}px;
  justify-content: flex-end;
  gap: ${typography.unit * 5}px;
`;

export const TagsSelectbox = styled(Selectbox)`
  min-width: 200px;
`;

export const NoImages = styled.div`
  margin: ${typography.unit * 10}px auto;
  display: flex;
`;
