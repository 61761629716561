import React from 'react';
import { ToggleSwitchProps } from './ToggleSwitch.consts';
import { Ball, Slide, StyledLabel, ToggleSwitchContainer } from './ToggleSwitch.styles';

const ToggleSwitch = ({ label, checked, disabled, size = 'medium', className, onClick, labelPosition = 'before' }: ToggleSwitchProps) => {
  return (
    <ToggleSwitchContainer disabled={disabled} className={className} onClick={onClick} data-automation-id="toggle">
      {label && labelPosition === 'before' && <StyledLabel disabled={disabled} labelPosition={labelPosition}>{label}</StyledLabel>}
      <Slide checked={checked} disabled={disabled} size={size}>
        <Ball checked={checked} disabled={disabled} size={size} />
      </Slide>
      {label && labelPosition === 'after' && <StyledLabel disabled={disabled} labelPosition={labelPosition}>{label}</StyledLabel>}
    </ToggleSwitchContainer>
  );
};

export default ToggleSwitch;
