import styled from '@emotion/styled';
import typography from 'styles/typography';
import Error from 'components/shared/error/Error';
import { StyleTimePickerProps } from './TimePicker.consts';

export const StyledTimeContainer = styled.div<StyleTimePickerProps>`
  display: flex;
  flex-direction: ${({ labelIsHorizontal }) => labelIsHorizontal && 'column'};
  position: relative;

  .MuiInputBase-root {
    font-size: 13px;
    max-width: fit-content;
    color: ${({ theme }) => theme.colors.text.primary} !important;

    &:hover:before {
      border-bottom: 1px solid ${({ theme }) => theme.colors.text.disabled} !important;
    }

    &:before {
      border-bottom-style: solid !important;
      border-color: ${({ theme }) => theme.colors.global.border};
    }

    &:after {
      border-bottom: 1px solid ${({ theme }) => theme.colors.button.hover} !important;
    }

    input {
      width: ${({ withAmPm }) => (withAmPm ? '60px' : '45px')};
    }

    button {
      width: 20px;

      &:disabled .MuiSvgIcon-root {
        fill: ${({ theme }) => theme.colors.checkbox.disabledCheckedBg};
      }

      &:hover {
        background-color: initial;
      }

      .MuiSvgIcon-root {
        fill: ${({ theme }) => theme.colors.global.inputPrimary};
        width: 18px;

        &:hover {
          fill: ${({ theme }) => theme.colors.button.hover};
        }
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }
`;

export const StyledLabel = styled.label<StyleTimePickerProps>`
  text-transform: capitalize;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text.primary};
  display: flex;
  align-items: center;
  margin-right: ${({ labelIsHorizontal }) => !labelIsHorizontal && `${typography.unit * 3}px`};
  margin-bottom: ${({ labelIsHorizontal }) => labelIsHorizontal && `${typography.unit}px`};
`;

export const StyledError = styled(Error)`
  position: absolute;
  top: ${typography.unit * 9}px;
`;
