import { TagDefault, TagsValidEntities } from 'utils/types/tags';

export enum TagFilterType {
  EntityType = 'entityType',
  Default = 'default',
  SearchQuery = 'searchQuery',
}

export const TagDefaultOptions = [
  { id: TagDefault.Default, name: 'Default' },
  { id: TagDefault.NonDefault, name: 'Non-Default' },
];

export interface TagProps {
  id: number;
  type: TagsValidEntities;
  name: string;
  amount?: string;
  amountByEntities?: any;
  isMasterTag?: boolean;
  validEntities?: [TagsValidEntities];
  createdBy: string;
  isLocked?: boolean;
}
