import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { generateCampaignStatusFilters, generateOfferStatusFilters } from 'utils/serverFilters';
import { ApprovalStatus } from 'utils/types';

export enum UsageStateId {
  CampaignUsage = 'campaignUsage',
  OfferUsage = 'offerUsage',
}
export enum UsageEntityType {
  ProductSet = 'productSet',
  LocationSet = 'locationSet',
  Offer = 'offer',
  TermCondition = 'termCondition',
}

export const UsageEntityTypeLabel: Record<UsageEntityType, string> = {
  [UsageEntityType.LocationSet]: 'Set',
  [UsageEntityType.ProductSet]: 'Set',
  [UsageEntityType.Offer]: 'Offer',
  [UsageEntityType.TermCondition]: 'TermCondition',
};
export type UsageGenericSlice = {
  usageState: (state: RootState) => any;
  reducer: any;
  actions: any;
  getFilters: any;
};
export enum UsageFiltersEum {
  Status = 'status',
  EntityId = 'entityId',
  EntityType = 'entityType',
}

export interface UsageFilters {
  [UsageFiltersEum.EntityId]: number;
  [UsageFiltersEum.Status]: ApprovalStatus[];
  [UsageFiltersEum.EntityType]: string;
}

export interface GenericUsageFilters {
  filters: UsageFilters;
}
export const initialState: GenericUsageFilters = {
  filters: {
    entityId: null,
    status: [],
    entityType: '',
  },
};

const filterGenerationFunctions: { [key: string]: (initialFilters: any) => Object } = {
  [UsageStateId.OfferUsage]: generateOfferStatusFilters,
  [UsageStateId.CampaignUsage]: generateCampaignStatusFilters,
};

const addMatchFilterForEntityType = (filters: any, field: string, value: any) => {
  filters.bool.must.push({
    match: {
      [field]: value,
    },
  });
};

const generateTermFilters = (fieldPrefix: string, termId: number): any => {
  return {
    bool: {
      should: [
        { match: { [`${fieldPrefix}.term.id`]: termId } },
        { match: { [`${fieldPrefix}.term.originalTermId`]: termId } },
      ],
    },
  };
};

export const createUsageGenericSlice = (sliceName: UsageStateId): UsageGenericSlice => {
  const getFilters = (initialFilters: UsageFilters): any => {
    const { entityId, status, entityType } = initialFilters;

    const filters: any = {
      bool: {
        must: [],
      },
    };

    switch (entityType) {
      case UsageEntityType.ProductSet:
        addMatchFilterForEntityType(filters, 'productsFromSets.productSetExternalId', entityId);
        break;
      case UsageEntityType.LocationSet:
        addMatchFilterForEntityType(filters, 'restaurantEligibility.restaurantGroups.id', entityId);
        addMatchFilterForEntityType(filters, 'restaurantEligibility.restaurantGroups.type', 'custom');
        break;
      case UsageEntityType.Offer:
        addMatchFilterForEntityType(filters, 'offerVersion.baseOfferId', entityId);
        break;
      case UsageEntityType.TermCondition:
        if (sliceName === UsageStateId.CampaignUsage) {
          filters.bool.must.push(generateTermFilters('offerVersion', entityId));
        } else if (sliceName === UsageStateId.OfferUsage) {
          filters.bool.must.push(generateTermFilters('versions', entityId));
        }
        break;
    }

    if (status?.length) {
      const statusFilter: any = filterGenerationFunctions[sliceName]?.(initialFilters) || {};
      if (Object.keys(statusFilter).length > 0) {
        filters.bool.must.push({ bool: { should: statusFilter } });
      }
    }
    return filters;
  };

  const usageSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      setFilter(state, action) {
        state.filters = {
          ...state.filters,
          [action.payload.filter]: action.payload.value,
        };
      },
      resetFilters(state) {
        state.filters = initialState.filters;
      },
    },
  });

  const usageState = (state: RootState) => state.usage[sliceName];
  return {
    usageState,
    reducer: usageSlice.reducer,
    actions: usageSlice.actions,
    getFilters,
  };
};

export const campaignUsage: UsageGenericSlice = createUsageGenericSlice(UsageStateId.CampaignUsage);
export const offerUsage: UsageGenericSlice = createUsageGenericSlice(UsageStateId.OfferUsage);

const usageReducer = combineReducers({
  [UsageStateId.CampaignUsage]: campaignUsage.reducer,
  [UsageStateId.OfferUsage]: offerUsage.reducer,
});

export default usageReducer;
