import React, { useState } from 'react';

export const usePersistCaretPosition = (initialValue: string): [number, (e: React.ChangeEvent) => void] => {
  const [caretPosition, setCaretPosition] = useState(initialValue?.length ?? 0);

  return [
    caretPosition,
    (e: React.ChangeEvent) => {
      const target = e.nativeEvent.target as HTMLInputElement;
      setCaretPosition(target.selectionStart);
    },
  ];
};
