import { OrderDirection } from 'utils/types';
import { Tag } from 'utils/types/tags';

export enum ImageFilterType {
  Archive = 'archive',
  Tags = 'tags',
  SearchQuery = 'searchQuery',
  Id = 'id',
}

export const SortBy: { [key: string]: { [key: string]: OrderDirection } } = {
  'Last updated': {
    updatedAt: OrderDirection.DESC,
  },
  'A - Z': {
    name: OrderDirection.ASC,
  },
  'Z - A': {
    name: OrderDirection.DESC,
  },
};

export interface UploadedImageProps {
  name: string;
  file: File;
  src: string;
  fileExtension: string;
}

export interface ImageProps {
  id?: number;
  name: string;
  description?: string;
  file?: string;
  fileName?: string;
  src?: string;
  fileExtension?: string;
  tags?: Tag[];
  updatedAt?: string;
  isArchive?: boolean;
  isLocked?: boolean;
  isBonusCampaign?: boolean;
}

export interface ImagePropsToSave {
  id?: number;
  name?: string;
  description?: string;
  file?: string;
  fileExtension?: string;
  tags?: (string | number)[];
  isArchive?: boolean;
  isBonusCampaign: boolean;
}
