import styled from '@emotion/styled';
import ButtonBase from '../buttonBase/ButtonBase';

export const StyledButtonBase = styled(ButtonBase)`
  color: ${({ theme }) => theme.colors.text.white};
  background-color: ${({ theme }) => theme.colors.global.inputPrimary};
  border: none;
  height: 32px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.button.hover};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.button.disabled};
  }
`;
