import styled from '@emotion/styled';
import { Icon, IconProps } from 'components/shared/icon';
import { ButtonText } from 'components/shared/button';
import { appTheme } from 'styles/themes/defaultTheme';
import typography from 'styles/typography';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  line-height: 30px;
`;

export const Title = styled.div<{ disabled: boolean; theme?: appTheme }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px;
  flex: 1;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.text.disabled : theme.colors.text.primary)};
`;

export const Action = styled.span<{ disabled: boolean }>`
  display: flex;
  width: 24px;
  justify-content: end;
`;

export const StyledIconButtonText = styled(ButtonText)`
  width: 14px;
  height: 24px;
`;

export const StyledIcon = styled(Icon)<IconProps & { disabled: boolean }>`
  fill: ${({ disabled, theme }) => (disabled ? theme.colors.global.inputDisabled : theme.colors.global.inputPrimary)};
  width: 10px;
`;

export const StyledSetTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledWholeGroupIcon = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.text.secondary};
  width: 14px;
  margin-left: ${typography.unit}px;
  vertical-align: middle;
`;

export const SetNameContainer = styled.span`
  overflow: hidden;
  width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SetChildrenCounter = styled.span`
  font-size: 13px;
`;
