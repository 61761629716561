import { appTheme } from 'styles/themes/defaultTheme';
import { Control } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form';
import { createTheme } from '@mui/material/styles';

export interface DatePickerProps {
  name: string;
  errors?: FieldErrors | string;
  validation?: Record<string, unknown>;
  control?: Control;
  label?: string;
  labelIsHorizontal?: boolean;
  disabled?: boolean;
  disablePast?: boolean;
  minDate?: Date;
  maxDate?: Date;
  value?: Date;
  onChange?: (date: any) => void;
  className?: string;
  theme?: appTheme;
}

export interface StyleDatePickerProps {
  disabled?: boolean;
  error?: boolean;
  labelIsHorizontal?: boolean;
  theme?: appTheme;
}

export const materialTheme = createTheme({
  palette: {
    primary: { main: '#1F5CF6' },
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Proxima Nova,-apple-system,sans-serif !important',
  },
});
