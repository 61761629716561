import styled from '@emotion/styled';
import zIndex from 'styles/zIndex';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import { StyledSubNavProps } from './TabStrip.consts';

export const StyledNav = styled.nav<StyledWithThemeProps>`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  margin-right: auto;
`;

export const StyledSubNav = styled.nav<StyledSubNavProps>`
  display: flex;
  align-items: center;
  height: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  width: 100vw;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  position: absolute;
  top: 100%;
  background-color: ${({ theme }) => theme.colors.global.background};
  z-index: ${zIndex.dropdown};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  transition: all ${typography.transition}s ease-out;
`;
