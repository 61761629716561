export enum UserManagementFilterType {
  Company = 'company',
  Role = 'role',
  Notifications = 'notifications',
  SearchQuery = 'searchQuery',
}

export interface UrlFilters {
  filter?: (name: string, value: any) => void;
  params?: any;
}

export const Companies = [
  { id: 'MCD', name: 'MCD' },
  { id: 'Other', name: 'Other' },
];
