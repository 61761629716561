import styled from '@emotion/styled';
import ButtonBase from '../buttonBase/ButtonBase';

export const StyledButtonBase = styled(ButtonBase)`
  color: ${({ theme }) => theme.colors.global.inputPrimary};
  background-color: ${({ theme }) => theme.colors.text.white};
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  height: 32px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.global.inputPrimary};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.button.disabled};
    border-color: ${({ theme }) => theme.colors.global.inputDisabled};
  }
`;
