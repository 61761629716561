import styled from '@emotion/styled';
import ButtonBase from '../buttonBase/ButtonBase';

export const StyledButtonBase = styled(ButtonBase)`
  color: ${({ theme }) => theme.colors.global.inputPrimary};
  background-color: transparent;
  border: none;
  padding: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.button.hover};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`;
