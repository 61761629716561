import styled from '@emotion/styled';
import typography from 'styles/typography';
import zIndex from 'styles/zIndex';
import { StyledWithThemeProps } from 'utils/types';

export const LoginPage = styled.div<StyledWithThemeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &:before {
    background-color: ${({ theme }) => theme.colors.global.brandBackdrop};
    mask-image: url('/assets/mcdLogo.svg');
    mask-repeat: no-repeat;
    mask-position: center center;
    content: '';
    position: absolute;
    width: 70vw;
    height: 70vh;
  }
`;

export const LoginContainer = styled.div<StyledWithThemeProps>`
  width: 450px;
  height: 500px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1), 0px 25.6px 57.6px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.global.background};
  z-index: ${zIndex.hoverBackdrop};

  p {
    text-align: center;
  }
`;

export const LoginContainerHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

export const LoginContainerBody = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  height: 100%;
  padding: 0 ${typography.unit * 22}px;
`;

export const Error = styled.div<StyledWithThemeProps>`
  min-height: ${typography.unit * 5}px;
  color: ${({ theme }) => theme.colors.global.error};
  text-align: center; 
`;
