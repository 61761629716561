import React from 'react';
import { Controller } from 'react-hook-form';
import { ControlledCheckboxProps } from 'components/shared/controlledCheckbox/ControlledCheckboxProps.consts';
import Checkbox from 'components/shared/checkbox/Checkbox';

const ControlledCheckbox = ({
  label,
  disabled,
  control,
  name,
  defaultValue,
  onChange,
  className,
}: ControlledCheckboxProps) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      shouldUnregister
      render={({ field }) => (
        <Checkbox
          label={label}
          checked={Boolean(field.value)}
          disabled={disabled}
          onClick={() => {
            field.onChange(!field.value);
            if (onChange) {
              onChange(!field.value);
            }
          }}
          className={className}
        />
      )}
    />
  );
};

export default ControlledCheckbox;
