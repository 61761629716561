import styled from '@emotion/styled';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';

export const Filters = styled.div<StyledWithThemeProps>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.global.border};
  padding: ${typography.unit * 2}px 0;
  margin-bottom: ${typography.unit * 2}px;
`;

export const StyledSelect = styled(Selectbox)`
  margin-right: ${typography.unit * 6}px;
` as unknown as typeof Selectbox;
