import { FormMode } from 'utils/types';
import { AuthType, User } from 'utils/types/users';

export interface UserManagementModalProps {
  user?: User;
  mode?: FormMode;
}

export const Companies = [
  {
    value: 'MCD',
    label: 'MCD',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const AuthTypes = [
  {
    label: 'McD GAS',
    value: AuthType.GAS,
  },
  {
    label: 'VCE',
    value: AuthType.VCE,
  },
];
