import React, { useEffect, useState } from 'react';
import { ActionType, OrderDirection } from 'utils/types';
import { ButtonContained, ButtonText } from 'components/shared/button';
import { closeModal } from 'app/slices/modals';
import { store } from 'app/store';
import { useSelector } from 'react-redux';
import {
  CampaignsContainer,
  CampaignsList,
  CampaignsSelectionModal,
  Container,
  Footer,
  Section,
  SectionHeader,
  SectionSelect,
  StyledSearchTextField,
} from 'pages/reports/redemptions/components/redemptionsFilterBar/components/campaignSelection/CampaignSelection.style';
import {
  AvailableStatuses,
  CampaignSelectionProps,
} from 'pages/reports/redemptions/components/redemptionsFilterBar/components/campaignSelection/CampaignSelection.consts';
import { CampaignFilterType, CampaignProps } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import CampaignListItem from 'pages/reports/redemptions/components/redemptionsFilterBar/components/campaignSelection/components/campaignListItem/CampaignListItem';
import CampaignFilterBar from 'pages/reports/redemptions/components/redemptionsFilterBar/components/campaignSelection/components/campaignFilterBar/CampaignFilterBar';
import { campaignsSelection } from 'app/genericSlices/campaigns';
import { buildObjectBy } from 'utils/mapping';
import { useQuery } from '@apollo/client';
import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import { StyledLoader } from 'pages/shared/shared.style';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import { Virtuoso } from 'react-virtuoso';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { hideTooltip } from 'utils/tooltip';
import ReactTooltip from 'react-tooltip';
import { FetchPolicies } from 'utils/types/common';

const CampaignSelection = ({ selectedCampaigns = [], maxSelections, onSave }: CampaignSelectionProps) => {
  const { filters } = useSelector(campaignsSelection.campaignsState);
  const [isDirty, setIsDirty] = useState(false);
  const { data, loading, fetchMore } = useQuery(campaignsGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: campaignsSelection.getFilters(filters),
        order: { externalId: OrderDirection.DESC },
        limit: 25,
      },
    },
  });
  const {
    getCampaigns: { items: campaigns },
  } = data || { getCampaigns: { items: undefined } };
  const [selectedCampaignsRecord, setSelectedCampaignsRecord] = useState(
    buildObjectBy('externalId', selectedCampaigns),
  );
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const { data: totalData, previousData: previousTotalData } = useQuery(campaignsGqls.queries.getTotalCount, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    variables: {
      data: {
        filters: campaignsSelection.getFilters({
          ...filters,
          [CampaignFilterType.ExcludedExternalIds]: Object.keys(selectedCampaignsRecord).map(Number),
        }),
        limit: 1,
      },
    },
  });
  const totalAvailable = (totalData || previousTotalData)?.getCampaigns?.total ?? 0;

  const addSelected = (campaign: CampaignProps) => {
    setSelectedCampaignsRecord({
      ...selectedCampaignsRecord,
      [campaign.externalId]: (({ id, externalId, title, status }) => ({ id, externalId, title, status }))(campaign),
    });
    setIsDirty(true);
  };

  const removeSelected = (campaignId: number) => {
    const newSelectedCampaignsRecord = { ...selectedCampaignsRecord };
    delete newSelectedCampaignsRecord[campaignId];
    setSelectedCampaignsRecord({
      ...newSelectedCampaignsRecord,
    });
    setIsDirty(true);
  };

  const save = () => {
    onSave(Object.values(selectedCampaignsRecord));
    store.dispatch(closeModal());
  };

  useEffect(() => {
    if (campaigns) {
      setFilteredCampaigns(
        campaigns.filter((campaign: CampaignProps) => !selectedCampaignsRecord[campaign.externalId]),
      );
    }
  }, [campaigns, selectedCampaignsRecord]);

  useEffect(() => {
    store.dispatch(
      campaignsSelection.actions.setFilters({
        [CampaignFilterType.Status]: AvailableStatuses,
      }),
    );
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isDirty]);

  return (
    <CampaignsSelectionModal title="Campaign Selection" ignoreOperations={['Campaigns']}>
      <Container>
        <CampaignsContainer>
          <Section>
            <SectionHeader>{`Available Campaigns ${totalAvailable ? `(${totalAvailable})` : ''}`}</SectionHeader>
            <SectionSelect>
              <StyledSearchTextField
                name="available-campaigns-search"
                onChange={(e) => {
                  store.dispatch(
                    campaignsSelection.actions.setFilter({
                      filter: [CampaignFilterType.SearchQuery],
                      value: [e.target.value],
                    }),
                  );
                  store.dispatch(
                    campaignsSelection.actions.setOrder(
                      e.target.value?.length ? { _score: OrderDirection.DESC } : { externalId: OrderDirection.DESC },
                    ),
                  );
                }}
              />
              <CampaignFilterBar />
              <CampaignsList>
                <Virtuoso
                  id="virtuoso-items-list"
                  data={filteredCampaigns?.length ? filteredCampaigns : []}
                  endReached={() =>
                    !loading &&
                    fetchMore({
                      variables: {
                        data: {
                          filters: campaignsSelection.getFilters(filters),
                          order: { externalId: OrderDirection.DESC },
                          offset: campaigns?.length ?? 0,
                          limit: 25,
                        },
                      },
                    })
                  }
                  components={{ Footer: () => (loading ? <StyledLoader size={LoaderSize.Small} /> : <></>) }}
                  itemContent={(index, campaign) => (
                    <CampaignListItem
                      key={`${campaign.externalId}_available`}
                      externalId={campaign.externalId}
                      title={campaign.title}
                      status={campaign.status}
                      action={ActionType.Add}
                      onActionClick={() => addSelected(campaign)}
                      disabled={Object.keys(selectedCampaignsRecord).length >= maxSelections}
                    />
                  )}
                />
              </CampaignsList>
            </SectionSelect>
          </Section>
          <Section>
            <SectionHeader>{`Selected Campaigns (${Object.keys(selectedCampaignsRecord).length})`}</SectionHeader>
            <SectionSelect>
              {Object.values(selectedCampaignsRecord)?.map((campaign, index) => (
                <CampaignListItem
                  key={`${campaign.externalId}_${index}_selected`}
                  externalId={campaign.externalId}
                  title={campaign.title}
                  status={campaign.status}
                  action={ActionType.Remove}
                  onActionClick={() => removeSelected(campaign.externalId)}
                  disabled={false}
                />
              ))}
            </SectionSelect>
          </Section>
        </CampaignsContainer>
        <Footer>
          <ButtonText
            {...(isDirty ? { 'data-tip': true, onClick: () => null } : { onClick: () => store.dispatch(closeModal()) })}
            data-for="cancel-tooltip"
          >
            Cancel
          </ButtonText>
          <ButtonContained
            disabled={
              !Object.keys(selectedCampaignsRecord).length ||
              Object.keys(selectedCampaignsRecord).length > maxSelections
            }
            onClick={save}
          >
            Save
          </ButtonContained>
          <Tooltip
            id="cancel-tooltip"
            content="Are you sure you want to cancel?"
            onDisapproveClick={() => {
              hideTooltip('#cancel-tooltip');
            }}
            onApproveClick={() => store.dispatch(closeModal())}
          />
        </Footer>
      </Container>
    </CampaignsSelectionModal>
  );
};

export default CampaignSelection;
