import styled from '@emotion/styled';
import { StyledWithThemeProps } from 'utils/types';
import typography from 'styles/typography';
import { Icon } from '../../icon';
import { StyledNotificationWithTheme } from '../notifications';

export const StyledMessageContainer = styled.div<StyledWithThemeProps>`
  display: flex;
  width: 100%;
  font-weight: 400;
  font-size: ${typography.fontSize}px;
  color: ${({ theme }) => theme.colors.text.primary};
  align-items: center;
`;

export const StyledIconMessage = styled(Icon)<StyledNotificationWithTheme>`
  width: 16px;
  margin-right: ${typography.unit * 2}px;
  fill: ${({ type, theme }) => theme.colors.notifications.primary[type]};
`;

export const StyledContent = styled.div`
  flex: 1;
`;
