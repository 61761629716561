import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { ButtonType } from 'components/shared/inputFiles/InputFiles.consts';
import InputFiles from 'components/shared/inputFiles/InputFiles';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { store } from 'app/store';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { getCampaignsImpactsByImageId, getOffersImpactsByImageId, updateImage } from 'utils/api/images';
import { FormMode, isNumber } from 'utils/types';
import { ButtonContained, ButtonOutlined, ButtonText } from 'components/shared/button';
import { closeModal, Modals, openModal } from 'app/slices/modals';
import { hasValue } from 'utils/text';
import { handleCampaignImpactChange } from 'utils/campaign';
import { hideTooltip } from 'utils/tooltip';
import { HeaderComponentType } from 'components/impact/impactModal/ImpactModal.consts';
import { handleOfferImpactChange } from 'utils/offer';
import InfoTooltip from 'components/shared/tooltip/info/InfoTooltip';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { ImageProps, ImagePropsToSave } from '../../Images.consts';
import {
  CheckboxWithTooltip,
  FileDetails,
  FileDetailsContainer,
  ImageFormContent,
  PropName,
  PropValue,
  StyledModal,
  StyledText,
} from './ImageForm.style';
import { ImageFormProps } from './ImageForm.consts';
import {
  archiveImageClicked,
  onTagCreation,
  preventFormSendOnEnter,
  readImages,
  unarchiveImageClicked,
} from '../../images.utils';

import {
  ImageContainer,
  ImageDescription,
  ImageName,
  ImagesFormContainer,
  ImageTags,
  ImageUploadContainer,
  StyledButtonsContainer,
  StyledImage,
} from '../shared.style';
import { FormContent } from '../imagesForm/ImagesForm.style';
import Checkbox from 'components/shared/checkbox/Checkbox';
import CopyButton from 'pages/shared/copyButton/CopyButton';
import { ValidationMessages } from 'utils/types/common';
import { isString } from 'lodash';

const ImageForm = ({ mode,
  image,
  onClose,
  className,
  editedImageSrc, viewOnly
}: ImageFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: image as any,
  });
  const [isBonusCampaign, setIsBonusCampaign] = useState(false);
  const [isFileChanged, setIsFileChanged] = useState(false);
  const { config } = useSelector(marketConfig);
  const { load: loadTags, tags } = useTagsQuery([TagsValidEntities.Image]);
  const [changedFile, setChangedFile] = useState<any>(editedImageSrc && image?.file);
  const [changedFileExtension, setChangedFileExtension] = useState<string>('');
  const [imgSrc, setImgSrc] = useState<string>(editedImageSrc || image?.file);
  const id = useMemo(() => Math.random().toString(8), [image.updatedAt]);
  const onImageUpdate = async (editedImage: ImagePropsToSave) => {
    try {
      editedImage.tags = editedImage.tags.map((tag: any) => (isNumber(tag)|| isString(tag) ? Number(tag) : Number(tag.id)));
      await updateImage(editedImage);
      showToast(MessageType.Success, 'Image updated successfully');
      store.dispatch(closeModal());
    } catch (e) {
      showToast(
        MessageType.Error,
        `Failed to update image${e.message.includes('already exists') ? ` - ${editedImage.name} already exists` : ''}`,
      );
    }
  };

  const onImageImpactClicked = async (img: ImageProps) => {
    const [offerImpacts, campaignImpacts] = await Promise.all([
      getOffersImpactsByImageId(img.id),
      getCampaignsImpactsByImageId(img.id),
    ]);

    if (offerImpacts.length || campaignImpacts.length) {
      const offerImpactProps = handleOfferImpactChange(offerImpacts);
      const campaignsImpactProps = handleCampaignImpactChange(campaignImpacts);
      store.dispatch(
        openModal({
          modal: Modals.ImpactModal,
          props: {
            title: 'View Usage',
            headerComponentType: HeaderComponentType.ImageUsageType,
            offerImpacts: offerImpactProps,
            campaignImpacts: campaignsImpactProps,
            entityType: 'Image',
            entityId: img.id,
            onCancel: () => store.dispatch(closeModal()),
          },
        }),
      );
    } else {
      showToast(MessageType.Info, `The image is not being used in campaigns or offers`);
    }
  };

  const onEditClicked = (img: ImageProps) =>
    store.dispatch(
      openModal({
        modal: Modals.ImageModal,
        props: {
          mode: FormMode.Edit,
          image,
          onClose: () => {
            reset(img);
            setIsBonusCampaign(image?.isBonusCampaign);
            store.dispatch(openModal({ modal: Modals.ImageModal, props: { mode: FormMode.View, image: img } }));
          },
        },
      }),
    );

  const saveNotification = async (editedImage: ImagePropsToSave) => {
    const [offerImpacts, campaignImpacts] = await Promise.all([
      getOffersImpactsByImageId(editedImage.id),
      getCampaignsImpactsByImageId(editedImage.id),
    ]);

    if (offerImpacts.length || campaignImpacts.length) {
      const offerImpactProps = handleOfferImpactChange(offerImpacts);
      const campaignsImpactProps = handleCampaignImpactChange(campaignImpacts);
      store.dispatch(
        openModal({
          modal: Modals.ImpactModal,
          props: {
            title: 'Save Notification',
            headerComponentType: HeaderComponentType.ImageSaveNotificationType,
            offerImpacts: offerImpactProps,
            campaignImpacts: campaignsImpactProps,
            entityType: 'Image',
            entityId: editedImage.id,
            onCancel: () => store.dispatch(closeModal()),
            onSubmit: () => onImageUpdate(editedImage),
            submitButtonString: 'Yes, Save',
          },
        }),
      );
    } else {
      await onImageUpdate(editedImage);
    }
  };

  const onSave = async (data: any) => {
    try {
      const { name, description, tags: iTags, isBonusCampaign: isBonusCampaign1 }: ImageProps = data;
      const tagsToSave = iTags as any as number[];
      const editedImage: any = {
        id: Number(image.id),
        name,
        description,
        tags: tagsToSave,
        isBonusCampaign: isBonusCampaign1,
      };

      if (changedFile) {
        editedImage.file = changedFile;
        editedImage.fileExtension = changedFileExtension;
        await saveNotification(editedImage);
      } else {
        await onImageUpdate(editedImage);
      }
    } catch (e) {
      showToast(MessageType.Error, `Failed to update image`);
    }
  };

  const onFileChange = async (files: FileList) => {
    const loadedImages = await readImages(files, config.bulkImagesUploadLimit);
    if (loadedImages && loadedImages[0]) {
      const { src, file, fileExtension } = loadedImages[0];
      setImgSrc(src);
      setChangedFile(file);
      setChangedFileExtension(fileExtension);
      setIsFileChanged(true);
    }
  };

  const shouldShowField = (fieldName: string) => {
    if (mode === FormMode.View && !viewOnly) {
      const fieldVal = getValues(fieldName);
      if (fieldVal && Array.isArray(fieldVal)) {
        return fieldVal.length > 0;
      }
      return fieldVal;
    }
    return true;
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isDirty]);

  useEffect(() => {
    loadTags();
  }, []);

  return (
    <StyledModal title={`${mode === FormMode.View ? 'View' : 'Edit'} Image ID ${image.id}`} isLocked={image?.isLocked}>
      <ImagesFormContainer
        className={className}
        onKeyDown={(e) => preventFormSendOnEnter(e)}
        data-automation-id="image-form"
      >
        <ImageFormContent>
          <ImageUploadContainer>
            <ImageContainer>
              <StyledImage
                src={isFileChanged ? imgSrc : `${imgSrc}?${id}`}
                alt={image.name}
                data-automation-id="image"
                crossOrigin="anonymous"
              />
            </ImageContainer>
            {mode === FormMode.Edit && (
              <InputFiles
                buttonType={ButtonType.Text}
                handleFiles={(files) => onFileChange(files)}
                multiple={false}
                filesType="image/*"
              >
                Upload Image
                <InfoTooltip
                  id="uploadTooltip"
                  content="Image size should be smaller than 100KB. Supported file types: png, jpg, jpeg, gif."
                />
              </InputFiles>
            )}
            <FileDetailsContainer>
              <FileDetails>
                <PropName>File Name</PropName>
                <PropValue>{image.fileName}</PropValue>
                <CopyButton textToCopy={image.fileName} />
              </FileDetails>
            </FileDetailsContainer>
          </ImageUploadContainer>
          <FormContent>
            <ImageName
              register={register}
              errors={errors}
              name="name"
              value={image?.name}
              label="Image Name"
              placeholder="Enter"
              disabled={mode === FormMode.View}
              validation={{
                required: ValidationMessages.RequiredField,
                maxLength: { value: 200, message: 'Up to 200 characters' },
                validate: (value: string) => (!hasValue(value) ? ValidationMessages.RequiredField : true),
              }}
              labelIsHorizontal
            />
            {shouldShowField('tags') && (
              <ImageTags
                key={`${Boolean(tags.length)}`}
                control={control}
                errors={errors}
                name="tags"
                enableTagHover={mode === FormMode.View ? true : false }
                label="Image Tags"
                placeholder="Select"
                disabled={false}
                multiple
                items={tags}
                maxItems={2}
                limit={10}
                withSearch
                withAmount
                onCreateOption={(tagName: string) => onTagCreation(tagName)}
                labelIsHorizontal
                reset
                validation={{
                  validate: (value: any) => (value.length > 10 ? 'Select a maximum of 10 tags' : true),
                }}
              />
            )}
            {shouldShowField('description') && (
              <ImageDescription
                register={register}
                errors={errors}
                name="description"
                label="Description"
                disabled={mode === FormMode.View}
                placeholder="Enter"
                validation={{ maxLength: { value: 200, message: 'Up to 200 characters' } }}
                rows={3}
                labelIsHorizontal
              />
            )}
            {!image?.isBonusCampaign ? (
              <CheckboxWithTooltip>
                <Checkbox
                  disabled={mode === FormMode.View}
                  checked={isBonusCampaign}
                  id="isBonusCampaign"
                  label="Bonus Campaign"
                  onClick={() => {
                    setIsBonusCampaign(!isBonusCampaign);
                    setValue('isBonusCampaign', !isBonusCampaign, { shouldDirty: true });
                  }}
                />
                <InfoTooltip
                  id="bonusCampaignTooltip"
                  className="test"
                  content="Bonus campaign images are instantly uploaded and made available, while regular offer images are only available after the offer is approved."
                />
              </CheckboxWithTooltip>
            ) : (
              <StyledText>Available for Bonus campaign</StyledText>
            )}
          </FormContent>
        </ImageFormContent>
        <StyledButtonsContainer>
          {mode === FormMode.View ? (
            <>
              <ButtonText onClick={() => !viewOnly ? store.dispatch(closeModal()) : onClose()}>Close</ButtonText>
              {!image.isLocked && !viewOnly && (
                <>
                  {image.isArchive ? (
                    <ButtonOutlined onClick={() => unarchiveImageClicked(image)}>Unarchive</ButtonOutlined>
                  ) : (
                    <>
                      <ButtonOutlined onClick={() => onImageImpactClicked(image)}>Image Usage</ButtonOutlined>
                      <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                        <>
                          <ButtonOutlined onClick={() => archiveImageClicked(image)}>Archive</ButtonOutlined>
                          <ButtonContained onClick={() => onEditClicked(image)}>Edit</ButtonContained>
                        </>
                      </RoleGuard>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <ButtonText
                {...(isDirty || editedImageSrc
                  ? { 'data-tip': true, onClick: () => null }
                  : { onClick: () => store.dispatch(closeModal()) })}
                data-for="cancel-tooltip"
              >
                Cancel
              </ButtonText>
              <Tooltip
                id="cancel-tooltip"
                content="Are you sure you want to cancel?"
                onDisapproveClick={() => {
                  hideTooltip('#cancel-tooltip');
                }}
                onApproveClick={() => store.dispatch(closeModal())}
              />
              <ButtonContained onClick={handleSubmit(onSave)} disabled={!isValid || (!isDirty && !changedFile)}>
                Save
              </ButtonContained>
            </>
          )}
        </StyledButtonsContainer>
      </ImagesFormContainer>
    </StyledModal>
  );
};

export default ImageForm;
