import styled from '@emotion/styled';
import typography from 'styles/typography';
import { ButtonText } from 'components/shared/button';
import { Icon, IconProps } from 'components/shared/icon';
import { StyledWithThemeProps } from 'utils/types';

export const Item = styled.div<StyledWithThemeProps>`
  display: flex;
  align-items: center;
  font-weight: 400;
  line-height: 30px;

  &:hover {
    background: ${({ theme }) => theme.colors.global.selectedBackground};
  }
`;

export const Title = styled.div<StyledWithThemeProps & { disabled: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.text.disabled : theme.colors.text.primary)};
`;

export const StyledLabelWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  width: 70px;
  opacity: ${({ disabled }) => disabled && 0.6};
`;

export const StyledIconButton = styled(ButtonText)`
  width: 14px;
  height: 24px;
  margin-left: ${typography.unit * 20}px;
`;

export const StyledPlus = styled(Icon)<IconProps & { disabled: boolean }>`
  fill: ${({ disabled, theme }) => (disabled ? theme.colors.text.disabled : theme.colors.global.inputPrimary)};
  width: 10px;
`;

export const StyledMinus = styled(Icon)<IconProps & { disabled: boolean }>`
  fill: ${({ disabled, theme }) => (disabled ? theme.colors.text.disabled : theme.colors.global.inputPrimary)};
  width: 12px;
`;
