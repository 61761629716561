import styled from '@emotion/styled/macro';
import typography from 'styles/typography';
import Banner from 'components/shared/notifications/banner/Banner';
import { StyledWithThemeProps } from 'utils/types';

export const StyledBanner = styled(Banner)<StyledWithThemeProps>`
  margin-bottom: ${typography.unit * 3}px;
`;

export const StyledBannerContent = styled.div<StyledWithThemeProps>`
  font-size: ${typography.fontSize}px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledSpan = styled.span`
  margin-bottom: ${typography.unit * 3}px;
  font-size: ${typography.fontSize}px;
`;
