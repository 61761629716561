import React from 'react';
import { useFormContext } from 'react-hook-form';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import { ErrorMessage } from '@hookform/error-message';

export const PriceOffValidationError = () => {
  const {
    formState: { errors },
  } = useFormContext();

  const productComboErrorIndex = findIndex(
    get(errors, 'versions.0.templateValues.offerTemplates', []) as any[],
    (o: any) => o !== null && o?.discountValue?.type === 'validate',
  );

  return (
    <>
      <ErrorMessage errors={errors} name="versions.0.templateValues.discountValue" />
      {productComboErrorIndex > -1 && (
        <ErrorMessage
          errors={errors}
          name={`versions.0.templateValues.offerTemplates.${productComboErrorIndex}.discountValue`}
        />
      )}
    </>
  );
};
