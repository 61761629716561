import React, { useEffect, useState } from 'react';
import { Impact } from '../impactItem/ImpactItem.consts';
import { ButtonContained } from 'components/shared/button';
import { downloadCSV } from 'utils/download';
import {
  StyledButtonOutlined,
  StyledButtonsContainer,
  StyledButtonText,
  StyledContentContainer,
  StyledModal,
} from './ImpactModal.style';
import { ImpactModalProps, ModalHeaderComponentMap, ModalInfoComponentMap } from './ImpactModal.consts';
import ImpactsTables from '../impactTables/ImpactsTables';
import { StyledSpan } from './shared.style';
import { getUsageReportRows } from 'utils/impact';

const ImpactModal = ({
  title,
  headerComponentType,
  infoComponentType,
  submitButtonString,
  onSubmit,
  onCancel,
  campaignImpacts,
  offerImpacts,
  imageImpacts,
  entityType,
  entityId,
  className,
}: ImpactModalProps) => {

  const [filteredCampaignImpacts, setfilteredCampaignImpacts] = useState<Impact[]>(campaignImpacts);
  const [filteredOfferImpacts, setfilteredOfferImpacts] = useState<Impact[]>(offerImpacts);
  const [filteredImageImpacts, setfilteredImageImpacts] = useState<Impact[]>(imageImpacts);

  const downloadImpactReport = () => {
    const rows = getUsageReportRows(filteredCampaignImpacts, filteredOfferImpacts, filteredImageImpacts);
    downloadCSV(rows, `UsageSummary_${entityType}_ID#${entityId}`);
  };

  const ModalHeaderComponent = ModalHeaderComponentMap[headerComponentType];
  const ModalInfoComponent = ModalInfoComponentMap[infoComponentType];

  return (
    <StyledModal title={title}>
      <StyledContentContainer className={className} data-automation-id="impact">
        {Boolean(ModalHeaderComponent) && <ModalHeaderComponent />}
        <ImpactsTables
          campaignImpacts={campaignImpacts}
          setCampaginImpacts={setfilteredCampaignImpacts}
          offerImpacts={offerImpacts}
          setOfferImpacts={setfilteredOfferImpacts}
          imageImpacts={imageImpacts}
          setImageImpacts={setfilteredImageImpacts}
          entityType={entityType}
        />
        {Boolean(ModalInfoComponent) && <ModalInfoComponent campaignImpacts={campaignImpacts} />}
        {onSubmit && <StyledSpan>Are you sure?</StyledSpan>}
        <StyledButtonsContainer data-automation-id="actions">
          {onSubmit && <StyledButtonText onClick={onCancel}>Cancel</StyledButtonText>}
          <StyledButtonOutlined onClick={() => downloadImpactReport()}>Download Usage Report</StyledButtonOutlined>
          <ButtonContained onClick={onSubmit || onCancel}>{submitButtonString || 'Close'}</ButtonContained>
        </StyledButtonsContainer>
      </StyledContentContainer>
    </StyledModal>
  );
};

export default ImpactModal;
