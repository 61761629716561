import styled from '@emotion/styled';
import typography from 'styles/typography';
import { ButtonBaseProps } from './ButtonBase.consts';

export const StyledButton = styled.button<ButtonBaseProps>`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  text-decoration: none;
  border-radius: ${typography.borderRadius}px;
  padding: 6px 16px;
  font-weight: 400;
  font-family: ${typography.primaryFont};
  font-size: ${typography.fontSize}px;
  white-space: nowrap;
  transition: all ${typography.transition}s;
`;
