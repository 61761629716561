import styled from '@emotion/styled';
import Modal from 'components/shared/modal/Modal';
import typography from 'styles/typography';

export const StyledModal = styled(Modal)`
  width: 785px;
  height: 85vh;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const CalenderWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  gap: ${typography.unit * 6}px;
`;
