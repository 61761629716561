import React, { useEffect } from 'react';
import { FilterIcon, Filters, StyledFilterBarSelect } from 'pages/shared/shared.style';
import { useUrlFilters } from 'hooks/use-url-filters';
import { SearchTextField } from 'components/shared/searchTextField/SearchTextField';
import { store } from 'app/store';
import { productChanges as productChangesSlice, setFilters } from 'app/slices/productChanges';
import { useSelector } from 'react-redux';
import { usePersistCaretPosition } from 'hooks/use-persist-caret-position';
import { ChangeDetailsOptionsLabel, SharedChangeDetailsOptions } from 'pages/reports/shared/shared.consts';
import { ProductChangesFilterType } from '../../ProductChanges.consts';

export const ProductChangesFilterBar = () => {
  const urlFilters = useUrlFilters((params: any) => {
    store.dispatch(
      setFilters(
        Object.keys(params).reduce(
          (res: any, key: any) => ({ ...res, [key]: Array.isArray(params[key]) ? params[key] : [params[key]] }),
          {},
        ),
      ),
    );
  });
  const { filters } = useSelector(productChangesSlice);
  const [caretPosition, setCaretPosition] = usePersistCaretPosition(filters[ProductChangesFilterType.SearchQuery]);

  useEffect(() => {
    if (!Object.keys(urlFilters.params).length) {
      urlFilters.filterMulti(filters);
    }
  }, []);

  return (
    <Filters>
      <FilterIcon name="filter" />
      <StyledFilterBarSelect
        placeholder="Select"
        label="Change Type"
        name="changeDetails"
        multiple
        items={
          Object.values(SharedChangeDetailsOptions)
            .filter((option) => option !== SharedChangeDetailsOptions.AddedToCustomSet)
            .map((option) => {
              return {
                id: option,
                name: ChangeDetailsOptionsLabel[option],
              };
            }) as any[]
        }
        onChange={(selectedItems: any) => {
          urlFilters.filter(
            ProductChangesFilterType.ChangeDetails,
            Object.values(selectedItems).map((i: any) => i.id),
          );
        }}
        initialSelectedItems={filters[ProductChangesFilterType.ChangeDetails]}
        reset
        maxItems={1}
        selectWidth={160}
      />
      <SearchTextField
        key={`${JSON.stringify(filters[ProductChangesFilterType.SearchQuery])}_SearchQuery`}
        name="search"
        caretPosition={caretPosition}
        value={filters[ProductChangesFilterType.SearchQuery] ?? ''}
        onChange={(e) => {
          urlFilters.filter(ProductChangesFilterType.SearchQuery, e.target.value);
          setCaretPosition(e);
        }}
      />
    </Filters>
  );
};
