import styled from '@emotion/styled/macro';
import { ButtonText } from 'components/shared/button';
import { Icon, IconProps } from 'components/shared/icon';
import StatusLabel from 'components/shared/statusLabel/StatusLabel';
import typography from 'styles/typography';
import zIndex from 'styles/zIndex';
import { ApprovalStatus, StyledWithThemeProps } from 'utils/types';

export const OffersGridItemBackdrop = styled.div`
  position: absolute;
  z-index: ${zIndex.hoverBackdrop};
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 190px;
  display: none;
  padding: ${typography.unit * 3}px ${typography.unit * 2}px ${typography.unit}px ${typography.unit}px;
  border-radius: 5px 5px 0 0;
`;

export const OffersGridItemWrapper = styled.div`
  cursor: pointer;
`;

export const OffersGridItem = styled.div<StyledWithThemeProps>`
  width: 280px;
  height: 240px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => `${theme.colors.global.border}`};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover ${OffersGridItemBackdrop} {
    display: flex;
    flex-direction: column;
  }
`;

export const OffersGridItemHeader = styled.header`
  height: 17px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 20px;
  padding: 0 ${typography.unit * 2}px 0 0;
  margin: 0 0 ${typography.unit * 2}px 0;
`;

export const OffersGridItemFooter = styled.footer`
  padding: 10px 6px 0;
  text-overflow: ellipsis;
  overflow: hidden;

  h6,
  p {
    font-size: 14px;
  }

  p {
    white-space: nowrap;
    display: initial;
  }

  h6 {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const OffersGridItemTemplate = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: ${typography.unit}px;
`;

export const OffersGridItemImageContainer = styled.div`
  height: 160px;
  display: flex;
  justify-content: center;
`;

export const OffersGridItemImage = styled.img`
  height: 160px;
  max-width: 260px;
`;

export const OffersGridItemActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledButtonText = styled(ButtonText)`
  margin-left: ${typography.unit * 3}px;
`;

export const StyledIcon = styled(Icon)<IconProps>`
  width: 18px;
  fill: #fff;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
  transition: all ${typography.transition}s ease;

  :hover {
    fill: ${({ theme }) => theme.colors.global.inputPrimary};
  }
`;

export const StyledView = styled(StyledIcon)`
  width: 24px;
`;

export const StyledStatusLabel = styled(StatusLabel)`
  border-width: ${({ type }) => (type === ApprovalStatus.Draft ? '0 1px 1px 0' : '0')};
  border-top-left-radius: ${typography.borderRadius}px;
`;

export const StyledArchiveIcon = styled(StyledIcon)`
  width: 22px;
`;

export const StyledAddIcon = styled(StyledIcon)`
  width: 18px;
`;

export const StyledRemoveIcon = styled(StyledIcon)`
  width: 20px;
`;

export const OfferNameWrapper = styled.div`
  flex: 1;
  display: flex;

  h6 {
    flex: 1;
  }
`;
