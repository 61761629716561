import { FormMode, StyledWithThemeProps } from 'utils/types';
import { PeriodProps } from 'pages/settings/schedulePeriods/SchedulePeriods.consts';

export const FormTitle: Partial<Record<FormMode, string>> = {
  [FormMode.New]: 'Create Period',
  [FormMode.Edit]: 'Edit',
};

export interface PeriodFormProps extends StyledWithThemeProps {
  mode: FormMode;
  period?: PeriodProps;
  periods?:any;
}
