import { gql } from '@apollo/client';

export const usersGqlsDefault = {
  queries: {
    getFirstLastEmail: gql`
      query {
        getEnvironments {
          firstName
          lastName
          adminEmail
        }
      }
    `,
  },
};
