import styled from '@emotion/styled';
import TextArea from 'components/shared/textArea/TextArea';
import typography from 'styles/typography';
import { ButtonContained, ButtonText } from 'components/shared/button';
import TabStrip from 'components/shared/tabStrip/TabStrip';
import { appTheme, defaultTheme } from 'styles/themes/defaultTheme';
import TextField from 'components/shared/textField/TextField';
import { StyledWithThemeProps } from 'utils/types';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import Modal from 'components/shared/modal/Modal';
import { Icon, IconProps, StyledClickableIconProps } from 'components/shared/icon';
import { ButtonDropdown } from 'components/shared/button/buttonDropdown';
import InfoTooltip from 'components/shared/tooltip/info/InfoTooltip';
import Tooltip from 'components/shared/tooltip/Tooltip';

export const ImageContainer = styled.div<StyledWithThemeProps>`

  img {
    width: 188px;
    height: 240px;
    object-fit: contain;
  }
button {
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 0; /* Initially hidden */
    transition: opacity ${typography.transition}s ease;
  }

&:hover  {
    button {
      opacity: 2;
    }
  }
`;

export const StyledTabStrip = styled(TabStrip)`
  margin: 0;
  height: 30px;
  font-size: 14px;

  a {
    padding: 0 ${typography.unit * 2}px;
  }
`;

export const OfferFormContentContainer = styled.div<{ theme?: appTheme; selected: boolean }>`
  border: ${({ theme }) => `1px solid ${theme.colors.global.border}`};
  border-radius: 0 0 5px 5px;
  padding: ${typography.unit * 2}px;
  display: ${({ selected }) => (selected ? 'flex' : 'none')};
`;

export const OfferFormImageContainer = styled.aside`
  flex: 0 1 200px;
`;

export const OfferFormImage = styled.div<StyledWithThemeProps>`
  position: relative;
  width: 188px;
  height: 240px;
  background: ${({ theme }) => `${theme.colors.global.imageBackground}`};
  display: flex;
  justify-content: center;
  word-break: break-all;

  }
`;

export const OfferFormContent = styled.div`
  flex: 1 1 auto;
`;

export const StyledTextField = styled(TextField)`
  margin-bottom: ${typography.unit * 5}px;

  label {
    margin-bottom: 0;
  }
`;

export const StyledTextArea = styled(TextArea)`
  margin-bottom: ${typography.unit * 4}px;
  margin-top: ${typography.unit * 2}px;
`;

export const StyledTermsAndConditionsTextArea = styled.div`
  position: relative;
  margin-top: ${typography.unit * 5}px;
`;

export const StyledInfoTooltip = styled(InfoTooltip)`
  position: absolute;
  right: ${typography.unit}px;
  top: ${typography.unit}px;
`;

export const StyledButtonContained = styled(ButtonContained)`
  margin-top: ${typography.unit * 8}px;
  align-self: self-start;
`;

export const OfferFormAdditionalDetails = styled.div`
  display: flex;
  margin: ${typography.unit * 5}px 0 0 0;
  gap: ${typography.unit * 5}px;
  flex-wrap: wrap;

  > * {
    width: 310px;
  }
`;

export const OfferFormFooter = styled.footer`
  display: flex;
  margin: auto 0 0 0;
  justify-content: flex-end;
  gap: ${typography.unit * 5}px;
  bottom: 0;
  padding-top: ${typography.unit * 2}px;
`;

export const StyledSelectbox = styled(Selectbox)`
  width: 100%;
`;

export const StyledButtonText = styled(ButtonText)`
  margin: ${typography.unit * 2}px 0 0 0;
`;

export const StyledModal = styled(Modal)`
  height: 85vh;
  overflow-y: visible;
  width: 700px;

  & > header {
    padding-bottom: ${typography.unit * 5}px;

    h3 {
      margin-bottom: ${typography.unit}px;
    }

    h4 {
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

export const OfferFormContainer = styled.div<StyledWithThemeProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-right: -${typography.unit * 5}px;

  ::-webkit-scrollbar {
    width: 21px;
  }

  ::-webkit-scrollbar-thumb {
    border: 7px transparent solid;
    background-clip: padding-box;
    border-radius: 10px;
  }
`;

export const TagsSelectbox = styled(Selectbox)`
  ul {
    top: auto;
    bottom: 32px;
  }
` as unknown as typeof Selectbox;

export const TemplateSelectbox = styled(Selectbox)`
  width: 310px;
` as unknown as typeof Selectbox;

export const StyledLabelContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const StyledInfo = styled(Icon)<StyledClickableIconProps>`
  fill: ${defaultTheme.colors.global.inputPrimary};
  width: 15px;
  margin-left: ${typography.unit * 2}px;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledButtonDropdown = styled(ButtonDropdown)<StyledWithThemeProps>`
  height: 32px;
  width: 135px;
`;

export const StyledUpdatedTime = styled.span`
  font-size: 12px;
  margin-left: ${typography.unit * 2}px;
`;

export const StyledProductTooltip = styled(Tooltip)`
  width: 500px;
  max-width: 500px;
  height: 620px;

  > div {
    height: 100%;
  }
`;

export const StyledError = styled.p<StyledWithThemeProps>`
  line-height: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.global.error};
`;

export const StyledIcon = styled(Icon) <IconProps>`
  width: 18px;
  fill: #fff;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
  transition: all ${typography.transition}s ease;

  :hover {
    fill: ${({ theme }) => theme.colors.global.inputPrimary};
  }
`;

export const StyledViewImage = styled(StyledIcon)`

width: 24px;
`;

export const StyledImageButtonText = styled(ButtonText)`
`;