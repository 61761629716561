import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { CheckboxGroupProps } from 'components/shared/checkboxGroup/CheckboxGroup.consts';
import { isInArray } from 'utils/array';
import { convertStringArrayToBooleanTrueRecord } from 'utils/mapping';
import {
  CheckboxGroupContainer,
  GroupWrapper,
  StyledCheckbox,
  StyledContainer,
  StyledError,
  StyledLabel,
} from './CheckboxGroup.style';

const CheckboxGroup = ({
  checkboxes,
  defaultValue,
  direction = 'row',
  disabled = false,
  control,
  name,
  label,
  labelIsHorizontal,
  validation,
  errors,
  className,
}: CheckboxGroupProps) => {
  const [selectedRecord, setSelectedRecord] = useState(convertStringArrayToBooleanTrueRecord(defaultValue) || {});

  const onCheckboxClicked = (value: string, onChange: (changedVal: any) => void) => {
    const selected = { ...selectedRecord };
    if (selected[value]) {
      delete selected[value];
    } else {
      selected[value] = true;
    }
    setSelectedRecord(selected);
    onChange(Object.keys(selected));
  };

  return (
    <StyledContainer className={className} labelIsHorizontal={labelIsHorizontal}>
      {label && (
        <StyledLabel labelIsHorizontal={labelIsHorizontal}>{`${label}${validation?.required ? '*' : ''}`}</StyledLabel>
      )}
      <GroupWrapper>
        <Controller
          control={control}
          rules={validation}
          name={name}
          defaultValue={defaultValue}
          render={({ field: { onChange, value } }) => (
            <CheckboxGroupContainer direction={direction} className={className} data-automation-id="checkbox-group">
              {checkboxes.map((checkbox, index) => (
                <StyledCheckbox
                  key={`${index}_${checkbox.label}`}
                  label={checkbox.label}
                  checked={value && isInArray(value, checkbox.id)}
                  disabled={disabled}
                  direction={direction}
                  onClick={() => onCheckboxClicked(checkbox.id, onChange)}
                />
              ))}
            </CheckboxGroupContainer>
          )}
        />
        <StyledError name={name} errors={errors} />
      </GroupWrapper>
    </StyledContainer>
  );
};

export default CheckboxGroup;
