import styled from '@emotion/styled';
import Modal from 'components/shared/modal/Modal';

export const StyledLocationSetModal = styled(Modal)<{ isViewMode: boolean }>`
  width: ${({ isViewMode }) => (isViewMode ? '564px' : '1080px')};
  min-width: ${({ isViewMode }) => (isViewMode ? '564px' : '1080px')};
  height: 85vh;
  max-height: 790px;

  header h3 {
    margin-bottom: 12px;
  }
`;
