import React, { useEffect } from 'react';
import Tooltip from 'components/shared/tooltip/Tooltip';
import ReactTooltip from 'react-tooltip';
import { ChipProps } from './Chip.consts';
import { StyledButtonClose, StyledChip } from './Chip.style';

const Chip = ({ onClose, children, title, enableTagHover, primaryChip, ...rest }: ChipProps) => {
  const id = Math.random().toString(32);
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <>
      <StyledChip onClose={onClose} enableTagHover={enableTagHover} {...rest} data-automation-id="chip" data-for={id} data-tip>
        <span>{children}</span>
        {onClose && !enableTagHover && <StyledButtonClose onClick={onClose} />}
      </StyledChip>
      {title && !(enableTagHover && primaryChip)  && <Tooltip id={id} onHover content={title} place="bottom" />}
    </>
  ); 
};

export default Chip;
