import styled from '@emotion/styled';
import { ButtonText } from 'components/shared/button';
import typography from 'styles/typography';
import { Icon, IconProps } from 'components/shared/icon';
import { ImageFormContainerProps } from './ImagesForm.consts';
import Modal from 'components/shared/modal/Modal';

export const StyledImagesFormModal = styled(Modal)`
  max-height: 90%;
`;
export const Forms = styled.div<{ isImagesBulk: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-right: -${typography.unit * 5}px;

  ::-webkit-scrollbar {
    width: 21px;
  }

  ::-webkit-scrollbar-thumb {
    border: 7px transparent solid;
    background-clip: padding-box;
    border-radius: 10px;
  }
`;

export const ImageFormContainer = styled.div<ImageFormContainerProps>`
  display: flex;
  border: ${({ isImagesBulk, theme }) => isImagesBulk && `1px solid ${theme.colors.global.border}`};
  border-radius: ${typography.borderRadius}px;
  margin-bottom: ${typography.unit * 3}px;
  padding: ${({ isImagesBulk }) => isImagesBulk && `${typography.unit * 3}px ${typography.unit * 2}px;`};
  position: relative;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: ${typography.unit * 4}px;
  overflow: hidden;
`;

export const RemoveButton = styled(ButtonText)`
  position: absolute;
  top: ${typography.unit * 2}px;
  right: ${typography.unit * 2}px;
`;

export const RemoveIcon = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.text.secondary};
  width: 14px;
`;