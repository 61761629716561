export const showActionsCell = (rowId: string | number) => {
  const cell = document.getElementById(`Actions-${rowId}`);
  if (cell) {
    cell.style.display = 'block';
  }
};

export const hideActionsCell = (rowId: string | number) => {
  const cell = document.getElementById(`Actions-${rowId}`);
  if (cell) {
    cell.style.display = 'none';
  }
};
