import React, { useEffect } from 'react';
import { FilterIcon, Filters, StyledFilterBarSelect } from 'pages/shared/shared.style';
import { useSelector } from 'react-redux';
import { useUrlFilters } from 'hooks/use-url-filters';
import { SearchTextField } from 'components/shared/searchTextField/SearchTextField';
import { store } from 'app/store';
import { imagesPage } from 'app/genericSlices/images';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import { usePersistCaretPosition } from 'hooks/use-persist-caret-position';
import { archiveStatusValues } from '../../../settings.const';
import { ImageFilterType } from '../../Images.consts';

const ImagesFilterBar = () => {
  const { load: loadTags, tags } = useTagsQuery([TagsValidEntities.Image]);
  const urlFilters = useUrlFilters((params: any) => {
    store.dispatch(
      imagesPage.actions.setFilters(
        Object.keys(params).reduce(
          (res: any, key: any) => ({ ...res, [key]: Array.isArray(params[key]) ? params[key] : [params[key]] }),
          {},
        ),
      ),
    );
  });
  const { filters } = useSelector(imagesPage.imagesState);
  const [caretPosition, setCaretPosition] = usePersistCaretPosition(filters[ImageFilterType.SearchQuery]);

  useEffect(() => {
    if (!Object.keys(urlFilters.params).length) {
      urlFilters.filterMulti(filters);
    }
    loadTags();
  }, []);

  return (
    <Filters>
      <FilterIcon name="filter" />
      <StyledFilterBarSelect
        placeholder="Select"
        label="Status"
        name="status"
        multiple
        items={archiveStatusValues as any[]}
        onChange={(selectedItems: any) => {
          urlFilters.filter(
            ImageFilterType.Archive,
            Object.values(selectedItems).map((i: any) => i.id),
          );
        }}
        initialSelectedItems={filters[ImageFilterType.Archive]}
        maxItems={1}
        selectWidth={160}
      />
      <StyledFilterBarSelect
        key={`${Boolean(tags.length)}`}
        name="tags"
        placeholder="Select"
        label="Tags"
        multiple
        items={tags}
        onChange={(selectedItems: any) => {
          urlFilters.filter(
            ImageFilterType.Tags,
            Object.values(selectedItems).map((i: any) => i.id),
          );
        }}
        initialSelectedItems={tags?.length ? filters[ImageFilterType.Tags] : []}
        withSearch
        withAmount
        reset
        maxItems={1}
        selectWidth={160}
      />
      <SearchTextField
        key={`${JSON.stringify(filters[ImageFilterType.SearchQuery])}_SearchQuery`}
        name="images-search"
        caretPosition={caretPosition}
        value={filters[ImageFilterType.SearchQuery] ?? ''}
        onChange={(e) => {
          urlFilters.filter(ImageFilterType.SearchQuery, e.target.value);
          setCaretPosition(e);
        }}
      />
    </Filters>
  );
};

export default ImagesFilterBar;
