import styled from '@emotion/styled';
import typography from 'styles/typography';
import { Icon } from 'components/shared/icon';
import TextField from 'components/shared/textField/TextField';
import { ButtonText } from 'components/shared/button';
import { DiscountType } from 'utils/types/offers';

export const StyledTextField = styled(TextField)`
  min-width: 75px;
  padding-right: ${typography.unit * 3}px;
  input {
    width: 75px;
  }
`;

export const StyledDiscountValueField = styled(StyledTextField)<{ type: string }>`
  visibility: ${({ type }) => type && type.includes(DiscountType.Free) && 'hidden'};
  width: ${({ type }) => type && type.includes(DiscountType.Free) && '0px'};
  min-width: ${({ type }) => type && type.includes(DiscountType.Free) && '0px'};
  padding-right: ${({ type }) => type && type.includes(DiscountType.Free) && '0px'};
`;

export const StyledIcon = styled(Icon)`
  width: 16px;
`;

export const StyledButtonText = styled(ButtonText)`
  width: 16px;
`;
