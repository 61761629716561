import {
  NotificationActionType,
  NotificationEntityType,
  UserNotification,
  UserNotificationMessage,
} from 'utils/types/notification';
import { capitalize } from 'utils/text';
import { store } from 'app/store';
import { CampaignFilterType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { formatDate } from 'utils/date';
import { DateFormats, TimeFormats } from 'utils/types';

export const getEnableManagementByZone = (): Boolean => {
  const { enableManagementByZone } = store.getState()?.config?.config;
  return enableManagementByZone;
};

export const notificationsMap = new Map<number, UserNotification>();

export const setNotificationsMap = (notifications: UserNotification[]) => {
  notifications.forEach(
    (notification) => !notification.isArchive && notificationsMap.set(notification.id, notification),
  );
};

const viewCampaignLink = ['View campaigns'];
const embz = getEnableManagementByZone() ? 'campaign-management' : 'campaigns';

const prepareBulkCampaignCreationNotificationTemplate = (
  userNotification: UserNotification,
  dateFormat: DateFormats,
  timeFormat: TimeFormats,
  startTimezone: string,
  endTimezone: string,
): UserNotificationMessage | undefined => {
  if (userNotification?.entitiesData?.length === 0) {
    return;
  }
  let failureFlag = false;
  let initiatedAt: any;
  const campaignExternalIds = userNotification?.entitiesData
    ?.map((obj: any) => {
      initiatedAt = obj?.initiatedAt;
      if (!obj.isSuccess) {
        failureFlag = true;
        return null;
      }
      return obj?.externalId;
    })
    .filter((id) => id !== null);
  const url = `./${embz}?${CampaignFilterType.Id}=${campaignExternalIds?.join(',')}`;
  if (failureFlag && campaignExternalIds.length === 0) {
    return {
      ...userNotification,
      message: `
          Bulk Campaign creation by ${userNotification?.user?.firstName} ${userNotification?.user?.lastName} 
          & ${formatDate(
            initiatedAt,
            { dateFormat, timeFormat, startTimezone, endTimezone },
            startTimezone,
          )} is not successful due to an error.`,
    };
  } else if (failureFlag && campaignExternalIds.length > 0) {
    return {
      ...userNotification,
      message: `
          Bulk Campaign creation by ${userNotification?.user?.firstName} ${userNotification?.user?.lastName} 
          & ${formatDate(
            initiatedAt,
            { dateFormat, timeFormat, startTimezone, endTimezone },
            startTimezone,
          )} is partially successful due to an error.`,
      linksString: viewCampaignLink,
      links: [url],
    };
  } else {
    return {
      ...userNotification,
      message: `
          Bulk campaign creation by ${userNotification?.user?.firstName} ${userNotification?.user?.lastName} 
          & ${formatDate(
            initiatedAt,
            { dateFormat, timeFormat, startTimezone, endTimezone },
            startTimezone,
          )} is successful.`,
      linksString: viewCampaignLink,
      links: [url],
    };
  }
};

const prepareBulkCampaignNotificationTemplate = (
  userNotification: UserNotification,
  dateFormat: DateFormats,
  timeFormat: TimeFormats,
  startTimezone: string,
  endTimezone: string,
): UserNotificationMessage | undefined => {
  if (userNotification?.entitiesData?.length === 0) {
    return;
  }
  const campaignExternalIds = userNotification?.entitiesData?.map((obj: any) => obj.externalId);
  const url = `./${embz}?${CampaignFilterType.Id}=${campaignExternalIds?.join(',')}`;
  return {
    ...userNotification,
    message: `
    Bulk Campaign ${
      userNotification.action === NotificationActionType.BulkSubmissionForApproval ? 'Submission for ' : ''
    }Approval by ${userNotification?.user?.firstName} ${userNotification?.user?.lastName} 
      & ${formatDate(
        userNotification?.entitiesData?.[0].initiatedAt,
        { dateFormat, timeFormat, startTimezone, endTimezone },
        startTimezone,
      )} is completed.`,
    linksString: viewCampaignLink,
    links: [url],
  };
};

const prepareNoLocationProductNotificationTemplate = (
  userNotification: UserNotification,
): UserNotificationMessage | undefined => {
  if (userNotification?.entitiesData?.length === 0) {
    return;
  }
  const idStrings = userNotification.entitiesData.map((id) => `${id}`);
  return {
    ...userNotification,
    message: `
          The following ${capitalize(userNotification.entityType)}s have no active ${
      userNotification.action === NotificationActionType.NoLocations
        ? NotificationEntityType.Location
        : NotificationEntityType.Product
    }s defined and have been automatically ${
      userNotification.entityType === NotificationEntityType.Campaign ? 'revoked' : 'rejected'
    }.`,
    linksString: idStrings,
    links: idStrings.map(
      (entityId) =>
        `./${
          userNotification.entityType === NotificationEntityType.Campaign ? embz : 'offers'
        }?searchQuery=${entityId}`,
    ),
  };
};

export const handleDifferenceBetweenSubscriptionAndState = (
  fromSubscription: UserNotification[],
): UserNotification[] => {
  fromSubscription.forEach((notification) => {
    notificationsMap.set(notification.id, notification);
    if (notification.isArchive) {
      notificationsMap.delete(notification.id);
    }
  });
  return Array.from(notificationsMap.values());
};

export const getNotificationTemplate = (
  userNotification: UserNotification,
  dateFormat: DateFormats,
  timeFormat: TimeFormats,
  startTimezone: string,
  endTimezone: string,
): UserNotificationMessage | undefined => {
  switch (userNotification.action) {
    case NotificationActionType.LocationUpdate:
    case NotificationActionType.ProductUpdate:
      return {
        ...userNotification,
        message: `${capitalize(userNotification.entityType)} update has occurred.`,
        linksString: ['Go to report'],
        links: [`./${userNotification.entityType}-changes`],
      };
    case NotificationActionType.GameLocationUpdateFailed:
      return { ...userNotification, message: `Location update failed for ${userNotification.game?.name}.` };
    case NotificationActionType.GameLocationUpdateSuccess:
      return {
        ...userNotification,
        message: `Location update occurred for ${userNotification.game?.name}.`,
        linksString: ['Go to report'],
        links: [`./${userNotification.entityType}-changes`],
      };
    case NotificationActionType.NoLocations:
    case NotificationActionType.NoProducts:
      return prepareNoLocationProductNotificationTemplate(userNotification);
    case NotificationActionType.BulkCampaignCreation:
      return prepareBulkCampaignCreationNotificationTemplate(
        userNotification,
        dateFormat,
        timeFormat,
        startTimezone,
        endTimezone,
      );
    case NotificationActionType.BulkSubmissionForApproval:
    case NotificationActionType.BulkCampaignApproval:
      return prepareBulkCampaignNotificationTemplate(
        userNotification,
        dateFormat,
        timeFormat,
        startTimezone,
        endTimezone,
      );
    case NotificationActionType.NewUser:
    case NotificationActionType.UserUpdate:
    case NotificationActionType.CampaignErrors:
    case NotificationActionType.NoRedemptions:
    case NotificationActionType.CampaignApprovalRequired:
    default:
      break;
  }
};
