import React from 'react';
import { useSelector } from 'react-redux';
import { users } from 'app/slices/users';
import { RoleGuardProps } from './RoleGuard.consts';

export const RoleGuard = ({ children, roles }: RoleGuardProps) => {
  const { user } = useSelector(users);
  if (!roles || roles.includes(user.role)) {
    return Array.isArray(children) ? <>{children.map((c) => c)}</> : children;
  }

  return null;
};
