import React from 'react';
import { GridCellParams, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { TableProps } from 'components/shared/table/Table.consts';
import { CampaignType, CampaignTypeLabel } from 'utils/types/campaigns';
import CellTitleWrapper from 'components/shared/table/components/cellTitleWrapper/CellTitleWrapper';
import { CampaignNameButton } from 'pages/dashboard/Dashboard.style';

export const getDashboardCampaignsTableProps = (onCampaignNameClick: (id: number) => void): TableProps => ({
  columns: [
    {
      field: 'title',
      headerName: 'Campaign Name',
      width: 300,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { row, value } = params;
        return (
          <CampaignNameButton onClick={() => onCampaignNameClick(row.externalId)}>
            <CellTitleWrapper title={value as string} />
          </CampaignNameButton>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Campaign Type',
      width: 200,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) => CampaignTypeLabel[params.value as CampaignType],
    },
    {
      field: 'offerName',
      headerName: 'Offer Name',
      width: 300,
      sortable: false,
    },
  ],
  rows: [] as any[],
  sortingMode: 'server',
  sortingOrder: ['desc', 'asc'],
});
