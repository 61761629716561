import { createSlice } from '@reduxjs/toolkit';
import { OrderDirection, ProductSetType } from 'utils/types';
import type { RootState } from 'app/store';
import { ProductSetsFilters } from 'pages/settings/productSets/ProductSets.consts';
import { DBFilters, generateFilters } from 'utils/dbFilters';

export interface Products {
  name: string;
  filters: { [ProductSetsFilters.SearchQuery]: string };
  order?: { [fieldName: string]: OrderDirection };
}

export const initialState: Products = {
  name: '',
  filters: { [ProductSetsFilters.SearchQuery]: '' },
  order: { name: OrderDirection.ASC },
};

export const getFilters = (initialFilters: { [key in ProductSetsFilters]?: any[] }, dbFilters: DBFilters) => {
  const filters: { OR: any[] } = {
    OR: [],
  };

  Object.entries(initialFilters).forEach(([filterType, values]) => {
    if (values.length) {
      switch (filterType) {
        case ProductSetsFilters.SearchQuery:
          Object.assign(filters, generateFilters(dbFilters));
          break;

        default:
          break;
      }
    }
  });

  return filters;
};

export const getProductSetsFilters = (filters: any) => {
  const searchValue = filters[ProductSetsFilters.SearchQuery];
  const orFilters = [
    { name: 'ProductSet_id', operator: 'is', type: 'number', value: searchValue },
    { name: 'Product_id', operator: 'is', type: 'number', value: searchValue },
    { name: 'ProductSet_name', operator: 'contains', type: 'string', value: searchValue },
    { name: 'Product_name', operator: 'contains', type: 'string', value: searchValue },
    { name: 'Product_plu', operator: 'is', type: 'number', value: searchValue },
  ];

  const dbFilters: DBFilters = {
    AND: [{ OR: orFilters }],
  };
  const { familyGroup, setType } = filters;
  if (familyGroup) {
    dbFilters.AND.push({ name: 'Product_familyGroup', operator: 'is', type: 'string', value: familyGroup });
  }
  if (setType === ProductSetType.NonFoodProduct) {
    dbFilters.AND.push({
      name: 'ProductSet_type',
      operator: 'is',
      type: 'string',
      value: ProductSetType.NonFoodProduct,
    });
  }
  return getFilters({ [ProductSetsFilters.SearchQuery]: [searchValue] }, dbFilters);
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setOrder(state, action) {
      state.order = action.payload;
    },
  },
});

export const products = (state: RootState) => state.products;
export const { setFilters, setOrder } = productsSlice.actions;

export default productsSlice.reducer;
