import styled from '@emotion/styled';
import typography from 'styles/typography';
import Error from '../error/Error';
import { StyleTextAreaProps, TextAreaProps } from './TextArea.consts';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTextArea = styled.textarea<StyleTextAreaProps>`
  background-color: ${({ theme }) => theme.colors.global.background};
  border: ${({ error, theme }) => `1px solid ${error ? theme.colors.global.error : theme.colors.global.border}`};
  border-radius: ${typography.borderRadius}px;
  padding: ${typography.unit * 2}px ${typography.unit * 2}px;
  outline: none;
  font-size: ${typography.fontSize}px;
  font-family: ${typography.primaryFont};
  resize: none;
  overflow: auto;
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 22px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.disabled};
  }

  &:focus {
    border: ${({ error, theme, readOnly }) =>
      `1px solid ${error ? theme.colors.global.error : !readOnly ? theme.colors.global.inputPrimary : 'initial'}`};
  }

  &:disabled {
    pointer-events: none;
  }
`;

export const StyledLabel = styled.div<TextAreaProps>`
  text-transform: capitalize;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text.primary};
  display: flex;
  align-items: center;
  margin-right: ${typography.unit * 5}px;
  margin-bottom: ${({ labelIsHorizontal }) => labelIsHorizontal && `${typography.unit}px`};
`;

export const StyledError = styled(Error)`
  margin-top: ${typography.unit}px;
`;
