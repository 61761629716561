import React from 'react';
import { ApprovalStatus } from 'utils/types';
import { CampaignAlert } from 'utils/types/campaigns';
import { useQuery } from '@apollo/client';
import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import QuickFilter from 'pages/campaigns/campaignManagement/components/quickFilter/QuickFilter';
import { CampaignFilterType, UrlFilters } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { QuickFiltersContainer } from 'pages/campaigns/campaignManagement/components/quickFilters/QuickFilters.style';
import { FetchPolicies } from 'utils/types/common';

const QuickFilters = ({ filterWithClear}: { filterWithClear: UrlFilters['filterWithClear']; }) => {
    const { data } = useQuery(campaignsGqls.queries.getCampaignQuickFilters, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
  });
  const {
    getCampaignsQuickFilters: { quickFilterCounts },
  } = data || { getCampaignsQuickFilters: { quickFilterCounts: undefined } };
  return (
    <QuickFiltersContainer>
      <QuickFilter
        number={quickFilterCounts?.alertCount ?? 0}
        name="Alerts"
        isError
        onClick={() => {
          filterWithClear(CampaignFilterType.Alert, [
            CampaignAlert.UpdateFailed,
            CampaignAlert.RevokeFailed,
            CampaignAlert.Conflicted,
            CampaignAlert.StopAssociationFailed,
          ])
        }}
      />
      <QuickFilter
        number={quickFilterCounts?.deploymentFailedCount ?? 0}
        name="Deployment Failed"
        isError
        onClick={() => {
          filterWithClear(CampaignFilterType.Status, [ApprovalStatus.DeploymentFailed])
        }}
      />
      <QuickFilter
        number={quickFilterCounts?.pendingApprovalCount ?? 0}
        name="Pending Approval"
        onClick={() => {
          filterWithClear(CampaignFilterType.Status, [ApprovalStatus.PendingApproval])
        }}
      />
    </QuickFiltersContainer>
  );
};

export default QuickFilters;
