import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

export enum Modals {
  OfferModal,
  ImageModal,
  ImagesModal,
  ImpactModal,
  ImageSelectionModal,
  TermConditionModal,
  CampaignModal,
  OfferSelectionModal,
  ProductSetModal,
  LocationSetModal,
  UserManagementModal,
  TagModal,
  CampaignSelectionModal,
  PeriodModal,
  ZonesManagementModal,
  MultipleDatesSelectionModal,
  AuditImpactModal,
  UsageModal,
}

export interface Action {
  payload: {
    modal?: Modals;
    props?: any;
    data?: any;
  };
  type?: string;
}

export interface ModalState {
  modal: Modals;
  props: any;
  data: any;
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    modal: null,
    props: {},
  } as ModalState,
  reducers: {
    openModal(state, action: Action) {
      state.modal = action.payload.modal;
      state.props = action.payload.props;
      state.data = { ...state.data, ...action.payload.data };
      window.history.pushState(
        { modal: action.payload.modal },
        null,
        `${window.location.pathname}${window.location.search}`,
      );
    },
    closeModal(state) {
      state.modal = null;
      state.data = {};
      state.props = {};
      if (Object.keys(window.history.state).includes('modal')) {
        window.history.back();
      }
    },
    setData(state, action: Action) {
      state.data = { ...state.data, ...action.payload.data };
    },
  },
});

export const modal = (state: RootState) => state.modals;
export const { openModal, closeModal, setData } = modalSlice.actions;

export default modalSlice.reducer;
