import styled from '@emotion/styled';
import typography from 'styles/typography';
import TextArea from 'components/shared/textArea/TextArea';
import { LanguageNavigationItemProps, StyledLanguageContentProps } from './TermConditionTranslations.const';

export const LanguageNavigationContainer = styled.div`
  display: flex;
`;

export const LanguageContentContainer = styled.div`
  display: flex;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LanguageContent = styled(TextArea)<StyledLanguageContentProps>`
  width: 100%;
  display: ${({ show }) => !show && 'none'};
`;

export const LanguageNavigationItem = styled.div<LanguageNavigationItemProps>`
  display: flex;
  padding: ${typography.unit}px 0;
  margin-right: ${typography.unit * 3}px;
  border-bottom: ${({ isActive, theme }) => isActive && `4px solid ${theme.colors.global.brandMain}`};
  font-weight: ${({ isActive }) => isActive && 500};
  cursor: pointer;
  font-size: ${typography.fontSize}px;
  text-transform: capitalize;
`;

export const StyledLabel = styled.label<LanguageNavigationItemProps>`
  font-size: 13px;
  margin-bottom: ${typography.unit * 2}px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.text.primary};
`;
