import React, { useEffect, useState } from 'react';
import AvailableSetsAndItemsAccordion
  from 'pages/shared/setItemsSelectionForm/setItemsListPanel/availableSetItemsListPanel/availableSetsAndItemsAccordion/AvailableSetsAndItemsAccordion';

import {
  Headline,
  Section,
  SelectionContainer,
  StyledSearch,
} from 'pages/shared/setItemsSelectionForm/setItemsListPanel/SetItemsListPanel.style';
import { AvailableSetItemsListPanelProps } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/availableSetItemsListPanel/AvailableSetItemsListPanel.consts';
import { capitalize } from 'utils/text';
import { SetItemsSelectionFormState } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import { getSelectedItems } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.utils';
import { useFormContext } from 'react-hook-form';

const AvailableSetItemsListPanel = ({
  setOf,
  onItemSetActionClick,
  onItemSelect,
  onExcludeItemClick,
  className,
  searchPlaceholder = 'Search',
  itemSetTitleFormatter,
  itemFormatter,
  accordions,
  selectAll,
  fetchTotalAvailableItems,
  fetchRootSetsByIds,
  forcedExcludedSetsIds,
  forcedExcludedItemsIds,
  supportSetFringes,
  setType,
}: AvailableSetItemsListPanelProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [totalAvailableItems, setTotalAvailableItems] = useState<number>(0);
  const { watch } = useFormContext<SetItemsSelectionFormState>();
  const selectedItemSets = Object.values(watch('selectedItemSetsById'));

  const selectedItemsIds = getSelectedItems(
    selectedItemSets,
    Object.values(watch('selectedItemsById')),
    setOf,
    supportSetFringes,
  ).map((item) => Number(item.id));

  useEffect(() => {
    let cancel = false;
    fetchTotalAvailableItems(searchValue, selectedItemsIds).then((total) => !cancel && setTotalAvailableItems(total));
    return () => {
      cancel = true;
    };
  }, [searchValue, JSON.stringify(selectedItemsIds)]);

  return (
    <Section className={className} data-automation-id="available-panel">
      <Headline>{`Available ${capitalize(setOf)} (${totalAvailableItems})`}</Headline>
      <StyledSearch
        name="items-search"
        data-automation-id="items-filter-input"
        placeholder={searchPlaceholder}
        onChange={(e) => setSearchValue(e.target.value)}
        debounceTime={500}
        autoFocus={true}
      />
      <SelectionContainer className={className}>
        {accordions.map((accordionProps) => (
          <AvailableSetsAndItemsAccordion
            key={accordionProps.name}
            accordion={accordionProps}
            isSingleAccordionPanel={accordions?.length === 1}
            setOf={setOf}
            itemFormatter={itemFormatter}
            itemSetTitleFormatter={itemSetTitleFormatter}
            onExcludeItemClick={onExcludeItemClick}
            onItemSelect={onItemSelect}
            onItemSetActionClick={onItemSetActionClick}
            selectAll={selectAll}
            searchValue={searchValue}
            fetchRootSetsByIds={fetchRootSetsByIds}
            forcedExcludedItemsIds={forcedExcludedItemsIds}
            forcedExcludedSetsIds={forcedExcludedSetsIds}
            supportSetFringes={supportSetFringes}
            setType={setType}
          />
        ))}
      </SelectionContainer>
    </Section>
  );
};

export default AvailableSetItemsListPanel;
