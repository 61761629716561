import React from 'react';
import {
  ButtonsRow,
  StyledButtonOutlined,
  StyledErrorContent,
  StyledErrorStack,
  StyledLink,
} from 'components/errorBoundaryFallback/ErrorBoundaryFallback.style';
import Modal from 'components/shared/modal/Modal';
import { useHistory } from 'react-router-dom';
import { tabName, tabToPathMap } from 'components/header/Header.consts';
import { store } from 'app/store';
import { closeModal } from 'app/slices/modals';

const ErrorBoundaryFallback = ({ error }: any) => {
  const history = useHistory();

  const ReloadPage = () => {
    store.dispatch(closeModal);
    history.go(0);
  };

  const NavigateToDashboard = () => {
    store.dispatch(closeModal);
    history.push(tabToPathMap[tabName.Dashboard]);
    history.go(0);
  };

  return (
    <div>
      <Modal title="Oops, something went wrong..." onClose={ReloadPage}>
        <StyledErrorContent data-automation-id="error-content">
          try reloading the page or try again later.
        </StyledErrorContent>
        <StyledErrorStack data-automation-id="error-message">
          <div>Error message:</div>
          {error.message}
          <StyledLink
            to="#"
            onClick={(e) => {
              window.location.href = `mailto:vcesupport@dynamicyield.com?subject=ErrorBoundaryException&body=${error.stack}`;
              e.preventDefault();
            }}
          >
            Email Support
          </StyledLink>
        </StyledErrorStack>
        <ButtonsRow>
          <StyledButtonOutlined onClick={ReloadPage}>Reload Page</StyledButtonOutlined>
          <StyledButtonOutlined onClick={NavigateToDashboard}>Back to Home</StyledButtonOutlined>
        </ButtonsRow>
      </Modal>
    </div>
  );
};

export default ErrorBoundaryFallback;
