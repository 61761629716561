import React, { useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'hooks/use-onclick-outside';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { MarketConfigurationGuard } from 'components/zoneGuard/MarketConfigurationGuard';
import { FeatureGuard } from 'components/featureGuard/FeatureGuard';
import { Tab, TabStripProps } from './TabStrip.consts';
import { StyledNav, StyledSubNav } from './TabStrip.style';
import TabStripItem from './components/TabStripItem/TabStripItem';

const TabStrip = ({ tabs, selectedTabId, onClick, className }: TabStripProps) => {
  const [selectedTab, setSelectedTab] = useState<string | number>(selectedTabId || tabs[0].id);
  const [openTab, setOpenTab] = useState<Tab>(null);
  const ref = useRef();

  const getIsSelected = (tab: Tab) =>
    selectedTab === tab.id || tab.children?.findIndex((childTab) => childTab.id === selectedTab) > -1;

  const getIsOpen = (tab: Tab) => openTab && openTab.id === tab.id;

  const handleClick = (tab: Tab, isMainTab: boolean) => {
    if (tab.children) {
      // eslint-disable-next-line no-unused-expressions
      openTab && openTab.id === tab.id ? setOpenTab(null) : setOpenTab(tab);
    } else {
      setSelectedTab(tab.id);
      // eslint-disable-next-line no-unused-expressions
      isMainTab && setOpenTab(null);
    }
    // eslint-disable-next-line no-unused-expressions
    onClick && onClick(tab.id);
  };

  useOnClickOutside(ref, () => setOpenTab(null));

  useEffect(() => {
    if (selectedTabId) {
      setSelectedTab(selectedTabId);
    }
  }, [selectedTabId]);

  return (
    <StyledNav className={className} ref={ref} data-automation-id="tab">
      {tabs.map((tab: Tab, index: number) => (
        <MarketConfigurationGuard configurations={tab.marketConfigurations} key={`${tab.id}_${index}`}>
          <FeatureGuard features={tab.features}>
            <TabStripItem
              tab={tab}
              isSelected={getIsSelected(tab)}
              isExpandable={tab.children?.length > 0}
              isOpen={getIsOpen(tab)}
              onClick={() => handleClick(tab, true)}
            />
          </FeatureGuard>
        </MarketConfigurationGuard>
      ))}

      <StyledSubNav isOpen={Boolean(openTab)} data-automation-id="tab">
        {openTab?.children.map((childTab, index: number) => (
          <RoleGuard roles={childTab.roles} key={childTab.id}>
            <MarketConfigurationGuard configurations={childTab.marketConfigurations} key={`${childTab.id}_${index}`}>
              <FeatureGuard features={childTab.features}>
                <TabStripItem
                  tab={childTab}
                  isSelected={getIsSelected(childTab)}
                  isExpandable={childTab.children?.length > 0}
                  isOpen={getIsOpen(childTab)}
                  onClick={() => handleClick(childTab, false)}
                />
              </FeatureGuard>
            </MarketConfigurationGuard>
          </RoleGuard>
        ))}
      </StyledSubNav>
    </StyledNav>
  );
};

export default TabStrip;
