import { appTheme } from 'styles/themes/defaultTheme';
import { Language } from 'utils/languages';

export interface TermTranslation {
  language: Language;
  content: string;
}

export type TranslationsMap = Record<Language, TermTranslation>;

export interface TermConditionProps {
  id?: number;
  name?: string;
  translations?: TermTranslation[];
  translationsMap?: TranslationsMap;
  template?: string[];
  createdAt?: string;
  updatedAt?: string;
  createdBy?: number;
  updatedBy?: number;
  isArchive?: boolean;
}

export interface ThemeProps {
  theme?: appTheme;
}

export enum TermFilterType {
  Id = 'id',
  Archive = 'archive',
  OfferTemplate = 'offerTemplate',
  SearchQuery = 'searchQuery',
}
