import { appTheme } from 'styles/themes/defaultTheme';
import { ActionType, GenericItem, GenericSet, SetOfEntity } from 'utils/types';
import { ListType } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import { GenericSetTitleFormatterProps } from 'components/shared/set/SetListItemHeader/SetListItemHeader.consts';

export enum SelectedDisplayMode {
  Hide = 'hide',
  Disable = 'disable',
  Display = 'display',
}

export interface SetListItemProps {
  itemSet: GenericSet;
  setOf: SetOfEntity;
  listType: ListType;
  onItemSetActionClick: (action: ActionType, set: GenericSet) => void;
  theme?: appTheme;
  className?: string;
  onItemActionClick: (action: ActionType, item: GenericItem, set?: GenericSet) => void;
  onExcludeItemClick?: (action: ActionType, item: GenericItem) => void;
  onExcludeSetClick?: (action: ActionType, set: GenericSet) => void;
  onExpandSetClick: (setId: number) => void;
  nestedSet?: boolean;
  underCustomSet?: boolean;
  underExcludedSet?: boolean;
  titleFormatter: (props: GenericSetTitleFormatterProps) => JSX.Element | string;
  selectedItemsIds: Set<number>;
  selectedFringesIds: Set<number>;
  selectedItemSetsIds: Set<number>;
  excludedItemsIds?: Set<number>;
  excludedSetsIds?: Set<number>;
  expandedSetsIds: Set<number>;
  selectedItemsDisplayMode?: SelectedDisplayMode;
  selectedSetsDisplayMode?: SelectedDisplayMode;
  selectedNestedSetsDisplayMode?: SelectedDisplayMode;
  itemFormatter: (item: GenericItem) => string | JSX.Element;
  forcedExcludedSetsIds?: Set<number>;
  forcedExcludedItemsIds?: Set<number>;
  supportSetFringes: boolean;
}
