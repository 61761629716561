import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const groupParamsByKey = (params: any) =>
  [...params.entries()].reduce((acc, tuple) => {
    const [key, val] = tuple;
    if (key === 'id') {
      acc[key] = val.split(',');
    } else if (Object.keys(acc).includes(key)) {
      if (Array.isArray(acc[key])) {
        acc[key] = [...acc[key], val];
      } else {
        acc[key] = [acc[key], val];
      }
    } else {
      acc[key] = val;
    }
    return acc;
  }, {});

export const useUrlFilters = (filterFunction: (a: any) => void) => {
  const location = useLocation();
  const history = useHistory();

  const serialize = (filters: any) => {
    const params = new URLSearchParams();
    if (!filters) {
      return '?';
    }
    Object.entries(filters).forEach(([key, value]: any) => {
      if (value?.length || (typeof value === 'number' && value.toString().length) || typeof value === 'boolean') {
        if (Array.isArray(value) && key !== 'id') {
          value.forEach((v) => params.append(key, v));
        } else {
          params.append(key, value);
        }
      }
    });
    return `?${params.toString()}`;
  };

  useEffect(() => {
    const params = groupParamsByKey(new URLSearchParams(location.search));
    filterFunction(params);
  }, [location.search]);

  return {
    filter: (name: string, value: any) =>
      history.push(serialize({ ...groupParamsByKey(new URLSearchParams(location.search)), [name]: value }), {
        prevUrl: location.search,
      }),
    filterWithClear: (name: string, value: any) =>
      history.push(serialize({ [name]: value }), {
        prevUrl: location.search,
      }),
    filterMulti: (filters: any) =>
      history.replace(serialize(filters), {
        prevUrl: location.search,
      }),
    params: groupParamsByKey(new URLSearchParams(location.search)),
  };
};
