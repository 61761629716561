import React from 'react';
import styled from '@emotion/styled';

export interface ContainerProps {
  children?: string | JSX.Element | JSX.Element[];
  className?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
`;

const PageContainer = ({ children, className }: ContainerProps) => {
  return (
    <Container className={className} data-automation-id="page-container">
      {children}
    </Container>
  );
};

export default PageContainer;
