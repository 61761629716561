import React, { useEffect, useState } from 'react';
import TextPageTitle from 'components/shared/text/textPageTitle/TextPageTitle';
import { tabName } from 'components/header/Header.consts';
import { FormMode } from 'utils/types';
import { useSelector } from 'react-redux';
import { store } from 'app/store';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import AddNewButton from 'pages/shared/addNewButton/AddNewButton';
import { termsConditionsPage } from 'app/genericSlices/termsConditions';
import {
  archiveTermClicked,
  openTermModal,
  unarchiveTermClicked,
} from 'pages/settings/termsConditions/termsConditions.utils';
import PageContainer from '../../shared/pageContainer/PageContainer';
import { TermConditionProps } from './TermsConditions.consts';
import { StyledTermConditionItem, TermConditionsList, TermConditionsListWrapper } from './TermsConditions.style';
import { Actions, StyledHeader, StyledLoader, StyledTotalAmountWithGrid } from '../../shared/shared.style';
import { TermsConditionsFilterBar } from './components/termsConditionsFilterBar/TermsConditionsFilterBar';
import { LoaderWrapper } from 'pages/campaigns/calendar/components/calendarView/CalendarView.style';
import { Loader } from 'components/shared/loader';
import { marketConfig } from 'app/slices/config';
import { downloadUsageReport } from 'utils/download';

const TermsConditions = () => {
  const { termsConditions } = useSelector(termsConditionsPage.termsConditionsState);
  const [isTnCArchieving, setIsTnCArchieving] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { config } = useSelector(marketConfig);
  useEffect(() => {
    store.dispatch(termsConditionsPage.loadTermsPage());
  }, []);

  return (
    <PageContainer>
      <StyledHeader data-automation-id="header">
        <TextPageTitle text={tabName.TermsConditions} />
        <Actions data-automation-id="actions">
          <TermsConditionsFilterBar />
          <AddNewButton onClick={() => openTermModal(FormMode.New, {})} />
        </Actions>
      </StyledHeader>
      {!termsConditions ? (
        <StyledLoader size={LoaderSize.Large} />
      ) : (
        <>
          <StyledTotalAmountWithGrid amount={termsConditions?.length} />
          {Boolean(termsConditions?.length) && (
            <TermConditionsListWrapper>
            {(isTnCArchieving || isDownloading) && (
                <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
              )}
            <TermConditionsList data-automation-id="terms-list">
              {termsConditions.map((term: TermConditionProps, index: number) => (
                <StyledTermConditionItem
                  key={index}
                  term={term}
                  editTermClicked={() => openTermModal(FormMode.Edit, term)}
                  downloadUsageReport={(term1)=>downloadUsageReport(term1, config, setIsDownloading)}
                  archiveTerm={(term1)=>archiveTermClicked(term1,setIsTnCArchieving)}
                  unarchiveTerm={unarchiveTermClicked}
                />
              ))}
            </TermConditionsList>
            </TermConditionsListWrapper>
          )}
        </>
      )}
    </PageContainer>
  );
};

export default TermsConditions;
