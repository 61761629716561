import { getAllItemsRecursively } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.utils';
import { SetOfEntity } from 'utils/types';
import { ProductBagInfo } from 'utils/types/products';

export const convertToGenericSet = (productBagInfo: ProductBagInfo) => {
  const allProductsUnderSets = getAllItemsRecursively(productBagInfo?.productSets, SetOfEntity.Products);
  const excludedItemsIds = new Set<number>(productBagInfo?.excludedProductsIds);
  return {
    products: productBagInfo?.products,
    sets: productBagInfo?.productSets,
    excludedItems: allProductsUnderSets.filter((product) => excludedItemsIds.has(Number(product.id))),
  };
};

export const checkForEmptyData = (data : any) => {
  const { excludedProductsIds, productSets, products } = data || {};
  const isDataEmpty = (
    Array.isArray(excludedProductsIds) &&
    Array.isArray(productSets) &&
    Array.isArray(products) &&
    !excludedProductsIds.length &&
    !productSets.length &&
    !products.length
  );
  return isDataEmpty;
}