import { AuditAction, AuditType } from 'pages/reports/audit/Audit.consts';

export const extractSetFromSelectedItems = (
  selectedItems: any[],
  mapping: Record<string, AuditType[] | AuditAction[]>,
) => {
  const resultSet = new Set<any>();
  Object.values(selectedItems)
    .map((item: any) => item.id)
    .forEach((id) => {
      const items = mapping[id];
      items.forEach((it) => {
        resultSet.add(it);
      });
    });
  return resultSet;
};

export const isUpdateRequired = (params: any[], set: Set<any>) => {
  return params.some((param) => !set.has(param));
};
