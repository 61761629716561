import React from 'react';
import { updateNotification } from 'utils/api/notifications';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { formatDate } from 'utils/date';
import { marketConfig } from 'app/slices/config';
import { useSelector } from 'react-redux';
import { NotificationMessageProps } from './NotificationMessge.consts';
import {
  StyledAction,
  StyledActionsContainer,
  StyledIcon,
  StyledLink,
  StyledMessage,
  StyledMessageContainer,
  StyledMessageFooter,
  StyledNotificationMessageContainer,
} from './NotificationMessge.style';
import { useHistory } from 'react-router-dom';

const NotificationMessage = ({ notification }: NotificationMessageProps) => {
  const history = useHistory();
  const { config } = useSelector(marketConfig);
  const { dateFormat, timeFormat, startTimezone, endTimezone } = config;
  const onUnreadClick = async (e: any) => {
    e.stopPropagation();
    try {
      await updateNotification({
        id: notification.id,
        isRead: false,
      });
    } catch (err) {
      showToast(MessageType.Error, 'Failed to update notification message');
    }
  };

  const onNotificationClick = async () => {
    try {
      await updateNotification({
        id: notification.id,
        isRead: true,
      });
    } catch (err) {
      showToast(MessageType.Error, 'Failed to update notification message');
    }
  };

  const onTrashClick = async (e: any) => {
    e.stopPropagation();
    try {
      await updateNotification({
        id: notification.id,
        isArchive: true,
      });
    } catch (err) {
      showToast(MessageType.Error, 'Failed to archive notification message');
    }
  };

  return (
    <>
      {notification && (
        <StyledNotificationMessageContainer onClick={!notification.isRead ? onNotificationClick : undefined}>
          <StyledMessageContainer isRead={notification.isRead}>
            <StyledMessage data-automation-id="notification-message">{notification.message}</StyledMessage>
            {notification.linksString?.map((linkString: string, index: number) => (
              <StyledLink key={`notification-${notification.id}-link-${index}`} data-automation-id="notification-link">
                <a onClick={() => history.push(notification.links[index])}>{linkString}</a>
                {notification.linksString.length - 1 !== index && <span>,</span>}
              </StyledLink>
            ))}
          </StyledMessageContainer>
          <StyledMessageFooter>
            <div>
              {formatDate(
                notification.createdAt,
                { dateFormat, timeFormat, startTimezone, endTimezone },
                startTimezone,
              )}
            </div>
            <StyledActionsContainer>
              {notification.isRead && <StyledAction onClick={onUnreadClick}>Mark as Unread</StyledAction>}
              <StyledAction onClick={onTrashClick} data-title="Clear">
                <StyledIcon name="trash" />
              </StyledAction>
            </StyledActionsContainer>
          </StyledMessageFooter>
        </StyledNotificationMessageContainer>
      )}
    </>
  );
};

export default NotificationMessage;
