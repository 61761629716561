export interface User {
  id: number;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  company?: string;
  role?: UserRole;
  active?: boolean;
  notifications?: string[];
  isLocked?:boolean;
}

export enum UserRole {
  SysAdmin = 'sysadmin',
  Admin = 'admin',
  Creator = 'creator',
  Trainee = 'trainee',
  Viewer = 'viewer',
}

export const UserRoleRecord: Record<UserRole, string> = {
  [UserRole.SysAdmin]: 'System Admin',
  [UserRole.Admin]: 'Admin',
  [UserRole.Creator]: 'Creator',
  [UserRole.Trainee]: 'Trainee',
  [UserRole.Viewer]: 'Viewer',
};

export enum UserNotificationType {
  NoRedemptions = 'noRedemptions',
  CampaignErrors = 'campaignErrors',
  CampaignRequireApproval = 'campaignApprovalRequired',
  ProductUpdate = 'productUpdate',
  LocationUpdate = 'locationUpdate',
}

export const UserNotificationRecord: Record<UserNotificationType, string> = {
  [UserNotificationType.NoRedemptions]: 'No Redemptions Notification',
  [UserNotificationType.CampaignErrors]: 'Campaign Error Notification',
  [UserNotificationType.CampaignRequireApproval]: 'Campaign Requires Approval Notification',
  [UserNotificationType.ProductUpdate]: 'Product Update Notification',
  [UserNotificationType.LocationUpdate]: 'Location Update Notification',
};

export enum AuthType {
  GAS = 'gas',
  VCE = 'vce',
}

export interface UserNameNotification {
  firstName: string;
  lastName: string;
}
