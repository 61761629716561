import { gql } from '@apollo/client';

export const dashbaoardGqls = {
  queries: {
    getDashboardOverview: gql`
      query {
        getDashboardOverview {
          campaigns
          alerts
          offers
          updates
          redemptions
        }
      }
    `,
  },
};
