import styled from '@emotion/styled';
import { Icon } from 'components/shared/icon';

export const IconContainer = styled.div`
  display: flex;
`;

export const StyledIcon = styled(Icon)`
  width: 12.25px;
  fill: ${({ theme }) => theme.colors.text.secondary};
`;
