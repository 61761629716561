import styled from '@emotion/styled';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';

export const StyledImpactList = styled.div<StyledWithThemeProps>`
  display: flex;
  flex-direction: column;
`;

export const StyledTitleSection = styled.div<StyledWithThemeProps>`
  width: 100%;
  padding-bottom: ${typography.unit * 2}px;
`;

export const StyledContainer = styled.div<StyledWithThemeProps>`
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  border-radius: ${typography.borderRadius}px;
`;

export const StyledFilterSection = styled.div<StyledWithThemeProps>`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.global.border};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${typography.unit * 2.5}px ${typography.unit * 4}px;
`;

export const StyledTitle = styled.h4<StyledWithThemeProps>`
  font-family: ${typography.primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: ${typography.fontSize}px;
  line-height: 20px;
  text-transform: capitalize;
  margin: 0;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledImpactCounter = styled.h6<StyledWithThemeProps>`
  font-family: ${typography.primaryFont};
  font-style: normal;
  font-weight: normal;
  font-size: ${typography.fontSize}px;
  line-height: 20px;
  margin: 0;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledTableContainer = styled.div`
  height: 234px;
  overflow-y: auto;
  overflow-x: hidden;
  .MuiDataGrid-root {
    border: 0;
  }
  .MuiDataGrid-main {
    .MuiDataGrid-columnHeaders {
      border-top: 0 !important;
      overflow: initial;
    }

    .MuiDataGrid-columnHeaderTitleContainer,
    .MuiDataGrid-columnHeaderTitle {
      font-size: 12px;
      padding: 0 !important;
      white-space: initial;
      line-height: 20px;
      text-align: center;
    }

    .MuiDataGrid-window {
      overflow-x: hidden;
    }

    .MuiDataGrid-cell {
      padding: 0 ${typography.unit * 2}px !important;
      font-size: 12px;
      justify-content: start;
    }

    .MuiDataGrid-iconButtonContainer {
      display: none;
    }
  }
`;
