import styled from '@emotion/styled';
import typography from 'styles/typography';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { StyledCheckboxGroupProps } from 'components/shared/checkboxGroup/CheckboxGroup.consts';
import Error from 'components/shared/error/Error';

export const StyledContainer = styled.div<StyledCheckboxGroupProps>`
  display: flex;
  flex-direction: ${({ labelIsHorizontal }) => labelIsHorizontal && 'column'};
`;

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

export const CheckboxGroupContainer = styled.div<StyledCheckboxGroupProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ direction }) => (direction === 'row' ? 'center' : 'flex-start')};
`;

export const StyledCheckbox = styled(Checkbox)<StyledCheckboxGroupProps>`
  margin-right: ${({ direction }) => direction === 'row' && `${typography.unit * 5}px`};
  margin-bottom: ${({ direction }) => direction === 'column' && `${typography.unit}px`};
`;

export const StyledLabel = styled.label<StyledCheckboxGroupProps>`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text.primary};
  display: flex;
  align-items: start;
  margin-right: ${({ labelIsHorizontal }) => !labelIsHorizontal && `${typography.unit * 3}px`};
  margin-bottom: ${({ labelIsHorizontal }) => labelIsHorizontal && `${typography.unit}px`};
  white-space: nowrap;
`;

export const StyledError = styled(Error)`
  position: absolute;
  white-space: nowrap;
  bottom: -${typography.unit * 4}px;
`;
