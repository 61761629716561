import styled from '@emotion/styled';
import typography from 'styles/typography';
import { Icon, IconProps } from 'components/shared/icon';
import StatusLabel from 'components/shared/statusLabel/StatusLabel';
import { StyledWithThemeProps } from 'utils/types';

export const SubtitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledStatusLabel = styled(StatusLabel)`
  margin-right: ${typography.unit * 2}px;
`;

export const IconTooltipWrapper = styled.div`
  display: flex;
`;

export const IconBase = styled(Icon)<IconProps>`
  margin-right: ${typography.unit * 2}px;
  cursor: pointer;
`;

export const AlertIcon = styled(IconBase)<IconProps>`
  width: 15px;
  fill: ${({ theme }) => theme.colors.global.error};
`;

export const CommentIcon = styled(IconBase)<IconProps>`
  width: 16px;
  fill: ${({ theme }) => theme.colors.global.inputPrimary};
`;

export const StyledSeparator = styled.div<StyledWithThemeProps>`
  height: 18px;
  width: 0;
  margin: 0 ${typography.unit * 2}px;
  border: 1px solid ${({ theme }) => theme.colors.global.border};
`;

export const StyledAdditionalStatusIcon = styled(IconBase)`
  width: 15px;
`;

export const StyledAdditionalStatusLabel = styled.div`
  display: flex;
`;

export const TriggerEventIcon = styled(Icon)<IconProps>`
  margin-right: ${typography.unit}px;
  width: 14px;
`;
