import styled from '@emotion/styled';
import { Icon, IconProps } from 'components/shared/icon';
import { ButtonOutlined } from 'components/shared/button';
import { defaultTheme } from 'styles/themes/defaultTheme';

export const StyledCopyButton = styled(ButtonOutlined)`
  height: 20px;
  width: 20px;
  padding: 1px;
  border: none;
`;

export const StyledClipboard = styled(Icon)<IconProps>`
  fill: ${defaultTheme.colors.global.inputPrimary};
  width: 14px;
`;
