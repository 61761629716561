import styled from '@emotion/styled';
import typography from 'styles/typography';
import { Icon, IconProps } from 'components/shared/icon';
import Table from 'components/shared/table/Table';
import { StyledWithThemeProps } from 'utils/types';

// region Campaigns
export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${typography.unit * 4}px;
`;

export const StyledTable = styled(Table)`
  .MuiDataGrid-row {
    cursor: pointer;
  }
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: ${typography.unit * 4}px; ;
`;

export const StyledBulkIcon = styled(Icon)<StyledWithThemeProps>`
  width: 20px;
  margin-right: ${typography.unit * 3}px;
  fill: ${({ theme }) => theme.colors.global.inputPrimary};
`;

// endregion

// region Campaigns table
export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const StyledStatusLabel = styled.div`
  display: flex;
`;

export const StyledLabelIcon = styled(Icon)`
  margin-left: ${typography.unit * 2}px;
`;

export const StyledAlertIcon = styled(Icon)<IconProps>`
  width: 16px;
  fill: ${({ theme }) => theme.colors.global.error};
  margin-left: ${typography.unit * 2}px;
`;

export const AlertIconContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const StyledTotalAmountContainer = styled.div`
  display: flex;
  gap: ${typography.unit * 3}px;
`;
// endregion
